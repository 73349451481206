<template lang="pug">
  include /mixins
  +b.SECTION.section--main(ref='content')
    +e.el(ref='componentFocusTarget')
    +b.personal-wrapper
      +e.nav(:class='{"is-passive": !show}')
        ui-sidebar(:collapsed='!show' ref='sidebar')
          template(v-slot='')
            v-button(@click='show = !show' variant='clear')
              icon(
                type='menu' 
                size='sm'
                color='brand-3'
              )
      +e.content
        transition(:name='transitionName' mode='out-in')
          router-view(:vendors='vendors'
                      :user='user'
                      :key='$route.params.id'
                      :role='role').child-view

</template>
<script>
import UiSidebar from '@cabinet/components/personal/UiSidebar'
import { onMounted } from '@vue/composition-api'
import useEvent from '@cabinet/composables/use-event'
function setPadding(context, root) {
  const content = root.$el
  const { footer } = root.$refs
  const { clientHeight: footerHeight } = footer
  if (window.innerWidth <= 992) {
    const { sidebar: { $el: sidebar } } = context.refs
    const { clientHeight: sidebarHeight } = sidebar
    content.style.paddingBottom = `${footerHeight + sidebarHeight}px`
    footer.style.bottom = `${sidebarHeight}px`
  } else {
    content.style.paddingBottom = `${footerHeight}px`
    footer.style.bottom = `0px`
  }
}
export default {
  setup(props, context) {
    onMounted(() => {
      setPadding(context, context.root)

    })
    useEvent(window, 'orientationchange', () => {
      setPadding(context, context.root)
    })
    useEvent(window, 'resize', () => {
      setPadding(context, context.root)
    })
  },
  computed: {
    vendors() {
      let { user, ...rest } = this.$props
      return rest
    }
  },
  props: {
    user: {
      default: false
    },
    role: {
      default: false
    }
  },
  components: {
    UiSidebar
  },
  
  data() {
    return {
      transitionName: 'slide-left',
      show: true
    }
  },
  methods: {
    // windowCheck() {
    //   if (location.pathname != '/account/') {
    //     if (window.innerWidth > 1200) {
    //       this.show = true
    //     } else {
    //       this.show = false
    //     }
    //   }
    // }
  }, 
  created() {
    if (this.user === 'AnonymousUser') {
      window.location = '/'
    }
    // this.windowCheck()
    // window.addEventListener('resize', this.windowCheck)
    
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setPadding)
  },
  beforeRouteUpdate(to, from, next) {
    let toDepth = to.meta.order
    let fromDepth = from.meta.order
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    //(this.transitionName)
    next()
  }
}
</script>

