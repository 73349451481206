import Vue from 'vue'
import { router } from '@cabinet/router'
import { mask } from 'vue-the-mask'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'
import { preloaderService } from '@cabinet/providers/preloader'
import wysiwyg from 'vue-wysiwyg'
import store from '@cabinet/store'
import { mapGetters } from 'vuex'
import install from '@cabinet/components/forms/blocks'
import FnPl from '@vue/composition-api'
import VueTag from '@aspectus/vue-tag'
import '@cabinet/filters'
import installComponents from '@cabinet/components'
import installAtoms from '@cabinet/components/atoms'
import '@cabinet/validations'

import './styles/index.sass'
import { usePadding } from '@cabinet/composables/use-padding'
import UiCount from '@cabinet/components/vendor/UiCount'
import UiCountSimple from '@cabinet/components/vendor/UiCountSimple'
import VueObserveVisibility from 'vue-observe-visibility'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.devtools = true

// import { RecycleScroller } from 'vue-virtual-scroller'

// Vue.component('RecycleScroller', RecycleScroller)

// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

Vue.use(store)
Vue.use(VueTag)
Vue.use(FnPl)
Vue.use(install)
Vue.use(installComponents)
Vue.use(installAtoms)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAd3m2XO1jSqzhWlvbMsdkZGUvn2MM7zag',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    language: "en",
  },
})

Vue.use(VueI18n)
Vue.use(VueObserveVisibility)

const messages = {
  current: window.django.catalog || {},
}
export const i18n = new VueI18n({
  locale: 'current',
  messages,
  silentTranslationWarn: true,
})

Vue.prototype._ = function () {
  return this.$t.apply(this, arguments)
}

window.$app = {}
window.$app.dispatcher = new Vue()

Vue.use(wysiwyg, {
  hideModules: {
    image: true, table: true, code: true
  },
})

window.router = router

Vue.directive('mask', mask)

window.addEventListener('load', () => {
  document.getElementById('loading').classList.add('is-disabled')
})

window.app = new Vue({
  components: {
    UiCount,
    UiCountSimple,
  },
  provide: {
    preloaderService,
  },
  i18n,
  router,
  store,
  delimiters: ['[[', ']]'],
  el: '#app',
  computed: {
    ...mapGetters('cartModule', ['getTime']),
  },
  setup(props, context) {
    usePadding(context.root)
  },
  data() {
    return {
      item: [],
      loading: true,
      loading: '/static/images/loading.svg',
      transitionName: 'slide-right',
      showError: false,
    }
  },
  methods: {
    openRegister(index, props = {}) {

      this.$refs.header.openModal("UiRegistration", null, {
        index,
        ...props
      })
    },
  },
  mounted() {

    // TODO: uncomment on production stage
    // let seal = this.$refs.seal
    // if (seal) {
    //   let script = document.createElement('script')
    //   script.setAttribute(
    //     'src',
    //     'https://seal.godaddy.com/getSeal?sealID=biYiW1ywvAmg5B1op7JXtgmkLTftzO5yImM1q67tPWybM2x2lgRkAqVRX16Q'
    //   )
    //   script.setAttribute('async', true)
    //   seal.appendChild(script)
    // }
    // TODO: uncomment on production stage
    window.onload = () => {
      
      this.loading = false
      // let preloader = document.querySelector('.preloader')
      // preloader.classList.add('is-passive')
      if (this.$route.path.includes('account')) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      
    }
  },

  watch: {
    $route(to, from) {
      const toDepth = to.meta.order
      const fromDepth = from.meta.order
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.routerView) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }
        }, 350)
      })
    },
  },
})

