<template lang="pug">
include /mixins
  //- .form__expander
+b.cell.cols--12(v-if='visibility || someSelected')
  card(:variant='["white", "tour"]')
    card-panel(offset='full')
      form.form
        card(v-if='visibility || someSelected', offset='bottom')
          card-panel
            row(offset)
              cell(cols='12')
                v-select(
                  v-model='tagsModel',
                  :val='tagsModel',
                  :options='tags',
                  size='lg',
                  bg='relief-3',
                  :placeholder='_("Selected tags")',
                  :taggable='true',
                  :multiple='true',
                  :allow-empty='true',
                  @remove='removeTag',
                  :close-on-select='true',
                  name='tags'
                )
              template(v-if='visibility')
                cell(cols='4')
                  v-select(
                    v-model='form.country',
                    :val='form.country',
                    :options='countries',
                    :searchable='true'
                    size='lg',
                    bg='relief-3',
                    :placeholder='_("Country")',
                    @input='fetchStates(form.country.id)',
                    :name='"country"'
                  )
                cell(cols='4')
                  v-select(
                    v-model='form.subdivision',
                    :val='form.subdivision',
                    :placeholder='_("State")',
                    :options='states',
                    :searchable='true'
                    size='lg',
                    bg='relief-3',
                    :disabled='!form.country',
                    @input='fetchCities(form.subdivision.id)',
                    :name='"state"'
                  )
                cell(cols='4')
                  v-select(
                    v-model='form.city',
                    :val='form.city',
                    :placeholder='_("City")',
                    :options='cities',
                    :searchable='true'
                    :disabled='!form.subdivision',
                    size='lg',
                    bg='relief-3',
                    :name='"city"'
                  )
                cell(cols='4')
                  v-select(
                    v-model='form.status',
                    :val='form.status',
                    :placeholder='_("Status")',
                    :options='getHallStatuses',
                    size='lg',
                    bg='relief-3',
                    :name='"status"'
                  )

                cell(cols='12')
                  row(offset, space='2xl', justification='center')
                    cell(cols='narrow')
                      v-button(
                        @click.prevent='resetFilter',
                        :variant='["clear"]',
                        hover='danger'
                      )
                        template(v-slot:prepend='')
                          cell
                            v-button-element 
                              icon(
                                name='refresh',
                                color='warning',
                                size='xl'
                              )
                        cell
                          v-button-element 
                            v-caption(color='warning') {{ _("Reset filter") }}
</template>

<script>
import filterMixin from './FilterMixin'
import { mapGetters, mapActions } from 'vuex'
export default {
  mixins: [filterMixin],
  // props: {
  //   visibility: {
  //     default: true
  //   },
  //   data: {
  //     required: true,
  //   }
  // },
  computed: {
  //   ...mapState('vendorsModule', [
  //     'countries',
  //     'states',
  //     'cities',
  //   ]),
    ...mapGetters('hallModule', [
      'getHallStatuses'
    ]),

  },
  // watch: {
  //   form: {
  //     deep: true,
  //     handler(val) {
  //       this.send()
  //       let items = Object.keys(val).filter(item => val[item]).map(key => {
  //         if (val[key]) {
  //           return {
  //             title: val[key].title,
  //             id: ID(),
  //             type: key
  //           }
  //         }
  //       })
  //       if (items.length) {
  //         this.someSelected = true
  //       } else {
  //         this.someSelected = false
  //       }
  //       this.addTags(items)
  //     }
  //   }
  // },
  data() {
    return {
      form: {
        country: '',
        subdivision: '',
        city: '',
        status: '',
      },
    }
  },
  methods: {
  //   ...mapActions('vendorsModule', [
  //     'fetchCountries',
  //     'fetchStates',
  //     'fetchCities'
  //   ]),
    ...mapActions('hallModule', [
      'getStatuses'
    ]),
  //   addTags(items) {

  //     this.tags = items
  //     this.tagsModel = items
  //   },
  //   removeTag(item) {
  //     this.form[item.type] = ''
  //   },
  //   send() {
  //     const data = Object.entries(this.form).reduce((acc, [key, value]) => {
  //       if (value.id) {
  //         acc[key] = value.id
  //       }
  //       return acc
  //     }, {})
  //     this.$emit('input', data)
  //   },
  //   resetFields(...args) {
  //     args.forEach(arg => this.form[arg] = '')
  //   },
  //   resetFilter() {
  //     Object.keys(this.form).forEach(e => this.form[e] = '')
  //     this.send()
  //   }
  },
  created() {
    this.getStatuses()
  }
}
</script>
