<template lang="pug">
  include /mixins
  +b.modal-wrapper--bg-contrast
    +e.close(@click='$emit("close")') 
      icon(type='close')


    card
      card-panel
        +b.FORM.form
          row(offset)
            cell(cols='12')
              +b.UI-TABS.tabs.mt-1(ref='tab')
                template(v-slot='{ activeItem,activate: activateTab }')
                  +b.tab-list
                    +e.body
                      +e.toggle--variant_default.tab-list__item(
                        @click='activate(index);activateTab(index)'
                        v-for='(item, index) in stepsList'
                      )
                        v-caption(
                          :color="activeItem === index ? 'default' : 'brand-5' "
                        ) {{ item.name }}
    
      card(variant='section' stacked='top')
        card-panel(offset='vertical')
          loading-wrapper(:loading='loading' )
            row(offset) 
              cell(cols='12')
                card(:variant='["offset"]')
                  card-panel(offset='horizontal')
                    transition-group(name='fade')
                      validation-observer(
                        v-if='stepsList[0].active'
                        key='1'
                        ref='observer'
                        autocomplete='off'
                        v-slot='{invalid}'
                        tag='form'
                      )
                        card
                          card-panel
                            row(offset)
                              cell(cols='12')
                                v-caption(size='3' color='default' variant='bold') {{ _('t Tickets manipulations')}}
                            card-panel
                              row(offset justification='center')
                                cell(cols='12')
                                  +b.scrollable-wrapper--vertical.--half
                                    card
                                      card-panel(v-if='form')
                                        validation-observer(
                                          ref='observer'
                                          v-slot='{invalid}'
                                          tag='form'
                                          autocomplete='off'
                                          @submit.prevent='save'
                                        )
                                          tickets-layout(
                                            v-for='(ticket, index) in form.price_descriptor'
                                            :ticket='ticket'
                                            :key='index'
                                            :form='form'
                                            :sectors='sectors'
                                            :index='index'
                                            :observer='$refs.observer'
                                            :descriptors='descriptors'
                                            :withdraw='true'
                                          )
                                cell(cols='narrow')
                                  v-button(
                                    @click='save'
                                    type='button'
                                    variant='success'
                                  )
                                    cell(cols='narrow')
                                      v-button-element() 
                                        v-caption(
                                          size='4' 
                                          :variant='["bold"]'
                                          color='text-1'
                                        ) {{ _('withdraw') }}
                      ui-show-withdraw-list(
                        key='2'
                        v-else-if='stepsList[1].active'
                        :list='list'
                        :show-id='id'
                        @fetch='fetchList'
                      )



</template>

<script>

import ShowService from '@cabinet/services/api/shows'
// import HelpersService from '@cabinet/services/api/helpers'
// import HallService from '@cabinet/services/api/hall'
import { useResponse } from '@cabinet/composables/use-response'
import TicketsLayout from '@cabinet/components/show/TicketsLayout'
import UiShowWithdrawList from '@cabinet/components/show/modals/withdraw/UiShowWithdrawList'
import UiTabs from '@cabinet/components/vendor/UiTabs'

/**
 * @typedef {Object} ReducedAmount
 * @property {Array} seats - количество сидений
 * @property {Array} sectors - количество билетов в секторах (га)
 */

/**
 * Собирает данные о количестве мест и секторов 
 * @returns {ReducedAmount}
 *         Количество билетов и сидений списаных по всем дескрипторам
 * */ 
function reduceDescriptorData() {
  return this.form.price_descriptor.reduce((acc, d) => {
    if (d.seats && d.seats.length) {
      acc.seats.push(...d.seats)
    }
    if (d.info.filter(s => s.amount).length) {
      acc.sectors.push(...d.info
        .filter(s => s.amount)
        .map(s => ({id: s.descriptor.sectors[0].descriptor_id, amount: s.amount}))
      )
    }
    return acc
  }, {
    seats: [],
    sectors: []    
  })
}
export default {
  props: {
    id: {
      required: true
    },
    sectors: {
      required: true,
    },
    
    descriptors: {
      type: Array,
      required: true
    }
  },
  components: {
    TicketsLayout,
    UiShowWithdrawList,
    UiTabs,
  },
  computed: {
    transitionName() {
      return this.stepsList.findIndex(s => s.active) ? 'slide-right' : 'slide-left'
    }
  },
  data() {
    return {
      stepsList: [
        {
          name: this._('t Withdraw'),
          active: true
        },
        {
          name: this._('t Refund'),
          active: false
        },
      ],
      loading: false,

      form: {

        price_descriptor: [{
          info: [
            {
              sector: [],
              row: [],
              seats: [],
            },
          ],
        }]
      },
      list: [],
    }
  },
  methods: {
    /**
     * сохранение списаний и очистка полей если запрос упешный
     * */ 
    async save() {

      let descriptorData = reduceDescriptorData.bind(this)()
      
      if (!(descriptorData.seats.length + descriptorData.sectors.length)) {
        return
      }
      try {
        await useResponse(
          ShowService.createWithdraw({
            id: this.id,
            data: descriptorData
          }), this, this.$refs.observer)
        
        await this.fetchList()
        this.form.price_descriptor = [{
          info: [
            {
              sector: [],
              row: [],
              seats: [],
            },
          ],
        }]
      } catch (err) {
        console.error(err)
      }
    },
    async fetchList() {
      this.loading = true
      this.list = (await ShowService.getWithdrawList(this.id)).data.data
      this.loading = false
    },
    async activate(index) {
      this.stepsList.forEach(s => s.active = false)
      if (index) {
        await this.fetchList()
      } 
      this.stepsList[index].active = true
    },
  },
  async created() {
    this.loading = true
    
    // let promises = this.sectors.map(async ({id}) => (await HallService.getSectorData({ id, showId: this.id})).data.data)
    // this.sectors_ = await Promise.all(promises)
    this.loading = false
    
  }
}
</script>