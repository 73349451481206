<template lang="pug">
  include /mixins
  card
    card-panel(offset='horizontal')
      row(
        offset 
        alignment='center'
      )
        cell(cols='12')
          row(offset alignment='center')
            cell(cols='narrow')
              +b.avatar--size_xl
                +e.IMG.image(
                  :src='user.avatar || "/static/images/avatar.svg"'
                  v-model='user.avatar'
                  name='profile avatar'
                  @click='toggleShow'
                )
            cell(cols='narrow' v-if='user.username != userData_.email')
              v-caption(
                size='6' 
                color='white'
                variant='regular'
              ) {{ user.username }}
        cell(cols='12' v-if='!userData_.is_manager && !userData_.is_staff')
          row(offset)
            cell(cols='12')
              v-caption(
                size='3' 
                color='brand-6'
              ) {{ userData_.profile.phone || userData_.profile.billing_address.phone }}
            cell(cols='12')
              v-caption(
                size='6' 
                color='white'
              ) {{ userData_.email }}

</template>

<script>
import { mapActions, mapState } from 'vuex'
import Cropper from '@cabinet/components/vendor/Cropper'
export default {
  computed: {
    ...mapState('userModule', [
      'userData'
    ])
  },

  data() {
    return {
      userData_: {
        city: null
      },
      user: {
        username: '',
        avatar: '',
      },
    }
  },
  methods: {
    ...mapActions('userModule', ['updateData', 'patchData']),
    updatePhoto(event) {
      this.imageRow = []
      let file = event.target.files[0]
      this.imageRow.push({
        preview: window.URL.createObjectURL(file),
        image: file,
      })
      let user = new user()

      this.imageRow.forEach((element, index) => {
        user = new user()
        user.append('image', element.image)
        UserService.updateUserInfo(user).then(
          res => {
            this.$store.commit('setUserInfo', res.data)
          },
          err => {}
        )
      })
    },
    promise() {
      return arg => {
        return new Promise((resolve, reject) => {
          if (arg) {
            resolve(arg)
          } else {
            reject('error')
          }
        })
          .then(res => {
            return this.patchData({
              profile: {
                avatar: res,
              },
            }).then(res => {
              
              this.user.avatar = res.data.profile.avatar
            })
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    toggleShow() {
      this.$modal.show(
        Cropper,
        {
          promise: this.promise(),
        },
        {
          height: 'auto',
          classes: 'loadPopup',
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  created() {
    this.user.username = this.userData.username
    this.user.avatar =
        this.userData.profile && this.userData.profile.avatar
          ? this.userData.profile.avatar
          : ''
    this.userData_ = { ...this.userData }
    // this.getData().then(res => {
      
    // })
  },
}
</script>

