<template lang="pug">
  include /mixins  
  +b.control-box(v-on='listeners')
    +b.COMPONENT(:is='component'
                 v-bind='$attrs'
                 :class='$blockClasses').control-button
      +e.element
        slot
</template>


<script>
import BemComponentMixin from '@cabinet/components/utils/bem/BemComponentMixin.js'
export default {
  mixins: [BemComponentMixin],
  inheritAttrs: false,
  bem: {
    block: 'i-control-button',
    modifierProps: ['variant', 'hover', 'border', 'shadow', 'type']
  },
  props: {
    variant: {
      type: [String, Array],
      default: ''
    },
    hover: {
      type: [String, Array],
      default: ''
    },
    border: {
      type: [String, Array],
      default: ''
    },
    shadow: {
      default: false,
      type: Boolean
    },
    type: {
      type: [String, Array],
      default: '',
    }
  },
  computed: {
    component() {
      return !this.attrs.type ? 'button' : 'router-link'
    },
    listeners() {
      return {
        ...this.$listeners,
        input: ($event) => {
          this.$emit('click')
        }
      }
    }

  }
}
</script>
