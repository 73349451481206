import { extend } from 'vee-validate'

import {
  required,
  email,
  min_value,
  max_value,
  min,
  max,
  length,
  alpha,
  alpha_spaces,
  regex,
  image,
} from 'vee-validate/dist/rules'
import { rules } from './utils'


extend('image', {
  ...image,
})

extend('min_value', {
  ...min_value,
})

extend('max_value', {
  ...max_value,
})

extend('regex', {
  ...regex,
})

extend('alpha_spaces', {
  ...alpha_spaces,
})

extend('alpha', {
  ...alpha,
})

extend('required', {
  ...required,
  message() {
    return `This field is required`
  }
})

extend('length', {
  ...length,
  message() {
    return `This field is not valid`
  }
})

extend('url', {
  validate(value) {
    if (value) {
      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/.test(value)
    }

    return false
  },
  message: 'This value must be a valid URL',
})

extend('min', {
  ...min,
  message(value, sec) {
    return `This field length should be not lower then ${sec.length}`
  }
})

extend('max', {
  ...max,
  message(value, sec) {
    return `This field length should be not greater then ${sec.length}`
  }
})

extend('email', {
  ...email,
  message() {
    return `Invalid email`
  }
})


extend('custom_password', {
  validate: value => {
    const strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])")
    return strongRegex.test(value)
  },
  message() {
    return `Password is too simple`
  }
})


extend('credit', {
  validate: value => {
    value = value.split(' ').join('')
    return rules.visaRegEx.test(value) ||
      rules.mastercardRegEx.test(value) ||
      rules.amexpRegEx.test(value) ||
      rules.discovRegEx.test(value)
  },
  message: field => `The ${field} value is not valid.`
})

extend('date', {
  validate: value => {
    let val = value.split('/')

    if (val[0] > 12) {
      return false
    }
    let newDate = new Date()
    newDate.setMonth(parseInt(val[0]) - 1)
    newDate.setYear(`20${val[1]}`)

    let d = new Date()
    let m = d.getMonth()
    d.setMonth(d.getMonth() + 1)
    if (d.getMonth() == m) d.setDate(0)
    d.setHours(0, 0, 0)
    d.setMilliseconds(0)
    return newDate / 1000 > d / 1000
  },
  message: field => `The ${field} value is not valid.`
})


extend('timeNotLower', {
  getMessage(field, val) {
    return self._('Must be greater than or equal to the start time')
  },
  validate(value, field) {
    let sign = self.form.locale ? ':' : ':'
    let startTime = self.form.start_at_time.split(sign)
    let endTime = value.split(sign)
    return startTime <= endTime
  }
})
extend('dateNotLower', {
  getMessage(field, val) {
    return self._('Must be greater than or equal to the start date')
  },
  validate(value, field) {
    let sign = self.form.locale ? '.' : '/'
    let startParts = self.form.start_at_date.split(sign)
    let endParts = value.split(sign)
    if (self.form.locale) {
      startParts = [startParts[2], startParts[1] - 1, startParts[0]]
      endParts = [endParts[2], endParts[1] - 1, endParts[0]]
    } else {
      startParts = [startParts[2], startParts[0] - 1, startParts[1]]
      endParts = [endParts[2], endParts[0] - 1, endParts[1]]
    }
    let start = new Date(...startParts) // month is 0-based
    let end = new Date(...endParts)
    return end >= start
  }
})
