
<script>
  import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
  const bem = {
    block: 'card',
    modifierProps: ['variant', 'offset', 'stacked', 'space']
  }
  export { default as CardPanel } from './Card.Panel'
  export { default as CardLabel } from './Card.Label'
  export default {

    functional: true,
    render(createElement, props) {
      const $blockClasses = useClassesComposition(props.props, bem)
      return createElement(
        props.props.tag,
        { ...props.data, ...props.listeners, class: ['card', $blockClasses, props.data.staticClass]},
        props.children
      )
    },
    props: {
      variant: {
        type: [Array, String]
      },
      offset: {
        type: [Array, String]
      },
      tag: {
        default: 'div'
      },
      stacked: {
        default: '',
        type: String
      },
      space: {
        type: [Array, String]
      }
    }
  }
</script>