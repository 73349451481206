<template lang="pug">
  include /mixins
  +b.SECTION.section--main(ref='content')
    +e.el(ref='componentFocusTarget')
    //- +b.personal-wrapper
    //-   //- cell(cols='12')
    //-   //-   row(offset :fullheight='true')
    //-   //-     cell(cols='12')
    card
      card-panel(offset='full')
        row(
          offset 
          column 
          alignment='center'
        )
          cell(cols='12')
            row(offset fullheight justification='center')
              cell(cols='12')
                v-caption(
                  size='1' 
                  :variant='["bold", "center"]'
                ) 404
              cell(cols='12')
                v-caption(
                  size='2' 
                  :variant='["bold", "center"]'
                ) {{ _('page not found') }}
              cell(cols='narrow' v-if='link && !userData')
                v-button(
                  tag='router-link'
                  :to='link'
                  :variant='["clear", "narrow"]' 
                  alignment='center'
                )
                  template(v-slot:prepend='')
                    cell
                      v-button-element()
                        icon(
                          type='left-arrow' 
                          color='default'
                          size='xs'
                        )
                  cell(cols='auto')
                    v-button-element
                      v-caption(
                        variant='bold' 
                        size='4'
                        color='default'
                      ) {{ _('ag back') }}


            
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('userModule', ['userData']),
    prefix() {
      return this.$route.params.provider
    },
    link() {
      return this.prefix ? `/${this.prefix}/auth/` : ''
    }
  },
}
</script>