<template lang="pug">
  include /mixins
  .modal-wrapper(style='width: 100%; ')
    .modal-wrapper__close.btn.btn--flex.btn--center(
      v-if="!hideCloseBtn"
      @click="$emit('close')"
    )
      icon(type='close' color='danger')
    .row.row--column.row--center
      .row__el
        h2(style='text-align: center') {{title}}
      .row__el
        card(
          offset='top'
        )
          div(style='text-align: center; white-space: pre-line;' v-html="content")
      +b.row.--center.--between(v-if='status === "no_paid"' style='width: 100%')
        +e.el(style='width: 100%')
          +b.btn-wrapper--auto
            +b.btn--center.--flex(@click='activate(redirect)'
                                  :class='[btnStyle.bg,  btnStyle.color]')
              span(:class='className')
    card(
      v-if='button' 
      offset='top'
    )
      row(offset)
        cell(cols='12')
          v-button(
            :variant='["success", "block"]' 
            @click='activate(redirect)'
            type='button'
            border='whole'
          )
            v-button-element(size='lg')
                v-caption(
                  :variant='["uppercase", "bold"]'
                ) {{ buttonText }}
  </div>          
</template>

<script>
export default {
  data() {
    return {
      className: 'icon-check_2'
    }
  },
  computed: {
    btnStyle() {
      let bg = this.className == 'icon-checkbox' ? 'btn--blue' : 'btn--success'
      let color = 'btn--white'
      return {
        bg,
        color
      }
    }
  },
  props: {
    buttonText: {
      required: false
    },
    button: {
      required: false,
    },
    redirect: {
      required: false,
    },
    title: {
      required: true
    },
    content: {
      required: true
    },
    status: {
      required: false
    },
    hideCloseBtn: {
      default: false,
    }

  },
  methods: {
    activate(url) {
      if (url) {
        this.setClassName('icon-checkbox')
        setTimeout(() => {
          this.makeRedirect(url)
        }, 500)
      } else {
        this.$emit('close')
      }
    },
    makeRedirect(url) {
      window.location = url
    },
    setClassName(name) {
      this.className = name
    }
  },
}
</script>

