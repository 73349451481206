import partial from 'ramda/src/partial'
import { create } from 'string-format'

export const ELEMENT_VALUE_MODIFIER_TEMPLATE = partial(create({}), ['{element}--{modifier}_{value}'])
export const ELEMENT_BOOL_MODIFIER_TEMPLATE = partial(create({}), ['{element}--{modifier}'])
export const ELEMENT_STATE_TEMPLATE = partial(create({}), ['is-{value}'])
export function ELEMENT_MODIFIER_TEMPLATE(data) {
  if (data.value == false) return false

  if (data.value === true) {
    return ELEMENT_BOOL_MODIFIER_TEMPLATE(data)
  }

  return ELEMENT_VALUE_MODIFIER_TEMPLATE(data)
}

export function constructModifier(
    element, modifier, val, template = ELEMENT_MODIFIER_TEMPLATE
) {
  const values = !Array.isArray(val) ? [val] : val

  return values.map(value => template({
    element, modifier, value
  })).filter(x => !!x)
}

export function elementModifiers(element, modifiers) {
  return Object.keys(modifiers)
    .filter(x => !!modifiers[x])
    .map(
      modifier => constructModifier(element, modifier, modifiers[modifier])
    )
}

export function elementStates(states) {
  return states.map(value => ELEMENT_STATE_TEMPLATE({ value }))
}
