import HallService from '@cabinet/services/api/hall'

export default {
  namespaced: true,
  state: {
    sectors: [],
    // defaultData: {},
    halls: [],
    hall: {},
    pagination: {},
    statuses: [],
  },
  getters: {
    getHallStatuses(state) {
      return state.statuses.map(s => ({title: s[1], id: s[0]}))
    },
  },
  actions: {
    async fetchHall({ commit }, id) {
      let result = (await HallService.fetchHall(id)).data
      commit('SET_HALL', result.data)
      return result.data
    },
    async getSectorsList({ commit }, id) {
      return (await HallService.getSectorsList(id)).data
    },
    async getSectorsListWithShow({ commit }, id) {
      return (await HallService.getSectorsListWithShow(id)).data
    },
    async deleteHall({ commit }, id) {
      return (await HallService.deleteHall(id))
    },
    async getStatuses({commit}) {
      let result = (await HallService.getStatuses()).data.statuses
      commit('SET_STATUSES', result)
    },
    async fetchHalls({ commit }, query) {
      let result = (await HallService.fetch(query)).data
      commit('SET_LOCATIONS', result)
    },
    async createSector({ commit }, data) {
      let result = await HallService.createSector(data)
      commit('SET_VALUES', result)
      return result
    },
    async putSector({ commit }, data) {
      let result = await HallService.putSector(data)
      commit('SET_VALUES', result)
      return result
    },
    async postBaseData({ commit }, data) {
      // let result = await 
      // commit('SET_BASE_DATA', result)
      return HallService.createHall(data)
    },
    async updateHall({ commit }, data) {
      return HallService.updateHall(data)
    },
    async getSectorData({ commit }, data) {
      let result = await HallService.getSectorData(data)
      return result
    },
    async getPureSectorData({ commit }, data) {
      let result = await HallService.getPureSectorData(data)
      return result
    },
    async deleteSector({ commit }, data) {
      let result = await HallService.deleteSector(data)
      return result
    },
    async deleteRow({ commit }, data) {
      let result = await HallService.deleteRow(data)
      return result
    }
  },
  mutations: {
    SET_HALL(state, payload) {
      state.hall = payload
    },
    SET_VALUES(state, payload) {
      state.sectors = payload
    },
    SET_BASE_DATA(state, payload) {
      state.defaultData = payload
    },
    SET_LOCATIONS(state, payload) {
      state.halls = payload.data
      state.pagination = payload.meta.pagination
    },
    REMOVE_HALL(state, payload) {
      let hall = state.halls.map(h => h.id).indexOf(payload)
      state.halls.splice(hall, 1)
    },
    SET_STATUSES(state, payload) {
      state.statuses = payload
    }
  }
}

