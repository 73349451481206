<template lang="pug">
  include /mixins
  +b.HEADER.header
    +e.block--flex(:class='blockClass')
      slot(name='logo')
      slot(name='page-title')
      slot(name='counter')
      cell(justification='right').flex-shrink-0
        card
          card-panel(
            :offset='["horizontal_xs-md",  "horizontal_md-xl"]'
          )
            row(offset alignment='center' :space='["md", "xl-md"]')
              slot(
                name='cart'
                :count='reducedLength'
                :goto='goTo'
              )
              transition(name='slide')
                +e.menu--left(v-if='menuActive' :class='"cell-xl"')
                  row(column='till-xl' justification='between' alignment='center')
                    slot(name='menu' :openModal='openModal' :logout='logout')
              
              cell(v-if='!isAccount').is-hidden-xl
                .header__toggler-wrapper
                  +e.toggler(@click.prevent='toggleMenu')
                    icon(:type='menuActive ? "close" : "menu"' size='xl')
</template>

<script>
import { headerMixin } from '@cabinet/components/header'
import { mapActions } from 'vuex'
export default {
  props: {
    blockClass: {
      default: ''
    }
  },
  mixins: [
    headerMixin
  ],
  methods: {
    ...mapActions('cartModule', ['fetchMainCart'])
  },
  mounted() {
    this.fetchMainCart()
  },
}
</script>