<template lang="pug">
  card()
    card-panel(offset='full' :variant='["bordered", "border"]')
      card-label(:position='["top", "right"]' )
        v-button(
          @click='$emit("remove", fee)'
          variant='clear'
        )
          cell
            v-button-element(size='xs')
              icon(type='close' color='warning' size='xs')
      card-label(:position='["bottom", "right"]' )
        v-button(
          variant='noborder' 
          :border='["top_left", "bottom_right"]'
          @click='$emit("input")'
        )
          cell
            v-button-element(size='lg')
              v-caption(
                size='5' 
                :variant='["bold", "uppercase"]'
              ) {{ _('ff edit') }}
      row(offset)
        cell(cols='12')
          row(offset) 
            cell(cols='narrow')
              row(offset)
                cell(cols='narrow')
                  v-caption(
                    size='5' 
                    variant='bold'
                  ) {{ index }}
            cell(cols='narrow')
              row(offset)
                cell(cols='narrow')
                  v-caption(
                    size='5' 
                    variant='bold'
                  ) {{ fee.title }}
            cell(cols='12')
              row(offset)
                cell(cols='narrow')
                  row(offset space='5xs')
                    cell(cols='narrow')
                      v-caption(size='5') {{ _('ff type') }}
                    cell(cols='narrow')
                      v-caption(
                        size='5' 
                        variant='bold'
                      ) {{ fee.type }}
                cell(cols='narrow')
                  row(offset space='5xs')
                    cell(cols='narrow')
                      v-caption(size='5') {{ _('ff value') }}
                    cell(cols='narrow')
                      v-caption(
                        size='5' 
                        variant='bold'
                      ) {{ fee.price }}

        cell(cols='9')
          v-caption(size='6') {{ fee.description }}
</template>

<script>
export default {
  props: {
    fee: {
      required: true
    },
    index: {
      required: true
    } 
  }
}
</script>