<template lang="pug">
  include /mixins
  card
    card-panel(offset='full')
      row() 
        cell(cols='12 narrow-md')
          .form__control
            label.form__label 
              flat-pickr(
                v-model='dateToFilter'
                :val='dateToFilter'
                :config='config'
                name='date'
                :placeholder="_('Date from - Date to')"
              ).flatpickr-input--size_lg.flatpickr-input--border_full
        cell(cols='12 8-md 7-lg 6-xl 5-2xl')
          ui-search(
            @input='fetchOrders({ ...$event, created_at__date__gte, created_at__date__lte })'
            @open='visible = !visible'
          )
            template(v-slot:activator='{ data, send }')
              cell(cols='12 narrow-md')
                v-button(
                  size='lg'
                  :border='["top_right", "bottom_right"]'
                  @click='send({ ...data, created_at__date__gte, created_at__date__lte })'
                  variant='success'
                )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ _('apply') }}
            template(v-slot:additional-filters='{ data, send }')
              ui-filter(
                ref='filter'
                :data='data'
                @input='send({...data, ...$event, created_at__date__gte, created_at__date__lte})'
                users
                :visibility='visible'
              )
        //- stats-filter(
        //-   :meta='meta'
        //-   @input='send' 
        //-   @fetch='fetchOrders({ ...$event, created_at__date__gte, created_at__date__lte })'
        //- )
          template(v-slot:prepend='')
          

    card-panel(offset='horizontal')
      //- .back
      //-   h5.flex.flex--start.no-padding
      //-     +b.btn-wrapper.--auto
      //-       +b.ROUTER-LINK.btn--flex(to='/account/')
      //-         +e.SPAN.icon--warning.--default
      //-           .icon-back
      //-         +e.SPAN.text--default {{ _('Back to root') }}
      loading-wrapper(:loading='loading')
        card(space='md' v-if='orders.length' v-cloak)
          card-panel
            +b.scrollable-wrapper--till-2xl.--full()
              +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
                +e.THEAD.thead
                  +e.TR.row--relief
                    +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom 
                      v-caption(size='6' variant='center' color='white') {{ _('Order №') }}
                    +e.TH.cell--variant_bold.--head 
                      v-caption(size='6' variant='center' color='white') {{ _('Date') }}
                    +e.TH.cell--variant_bold.--head 
                      v-caption(size='6' variant='center' color='white') {{ _('Name') }}
                    +e.TH.cell--variant_bold.--head
                      v-caption(size='6' variant='center' color='white') {{ _('Vendor Name') }}
                    +e.TH.cell--variant_bold.--head 
                      v-caption(size='6' variant='center' color='white') {{ _('Show name') }}
                    +e.TH.cell--variant_bold.--head 
                      v-caption(size='6' variant='center' color='white') {{ _('Cost') }}
                    +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom 
                      v-caption(size='6' variant='center' color='white') {{ _('Status') }}
                +e.TBODY.tbody
                  +e.TR.row(
                    v-for='(order, index) in orders'
                  )
                    +e.TD.cell.--bordered_left_top.--bordered_left_bottom
                      router-link.link(
                        :to='{name: "order-details", params: { id: order.id }}'
                        @click='navigateTo(order.id)'
                      )
                        v-caption(
                          size='6'
                          :variant='["center", "bold"]'
                        ) {{ order.id }}
                    +e.TD.cell 
                      router-link.link(
                        :to='{name: "order-details", params: { id: order.id }}'
                        @click='navigateTo(order.id)'
                      )
                        v-caption(
                          size='6'
                          :variant='["center", "bold"]'
                        )  {{ date(order.created_at) }}
                        
                    +e.TD.cell 
                      router-link.link(
                        :to='{name: "order-details", params: { id: order.id }}'
                        @click='navigateTo(order.id)'
                      )
                        v-caption(
                          size='6'
                          :variant='["center", "bold"]'
                        ) {{ order.first_name }} {{ order.last_name }} 

                    +e.TD.cell
                      router-link.link(
                        :to='{name: "order-details", params: { id: order.id }}'
                        @click='navigateTo(order.id)'
                      )
                        v-caption(
                          size='6'
                          :variant='["center", "bold"]'
                        ) {{ order.vendor ? order.vendor.first_name + " " +  order.vendor.last_name : ""}} 

                    +e.TD.cell 
                      router-link.link(
                        :to='{name: "order-details", params: { id: order.id }}'
                        @click='navigateTo(order.id)'
                      )
                        row(justification='center' alignment='center') 
                          cell()
                            v-caption(
                              size='6'
                              :variant='["center", "bold"]'
                            ) {{ order.shows[0] }} 
                          cell()
                            icon(
                              type='right_arrow' 
                              v-if='order.shows.length > 1' 
                              :title='shows(order).replace(/,/g, "")'
                            )
                    +e.TD.cell 
                      router-link.link(
                        :to='{name: "order-details", params: { id: order.id }}'
                        @click='navigateTo(order.id)'
                      )
                        v-caption(
                          size='6'
                          :variant='["center", "bold"]'
                        ) $ {{ order.price | toUSD }} 
                    +e.TD.cell.--bordered_right_top.--bordered_right_bottom 
                      v-caption(
                        size='6' 
                        :variant='["center", "bold"]'
                      ) {{ order.is_refunded ?  _("refunded") : order.status }}
          card-panel(offset='top')
            ui-pagination(
              :offset='meta.offset'
              :total='meta.total'
              :limit='meta.limit'
              @input='fetchOrders({ ...search, page: $event })'
            )

        notice(v-if="!orders.length && !loading" v-cloak)
          template(v-slot:icon='')
            icon(
              type='error' 
              color='warning' 
              size='5xl'
            )
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('No orders found')}}

</template>

<script>
import { /* onMounted, */ ref } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import usePagination from '@cabinet/composables/use-pagination'
import OrdersService from '@cabinet/services/api/orders'

import VendorsService from '@cabinet/services/api/vendors'
import { useDateWatcher } from '@cabinet/composables/use-date-watcher'
import flatPickr from 'vue-flatpickr-component'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import UiSearch from '@cabinet/components/forms/UiSearch'
import UiFilter from '@cabinet/components/forms/UiFilter'
export default { 
  components: {
    
    flatPickr,
    UiSearch,
    UiPagination,
    UiFilter
  },
  data() {
    return {
      visible: false,
    }
  },
  
  setup(props, context) {
    
    const orders = ref([])
    const meta = ref({})
    const loading = ref(false)
    const dateToFilter = ref('')
    const search = ref('')
    const orderPaginationCount = Number(window.ORDER_PAGINATION_COUNT)
    
    const config = ref({
      mode: 'range',
      disableMobile: 'true',
      dateFormat: 'm/d/Y',
    })

    const [created_at__date__gte, created_at__date__lte] = useDateWatcher(dateToFilter)

    const fetchOrders = async (data) => {
      let fetchData = OrdersService.fetchProviderList
      if (context.root.$store.state.userModule.userData.is_vendor) {
        fetchData = VendorsService.fetchVendorOrders
      } 
      search.value = data
      loading.value = true
      try {
        let res = await useResponse(fetchData({...data, limit: orderPaginationCount }), context.parent)
        orders.value = res.data
        meta.value = usePagination(res.meta.pagination)
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
      }
    }

    const shows = (td) => {
      return `${td.shows.map(i => i + '\n')}`
    }

    const date = (date) => {
      date = new Date(date)
      let d = new Date(date.valueOf() - date.getTimezoneOffset() * 60000)
      return d.toLocaleString('en-US', {timeZone: 'America/New_York', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})
    }

    
    // onMounted(() => {
    //   // fetchOrders()
    // })
    return {
      orders,
      meta,
      loading,
      dateToFilter,
      config,
      created_at__date__gte,
      created_at__date__lte,
      shows,
      date,
      fetchOrders,
      search,
    }
  },
  // methods: {
  //   
  //   ...mapActions('ordersModule', [
  //     'fetchProviderList'
  //   ]),
  //   fetchOrders(data) {
  //     this.loading = true      
  //     return this.fetchProviderList(data)
  //       .then(res => {
  //         //('from catch', res)
  //         this.loading = false
  //         return res
  //       })
  //       .catch(err => {
  //       })
  //   },
  //   navigateTo(id) {
  //     this.$router.push({
  //       name: 'order-details',
  //       params: {
  //         id
  //       }
  //     })
  //   },
    
  // },
  // created() {
  //   this.fetchOrders()
  // },
  
}
</script>

