<template lang="pug">
include /mixins
loading-wrapper(:loading='loading')
  validation-observer(
    ref='observer',
    v-slot='{ invalid }',
    tag='form',
    @submit.prevent='submit'
  )
    card(variant='tour')
      card-panel(offset='full')
        card-panel(offset='bottom', v-if='shipments.length')
          row(offset)
            cell(cols='12')
              v-caption(size='4', variant='bold') {{ _("Choose delivery method:") }}

            cell(cols='12')
              ValidationProvider(
                v-slot='{ errors }',
                name='shipment',
                rules='required'
              )
                row(offset, space='sm', alignment='center')
                  cell(v-for='method in shipments', :key='method.id')
                    row(alignment='center', space='md', offset)
                      cell
                        .control-input.control-input--variant_radio
                          row(offset, space='5xs')
                            cell(cols='12')
                              row(
                                offset,
                                alignment='center',
                                space='5xs',
                                nowrap
                              )
                                cell(cols='narrow')
                                  +b.LABEL.control-radio
                                    +b.INPUT.control-radio__element(
                                      type='radio',
                                      v-model='formData.shipment',
                                      :value='method.id'
                                    )
                                    +e.label
                                cell(cols='auto')
                                  v-caption(size='6') {{ method.title }}
                  cell(cols='12', v-if='errors[0]')
                    .form__error {{ errors[0] }}

        card-panel(offset='bottom', v-if='payments.length')
          row(offset)
            cell(cols='12')
              v-caption(size='4', variant='bold') {{ _("Choose pay method:") }}
            cell(cols='12')
              ValidationProvider(
                v-slot='{ errors }',
                name='payment',
                rules='required'
              )
                row(offset, space='sm', alignment='center')
                  cell(v-for='method in payments', :key='method.title')
                    row(alignment='center', space='md', offset)
                      cell
                        .control-input.control-input--variant_radio
                          row(offset, space='5xs')
                            cell(cols='12')
                              row(
                                offset,
                                alignment='center',
                                space='5xs',
                                nowrap
                              )
                                cell(cols='narrow')
                                  +b.LABEL.control-radio
                                    +b.INPUT.control-radio__element(
                                      type='radio',
                                      v-model='formData.payment',
                                      :value='method.id'
                                    )
                                    +e.label
                                cell(cols='auto')
                                  v-caption(size='6') {{ method.label }}
                                img(
                                  v-if='method.id === "apple-id"'
                                  src="/static/images/apple-pay.svg"
                                  style="width: 70px; margin-left: 10px"
                                  )
                  cell(cols='12', v-if='errors[0]')
                    .form__error {{ errors[0] }}
        card-panel(offset='bottom')
          row(offset)
            cell(cols='6 4-md 6-lg 4-xl')
              ui-input(
                :placeholder='_("c Email")',
                type='email',
                name='email',
                v-model='formData.email',
                bg='relief-3',
                size='lg',
                border='whole',
                validate='required'
              )
            cell(cols='6 4-md 6-lg 4-xl')
              ui-input(
                :placeholder='_("c Phone number")',
                type='tel',
                name='phone',
                v-model='formData.phone',
                bg='relief-3',
                size='lg',
                v-mask='["(###) ###-####"]',
                border='whole',
                validate='required'
              )
              p(style="font-size: 12px; color: #192440; padding-left: 12px") {{ _("xxx-xxx-xxxx") }}
        card-panel(offset='bottom')
          row(offset)
            cell(v-if='canActivate', cols='12')
              card-panel(offset='bottom')
                v-caption(size='4', variant='bold') {{ _("c Delivery address:") }}
              row(offset)
                cell(cols='6 4-md 6-lg')
                  ui-input(
                    :placeholder='_("First name*")',
                    :val='formData.delivery_address.first_name',
                    type='search',
                    name='delivery address first name',
                    bg='relief-3',
                    size='lg',
                    border='whole',
                    v-model='formData.delivery_address.first_name',
                    validate='required|alpha_spaces'
                  )
                cell(cols='6 4-md 6-lg')
                  ui-input(
                    :placeholder='_("Last name*")',
                    :val='formData.delivery_address.last_name',
                    type='search',
                    name='delivery address last name',
                    bg='relief-3',
                    size='lg',
                    border='whole',
                    v-model='formData.delivery_address.last_name',
                    validate='required|alpha_spaces'
                  )
                //- cell(cols='6 4-md 6-lg 4-xl')
                //-   ui-input(
                //-     v-model='formData.delivery_address.city',
                //-     :val='formData.delivery_address.city',
                //-     :placeholder='_("City *")',
                //-     name='delivery address city',
                //-     type='search',
                //-     validate='required',
                //-     bg='relief-3',
                //-     size='lg',
                //-     border='whole'
                //-   )
                cell(cols='12 10-md 8-lg 10-xl')
                  validation-provider(
                    rules="required"
                    v-slot="{ errors }"
                    name="deliveryAddress"
                  )
                    multiselect.autocomplete-select(
                      v-model="deliveryAddress",
                      label="description",
                      track-by="place_id",
                      :placeholder='_("Address, state, country *")',
                      :options="searchResults",
                      :allow-empty="false"
                      :multiple="false",
                      :searchable="true",
                      :options-limit="1",
                      :show-no-results="false",
                      :hide-selected="false",
                      name="deliveryAddress"
                      @search-change="autocompleteInput($event)"
                      @select="select($event, 'delivery_address')"
                    )
                    p.control-hint.control-hint--styling_default.control-hint--variant_error(v-if="errors.length > 0")
                      span(v-for="error in errors") {{ error }}
                    //- p {{ formData.delivery_address.address_line_1 }}
                  validation-provider(
                    rules="required"
                    v-slot="{ errors }"
                    :name="input"
                    :key='`hidden_input_${input}`'
                    v-for="input in hiddenInputs"
                  )
                    input(type="hidden" :name="input" v-model="formData.delivery_address[input]")
                    .control-hint.control-hint--styling_default.control-hint--variant_error(v-if="errors.length > 0")
                      span(v-for="error in errors") {{ input }} {{ _('required') }}

                  //- ui-input(
                  //-   :placeholder='_("Address *")',
                  //-   :val='formData.delivery_address.address_line_1',
                  //-   type='search',
                  //-   name='delivery address address',
                  //-   v-model='formData.delivery_address.address_line_1',
                  //-   validate='required',
                  //-   bg='relief-3',
                  //-   size='lg',
                  //-   border='whole'
                  //- )
                cell(cols='12 2-md 4-lg 2-xl')
                  ui-input(
                    :placeholder='_("Unit #")',
                    :val='formData.delivery_address.unit',
                    type='search',
                    name='delivery address unit',
                    v-model='formData.delivery_address.unit',
                    validate='',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                //- cell(cols='6 4-md 6-lg 4-xl')
                //-   ui-input(
                //-     v-model='formData.delivery_address.subdivision',
                //-     :val='formData.delivery_address.subdivision',
                //-     :placeholder='_("State/Province *")',
                //-     name='delivery address state',
                //-     type='search',
                //-     validate='required',
                //-     bg='relief-3',
                //-     size='lg',
                //-     border='whole'
                //-   )
                //- cell(cols='6 4-md 6-lg 4-xl')
                //-   ui-input(
                //-     :placeholder='_("Postal Code *")',
                //-     :val='formData.delivery_address.zipcode',
                //-     name='delivery address zipcode',
                //-     type='search',
                //-     v-model='formData.delivery_address.zipcode',
                //-     validate='required',
                //-     bg='relief-3',
                //-     size='lg',
                //-     border='whole'
                //-   )
                //- cell(cols='6 4-md 6-lg 4-xl')
                //-   ui-input(
                //-     v-model='formData.delivery_address.country',
                //-     :val='formData.delivery_address.country',
                //-     :placeholder='_("Country *")',
                //-     name='delivery address country',
                //-     type='search',
                //-     validate='required',
                //-     bg='relief-3',
                //-     size='lg',
                //-     border='whole'
                //-   )
            cell(cols='12 6-md 12-lg')
              row(offset)
                cell(cols='12')
                  ui-input(
                    :placeholder='_("Comment")',
                    type='textarea',
                    size='lg',
                    name='comment',
                    :val='formData.comment',
                    bg='relief-3',
                    border='whole',
                    v-model='formData.comment',
                    validate=''
                  )
                cell(cols='12')
                  row(offset, alignment='center')
                    cell(cols='12 6-sm 12-md 6-xl')
                      checkbox(
                        name='agree',
                        :rules='{ required: { allowFalse: false } }'
                      )
                        template(v-slot='')
                          +b.INPUT.control-checkbox__input(
                            type='checkbox',
                            v-model='agree',
                            size='lg',
                            border='whole',
                            :value='agree'
                          )
                        template(v-slot:text='')
                          v-caption(size='5') {{ _("I agree with ") }}
                            a(
                              :href='order.rules_link',
                              target='_blank',
                              style='text-decoration: underline'
                            ) {{ _("site rules *") }}
                    cell(cols='12 6-sm 12-md 6-xl')
                      v-button(
                        type='submit',
                        variant='success',
                        border='whole'
                      )
                        cell.cols(justification='center')
                          v-button-element(size='lg')
                            v-caption(size='4', variant='bold') {{ _("Go to payment") }}
</template>

<script>
import OrdersService from '@cabinet/services/api/orders'
import Checkbox from '@cabinet/components/forms/blocks/Checkbox'
import { mapState } from 'vuex'
import { errorHandler } from '@cabinet/util'

import { isEmpty, mergeLeft } from 'ramda'

// import { checkPayment } from '@cabinet/components/forms/blocks/checkStripePaument'
import AutocompleteMixin from './../../../mixin/AutocompleteMixin'
import UiSelect from '@cabinet/components/forms/blocks/UiSelect'
import UiCreditCardView from '@cabinet/components/forms/UiCreditCardView'
export default {
  components: {
    UiCreditCardView,
    Checkbox,
    UiSelect,
  },
  mixins: [ AutocompleteMixin ],
  props: {
    order: {
      required: true
    },
    dataInfo: {
      type: Object
    },
  },
  computed: {
    ...mapState('userModule', ['userData']),
    isPayment() {
      let payment = this.payments.find(p => p.id === this.formData.payment)
      return payment && payment.title
    },
    isNotDelivery() {
      let item = this.payments.find(i => i.id == this.formData.payment)
      return item && item.title === 'cash'
    },
    canActivate() {
      let item = this.shipments.find(i => i.id == this.formData.shipment)
      return item && item.is_delivery_field
    },
    token() {
      if (isEmpty(this.formData.payment)) {
        return null
      }
      return this.payments.find(i => i.id == this.formData.payment).public_key
    },
    stripeMethod() {
      return this.payments.filter(i => i.title === 'stripe')[0]
    }
  },
  watch: {
    'formData.shipment': {
      handler(val) {
        const shipment = this.shipments.find(p => p.id == val)
        if (shipment) {
          this.$emit('shipment', shipment.price)
        } else {

          this.$emit('shipment', 0)
        }
      }
    },
    'formData.payment': {
      handler(val) {
        const cash = this.payments.find(p => p.title === 'cash')
        const complimentary = this.payments.find(p => p.title === 'complimentary')
        if (cash && val === cash.id || complimentary && val === complimentary.id) {
          this.$emit('cash-selected', true)
        } else {
          this.$emit('cash-selected', false)
        }
      }
    }
  },
  data() {
    return {
      shipments: [],
      payments: [],
      loading: false,
      invisible: true,
      invisibleCaptcha: false,
      agree: false,
      formData: {
        is_applepay: false,
        shipment: '',
        payment: '',
        delivery_address: {
          country: '',
          subdivision: '',
          city: '',
          address_line_1: '',
          address_line_2: "",
          unit: "",
          zipcode: "",
          first_name: "",
          last_name: "",
          route: '',
          streetNumber: '',
        },
        email: '',
        phone: '',
        comment: '',
        tz: ''
      },
      deliveryAddress: '',
      /*to validate existance before concatenation*/
      hiddenInputs: ['city', 'subdivision', 'country', 'zipcode', 'route', 'streetNumber'],
    }
  },
  methods: {
    async submit() {
      // await store.dispatch('ordersModule/createOrder', this.cartInfo.id)
      if (!this.invisibleCaptcha) {

        this.$refs.observer.validate().then(result => {

          if (result) {
            this.formData.tz = new Date().getTimezoneOffset() / 60
            /*if address from autocomplete*/
            if (this.deliveryAddress.place_id) this.formatAddress()
            this.loading = true
            let form = this.transform(JSON.parse(JSON.stringify(this.formData)))

            OrdersService.update(form)
              .then(({ data }) => {
                console.log(data)
                this.loading = false
                this.$emit('set-info', { pk: this.token, payment: this.isPayment, token: this.token, clientSecret: data.data.client_secret, isApple: data.data.is_applepay })
                this.formData.is_applepay = false
                this.$refs.observer.reset()
              })
              .catch(err => {
                this.loading = false
                console.error(err)
                let { data: errors } = err.response
                errorHandler(errors.errors, this.$refs.observer, err.response, this)
              })
          }
        })
      }
    },
    transform(data) {
      let form = { ...data }
      if (this.formData.payment === 'apple-id') {
        form.is_applepay = true
        form.payment = this.stripeMethod.id
      }
      if (!this.canActivate) {
        delete form.delivery_address
      }
      return form
    },
    createApplePayment() {
      const paymentMethod = {
        label: 'ApplePay',
        title: 'apple',
        id: 'apple-id'
      }
      const applePayment = mergeLeft(paymentMethod, this.stripeMethod)
      this.payments.push(applePayment)
    },
    formatAddress() {
      this.formData.delivery_address.address_line_1 = `${this.formData.delivery_address.streetNumber} ${this.formData.delivery_address.route}`
      delete this.formData.delivery_address.route
      delete this.formData.delivery_address.streetNumber
    }
  },
  async mounted() {
    try {
      this.shipments = (await OrdersService.getShipments()).data.data
    } catch (err) {
      console.error(err)
    }
    try {
      this.payments = (await OrdersService.getPayments()).data.data
    } catch (err) {
      console.error(err)
    }

    // const resultPayement = await checkPayment({ pk: this.stripeMethod.public_key })
    // if (resultPayement) {
    //   this.createApplePayment()
    // }

    if (this.userData) {
      if (this.userData.profile) {
        this.formData.first_name = this.userData.profile.first_name
        this.formData.last_name = this.userData.profile.last_name

        if (this.userData.profile.delivery_address) {
          this.formData.phone = this.userData.profile.delivery_address.phone
        } else if (this.userData.profile.billing_address) {
          this.formData.phone = this.userData.profile.billing_address.phone
        } else {
          this.formData.phone = this.userData.profile.phone
        }
        this.formData.email = this.userData.email
      }
    }
  }
}
</script>
