<template lang="pug">
  include /mixins

  card
    card-panel(offset='horizontal')
      card-panel
        v-button(
          tag='router-link'
          to='/account/orders/'
          :variant='["clear", "narrow"]'
          alignment='center'
        )
          template(v-slot:prepend='')
            cell
              v-button-element()
                icon(
                  type='left-arrow'
                  color='default'
                  size='xs'
                )
          cell(cols='auto')
            v-button-element
              v-caption(
                variant='bold'
                size='6'
                color='default'
              ) {{ _('ac back') }}
      card-panel(offset='bottom')
        card(variant='white')
          card-panel(offset='full')
            row(offset)
              cell(cols='12')
                +b.scrollable-wrapper--till-2xl.--full
                  +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
                    +e.THEAD.thead
                      +e.TR.row--relief
                        +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom(colspan='4')
                          v-caption(size='5' color='white') {{ _('Order:') }} {{ providerOrder.number }}
                        +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom(width='20%')
                          v-caption(size='5' color='white') {{ date }}
                    +e.TBODY.tbody
                      +e.TR.row
                        +e.TD.cell.--bordered_left_top.--bordered_left_bottom(colspan='4')
                          row(offset alignment='center' :space='["sm", "2xl-lg"]')
                            cell(cols='narrow')
                              v-caption(size='5' variant='bold') {{ providerOrder.first_name }} {{ providerOrder.last_name }}
                            cell(cols='narrow')
                              v-caption(size='5' variant='regular') {{ providerOrder.email }}
                            cell(cols='narrow')
                              v-caption(size='5' variant='regular') {{ providerOrder.phone }}
                            cell(cols='narrow 2-md')
                              //- v-select(
                              //-   v-model='providerOrder.status'
                              //-   :val='providerOrder.status'
                              //-   :options='getStatuses'
                              //-   size='lg'
                              //-   bg='relief-3'
                              //-   :style='{"border": "1px solid " + (updated ? "green" : "transparent")}'
                              //-   @input='changeStatus($event)'
                              //-   name='status'
                              //- )
                        +e.TD.cell
                          v-caption(size='5' variant='regular') $ {{ price | toUSD }}
                      +e.TR.row
                        +e.TD.cell.--bordered_left_top.--bordered_left_bottom(colspan='4')
                          row(offset)
                            cell(cols='12 6-md')
                              row(offset)
                                cell(cols='narrow')
                                  v-caption(size='5' variant='bold') {{ _('Pay method: ') }}
                                cell(cols='narrow')
                                  v-caption(size='5') {{ providerOrder.payment_method }}
                            cell(cols='12 6-md')
                              row(offset)
                                cell(cols='narrow')
                                  v-caption(size='5' variant='bold') {{ _('Delivery method: ') }}
                                cell(cols='narrow')
                                  v-caption(size='5') {{ providerOrder.delivery_method || ''}}
                        +e.TD.cell
                      +e.TR.row
                        +e.TD.cell.--bordered_left_top.--bordered_left_bottom(colspan='5')
                            row(offset)
                              cell(cols='12 9-lg')
                                card-panel(offset='bottom')
                                  row(offset)
                                    cell(cols='12'  v-if='providerOrder.delivery_address')
                                      row(offset)
                                        cell(cols='12')
                                          row(offset)
                                            cell(cols='12')
                                              v-caption(size='5' variant='bold') {{ _('Delivery address: ') }}
                                            cell(cols='6 5-md')
                                              ui-input(
                                                :placeholder="_('Address *')"
                                                :val='providerOrder.delivery_address.address_line_1'
                                                type='search'
                                                name='delivery address address'
                                                v-model='providerOrder.delivery_address.address_line_1'
                                                validate='required'
                                                size='lg'
                                                bg='relief-3'
                                                border='whole'
                                                :disabled='disabled'
                                              )
                                            cell(cols='6 2-md')
                                              ui-input(
                                                :placeholder="_('Unit #')"
                                                :val='providerOrder.delivery_address.unit'
                                                type='search'
                                                name='delivery address unit'
                                                v-model='providerOrder.delivery_address.unit'
                                                validate=''
                                                size='lg'
                                                bg='relief-3'
                                                border='whole'
                                                :disabled='disabled'
                                              )
                                        cell(cols='12')
                                          row(offset)
                                            cell(cols='12 6-sm 3-lg')
                                              ui-input(
                                                v-model='providerOrder.delivery_address.city'
                                                :val='providerOrder.delivery_address.city'
                                                :placeholder="_('City *')"
                                                name='delivery address city'
                                                type='search'
                                                validate='required'
                                                size='lg'
                                                bg='relief-3'
                                                border='whole'
                                                :disabled='disabled'
                                              )
                                            cell(cols='12 6-sm 3-lg')
                                              ui-input(
                                                v-model='providerOrder.delivery_address.subdivision'
                                                :val='providerOrder.delivery_address.subdivision'
                                                :placeholder="_('State/Province *')"
                                                name='delivery address state'
                                                type='search'
                                                validate='required'
                                                size='lg'
                                                bg='relief-3'
                                                border='whole'
                                                :disabled='disabled'
                                              )

                                            cell(cols='12 6-sm 3-lg')
                                              ui-input(
                                                :placeholder="_('Postal Code *')"
                                                :val='providerOrder.delivery_address.zipcode'
                                                name='delivery address postal code'
                                                type='search'
                                                v-model='providerOrder.delivery_address.zipcode'
                                                validate='required'
                                                size='lg'
                                                bg='relief-3'
                                                border='whole'
                                                :disabled='disabled'
                                              )

                                            cell(cols='12 6-sm 3-lg')
                                              ui-input(
                                                v-model='providerOrder.delivery_address.country'
                                                :val='providerOrder.delivery_address.country'
                                                :placeholder="_('Country *')"
                                                name='delivery address country'
                                                type='search'
                                                validate='required'
                                                size='lg'
                                                bg='relief-3'
                                                border='whole'
                                                :disabled='disabled'
                                              )

                                            cell(
                                              cols='12'

                                            )
                                              row(
                                                offset
                                                alignment='center'
                                              )
                                                cell(cols='narrow 4-md 3-xl 2-2xl')
                                                  v-button(
                                                    border='whole'
                                                    @click='disabled = !disabled'
                                                  )
                                                    v-button-element(size='lg')
                                                      v-caption(
                                                        size='6'
                                                        :variant='["bold", "uppercase"]'
                                                      ) {{_('o edit') }}

                                                cell(cols='narrow 4-md 3-xl 2-2xl')
                                                  v-button(
                                                    @click='save'
                                                    :variant='["success-lite"]'
                                                    border='whole'
                                                    :disabled='disabled'
                                                  )
                                                    v-button-element(size='lg')
                                                      v-caption(
                                                        size='6'
                                                        :variant='["bold", "uppercase"]'
                                                      ) {{_('o save') }}
                                                cell(cols='narrow')
                                                  v-button(
                                                    @click='print'
                                                    :variant='["clear", "narrow"]'
                                                  )
                                                    cell(alignment='center')
                                                      v-button-element
                                                        v-caption(
                                                          size='6'
                                                          color='default'
                                                          variant='regular'

                                                        )  {{_('o Print envelope') }}
                                                    template(v-slot:prepend='')
                                                      cell(alignment='center')
                                                        v-button-element
                                                          icon(type='print-3' color='default' size='xl')


                              cell(cols='12' v-if='providerOrder.comment')
                                card-panel(offset='bottom')
                                  row(offset)
                                    cell(cols='12')
                                      v-caption(size='5' variant='bold') {{ _('Comment:')}}
                                    cell(cols='12')
                                      v-caption(size='5') {{ providerOrder.comment }}
                              cell(cols='12')
                                card-panel(offset='bottom')
                                  row(offset)
                                    transition(name='fade')
                                      cell(
                                        cols='narrow'
                                        v-if='ticketsData.length'
                                      )
                                        v-button(
                                          @click='sendTickets_'
                                          :variant='["clear", "narrow"]'
                                        )
                                          cell(alignment='center')
                                            v-button-element
                                              v-caption(
                                                size='6'
                                                color='default'
                                                variant='regular'
                                              ) {{_('o Send tickets') }}
                                          template(v-slot:prepend='')
                                            cell(alignment='center')
                                              v-button-element
                                                icon(type='paper-plane' color='default' size='xl')

                                    transition(name='fade')
                                      cell(
                                        cols='narrow'
                                        v-if='!isRefunded'
                                      )
                                        v-button(
                                          @click='showModal'
                                          :variant='["clear", "narrow"]'
                                        )
                                          cell(alignment='center')
                                            v-button-element
                                              v-caption(
                                                size='6'
                                                color='default'
                                                variant='regular'
                                            ) {{_('o Refund') }}
                                          template(v-slot:prepend='')
                                            cell(alignment='center')
                                              v-button-element(color='default')
                                                icon(type='share' color='default' size='xl')

                                    transition(name='fade')
                                      cell(
                                        cols='narrow'
                                        v-if='ticketsData.length && permissions.ticket'

                                      )
                                        v-button(
                                          @click='printTickets(ticketsData)'
                                          :variant='["clear", "narrow"]'
                                        )
                                          cell(alignment='center')
                                            v-button-element
                                              v-caption(
                                                size='6'
                                                color='default'
                                                variant='regular'
                                              ) {{_('o Print tickets') }}
                                          template(v-slot:prepend='')
                                            cell(alignment='center')
                                              v-button-element
                                                icon(type='ticket-1' color='default' size='xl')

                                    cell(
                                      cols='narrow'
                                    )
                                      v-button(
                                        @click='printReceipt'
                                        :variant='["clear", "narrow"]'
                                      )
                                        cell(alignment='center')
                                          v-button-element
                                            v-caption(
                                              size='6'
                                              color='default'
                                              variant='regular'
                                            )  {{_('o Print receipt') }}
                                        template(v-slot:prepend='')
                                          cell(alignment='center')
                                            v-button-element
                                              icon(type='bill' color='default' size='xl')



      card-panel
        loading-wrapper(:loading='loading')

          row(offset space='none' v-if='allItems && allItems.length')
            cell(cols='narrow')
              v-checkbox(variant='contrast')
                +b.INPUT(
                  type="checkbox"
                  @input='setAll'
                  v-model='selectAll'
                  name='all_tickets'
              ).control-checkbox__input
            cell(cols='12')
              row(
                offset

              )
                cell(
                  cols='12 6-md'
                  v-for='(ticket, index) in allItems'
                  :key='ticket.id'
                )
                  card-panel(
                    offset='vertical'
                  )
                    ticket(
                      :ticket='ticket'
                    )
                      template(v-slot:label='' v-if='ticket.refund')
                        card-label(:position='["top", "right"]')
                          v-tag(type='warning') {{ _('refunded') }}
                      template(v-slot:action='' v-if='!ticket.refund')
                        card-label(:position='["top", "left"]' offset='full')
                          v-checkbox(variant='contrast')
                            +b.INPUT(
                              type='checkbox'
                              v-model='ticketsData'
                              :value='ticket.id'
                              :name='"ticket" + index'
                            ).control-checkbox__input
</template>

<script>
import { successHandler, errorHandler } from '@cabinet/util'
import { mapState, mapActions, mapGetters } from 'vuex'
import HelpersService from '@cabinet/services/api/helpers'
import OrdersService from '@cabinet/services/api/orders'

import VendorsService from '@cabinet/services/api/vendors'
import Ticket from '@cabinet/components/booking/Ticket'
import UiCommentForm from '@cabinet/components/forms/UiCommentForm'

export default {
  components: {
    Ticket,
  },
  computed: {
    ...mapState('userModule', ['userData']),
    ...mapState('ordersModule', ['providerOrder']),
    ...mapGetters('ordersModule', ['getStatuses']),
    permissions() {
      return this.userData.permissions
    },
    allItems() {
      return this.providerOrder && this.providerOrder.tickets ? this.providerOrder.tickets : []
    },
    price() {
      return (this.userData.is_vendor || this.userData.is_manager) ? this.providerOrder.base_price : this.providerOrder.price
    },
    date() {

      let date = new Date(this.providerOrder.created_at)
      let d = new Date(date.valueOf() - date.getTimezoneOffset() * 60000)
      return d.toLocaleString('en-US', {timeZone: 'America/New_York', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})
    },
    isRefunded() {
      return this.providerOrder.is_refunded
    }
  },
  data() {
    return {

      delivery_predictions: [],
      loading: true,
      selectAll: '',
      ticketsData: [],
      disabled: true,
      orderStatus: '',
      disabled: true,
      updated: false,
    }
  },
  watch: {
    ticketsData(nval, oval) {
      if (nval.length != oval.length && nval.length != this.allItems.length) {
        this.selectAll = false
      }
    },
  },

  methods: {
    ...mapActions('ordersModule', ['refund', 'fetchProviderOrder', 'updateOrderInfo']),
    ...mapActions('ticketsModule', [ 'sendTickets', 'printTickets']),
    ...mapActions('vendorsModule', [
      'fetchCountries',
      'fetchStates',
      'fetchCities',
      'fetchVendors',
    ]),
    print() {
      window.open(`/api/v1/order/envelope/${this.$route.params.id}/?as=pdf`)

    },
    printReceipt() {
      window.open(this.providerOrder.order_check)
    },
    async sendTickets_() {
      this.loading = true
      try {
        let data = (await this.sendTickets(this.ticketsData)).data
        successHandler(data, this)
        this.setAll()
        this.loading = false
      } catch (error) {
        let { errors } = error.response.data
        errorHandler(errors, this)
        this.loading = false
      }
    },
    setValue({ country, subdivision, city, code }, type) {
      // this.formData.billing_address = {
      //   country: country.id,
      //   subdivision: subdivision.id,
      //   city: city.id,
      // }

      this.providerOrder.delivery_address.country = {
        title: country.title,
        id: country.id,
      }
      this.providerOrder.delivery_address.subdivision = {
        title: subdivision.title,
        id: subdivision.id,
      }
      this.providerOrder.delivery_address.city = {
        title: city.title,
        id: city.id,
      }
      this.providerOrder.delivery_address.zipcode = code
      this.predictions = []
      this.delivery_predictions = []
    },
    refundPromise(val, vue) {
      return new Promise((resolve, reject) => {
        if (val) {
          resolve(val)
        }
        reject('Rejected to continue')
      })
        .then(res => {
          return this.refund({
            id: this.$route.params.id,
            data: {
              comment: res.comment,
              price: res.price,
            }
          })
            .then(async res => {
              successHandler(res.data, this)
              await this.fetchProviderOrder(this.$route.params.id)
              this.setAll()
            })
            .then(res => {
              let status = this.getStatuses.find(
                s => s.id === this.providerOrder.status
              )
              this.providerOrder.status = status
              this.providerOrder.delivery_address = this.providerOrder.delivery_address
                .replace(/<br\/>/g, '\n')
                .replace(/(<([^>]+)>)/gi, '')
            })
        })
        .catch(err => {
          let { errors } = err.response.data
          errorHandler(errors, null, err.response, vue)
        })
    },
    async sendRequest(query, type) {
      if (!query) return
      try {
        let data = (await HelpersService.getLocationByZipCode(query)).data
        this[type] = data
      } catch (error) {}
    },
    showModal() {
      this.$modal.show(
        UiCommentForm,
        
        {
          fn: this.refundPromise,
          price: this.price
        },
        {
          width: 360,
          maxWidth: 310,
          adaptive: true,
          scrollable: true,
          height: 'auto',
        },
        {
          'before-close': () => {
            this.selectAll = false
            this.fetchData()
          }
        }
      )
    },
    setAll($event) {
      if (!this.selectAll) {
        this.ticketsData = this.allItems
          .filter(t => t.id && !t.refund)
          .map(i => i.id)
      } else {
        this.ticketsData = []
      }
    },
    focus() {
      this.disabled = !this.disabled
      if (this.disabled) {
        this.changeStatus(this.providerOrder.delivery_address)
        return
      }
      let { input } = this.$refs
      this.$nextTick(() => {
        input.$el.querySelector('textarea').focus()
      }, 200)
    },
    save() {

      let service = OrdersService
      if (this.userData.is_vendor) {
        service = VendorsService
      }
      service
        .updateOrderDelivery({id: this.$route.params.id, data: this.providerOrder.delivery_address})
        .catch(err => {
          console.error(err)
        })
    },
    async fetchData() {
      if (this.userData.is_vendor) {
        const res = await VendorsService.fetchVendorOrder(this.$route.params.id)
        await this.$store.commit('ordersModule/SET_PROVIDE_ORDER', res.data.data)
      } else {
        await this.fetchProviderOrder(this.$route.params.id)
      }
    }
  },
  async mounted() {
    await this.fetchData()

    let status = this.getStatuses.find(s => s.id === this.providerOrder.status)
    this.providerOrder.status = status
    this.providerOrder.delivery_address = this.providerOrder.delivery_address || this.providerOrder.billing_address

    this.loading = false
  },
}
</script>

