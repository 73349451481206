<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      autocomplete='off'
    )

      card-panel
        card-panel(v-if='items.length')
          +b.scrollable-wrapper--till-2xl.--full
            +b.TABLE.table--variant_scroll.--space_lg
              +e.THEAD.thead
                +e.TR.row--relief
                  +e.TH.cell--variant_bold.--head 
                    row(alignment='center' space='md' nowrap)
                      cell
                        v-checkbox
                          +b.INPUT(type="checkbox"
                                  :value='allSelected'
                                  @input='allSelected = !allSelected;selectItems(allSelected)').control-checkbox__input
                      cell
                        v-caption(color='white'
                                  size='6') {{ _('u Name') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('u Active shows') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('u Country') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('u State/province') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('u City') }}
              +e.TBODY.tbody
                +e.TR.row(v-for='(usher, index) in items' :key='usher.id')
                  +e.TD.cell--bordered_left_top.--bordered_left_bottom
                    row(alignment='center' space='md' nowrap)
                      cell
                        v-checkbox(:name='"usher"+index')
                          +b.INPUT(
                            type="checkbox"
                            :value='usher.id'       
                            v-model='selected').control-checkbox__input
                      cell
                        v-caption(
                          size='6'
                          color='default'
                        ) {{ usher.first_name }} {{ usher.last_name}}
                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ usher.shows.length }}
                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ usher.country }}
                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ usher.subdivision }}
                  +e.TD.cell--bordered_right_top.--bordered_right_bottom
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ usher.city }}
          card-panel
            row(justification='center')
              cell(cols='narrow')
                v-button(
                  :variant='["clear", "block"]' 
                  hover='danger'
                  @click='assign'
                  :disabled='!selected.length'
                )
                  v-button-element
                    v-caption(
                      color='warning'
                      :variant='["uppercase", "bold"]'
                    ) {{ _('appoint') }} {{ selected.length ? `${selected.length} usher(s)`: ''}}
        card-panel(v-else)
          notice
            template(v-slot:icon='')
              icon(
                type='error' 
                color='warning' 
                size='5xl'
              )
            template(v-slot:caption='')
              v-caption(variant='bold') {{ _('no ushers in list')}}
</template>

<script>
import UshersService from '@cabinet/services/api/ushers'
import { ref, onMounted } from '@vue/composition-api'
import { useToggleSelect } from '@cabinet/composables/use-selected'
import { useResponse } from '@cabinet/composables/use-response'
import { useSplice } from '@cabinet/composables/use-splice'
export default {
  props: {
    id: {
      required: true
    }
  },
  setup(props, context) {
    // let { id } = props
    const loading = ref(false)
    const allSelected = ref(false)
    const items = ref([])
    const selected = ref([])

    

    const fetchUshers = async () => {
      try {
        items.value = (await UshersService.fetch()).data.data

      } catch (err) {
        console.error(err)
      }
      
    }
      

    const selectItems = value => {
      selected.value = useToggleSelect(items, value)
    }
    onMounted(async () => {
      await fetchUshers()
    })

    const assign = async () => {
      loading.value = true
      try {
        let res = await useResponse(UshersService.addShow({show: props.id, ushers: selected.value}), context.root, context.refs.observer)
        if (res) {
          useSplice(res.data.ushers, items)
          loading.value = false
        } else {
          loading.value = false
        }

      } catch (err) {
        console.error(err)
      }
      
    }

    return {
      loading,
      allSelected,
      items, 
      assign,
      selected,
      selectItems
    }
  }
}
</script>