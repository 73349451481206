import { Service } from './base'
import Loadable from './../mixin/Loadable'

export const PreloaderService = Service.extend({
  // 50 miliseconds is magic. So maybe tweaking this value can take
  // some effect, but not for long.
  offset: 50,

  mixins: [Loadable],

  created() {
    this.$once('loadingFinished', x => {
      this.deferredLoadFinish()
    })
  },

  methods: {
    deferredLoadFinish() {
      // HACK: The problem with probaby all nested loading events is
      // that they might be finished, but others will start at the
      // same time or even after some time.
      setTimeout(x => {
        if (this.isLoading) {
          this.$once('loadingFinished', x => {
            this.deferredLoadFinish()
          })
        } else {
          this.$emit('preloadingFinished')
        }
      }, this.$options.offset)
    },
  }
})

export const preloaderService = new PreloaderService({})

export const PreLoadable = {
  extends: Loadable,
  preloaderService: preloaderService,

  methods: {
    setLoading() {
      Loadable.methods.setLoading.apply(this, arguments)

      this.$options.preloaderService.setLoading()
    },

    unsetLoading() {
      Loadable.methods.unsetLoading.apply(this, arguments)

      this.$options.preloaderService.unsetLoading()
    }
  }
}
