<template lang="pug">
  include /mixins
  card(space='xs')
    card-panel(
      offset='top' 
    )
      slot
    card-panel(
      offset='top' 
    )
      loading-wrapper(:loading='loading')
        +b.scrollable-wrapper--till-2xl.--full
          +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
            +e.THEAD.thead
              +e.TR.row--relief
                +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom()
                  v-caption(color='white') {{ _('d Tour name') }}
                +e.TH.cell--variant_bold.--head.--bordered_right()
                  v-caption(color='white' variant='center') {{ _('d Events') }}
                +e.TH.cell--variant_bold.--head() 
                  v-caption(color='white') {{ _('d Tickets (qty)') }}
                +e.TH.cell--variant_bold.--head() 
                  v-caption(color='white') {{ _('d Amount') }}
                +e.TH.cell--variant_bold.--head() 
                  v-caption(color='white') {{ _('d Sold tickets (qty)') }}
                +e.TH.cell--variant_bold.--head()
                  v-caption(color='white') {{ _('d Sold tickets') }}
                +e.TH.cell--variant_bold.--head()
                  v-caption(color='white') {{ _('d Tickets in sale') }}
                +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom()
                  v-caption(color='white') {{ _('d Scans') }}
            +e.THEAD.thead
              +e.TH.cell--variant_bold.--foot.--bordered_left_top.--bordered_left_bottom()
                v-caption(color='default') {{ _('Total:') }}
              +e.TH.cell--variant_bold.--foot.--bordered_right()
                v-caption(color='default' variant='center') {{ total.shows }}
              +e.TH.cell--variant_bold.--foot() 
                v-caption(color='default') {{ total.seatsAmount }}
              +e.TH.cell--variant_bold.--foot() 
                v-caption(color='default') $ {{ total.totalPrice | toUSD }}
              +e.TH.cell--variant_bold.--foot() 
                v-caption(color='default') {{ total.ticketsAmount }}
              +e.TH.cell--variant_bold.--foot()
                v-caption(color='default') $ {{ total.soldPrice | toUSD }}
              +e.TH.cell--variant_bold.--foot()
                v-caption(color='default') $ {{ total.unsoldPrice | toUSD }}
              +e.TH.cell--variant_bold.--foot.--bordered_right_top.--bordered_right_bottom()
                v-caption(color='default') {{ total.scannedTickets }}
            StatsRow(
              :event='event' 
              v-for='event in events' 
              :key='event.id'
              @loading='loading = $event'
            )
            +e.TFOOT.tfoot
              +e.TH.cell--variant_bold.--foot.--bordered_left_top.--bordered_left_bottom()
                v-caption(color='default') {{ _('Total:') }}
              +e.TH.cell--variant_bold.--foot.--bordered_right()
                v-caption(color='default' variant='center') {{ total.shows }}
              +e.TH.cell--variant_bold.--foot() 
                v-caption(color='default') {{ total.seatsAmount }}
              +e.TH.cell--variant_bold.--foot() 
                v-caption(color='default') $ {{ total.totalPrice | toUSD }}
              +e.TH.cell--variant_bold.--foot() 
                v-caption(color='default') {{ total.ticketsAmount }}
              +e.TH.cell--variant_bold.--foot()
                v-caption(color='default') $ {{ total.soldPrice | toUSD }}
              +e.TH.cell--variant_bold.--foot()
                v-caption(color='default') $ {{ total.unsoldPrice | toUSD }}
              +e.TH.cell--variant_bold.--foot.--bordered_right_top.--bordered_right_bottom()
                v-caption(color='default') {{ total.scannedTickets }}
                
</template>
<script>
import StatsRow from '@cabinet/components/dashboard/StatsRow'

export default {
  components: {
    StatsRow,
  },
  props: {
    events: {
      required: true
    }
  },
  computed: {
    total() {
      return this.events.reduce((acc, i) => {

        acc.shows += i.show && i.show.length
        acc.seatsAmount += i.seats_amount
        acc.totalPrice += parseFloat(i.total_price)
        acc.ticketsAmount += parseFloat(i.tickets_amount)
        acc.soldPrice += parseFloat(i.sold_price)
        acc.unsoldPrice += parseFloat(i.unsold_price)
        acc.scannedTickets += parseFloat(i.scanned_tickets) || 0
        return acc
      }, {
        shows: 0,
        seatsAmount: 0,
        totalPrice: 0,
        ticketsAmount: 0,
        soldPrice: 0,
        unsoldPrice: 0,
        scannedTickets: 0,
      })
    }
  },
  data() {
    return {
      loading: false
    }
  },
}
</script>