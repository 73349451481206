export default {
  data() {
    return {
      count: 0,
    }
  },
  methods: {
    initFlyTicket() {
      ++this.count
      const image = document.createElement('img')
      image.id = `fly-ticket-${this.count}`
      const startPointButton = this.$refs.flyButton
      const startX = startPointButton.getBoundingClientRect().left + (startPointButton.getBoundingClientRect().width / 2)
      const startY = window.pageYOffset + startPointButton.getBoundingClientRect().top
      image.src = '/static/images/ticket.png'
      image.classList.add('fly-ticket')
      document.body.appendChild(image)
      image.style.top = `${startY}px`
      image.style.left = `${startX}px`
      const cart = document.getElementById('fly-cart')
      const endY = cart.getBoundingClientRect().top
      const endX = cart.getBoundingClientRect().left
      image.classList.add('active')
      image.style.top = `${endY}px`
      image.style.left = `${endX}px`
      const timeout = 2000
      setTimeout(() => {
        const currentImage = document.getElementById(`fly-ticket-${this.count}`)
        currentImage.remove()
      }, timeout)
    },
  }
}
