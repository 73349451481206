<template lang="pug">
  include /mixins
  card
    card-panel
      slot(:action='openModal' :thumb='thumb')
   
</template>

<script>
import { getYoutubeId, getYoutubeImg } from '@cabinet/util'
import { computed } from '@vue/composition-api'
import VideoModal from '@cabinet/components/atoms/VideoModal.vue'

export default {
  name: 'VideoWrapper',
  props: {
    link: {
      required: true,
      type: String,
    }, 
    thumbItem: {
      required: false, 
      type: String
    }
  },

  setup(props, context) {

    const youtubeLink = computed(() => {
      return getYoutubeId(props.link)
    })

    const thumb = computed(() => {
      return props.thumbItem !== "" ? props.thumbItem : getYoutubeImg(props.link)
    })

    const openModal = () => {
      context.root.$modal.show(
        VideoModal, {
          video: `https://www.youtube.com/embed/${youtubeLink.value}?autoplay=1`
        }, {
          minWidth: 300,
          width: 800,
          adaptive: true,
          height: 'auto'
        })      
    }

    return {
      openModal,

      thumb
    }
  }
}
</script>