<template lang="pug" functional>
  include /mixins
  +b-context('table')
    +e.TR.row
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          :variant='["bold"]'
        ) {{ props.report.created_at | humanDate('en')}}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          :variant='["bold"]'
        ) {{ props.report.type }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          :variant='["bold"]'
        ) {{ props.report.subject }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          :variant='["bold"]'
        ) $ {{ props.report.price }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          :variant='["bold"]'
        ) $ {{ props.report.price_after }}
      +e.TD.cell(v-if='props.report.link')
        v-button(variant='clear')
          row(alignment='center')
            cell()
              v-caption(
                size='6'
                color='default'
                :variant='["bold"]'
              ) {{ props.report.price_after }}
            cell()
              icon(type='download')

          
</template>

<script>
export default {
  
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>