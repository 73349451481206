<template lang="pug">
  include /mixins
  +b.cell.cols--12(v-if='visibility || someSelected')
    card(:variant="['white', 'tour']")
      card-panel
        //- (offset='full')
        form.form
          card()
            card-panel(offset='horizontal_xs')
              row(offset)
                cell(cols='12')
                  v-select(
                    v-model='tagsModel'
                    :val='tagsModel'
                    :options='tags'
                    :placeholder="_('Selected tags')"
                    :taggable='true'
                    :multiple='true'
                    :searchable='true'
                    bg='relief-3'
                    :allow-empty='true'
                    @remove='removeTag'
                    :close-on-select='true'
                    name='tags'
                  )
                template(v-if='visibility')
                  cell(cols='12 6-sm 4-lg')
                    v-select(
                      v-model.number='form.country'
                      :val='form.country'
                      :options='countries'
                      :placeholder="_('Country')"
                      :searchable='true'
                      bg='relief-3'
                      @input='fetchStates(form.country.id);form.subdivision = "";form.city = ""'
                      :name='"country"'
                    )
                    
                  cell(cols='12 6-sm 4-lg')
                    v-select(
                      v-model.number='form.subdivision'
                      :val='form.subdivision'
                      :placeholder="_('State')"
                      :options='states'
                      :disabled='!form.country'
                      :searchable='true'
                      bg='relief-3'
                      @input='fetchCities(form.subdivision.id);form.city = ""'
                      :name='"subdivision"'
                    )
                  cell(cols='12 6-sm 4-lg')
                    v-select(
                      v-model.number='form.city'
                      :val='form.city'
                      :placeholder="_('City')"
                      :options='cities'
                      :disabled='!form.subdivision'
                      :searchable='true'
                      bg='relief-3'
                      :name='"city"'
                    )
                  
                  cell(cols='12 6-sm 4-lg' v-if='!users')
                    v-select(
                      v-model.number='form.status'
                      :val='form.status'
                      :options='eventStatuses'
                      :placeholder="_('Status')"
                      :searchable='true'
                      bg='relief-3'
                      :name='"status"'
                    )
                  cell(cols='12 6-sm 4-lg' v-if='!users')
                    v-select(
                      v-model.number='form.type'
                      :val='form.type'
                      :options='eventTypes'
                      :placeholder="_('Type')"
                      :searchable='true'
                      bg='relief-3'
                      :name='"type"'
                    )
                  cell(cols='12 6-sm 4-lg' v-if='!users')
                    .form__control
                      label.form__label 
                        flat-pickr(
                          v-model='date'
                          :val='form.date'
                          :config='config'
                          name='date'
                          :placeholder="_('Date from - Date to')"
                        ).flatpickr-input--bg_relief-3.flatpickr-input--border_full
                  cell(cols='12')
                    row(space='2xl' justification='center')
                      cell(cols='narrow')
                        v-button(
                          @click.prevent='resetFilter'
                          :variant='["clear"]'
                          hover='danger'
                        )
                          template(v-slot:prepend='')
                            cell
                              v-button-element 
                                icon(name='refresh' color='warning' size='xl')
                          cell
                            v-button-element 
                              v-caption(color='warning') {{ _('Reset filter') }}
          

</template>

<script>
import filterMixin from './FilterMixin'
export default {
  mixins: [filterMixin]
}
</script>

