import Api from '@cabinet/services'

export default {
  getAllShows(data, provider) {
    return Api().get('shows/list/', { params: data, headers: {
      provider
    }})
  },

  
  createShow(data) {
    return Api().post('shows/create/', data)
  },
  getShowOptions() {
    return Api().options('shows/create/')
  },
  deleteShow(id) {
    return Api().delete(`/shows/${id}/delete/`)
  },
  editShow({ id, data }) {
    return Api().put(`/shows/${id}/update/`, data)
  },
  fetchShow(id) {
    let _id = ''
    let query = ''
    if (typeof id === 'object') {
      _id = id.id
      query = id.query

    }
    return Api().get(`shows/${_id}/receive/${query}`)
  },
  fetch(type, meta) {
    return Api().post('/events/', {
      event_type: type,
      ...meta
    })
  },
  fetchShows({ id, query }) {
    return Api().get(`shows/${id}/list/`, {
      params: query
    })
  },
  deleteTickets(id) {
    return Api().delete(`shows/seat/${id}/delete/`)
  },
  deleteTicketsGroup(ids) {
    return Api().delete(`shows/seat/${id}/delete/`)
  },
  assignDiscount({ id, data}) {
    return Api().post(`shows/${id}/sale/create/`, data)
  },
  fetchDiscounts({ id }) {
    return Api().get(`shows/${id}/sale/list/`)
  },
  deleteDiscount({ id, data }) {
    return Api().post(`shows/${id}/sale/delete/`, data)
  },

  deleteSeats(id, {seats, info}) {
    return Api().put(`/shows/price/seats/remove/${id}/`, {seats, info})
  },
  deleteSector({descriptorId, sectors, info}) {
    return Api().put(`/shows/price/sector/remove/${descriptorId}/`, {sectors, info})
  },

  getWithdrawList(id) {
    return Api().get(`shows/${id}/withdraw/list/`)
  },
  createWithdraw({id, data}) {
    return Api().post(`shows/${id}/withdraw/create/`, data)
  },
  removeWithdraw({id, printed}) {
    return Api().post(`shows/${id}/withdraw/remove/`, {printed})
  },

  charge({id}) {
    return Api().post(`/shows/${id}/ask/to/charge/`)
  },
  hold({id}) {
    return Api().post(`/shows/${id}/hold/`)
  },
  unhold({id}) {
    return Api().post(`/shows/${id}/unhold/`)
  },
  getAvailableSectors({id}) {
    return Api().get(`/shows/${id}/available-sectors/`)
  },
}
