<template lang="pug">
  include /mixins
  ValidationObserver(
    ref='observer'
    tag='form'
    @submit.prevent='send'
  )
    row(offset)
      ValidationProvider(
        name='non field errors'
        v-slot='{errors}'
      )
        cell(cols='12' v-if='errors.length') {{ errors[0]}}
      cell(cols='12')
        row(offset)
          cell(cols='auto')
            ui-input(
              :placeholder="_('promocode')"
              :val='code'
              type='search'
              :size='["sm", "lg-md"]'
              name='code'
              border='whole'
              v-model='code'
              validate='required'
            )
          cell(cols='narrow')
            v-button(
              type='submit'
              variant='success'
              border='whole'
              :class="{'is-disabled': disabled }"
            )
              card(:space='["2xs", "sm-md"]')
                card-panel(offset='vertical')
                  cell(justification='center' cols='12')
                    v-button-element(:size='["sm", "2xs-md"]') 
                      icon(v-if='done' type='check' size='sm')
                      v-caption(
                        size='6'
                        :variant='["uppercase", "bold"]'
                        v-else
                      ) {{  _('send code') }}

</template>

<script>
import PromosService from '@cabinet/services/api/promos'
import { useResponse } from '@cabinet/composables/use-response'

export default {
  data() {
    return {
      code: '',
      loading: false,
      done: false
    }
  },
  props: {
    disabled: {
      required: true,
    },
  },
  methods: {
    async send() {
      if (!this.done) {
        const isValid = this.$refs.observer.validate()

        if (isValid) {
          try {
            const res = await useResponse(PromosService.addPromo({code: this.code}), this, this.$refs.observer)  
            this.done = true
            console.log(res)
          } catch (error) {
            console.error(error)
          }
        }
      }
    }
  }

}
</script>

