<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; ')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    card
      card-panel(offset='top')
        validation-observer(
          tag='form'
          @submit.prevent='activate'
          v-slot='{invalid}'
          ref='observer'
          autocomplete='off'
        )
          row(offset)
            cell(cols='12')
              v-caption(size='6') {{ _('lorem test pay' )}}
            cell(cols='12')
              v-button(
                variant='success'
                type='submit'
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('test pay') }}        
</template>
<script>
export default {
  props: {
    action: {
      required: true
    }
  },
  methods: {
    activate() {
      this.action().then(res => {
        this.$emit('close')
        return res
      })
    }
  },
}
</script>

