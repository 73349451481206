<template lang='pug'>
  include /mixins
  +b.SECTION
    +e.el(ref='componentFocusTarget')
    card
      card-panel(offset='full')
        card-panel
          //- v-button(tag='router-link')
          //-   v-button-element()
          //- +b.FORM.form--events
          //-   +e.block
          //-     h3.no-padding.flex.iconed
          //-       +b.row--alignment_center
          //-         +b.cols.cell
          //-           router-link(to="/account/halls/create/")
          //-             icon(name='plus')
          //-           //- ui-button(btn-class='btn btn--success plus'
          //-           //-           component-type='router-link' to="/account/halls/create/").btn-wrapper--standart
          //-             //- +b.(@click='addHall')
          //-         +e.el
          //-           span {{ _('Add new hall') }}
            //- +b.row--offset_lg(v-if='isButtonVisible')
            //-   +b.cols--3.cell(style='margin:0 auto')
            //-     ui-button(:variant='["success"]' @click.prevent='expand')
            //-       template
            //-         +b.row.alignment_center.--justification_center
            //-           +b.cols.cell
            //-             icon(name='filter_list')
            //-           +b.cols.cell
            //-             span {{ active ? _('collapse') : _('expand') }} {{ _(' filter')}}
            //- div(v-show='active')
          row(offset)
            cell(cols='12 narrow-sm')
              +b.control-box
                v-button(
                  variant='danger'
                  border='whole'
                  tag='router-link'
                  :to='{name: "create-hall"}'
                )
                  template(v-slot:prepend='')
                    cell
                      v-button-element(
                        size='lg'
                        variant="warning-lite"
                      )
                        v-caption(variant='bold')
                          icon(
                            type='add'
                            variant='opaque'
                            size='md'
                          )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ _('Add new Hall') }}
            cell(cols='12 8-md 7-lg 6-xl 5-2xl')
              ui-search(
                @input='send' 
                @open='visible = !visible'
              )
                template(v-slot:activator='{ data, send }')
                  cell(cols='narrow')
                    v-button(
                      size='lg'
                      :border='["top_right", "bottom_right"]'
                      @click='send(data)'
                    )
                      cell(cols='auto')
                        v-button-element(size='lg') 
                          v-caption(
                            :variant='["bold", "uppercase"]' 
                            size='6'
                          ) {{ _('apply') }}
                template(v-slot:additional-filters='{data, send}')
                  ui-hall-filter(
                    @input='send($event)'
                    :visibility='visible'
                    :data='data'
                    users
                  )
        card-panel(offset='vertical')
          row(offset)
            cell(cols='12')
              loading-wrapper(:loading='loading')
                +b.scrollable-wrapper--till-2xl.--full
                  +b.TABLE.table.--variant_scroll.--space_lg.--highlight
                    +e.THEAD.thead 
                      +e.TR.row--relief(v-if='!loading && halls.length')
                        +e.TH.cell--variant_bold.--bordered_left_top.--bordered_left_bottom 
                          v-caption(color='white') {{ _('Hall №') }}
                        +e.TH.cell--variant_bold 
                          v-caption(color='white') {{ _('Hall name') }}
                        +e.TH.cell--variant_bold 
                          v-caption(color='white') {{ _('Hall location') }}
                        +e.TH.cell--variant_bold 
                          v-caption(color='white') {{ _('Hall status') }}
                        +e.TH.cell--variant_bold
                        +e.TH.cell--variant_bold.--bordered_right_top.--bordered_right_bottom 
                      +e.TR.row--relief(v-else-if='!loading && !halls.length')
                        +e.TH.cell--variant_bold.--bordered_right_top.--bordered_right_bottom.--bordered_left_top.--bordered_left_bottom(colspan='6')
                          v-caption(color='white') {{ _('You have no halls yet.') }}
                    +e.TBODY.tbody(v-if='halls.length')
                      +e.TR.row(v-for='(hall, index) in halls' :key='hall.id')
                        +e.TD.cell--bordered_left_top.--bordered_left_bottom
                          v-caption(color='default' size='6') {{ hall.id }} 
                        +e.TD.cell
                          router-link.link(:to='{name: "edit-hall", params: { id: hall.id }}') 
                            v-caption(color='default' size='6') {{ hall.title }} 
                        +e.TD.cell 
                          v-caption(color='default' size='6') {{ hall.location.country.title }} {{ hall.location.subdivision.title }} {{ hall.location.city.title }}
                        +e.TD.cell 
                          v-caption(color='default' size='6') {{ hall.status }} 
                        +e.TD.cell
                          +b.ROUTER-LINK.link(:to='"/account/halls/edit/"+hall.id')
                            row(offset space='2xs' alignment='center')
                              cell(cols='narrow')
                                icon(
                                  type='pencil' 
                                  size='xs'
                                  color='success'
                                )
                              cell(cols='narrow')
                                v-caption(size='6' color='success') {{ _('h edit') }} 
                        +e.TD.cell--bordered_right_top.--bordered_right_bottom
                          +b.A(@click='modalTrigger(hall.id)' v-if='hall.editable').link
                            row(offset space='2xs' alignment='center')
                              cell(cols='narrow')
                                icon(
                                  type='close'
                                  size='xs'
                                  color='warning'
                                )
                              cell(cols='narrow')
                                v-caption(size='6' color='warning') {{ _('h delete') }}

        card-panel(offset='vertical')  
          ui-pagination(
            :offset='pagination.offset'
            :total='pagination.total'
            :limit='pagination.limit'
            v-if='pagination.total > pagination.limit'
            @input='send({ page: $event })'
          )
</template>

<script>
import UiSearch from '@cabinet/components/forms/UiSearch'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import UiHallFilter from '@cabinet/components/forms/UiHallFilter'
import { mapState, mapActions } from 'vuex'
import { useResponse } from '@cabinet/composables/use-response'


export default {
  components: {
    UiSearch,
    UiPagination,
    UiHallFilter,
  },
  data() {
    return {
      visible: false,
      active: false,
      isButtonVisible: false,
      data: {
        pagination: {
          offset: 0,
          total: 0,
          per_page: 0,
        },
      },
      query: {

      },
      loading: true
    }
  },
  computed: {
    
    ...mapState('hallModule', [
      'halls',
      'pagination'
    ]),
    // halls() {
    //   return [
    //     {
    //       title: 'Independence hall',
    //       id: 1,
    //       location: 'Chicago',
    //       status: 1,
    //       number: 1,
    //     },
    //   ]
    // },
  },
  methods: {
    ...mapActions('hallModule', [
      'fetchHalls',
      'deleteHall'
    ]),
    expand() {
      this.active = !this.active
    },
    status(item) {
      return !item.editable ? "pointer-events:none; opacity: .5" : ''
    },
    async sendData(data) {
      this.loading = true
      let item = {
        page: {
          id: data
        }
      }
      data = Object.assign({}, item)
      await this.send(data)
      this.loading = false
    },
    async send(data) {
      this.loading = true
      // let result 
      // if (typeof data !== 'string') {
      //   this.query = data
      //   result = Object.keys(data).filter(k => data[k]).map(k => data[k].id ? `${k}=${data[k].id}` : '').join('&')
      //   if (result.length) {
      //     result = `?${result}`
      //   }
      // } else {
      //   result = `?search=${data}`
      // }
      try {
        
        await this.fetchHalls(data)
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async deleteHall_(id) {
      if (id) {
        try {
          await useResponse(this.deleteHall(id), this, null)
          this.fetchHalls()
        } catch (error) {
          console.error(error)
          
        }
      } else {
        return Promise.reject()
      }

    },
    modalTrigger(id) {
      import('@cabinet/components/modals/UiDelete').then(res => {
        this.$modal.show(res.default,
          {
            fn: this.deleteHall_,
            itemID: id,
            type: this._('hall'),
            
          },
          {
            height: 'auto',
            adaptive: true,
            width: 310,
          }
        )
      })
    },
    navigateTo(id) {
      this.$router.push({
        name: 'edit-hall',
        params: {
          id
        },
      })
    },
    addHall() {
      this.$router.push({
        name: 'create-hall',
      })
    },
    checkWidth() {
      if (window.innerWidth < 1366) {
        this.isButtonVisible = true
      } else {
        this.isButtonVisible = false
        this.active = true
      }
    }
  },
  async mounted() {
    this.loading = true
    // await this.fetchHalls()
    this.loading = false
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    })
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth)
  }
}
</script>
