import ShowService from '@cabinet/services/api/shows'


export default {
  namespaced: true,
  state: {
    type: 'active',
    counter: 0,
    shows: {
      active: {
        pagination: {},
        events_row: []
      },
      archived: {
        pagination: {},
        events_row: []
      }
    },
    show: {},
    discount_methods: [],
    payment_methods: [],
    shipment_methods: [],
    statuses: []
  },
  getters: {
    discount_methods({ discount_methods }) {
      return discount_methods.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
    payment_methods({ payment_methods }) {
      return payment_methods.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
    shipment_methods({ shipment_methods }) {
      return shipment_methods.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
    statuses({ statuses}) {
      return statuses
    }

  },
  actions: {
    setCounter({ commit }, data) {
      commit('SET_COUNT', data)
    },
    createShow({ commit }, { data }) {
      return ShowService.createShow(data)
    },
    editShow({ commit }, data) {
      return ShowService.editShow(data)
    },
    setType({ commit }, type) {
      commit('SET_TYPE', type)
    },
    async deleteShow({ commit, state }, id) {
      await ShowService.deleteShow(id)
      let { counter } = state
      counter--
      commit('SET_COUNT', counter)
      return id
    },
    async fetchShows({ commit }, data) {
      let { data: result } = (await ShowService.fetchShows(data))
      commit('SET_SHOWS', result)
      return result.data
    },
    async fetchShow({ commit }, id) {
      let { data } = (await ShowService.fetchShow(id)).data
      return data
    },
    async deleteTickets({ commit }, id) {
      let { data } = (await ShowService.deleteTickets(id)).data
      return data
    },
    async deleteSector({ commit }, {descriptorId, sectors, info}) {
      let { data } = (await ShowService.deleteSector({descriptorId, sectors, info})).data
      return data
    },
    async getShowOptions({ commit }) {
      let { data } = (await ShowService.getShowOptions())
      commit('SET_VENDORS', data)
    },
    async assignDiscount({ commit }, payload) {
      return await ShowService.assignDiscount(payload)
    },
    async deleteDiscount({ commit }, payload) {
      return await ShowService.deleteDiscount(payload)
    },
    async addShowWithdraw({ commit }, payload) {
      return await ShowService.addWithdraw(payload)
    },
    async getTicketsList({ commit }, payload) {
      return await ShowService.getTicketsList(payload)
    },
    async deleteTicket({ commit }, payload) {
      return await ShowService.deleteTicket(payload)
    },
  },
  mutations: {
    DELETE_SHOW(state, payload) {
      let index =
        state.shows[state.type].events_row.findIndex(e => e.id === payload)
      state.shows[state.type].events_row.splice(index, 1)
    },
    SET_TYPE(state, type) {
      state.type = type
    },
    SET_SHOWS(state, shows) {
      state.shows[state.type].events_row = shows.data
      state.shows[state.type].pagination = shows.meta.pagination
    },
    SET_COUNT(state, payload) {
      state.counter = payload
    },
    SET_VENDORS(state, {
      // discount_methods,
      payment_methods,
      shipment_methods,
      statuses
    }) {

      // state.discount_methods = discount_methods
      state.payment_methods = payment_methods
      state.shipment_methods = shipment_methods
      state.statuses = statuses.map(s => {
        return {
          title: s[1],
          id: s[0],
          '$isDisabled': false
        }
      })

    },
    DISABLE_STATUS(state, val) {
      const index = state.statuses.findIndex(status => status.title === val.title)
      if (index) {
        state.statuses[index].$isDisabled = val.isDisabled
      }
    }
  },

}
