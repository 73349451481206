import Api, {editedSlug as provider} from '@cabinet/services'

let headers = provider ? {headers: {provider: provider}} : {headers: {}}


export default {
  register(data, isProvider) {
    return Api().post(`register/${isProvider}`, data, headers)
  },
  login(data) {
    return Api().post('login/', data, headers)
  },
  restorePassword(data) {
    return Api().post('reset/confirmation/', data, headers)
  },
  logout() {
    return Api().post('logout/', {}, headers)
  },
  getData() {
    
    return Api().get('profiles/my/receive/', headers)
  },
  updateData(data) {
    return Api().put('profiles/my/update/', data, headers)
  },
  patchData(data) {
    return Api().patch('profiles/my/update/', data, headers)
  },
  changePassword(data) {
    return Api().post('profiles/my/password-change/', data, headers)
  },
  getQr() {
    return Api().get('profiles/my/settings/receive/', headers)
  },
  updateSettings(data) {
    return Api().patch('profiles/my/settings/update/', data, headers)
  },
  loginWithTwoFactor(url, data) {
    return Api().post(`${url}`, data, headers)
  },
  restore(data) {
    return Api().post('reset/', data, headers)
  },
  recoveryPassword(data) {
    return Api().post('', data, headers)
  },
  sendSms(data) {
    return Api().post('sms-mailing/', data, headers)
  }
}
