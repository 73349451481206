import Api, {editedSlug as provider} from '@cabinet/services'
export default {
  /**
   * Get general statistics
   */
  getGeneral(data) {
    return Api().get(`/statistic/general/`, {
      params: data
    })
  },
  /**
   * Get events statistics
   */
  getEventsStatistics(data) {
    return Api().get(`/statistic/events/`, {
      params: data,
      
    })
  },
  getFullFinanceReport(tz) {
    return Api().get(`/statistic/events/report/?as=pdf&tz=${tz}`, {responseType: 'blob'})
  },
  /**
   * Get show report
   */
  getShowStatistics(id, data) {
    return Api().get(`/statistic/show/${id}/`, { params: data })
  },
  /**
   * Get show report in pdf
   */
  getShowStatisticsInPdf(id, tz) {
    return Api().get(`/statistic/show/${id}/report/?as=pdf&tz=${tz}`, {responseType: 'blob'})
  },

  getVendorShowStatisticsInPdf(id) {
    return Api().get(`/statistic/show-vendor/${id}/report/?as=pdf`, {responseType: 'blob'})
    
  }

}
