<template lang="pug">
  //- loading-wrapper(:loading='loading')
  validation-observer(
    ref='observer'
    tag='form'
    v-slot='{invalid}'
    @submit.prevent='save'
    autocomplete='off'
  )
    card
      card-panel(style='height: 46px' offset='bottom')
        .aspect-ratio--ratio_16x10(v-if='payment.image')
          .aspect-ratio__element
            img(:src='payment.image' style='display:block')
        v-caption(
          v-else 
          size='4' 
          variant='bold'
        ) {{ payment.title }}
      card-panel(offset='vertical')
        row(offset alignment='center')
          cell(cols='narrow')
            row(offset space='xs' alignment='center')
              cell(cols='narrow')
                v-checkbox
                  input(
                    type='checkbox'
                    size='lg'
                    border='whole'
                    bg='relief-3'
                    v-model='payment.is_active'
                    @input='activate'
                    name='active'
                  ).control-checkbox__input
              cell(cols='narrow') 
                v-caption(size='6' variant='regular') {{ _('activate') }}
          transition(name='fade')
            cell(cols='narrow' v-if='payment.initial_id !== "cash" && payment.is_active')
              ui-toggler(
                type='checkbox'
                v-model='payment.is_provider'
                @input='deactivate(payment.is_provider)'
                size='lg'
                border='whole'
                bg='relief-3'
                :val='payment.is_provider'
                name='is active'
              )
                template(v-slot:append='') 
                  v-caption(size='6' variant='regular') {{ _('Use personal account') }}
        transition(name='fade')
          card-panel(offset='vertical' v-if='payment.initial_id !== "cash"')
            row(offset)
              cell(cols='12')
                v-caption(size='6' variant='regular') {{ _('Use services access (if you don\'t have  a personal account)') }}
        transition(name='fade')
          row(offset v-if='payment.initial_id !== "cash" && payment.is_provider && payment.is_active')
            cell(cols='6 12-md 6-lg 12-xl 6-2xl')
              ui-input(
                :placeholder="_('Client ID*')"
                :val='payment.client_id'
                type='search'
                name='client id'
                validate='required'
                v-model='payment.client_id'
                size='lg'
                border='whole'
                bg='relief-3'
              )
            cell(cols='6 12-md 6-lg 12-xl 6-2xl')
              ui-input(
                :placeholder="_('Secret key')"
                :val='payment.secret'
                type='search'
                name='secret'
                validate='required'
                v-model='payment.secret'
                size='lg'
                border='whole'
                bg='relief-3'
              )
            cell(cols='6 12-md 6-lg 12-xl 6-2xl')
              v-button(
                variant='success'
                type='submit'
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('pp Save') }}
            cell(cols='6' v-if='!payment.checked')
              v-button(
                type='button'
                
                @click='verifyAccount'
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('verify account') }}
</template>

<script>
import { ref } from '@vue/composition-api'
import PaymentsService from '@cabinet/services/api/payments'
import { useResponse } from '@cabinet/composables/use-response'

export default {
  props: {
    payment: {
      required: true,
      type: Object
    }
  },
  setup(props, context) {
    const loading = ref(false)
    const save = async ( ) => {

      const isValid = await context.refs.observer.validate()
      if (isValid) {
        loading.value = true
        try {
          await useResponse(PaymentsService.customize({
            id: props.payment.id,
            data: props.payment
          }), context.root, context.refs.observer)
          loading.value = false
        } catch (error) {
          loading.value = false
          console.error(error)
        }
      }
    }
    const activate = async () => {
      await PaymentsService.activate({variant: props.payment.initial_id})
      context.emit('fetch')
    }

    const deactivate = (value) => {
      if (!value) {
        PaymentsService.deactivate(props.payment.id)
        context.emit('fetch')
      }
    }

    const verifyAccount = () => {

      if (props.payment.initial_id === 'authorize' || props.payment.initial_id === 'stripe' || props.payment.initial_id == 'paypal') {
        const component = import(`@cabinet/components/settings/ProviderVerify${props.payment.initial_id}.vue`)

        
        context.root.$modal.show(
          () => component,
          {
            id: props.payment.id,
            paymentKey: props.payment.client_id
          },
          {
            adaptive: true,
            maxWidth: 330,
            height: 'auto',
            scrollable: true,
          },
          {
            'before-close': () => {
              context.emit('fetch')
            }
          }
        )
      } 
    }

    
    return {
      loading,


      save,
      activate,
      deactivate,
      verifyAccount
    }
  }
}
</script>