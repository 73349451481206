<template lang="pug">
  include /mixins
  .form__dropdown
    div(v-for='(prediction, index) in values'
         :key='index') 
      .form__dropdown-element(v-for='code in prediction.city.zipcodes'
          v-html='replace(code)'
          @click='setValue(prediction, code)')
</template>

<script>
export default {
  props: {
    values: {
      type: Array
    },
    value: {
      default: ''
    }
  },
  methods: {
    replace(item) {
      return item.replace(this.value, `<b>${this.value}</b>`)
    },
    setValue(prediction, code) {
      prediction.code = code
      this.$emit('change', prediction)
      this.$emit('input', prediction)
    }
  }
}
</script>

