import { watch, ref } from '@vue/composition-api'

export function useDateWatcher(date) {
  const created_at__date__gte = ref('')
  const created_at__date__lte = ref('')
  watch(date,
    (values) => {
      if (values) {
        let val = values.split(' to ')
        if (val.length > 1) {
          let splittedDateStart = val[0].replace(/\//g, '-').split('-')
          let splittedDateEnd = val[1].replace(/\//g, '-').split('-')

          splittedDateStart = [
            splittedDateStart[2],
            splittedDateStart[0],
            splittedDateStart[1]
          ].join('-')
          splittedDateEnd = [
            splittedDateEnd[2],
            splittedDateEnd[0],
            splittedDateEnd[1]
          ].join('-')

          created_at__date__gte.value = splittedDateStart
          created_at__date__lte.value = splittedDateEnd
        } else {
          let splitedDate = values.replace(/\//g, '-').split('-')
          splitedDate = [
            splitedDate[2],
            splitedDate[0],
            splitedDate[1]
          ].join('-')
          created_at__date__gte.value = splitedDate
          created_at__date__lte.value = splitedDate

        }
      }
    }
  )



  return [
    created_at__date__gte,
    created_at__date__lte
  ]
}
