<template lang="pug">
  include /mixins
  +b-context('table')
    +e.TBODY.tbody
      +e.TR.row(@click='toggleExpand' style='cursor:pointer')
        +e.TD.cell(width='23%')
          row(
            offset
            alignment='center' 
            nowrap 
            space='5xs' 
            justification='between'
          )
            cell(cols='narrow')
              v-caption(
                size='6'
                color='default'
                variant='bold'
              ) {{ event.title }}
            cell(cols='narrow')
              icon(
                :type='isExpanded ? "uparrow" : "downarrow"' 
                size='3xs'
                color='brand-4'
              )
        +e.TD.cell--bordered_right(width='23%')
          v-caption(
            size='6'
            color='default'
            :variant='["bold", "center"]'
          ) {{ event.show ? event.show.length : '-'}}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
            variant='bold'
          ) {{ event.seats_amount }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
            variant='bold'
          ) $ {{ event.total_price | toUSD }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
            variant='bold'
          ) {{ event.tickets_amount }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
            variant='bold'
          ) $ {{ event.sold_price | toUSD }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
            variant='bold'
          ) $ {{ event.unsold_price | toUSD }}
        +e.TD.cell--bordered_right_top.--bordered_right_bottom(width='9%')
          row(alignment='center' space='5xs')
            cell
              v-caption(
                size='6'
                color='default'
                variant='bold'
              ) {{ event.scaned_tickets }}
            cell(v-if='event.show.some(s => s.is_invalid)')
              icon(
                type='error' 
                color='warning' 
                size='xs'
              )
      +e.TR.row(v-for='(show, index) in event.show' :key='show.id' v-show='isExpanded')
        +e.TD.cell(width='23%')
          //- v-caption(
          //-   size='6'
          //-   color='default'
          //-   v-if='!index'
          //- ) {{ event.title }}
        +e.TD.cell--bordered_right(width='23%') 
          v-button(
            tag='router-link'
            :to='{name: "provider-dashboard-event", params: {id: show.show}}'
            variant='clear'
          )
            row
              cell(cols='12')
                v-caption(
                  size='6'
                  :color='show.is_active ? "success" : "danger"'
                  :title='show.is_active ? _("success") : _("danger")'
                  variant='center'
                ) {{ show.title }}
              cell(cols='12')
                v-caption(
                  variant='center'
                  size='7'
                ) {{ _('stats updated:') }} {{ show.last_update[1] | humanDate(offset)}}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
          ) {{ show.seats_amount }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
          ) $ {{ show.total_price | toUSD }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
          ) {{ show.tickets_amount }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
          ) $ {{ show.sold_price | toUSD }}
        +e.TD.cell(width='9%')
          v-caption(
            size='6'
            color='default'
          ) $ {{ show.unsold_price | toUSD }}
        +e.TD.cell--bordered_right_top.--bordered_right_bottom(width='9%')
          row(alignment='center' space='5xs' justification='between')
            cell
              row(alignment='center' space='5xs')
                cell
                  v-caption(
                    size='6'
                    color='default'
                  ) {{ show.scaned_tickets }}
                cell(v-if='show.is_invalid')
                  icon(
                    type='error' 
                    color='warning' 
                    size='xs'
                  )
            cell
              icon(
                style='cursor: pointer'
                type='refresh'
                @click='getShowStatisticsAndRebuild(show.show)'
              )
    


</template>

<script>
import StatsService from '@cabinet/services/api/stats'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    event: {
      required: true
    }
  },
  computed: {
    offset() {
      return new Date().getTimezoneOffset() / -60
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
    async getShowStatisticsAndRebuild(id) {
      try {
        this.$emit('loading', true)
        let res = await useResponse(StatsService.getShowStatistics(id, { refresh: 1}), this)      
        const itemIndex = this.event.show.findIndex(i => i.show == id)
        this.event.show.splice(itemIndex, 1, res.data)
        this.$emit('loading', false)
      } catch (error) {
        console.error(error)
        this.$emit('loading', false)
      }
    }
  }
}
</script>