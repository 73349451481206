<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    autocomplete='off'
  )
    row(offset justification='between')
      cell(cols='12')
        ui-input(:placeholder="_('Hall name*')"
                  type='search'
                  name='title'
                  :val='formData.title'
                  :class='{"is-disabled": !formData.editable}'
                  size='lg'
                  bg='relief-3'
                  v-model='formData.title'
                  validate='required').form__control--styled
      cell(cols='12 6-sm 4-md 3-xl')
        v-select(v-model='formData.location.country'
                  :val='formData.location.country'
                  :full='true'
                  :options='countries'
                  size='lg'
                  bg='relief-3'
                  searchable
                  @input='fetchStates(formData.location.country.id);resetFields("subdivision", "city")'
                  :placeholder="_('Country*')"
                  validate='required'
                  :class='{"is-disabled": !formData.editable}'
                  name='country')
      cell(cols='12 6-sm 4-md 3-xl')
        v-select(v-model='formData.location.subdivision'
                  :val='formData.location.subdivision'
                  :full='true'
                  :options='states'
                  size='lg'
                  bg='relief-3'
                  searchable
                  @input='fetchCities(formData.location.subdivision.id);resetFields("city")'
                  :disabled='!formData.location.country'
                  :placeholder="_('State*')"
                  validate='required'
                  :class='{"is-disabled": !formData.editable}'
                  name='state')
      cell(cols='12 6-sm 4-md 3-xl')
        v-select(v-model='formData.location.city'
                  :val='formData.location.city'
                  :full='true'
                  :options='cities'
                  size='lg'
                  bg='relief-3'
                  searchable
                  :disabled='!formData.location.subdivision'
                  :placeholder="_('City*')"
                  validate='required'
                  :class='{"is-disabled": !formData.editable}'
                  name='city')
      cell(cols='12 6-sm 4-md 3-xl')
        v-select(v-model='formData.status'
                  :val='formData.status'
                  :placeholder="_('Status*')"
                  validate='required'
                  :full='true'
                  :options='hallStatuses'
                  size='lg'
                  bg='relief-3'
                  searchable
                  :class='{"is-disabled": !formData.editable}'
                  name='status')
      cell(cols='12')
        ui-input(:placeholder="_('Location*')"
                  type='search'
                  name='title'
                  :val='formData.location.address_line_1'
                  :class='{"is-disabled": !formData.editable}'
                  size='lg'
                  bg='relief-3'
                  v-model='formData.location.address_line_1'
                  validate='required').form__control--styled
      //- cell(cols='12' style='position:relative')
      //-   gmap-map(:center='center', :zoom='7', style='width: 100%; height: 300px')
      //-     gmap-marker(:position='center',
      //-               :clickable='false'
      //-               :draggable='false')
      //-   gmap-autocomplete(
      //-     :placeholder="_('This is a placeholder text')"
      //-     @place_changed="setPlace"
      //-     ref='auto'
      //-     name='location'
      //-     :value='formData.location.address_line_1'
      //-     :component-restrictions='{country: ["ca","us"]}'
      //-   ).form__input#input
      cell(cols='12')
        row(alignment='center' space='md')
          cell(cols='narrow')
            v-checkbox
              +b.INPUT(
                type='checkbox'
                v-model='formData.has_schema'
                :value='formData.has_schema'
                name='has schema'
              ).control-checkbox__input
          cell(cols='narrow') {{ _('This hall has schema') }}

            
        //- +e.block
        //-   +e.control--100
        //-     ui-checkbox(type='checkbox'
        //-                 v-model='formData.has_schema'
        //-                 :val='true'
        //-                 name='has schema'
        //-                 validate='')
        //-       template(v-slot:text='') {{ _('This hall has schema') }}


                
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { errorHandler } from '@cabinet/util'
export default {
  data() {
    return {
      // removedImages: [],
      // removedVideos: [],
      place: null
    }
  },
  watch: {
    formData: {
      handler(val) {
        let {
          title,
          location: { country, city, subdivision/* , latitude, longitude */ },
          status,
          /* description */
        } = val

        if (
          title &&
          country.id &&
          city.id &&
          subdivision.id &&
          /* description && */ 
          (status && status.id)
        ) {
          this.$emit('validation', { valid: true })
        } else {
          this.$emit('validation', { valid: false })
        }
      },
      deep: true,
    },
    'formData.location.country': {
      handler(val) {
        if (Number.isInteger(val)) {
          this.formData.location.country = this.countries.find(c => c.id === val)
        }
      },
      immediate: true,
      deep: true
    },
    'formData.location.subdivision': {
      async handler(val) {
        if (val.id || val) {
          await this.fetchStates(this.formData.location.country.id || this.location.country)
        }
        if (Number.isInteger(val)) {
          this.formData.location.subdivision = this.states.find(c => c.id === val)
        }
      },
      deep: true,
      immediate: true,
    },
    'formData.location.city': {
      async handler(val) {
        if (val.id || val) {
          await this.fetchCities(this.formData.location.subdivision.id || this.location.subdivision)
        }
        if (Number.isInteger(val)) {
          this.formData.location.city = this.cities.find(c => c.id === val)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    formData: {
      required: true,
    },
  },
  computed: {
    ...mapGetters('hallModule', {
      hall_statuses: 'getHallStatuses',
    }),
    hallStatuses() {
      // this.hall_statuses.forEach(s => {

      //   const noItems = !this.formData.seats_file || !this.formData.sectors_file || !this.formData.elements_file
      //   if (s.id === 'published' && noItems) {
      //     s.$isDisabled = true
      //   } else {
      //     s.$isDisabled = false
      //   }
      // })
      return this.hall_statuses
    },
    ...mapState('vendorsModule', [
      'countries',
      'states',
      'cities',
      'locations',
    ]),
  },
  methods: {
    setPlace(place) {
      this.place = place
      this.formData.location.latitude = this.place.geometry.location.lat()
      this.formData.location.longitude = this.place.geometry.location.lng()
    },
    ...mapActions('vendorsModule', [
      'fetchCountries',
      'fetchStates',
      'fetchCities',
    ]),
    ...mapActions('hallModule', ['postBaseData', 'updateHall', 'getStatuses']),
    setLocation(event) {
      this.formData.location.latitude = event.lat
      this.formData.location.longitude = event.lng
    },
    resetFields(...args) {
      args.forEach(arg => (this.formData.location[arg] = ''))
    },
    
    transform(data) {
      Object.keys(data.location).forEach(k => {
        if (
          (k == 'country' || k == 'subdivision' || k == 'city') &&
          data.location[k].id
        ) {
          data.location[k] = data.location[k].id
        }
      })
      Object.keys(data).forEach(k => {
        if (k == 'status' && data[k].id) {
          data[k] = data[k].id
        } 
      })
      // if (this.removedImages.length) {
      //   data.removed_images = this.removedImages
      // }
      // if (this.removedVideos.length) {
      //   data.removed_videos = this.removedVideos
      // }

      return data
    },
    // getFile(files) {
    //   // this.formData[model] = ''
    //   for (var i = 0; i < files.length; i++) {
    //     var file = files[i]
    //     var reader = new FileReader()
    //     reader.onload = e => {
    //       let val = e.target.result
    //       this.formData.image_gallery.items.push({
    //         showpiece: val,
    //       })
    //       // this.formData[model] = val
    //       // let svgS = atob(
    //       //   e.target.result.replace(/data:image\/svg\+xml;base64,/, '')
    //       // )
    //       // this.svg = svgS
    //       // let { svg: svgWrapper } = this.$refs
    //       // this.$nextTick(() => {
    //       //   let svg = svgWrapper.querySelector('svg')
    //       //   new XMLSerializer().serializeToString(svg)
    //       // })
    //       // if (this.id) {
    //       //   this.getSectorsList(this.id).then(res => {
    //       //     res.data.forEach(sector => {
    //       //       this.deleteSector(sector.id)
    //       //     })
    //       //   })
    //       // }
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },
    
    async save() {
      // let { country, subdivision, city } = this.formData.location
      const isValid = await this.$refs.observer.validate() 
      if (isValid) {
        let newFormData = JSON.parse(JSON.stringify(this.formData))
        let data = this.transform(newFormData)
        let {
          seats_file,
          background,
          sectors_file,
          elements_file,
          ...rest
        } = data
        let type = this.$route.params.id ? 'updateHall' : 'postBaseData'
        let id = this.$route.params.id ? this.$route.params.id : undefined
        return this[type]({ data: rest, id })
          .then(res => {
            // successHandler(res.data, this)
            // this.$route.push('edit-hall')
            if (res.data.data.has_schema) {
              this.$router.push(`/account/halls/edit/${res.data.data.id}#step2`)
            } else {
              this.$router.push(`/account/halls/edit/${res.data.data.id}#step3`)
            }

            let text = 'You can pass'
            return {
              valid: true,
              msg: text,
            }
          })
          .catch(err => {
            try {
              console.error(err)
              let { errors } = err.response.data
              errorHandler(errors, this)
              return {
                valid: false,
                msg: 'not valid',
              }
            } catch (error) {
              return {
                valid: false,
                msg: 'not valid',
              }
            }
          })
      }  
      return Promise.reject({
        valid: false,
        msg: 'not valid',
      })
    },
    setData(item) {
      return value => {
        return new Promise(resolve => {
          resolve(value)
        }).then(result => {
          this.formData[item] = result
        })
      }
    },
    
  },
  mounted() {
    this.getStatuses()
    this.fetchCountries()
  },
}
</script>




