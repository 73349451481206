import Api from '@cabinet/services/'

export default {
  fetch() {
    return Api().get('general/')  
  },
  fetchCountries() {
    return Api().get('/location/countries/list/?pagination=0')  
  },
  fetchStates(id) {
    return Api().get(`/location/subdivision/list/${id}/?pagination=0`)  
  },
  fetchCities(id) {
    return Api().get(`/location/city/list/${id}/?pagination=0`)  
  },
  fetchLocations(data) {
    data = Object.keys(data).map(k => `${k}=${data[k].id || data[k]}`).join('&')
    return Api().get(`/places/hall/list/?pagination=0&${data}`)
  },
  getLocationByZipCode(id) {
    return Api().get(`/location/by/zipcode/?search=${id}`)
  },
  getImagesRow(id) {
    return Api().get(`/gallery/detail/${id}/`)
  },
  getColors() {
    return Api().get(`/colors/list/`)
  }
}
