<template lang="pug">
card
  SecurityAccordion
    template(v-slot:caption='')
      | {{ _('m request hall' )}}
    template(v-slot:content='')
      card-panel(offset='bottom')
        row(offset space='xl-xl')
          cell(cols='narrow')
            v-button(
              variant='success'
              @click='requestHall'
            )
              v-button-element(size='lg') 
                v-caption(
                  :variant='["bold", "uppercase"]' 
                  size='6'
                ) {{ _('request hall') }}

</template>

<script>

import RequestHallModal from '@cabinet/components/modals/RequestHallModal'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
export default {
  components: {
    SecurityAccordion,
    RequestHallModal
  },
  setup(props, context) {
    const requestHall = () => {
      context.root.$modal.show(
        RequestHallModal, 
        {},
        {
          adaptive: true,
          classes: ['middle-modal-wrap', 'modal--variant_vendors'],
          maxWidth: 600,
          height: 'auto',
          scrollable: true,
        }
      )
    }
    return {
      requestHall
    }
  }
}
</script>