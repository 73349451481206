import Api, {editedSlug as provider} from '@cabinet/services'

export default {
  fetch(data) {
    return Api().get('/promocode/list/', {params: data})
  },
  
  create(data) {
    return Api().post('/promocode/create/', data)
  },
  fetchOptions() {
    return Api().options('/promocode/create/')
  },
  deletePromo(id) {
    return Api().delete(`/promocode/${id}/delete/`)
  },
  addPromo(data) {
    return Api().post(`/promocode/add/`, data, {
      headers: {
        provider
      }
    })
  }
}
