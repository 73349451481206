<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%;')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")' v-if='!uid || !doLogin') 
      icon(type='close')
    loading-wrapper(:loading='loading')
      validation-observer(
        ref='observer'
        v-slot='{invalid}'
        tag='form'
        @submit.prevent='send',
      )
        row(offset)
          cell(cols='12')
            v-caption(size='2' color='default' variant='bold') {{ text }}
          //- cell(cols='12' v-if='errors.has("non field errors" || "message")')
          //-   .form__error(v-if='errors.has("non field errors" || "message")') {{ errors.first("non field errors" || "message")}}
            
        validation(name='non field errors')
        row(v-if='!recovery || doLogin === "True"' offset)
          cell(cols='12')
            ui-input(
              :placeholder="_('E-mail')"
              bg='relief-3'
              size='lg'
              type='search'
              name='email'
              inputmode='email'
              v-model.trim='formData.email'
              validate='required|email'
              autocomplete='on'
            )
          
          cell(cols='12')
            ui-input(:placeholder="_('Password')"
                    bg='relief-3'
                    size='lg'
                    type='password'
                    name='password'
                    v-model='formData.password'
                    custom-ref='password'
                    :toggle-password="true"
                    validate='required|min:8|max:32')
        
        row(v-else-if='recovery' offset)
          cell(cols='12')
            ui-input(
              :placeholder="_('new password')"
              bg='relief-3'
              size='lg'
              type='password'
              name='new password1'
              :toggle-password="true"
              v-model='formData.new_password1'
              validate='required|min:8|max:32|custom_password'
            )
          cell(cols='12')
            ui-input(
              :placeholder="_('password again')"
              bg='relief-3'
              size='lg'
              type='password'
              name='new password2'
              :toggle-password="true"
              v-model='formData.new_password2'
              :custom-class='formData.new_password1 != formData.new_password2 ? "is-error" : null'
              validate='required'
            )
        row(v-if='recovery' offset)
          cell(cols='12').--no-padding-top
            row(
              alignment='center'
              space='2xs'
            )
              cell(cols='12')
                v-caption(
                  size='6' 
                  variant='bold'
                ) {{ _('Your password must include:')}}

              cell
                icon(
                  type='check'
                  size='xs' 
                  :color='message.lengthMessage ? "success" : "brand-4"'
                )
              cell
                v-caption(
                  size='6' 
                  variant='bold'
                ) {{ _('8-32 characters') }}

            row(
              alignment='center'
              space='2xs'
            )
              cell
                icon(
                  type='check'
                  size='xs'
                  :color='message.customMessage ? "success" : "brand-4"'
                )
              cell
                v-caption(
                  size='6' 
                  variant='bold'
                ) {{ _('at least one letter and one number') }}

        row(v-if='!recovery' offset)
          cell(cols='12')
            +b.A.link--default(@click='openModal("else")') 
              v-caption(size='5' :variant='["center", "bold"]') {{ _('forgot password?') }}
        row(offset)
          cell(cols='12')
            +b.control-box
              v-button(
                variant='success'
                type='submit'
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ recovery && !doLogin ? _('RESTORE') : _('SIGN IN') }}
          cell(cols='12')
            v-caption(size='5' :variant='["center", "bold"]') {{ _('If you don\'t have an account') }}
          cell(cols='12')
            +b.control-box
              v-button(
                variant='accent',
                @click='openModal("register", urls)'
              )
                v-button-element(size='lg')
                  v-caption(size='6' :variant='["center", "bold", "uppercase"]') {{ _('sign up') }}
</template>

<script>
import axios from 'axios'
import UserService from '@cabinet/services/api/user'
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import { successHandler, errorHandler } from '@cabinet/util'
import Preloader from '@cabinet/components/vendor/Preloader'
import { usePasswordValidation } from '@cabinet/composables/use-custom-validation'
import { mapState } from 'vuex'
import {editedSlug as provider} from '@cabinet/services'
import UiRegistration from '@cabinet/components/auth/UiRegistration'
import UiRestore from '@cabinet/components/auth/UiRestore'
import UiToken from '@cabinet/components/modals/UiToken'
export default {
  props: {
    recovery: {
      default: false,
    },
    uid: {
      default: () => {},
    },
    token: {
      default: () => {},
    },
    doLogin: {
      default: false,
    },
    next: {
      default: false,
    },
    type: {
      default: false,
    },
    urls: {
      type: Array
    }
  },
  components: {
    UiInput,
    Preloader,
  },

  computed: {
    ...mapState('userModule', ['prefix']),
    // condition() {
    //   return this.$route.name != 'login' || this.$route.name != 'confirmation'
    // },
    urls_() {
      return this.urls.map(url => {
        let icon = url.match(/\/accounts\/(.*)\/login\//)[1]
        let colors = {
          google: '#dc334a',
          twitter: '#2FA9DC',
          facebook: '#3F65AD'
        }
        
        return {
          url, 
          icon,
          color: colors[icon]
        }
      })
    },
    text() {
      if (this.recovery && this.doLogin === 'False') {
        return this._('restore password')
      } else if (this.doLogin && !this.recovery) {
        return this._('login')
      }
    },
  },
  watch: {
    'formData': {
      async handler(value) {
        let res = await usePasswordValidation(this.formData.new_password1)
        this.message = res
      },
      deep: true        
    }
  },
  data() {
    return {
      message: {},
      loading: false,
      formData: {
        email: '',
        password: '',
        new_password1: '',
        new_password2: '',
      },
    }
  },
  methods: {
    openModal(action, urls) {
      let file
      this.$emit('close')
      if (action == 'register') {
        file = UiRegistration
      } else {
        file = UiRestore
      }
      this.$modal.show(
        file,
        {
          urls: urls,
          type: this.type
        },
        {
          adaptive: true,
          height: 'auto',
          maxWidth: 330,
        }
      )
    },
    openTokenModal(url, next, type) {
      this.$emit('close')
      this.$modal.show(
        UiToken,
        {
          url: url,
          loginData: this.formData,
          next: next,
          type: type,
        },
        {
          adaptive: true,
          height: 'auto',
          maxWidth: 330,
        }
      )
    },
    async send() {
      const isValid = await this.$refs.observer.validate() 
      if (isValid) {
        this.loading = true
        if (this.doLogin === 'True' || !this.recovery) {
          UserService.login(this.formData)
            .then(response => {
              this.$emit('close')
              if (response.data.data.two_factor) {
                this.openTokenModal(
                  response.data.data.two_factor,
                  this.next,
                  this.type
                )
              } else {

                successHandler(response.data, this).then(res => {
                  if (this.next) {
                    if (next.includes('is_ajax')) {
                      axios.get(this.next).then(res => {
                        let url = window.URL.createObjectURL(new Blob([res.data]))
                        let link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `ticket.pkpass`)
                        document.body.appendChild(link)
                        link.click()
                      })

                    } else {
                      location.href = this.next
                    }
                  } else if (this.type == 'checkout') {
                    window.location = `${this.prefix}/checkout/`
                    return 
                  } else {
                    if (response.data.data.is_manager || response.data.data.is_staff || response.data.data.is_vendor) {
                      window.location = '/account/events/'
                    } else {
                      window.location = `/${provider}/account/`
                    }
                  }
                })
              }
              this.loading = false
            })
            .catch(err => {
              //(err)
              this.loading = false
              let { data: errors } = err.response
              errorHandler(errors.errors, this.$refs.observer, err.response, this)
            })
        } else {
          delete this.formData.password
          delete this.formData.email
          this.formData.uid = this.uid
          this.formData.token = this.token
          UserService.restorePassword(this.formData)
            .then(res => {
              this.loading = false
              successHandler(res.data, this)
            })
            .catch(err => {
              //(err)
              this.loading = false
              let { data: errors } = err.response
              errorHandler(errors.errors, this.$refs.observer, err.response, this)
            })
        }
      }
    },
  },
  mounted() {
  },
  
}
</script>



