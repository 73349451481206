<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'tag',
  modifierProps: ['type', 'round']
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      'div',
      { ...props.data, class: ['tag', $blockClasses, props.data.staticClass] },
      props.children
    )
  },
 
  props: {
    type: {
      type: [String, Array],
      default: ''
    },
    round: {
      type: [Boolean, String, Array],
      default: ''
    }
  },
}
</script>
