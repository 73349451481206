<template lang="pug">
  loading-wrapper(
    :loading='loading'
  )
    card
      SecurityAccordion
        template(v-slot:caption='')
          | {{ _('m Rules' )}}
        template(v-slot:content='')
          validation-observer(
            ref='observer'
            v-slot='{invalid}'
            tag='form'
            autocomplete='off'
          )
            card-panel()
              validation-provider(
                name='rules'
                rules='required'
              )
                wysiwyg(
                  v-model="rules"
                )
            card-panel(offset='vertical')
              row(offset space='xl-xl' justification='end')
                cell(cols='narrow')
                  v-button(
                    variant='success'
                    @click='send'
                  )
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ isEmpty ? _('save 1 ') : _('update 1 ') + _('rules') }}
</template>

<script>
import RulesService from '@cabinet/services/api/rules'
import { useResponse } from '@cabinet/composables/use-response'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import ModalSample from '@cabinet/components/modals/ModalSample'
export default {
  components: {
    SecurityAccordion,
  },
  data() {
    return {
      rules: '',
      loading: false,
      isEmpty: true
    }
  },
  methods: {
    setInitial() {
      return this.isEmpty = false
    },
    showModal({ title, content }, data = {data: {}, meta: {}}) {
    this.$modal.show(ModalSample, {
      title: title,
      content: content,
      redirect: data.redirect || data.meta.redirect,
      status: data.data.status || null,
      button: data.button,
      buttonText: data.buttonText,
    }, {
      width: 320,
      height: 'auto',
      adaptive: true,
      scrollable: true
    }, {
      'before-close'() {
        if (data.redirect || data.meta.redirect) {
          redirect(data.redirect || data.meta.redirect)
        }
      }
    })
    },
    async send() {
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        try {
          this.isEmpty ? await this.create() : await this.update()
          this.showModal({ title: this._('rules title'), content: this._('rules content')})
        } catch (error) {
          console.error(error)
        }
      }
      this.loading = false
    },
    async update(data = this.rules) {
      try {
        await useResponse(
          RulesService.update({rules: data }),
          this,
          this.$refs.observer
        )
      } catch (error) {
        console.log(error)
      }
    },
    async create(data = this.rules) {
      try {
        await useResponse(
          RulesService.create({rules: data }),
          this,
          this.$refs.observer
        )
      } catch (error) {
        console.log(error)
      }
    },
    async fetch() {
      try {
        const res = await useResponse(
          RulesService.receive(),
          this,
          this.$refs.observer
        )
        this.rules = res.data.rules
        if (res.data.rules.length) {
          this.setInitial()
        } 
      } catch (error) {
        console.log(error)
      }
    },
    
  },
  created() {
    this.fetch()
  }
}
</script>