<template lang='pug' functional>
  include /mixins
  ValidationProvider(:name='props.name' v-slot='{errors}')
    slot(name='error' :errors='errors')
      +b.control-hint.--styling_default.--variant_error(v-if='errors.length') {{ errors[0] }}

</template>

<script>
export default {
  name: 'Validation',
  props: {
    name: {
      required: true
    }
  }
}
</script>

