import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { withDefaultProps } from '@aspectus/vue-utils'

const provider = tag => withDefaultProps({ autocomplete: 'off' })(ValidationProvider)
Vue.component('ValidationProvider', provider())
Vue.component('ValidationObserver', ValidationObserver)


