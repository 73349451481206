<template lang='pug'>
include /mixins
row(offset)
  cell(cols='12')
    validation(name='non field errors')
  cell(v-if='ticket.color', cols='12')
    +b.tier-separator(:style='{ backgroundColor: ticket.color.color }')
  cell(cols='12')
    row(offset)
      cell(cols='1', v-if='!withdraw && !discount')
        v-button(
          @click='removeTickets(index)',
          :variant='["clear"]',
          type='button'
        )
          template(v-slot:prepend='')
            v-button-element(size='lg')
              icon(type='close', color='danger', size='xs')

      cell(cols='11 4-md', v-if='!withdraw && !discount')
        ui-input.form__control--styled(
          :placeholder='_("Name")',
          type='search',
          :name='index + "ticket title"',
          bg='relief-3',
          :val='ticket.title',
          v-model='ticket.title',
          autocomplete='off',
          validate='required'
        )
        ValidationProvider(v-slot='{ errors }', name='price descriptor seats')
          .form__error(v-if='errors[0]') {{ errors[0] }}
      cell(cols='12 2-md', v-if='!withdraw && !discount')
        ui-input(
          :placeholder='_("Price")',
          type='search',
          v-digitsonly='',
          :name='index + "ticket price"',
          :val='ticket.price',
          bg='relief-3',
          autocomplete='off',
          v-model='ticket.price',
          validate='required'
        )
      cell(cols='6 3-md', v-if='!withdraw && !discount')
        v-select(
          v-model='ticket.color',
          bg='relief-3',
          :full='true',
          :val='ticket.color',
          :options='colors',
          :placeholder='_("Color")',
          :name='index + "ticket color"',
          validate='required'
        )

      cell(cols='6 12-md', v-if='permissions && !withdraw && !discount')
        ValidationProvider(:name='index + "ticket ga"', v-slot='{ errors }')
          row(offset)
            cell(cols='12')
              row(alignment='center', space='md')
                cell(cols='narrow')
                  v-checkbox
                    +b.INPUT.control-checkbox__input(
                      type='checkbox',
                      v-model='ticket.ga'
                    )
                cell(cols='narrow')
                  v-caption(size='6', variant='regular') {{ _("Activate general admission") }}
            cell(cols='12')
              .form__error(v-if='errors[0]') {{ errors[0] }}
  slot(name='discounts') 
  +b.cell.cols--12
    form-repeater(v-model='ticket.info', :disable-remove='true')
      template(#default='repeater')
        //- v-for='(seat, idx) in ticket.info'
        //- :key='seat.id || idx'
        card(
          :name='index + "seat_block" + repeater.index',
          :variant='withdraw || discount ? "section" : ""',
          :key='`card_${repeater.index}`'
        )
          card-panel(:offset='withdraw || discount ? "full" : ""') 
            ticket-info(
              :seat='repeater.value',
              :ticket='ticket',
              :idx='repeater.index',
              :index='index',
              :withdraw='withdraw',
              :key='`ticket_info_${repeater.index}`',
              :discount='discount',
              @validate='$emit("validate", { seat: $event, index: index, idx: repeater.index })',
              @loading='loading = $event',
              @set-data='setData($event, repeater.index)',
              @set-info='setInfo($event)',
              @set-seats='setSeats($event)',
              @set-sectors='setSectors($event, repeater.index)',
              :sectors-from-props='[...componentSectors]',
              :observer='observer',
              :save='save',
              :is-admission-active='ticket.ga',
              :descriptors='descriptors'
            )
          //- :errors="errors"
        ValidationProvider(v-slot='{ errors }', :name='index + "seat_block"')
          cell(cols='12', v-if='errors.length')
            .form__error(v-for='(error, index) in errors', :key='index') {{ error }}
        ValidationProvider(
          v-slot='{ errors }',
          :name='index + "seat_block" + repeater.index'
        )
          cell(cols='12', v-if='errors.length')
            .form__error(v-for='(error, index) in errors', :key='index') {{ error }}
  cell(cols='12')
    slot(name='sector-errors')
  cell(cols='12')
    slot(name='seats-errors')

  cell(:cols='["12"]')
    row
      cell(cols='narrow')
        v-button(@click='addSeats(ticket)', type='button', variant='clear')
          cell(cols='narrow')
            v-button-element 
              v-caption(size='4', :variant='["bold"]', color='default') {{ _("add seats") }}
          template(v-slot:prepend='')
            cell(justification='center', cols='narrow')
              v-button-element 
                icon(type='add', color='success') 
</template>

<script>
import TicketInfo from './TicketInfo'
import ShowsService from '@cabinet/services/api/shows'
import { v4 as uuidv4 } from 'uuid'
import { mapActions, mapState } from 'vuex'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: [
    'observer',
    'ticket',
    'withdraw',
    'index',
    'form',
    'sectors',
    'colors',
    'save',
    'discount',
    'descriptors'
  ],
  components: {
    TicketInfo,
  },
  computed: {
    ...mapState('userModule', ['userData']),
    permissions() {
      return this.userData.permissions.general_admission
    },
  },
  data() {
    return {
      counter: 0,
      loading: false,
      componentSectors: [],
    }
  },
  watch: {
    sectors: {
      handler(val) {
        if (val && val.length) {
          this.componentSectors = [...JSON.parse(JSON.stringify(val))]
          // this.componentSectors.forEach(v => {
          //   if (v.row && v.row.length) {
          //     let row = v.row.find(i => i.id == 'all')
          //     if (!row) {
          //       v.row.unshift({ id: 'all', title: this._('all') })
          //     } 
          //     v.row.forEach(row => {
          //       if (row.seat && row.seat.length) {
          //         let item = row.seat.find(i => i.id == 'all')
          //         if (!item) {
          //           row.seat.unshift({ id: 'all', title: this._('all') })
          //         }
          //       }
          //     })
          //   }
          // })
        }
      }
    },
    'ticket': {
      async handler(val) {
        setTimeout(() => {
          let { seats, sectors } = val.info.reduce((acc, info) => {

            if (info.sector && info.sector.type === 'no_seats') {
              acc.sectors.push(info.sector)
            }
            if (info.seats && info.seats.length) {
              acc.seats.push(...info.seats)
            }
            return acc
          }, {
            seats: [],
            sectors: []
          })
          if (this.ticket.color) {
            if (sectors.length) {
              sectors.forEach(s => {
                let sectorOnMap = document.querySelector(`[data-title="${s.title}"]`)
                if (this.ticket.color.color && sectorOnMap) {
                  sectorOnMap.style.fill = val.color.color
                }
              })
            }
            if (seats.length) {
              seats.forEach(s => {
                let seatOnMap = document.getElementById(s.geom_id)
                if (this.ticket.color.color && seatOnMap) {
                  seatOnMap.style.fill = val.color.color
                }
              })
            }

          }
          if (val.seats && val.seats.length && val.ga) {
            this.observer.setErrors({
              [`${this.index}ticket ga`]: [this._(`Seats cannot be assigned as general admission`)]
            })
          }
        }, 100)
      },
      deep: true,
    },


    // sectors: {
    //   handler(sectors) {

    //   // immediate: true,
    // },
  },
  methods: {
    ...mapActions('hallModule', ['getSectorData']),
    ...mapActions('showsModule', ['deleteTickets', 'deleteSector']),
    setSectors(data, idx) {
      console.log('setSectors')
      this.$set(this.ticket, 'sectors', data)
    },
    setSeats(data) {
      console.log('setSeats', data)
      this.$set(this.ticket, 'seats', data)
    },
    setInfo(data) {
      console.log('setInfo', data)
      this.$set(this.ticket, 'info', data)
    },
    setData(data, idx) {
      console.log('setData', data, idx)
      this.$set(this.ticket.info, idx, {
        ...this.ticket.info[idx],
        ...data
      })
    },
    /**
     * @param { int } index - индекс текущего price_descriptor
     * @param { Object } ticket - текущий price_descriptor
     * @emits remove - индекс price_descriptor, который нужно удалить из массива price_descriptors
     * */
    wipeTicketData(index, ticket) {
      // очищаем цвета всех секторов и мест, которые находились в дескрипторе
      ticket.info.forEach(i => {
        if (i.sector.type === 'no_seats') {
          let sector = document.getElementById(i.sector.geom_id)
          this.changeColor(sector)
        }
        if (i.seats && i.seats.length) {
          i.seats.forEach(s => {
            let seat = document.getElementById(s.geom_id)
            this.changeColor(seat)
          })
        }
      })
      /**
       * Remove event.
       * @property {int} index - индекс текущего price_descriptor
       */
      this.$emit('remove', index)
    },
    /**
     * удаляет места, ряды, сектора
     * @param { int } index - индекс текущего price_descriptor
     * @param { Object } ticket - текущий price_descriptor
    */
    async removeTickets(index, ticket = this.ticket) {
      if (ticket.id) {
        try {
          await useResponse(ShowsService.deleteTickets(ticket.id), this)
          this.wipeTicketData(index, ticket)
        } catch (error) {
          console.log(error)
        }
      } else {
        this.wipeTicketData(index, ticket)
      }
    },
    /**
     * изменяет цвет дом ноды
     * @param { Node.ELEMENT_NODE } item - дом нодa(circle, polygon)
     * */
    changeColor(item) {
      item.style.fill = '#00A0E3'
    },
    /**
     * добавляет в текущий price_descriptor еще один TicketInfo
     * @param { Object } ticket - ref props.ticket
     * */
    addSeats(ticket = this.ticket) {
      ticket.info.push({
        sector: {},
        row: {},
        seats: [],
        id: uuidv4()
      })
    },
  },
  mounted() {
    if (Number.isInteger(this.ticket.color)) {
      this.ticket.color = this.colors.find(color => color.id === this.ticket.color)
    }
    this.$emit('loaded')
  }
}

</script>

