<template lang='pug'>
  include /mixins
  .form__control
    label.form__label 
      .form__label-text(class='is-active') {{ $attrs.placeholder }}
      slot(name='input')
    .form__input-icon(v-if='$slots.icon')
      slot(name='icon')
    slot(name='dropdown')
    slot(name='error')
      transition(name='fade')
        +b.control-hint.--styling_default.--variant_error(v-if='errors.has($attrs.name)') {{ errors.first($attrs.name) }}
</template>

<script>
import { inputMixin } from '@cabinet/mixin/input.js'
export default {
  inheritAttrs: false,
  props: {
    inputType: {
      default: '!number'  
    },
    val: {
      default: () => {},
    },
  },
  mixins: [ inputMixin ],
  data() {
    return {
      active: false
    }
  },
  mounted() {
    
  }
}
</script>
