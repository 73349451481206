<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    style='display: flex'
    autocomplete='off'
  )
    loading-wrapper(:loading='loading')
      card(variant='fullheight')
        card-panel(offset='full' :variant='["fullheight", "flex"]')
          row(offset)
            cell(cols='12')
              +b.UI-TABS.tabs.mt-1(ref='tab')
                template(v-slot='{ activeItem, activate }')
                  +b.tab-list
                    +e.body
                      //- @click='isDisabled ? () => null : activateLocaly(index, true)'
                      +e.toggle--variant_default.tab-list__item(
                        @click='activateLocaly(index, true)'
                        v-for='(item, index) in stepsList'
                      )
                        v-caption(
                          :style='item.disabled ? "pointer-events:none;opacity:.5;" : ""'
                          :variant="[activeItem === index ? 'bold' : 'secondary', 'uppercase']"
                        ) {{ index + 1}} {{ _('hall step') }}
          card(:variant='["section", "flex"]' stacked='top' style='flex-grow:11111') 
            card-panel(:offset='["bottom"]' style='flex-grow:11111').svg-item
              row(offset)
                cell(cols='12')
                  card(:offset='["top", "bottom"]' :variant='["offset"]')
                    //- card-panel(offset='full')
                    //-   validation(name='server_error')


                    card-panel(offset='full')   
                      validation(name='server_error')
                      transition(:name='transitionName' mode='out-in')
                        component(:is='activeComponent'
                                  :form-data='formData'
                                  :index='activeStep'
                                  @fetch='setFormData($event)'
                                  @validation='deactivateButton($event)'
                                  ref='activeStep').child-view
            card-panel(:offset='["horizontal", "bottom"]' style='flex-grow:1')
              row(offset :justification='["center", "end-md"]' key='row')
                cell(cols='12 auto-sm 2-md' v-if='activeStep' key='1')
                  +b.control-box
                    v-button(
                      @click='activateLocaly(activeStep, "decrease")'
                      type='button'
                      :disabled='!active || !formData.editable'
                    )
                      cell(justification='center')
                        v-button-element(size='lg')
                          v-caption(size='6', :variant='["bold", "uppercase"]') {{ _('prev step') }}


                cell(cols='12 auto-sm 2-md' key='2')
                  +b.control-box
                    v-button(
                      @click='save'
                      variant='success'
                      type='button'
                      :disabled='!formData.editable'
                    )
                      cell(justification='center')
                        v-button-element(size='lg')
                          v-caption(size='6', :variant='["bold", "uppercase"]') {{ _('save') }}
                      //- :class='!active ? "is-disabled" : ""'


                cell(cols='12 auto-sm 2-md' v-if='isNotLast' key='3')
                  v-button(
                    @click='activateLocaly(activeStep, "increase")'
                    type='button'
                    :disabled='!active || !formData.editable'
                  )
                    cell(justification='center')
                      v-button-element(size='lg')
                        v-caption(size='6', :variant='["bold", "uppercase"]') {{ _('next step') }}
</template>

<script>
import { successHandler, errorHandler } from '@cabinet/util'
import { EventBus } from '@cabinet/components/eventbus/eventbus'
import axios from 'axios'

import UiTabs from '@cabinet/components/vendor/UiTabs'
import StepFirst from '@cabinet/components/hall/StepFirst'
import StepSecond from '@cabinet/components/hall/StepSecond'
import StepThird from '@cabinet/components/hall/StepThird'
import { mapActions } from 'vuex'
export default {
  components: {
    UiTabs,
    StepFirst,
    StepSecond,
    StepThird,
  },
  computed: {
    isNotLast() {
      return this.activeStep < this.steps.length - 1
    },
    activeComponent() {
      return this.steps[this.activeStep]
    },
  },
  data() {
    return {
      active: false,
      stepsList: [
        {
          // name: this._('1 step'),
          disabled: false,
        },
        {
          // name: this._('2 step'),
          disabled: true,
        },
        {
          // name: this._('3 step'),
          disabled: true,
        },
      ],
      hash: 0,
      transitionName: 'slide-left',
      steps: ['StepFirst', 'StepSecond', 'StepThird'],
      activeStep: 0,

      loading: false,
      formData: {
        has_schema: true,
        editable: true,
        title: '',
        location: {
          country: '',
          subdivision: '',
          city: '',
        },

        seats_file: ``,
        elements_file: ``,
        sectors_file: ``,

      },
      seats_file: ``,
      elements_file: ``,
      sectors_file: ``,
    }
  },
  methods: {
    deactivateButton({ valid }) {
      this.active = valid
    },
    ...mapActions('hallModule', ['fetchHall', 'updateHall', 'postBaseData']),
    isStepValid(value) {
      this.stepsList[value].disabled = false
    },
    setFormData(data) {
      let { seats_file, sectors_file } = data
      this.seats_file = seats_file
      this.sectors_file = sectors_file
      // this.elements_file = elements_file
      this.formData.seats_file = seats_file
      this.formData.sectors_file = sectors_file
      // this.formData.elements_file = elements_file
      // let status = JSON.parse(JSON.stringify(this.formData.status))
      // this.formData.status = {
      //   id: status.toLowerCase(),
      //   title: status,
      // }
      // this.stepsList.map(s => (s.disabled = false))
    },
    async transform(data) {
      let localData = JSON.parse(JSON.stringify(data))
      EventBus.$emit('require')
      Object.keys(localData).forEach(async k => {
        //


        Object.keys(localData.location).forEach(k => {
          if (
            (k == 'country' || k == 'subdivision' || k == 'city') &&
            localData.location[k].id
          ) {
            localData.location[k] = localData.location[k].id
          }
          if (k == 'latitude' || k == 'longitude') {
            localData.location[k] = localData.location[k]
          }
        })

        if (
          (k == 'status' || k == 'type') &&
          (localData[k].id > -1 || localData[k].id)
        ) {
          localData[k] = localData[k].id
        } else if (typeof localData[k] == 'string') {
          if (localData[k].includes('.svg')) {
            delete localData[k]
          } else if (localData[k].includes('svg+xml')) {
            localData[k] = localData[k]
          }
        } else if (
          !localData[k] &&
          typeof localData[k] !== 'boolean'
        ) {
          delete localData[k]
        }
      })

      //
      return localData
    },
    async save() {
      this.loading = true
      let isValid = await this.$refs.activeStep.$refs.observer.validate()
      if (isValid) {
        let data = await this.transform(this.formData)
        //
        if (this.$route.params.id) {
          let obj = {
            data: data,
            id: this.$route.params.id,
          }

          if (this.activeStep === 2) {
            this.$refs.activeStep.workWithSectors()
              .then(res => {
                let result = res.filter(i => i)
                if (result.length > 0) {
                  result.forEach(res => {
                    let { errors } = res.response.data
                    errorHandler(errors, this.$refs.observer)
                    this.loading = false
                  })
                } else {
                  this.updateHall(obj)
                    .then(res => {
                      successHandler(res.data, this)
                      this.loading = false
                      this.$router.push({ name: 'halls-list' })
                    })

                  this.loading = false

                }
              })


          } else {
            this.updateHall(obj)
              .then(res => {
                successHandler(res.data, this)
                this.loading = false
                this.$router.push({ name: 'halls-list' })
              })
              .catch(error => {
                let { errors } = error.response.data
                errorHandler(errors, this.$refs.observer)
                this.loading = false
              })
          }

        } else {
          this.postBaseData({ data })
            .then(res => {
              successHandler(res.data, this)
              this.$router.push({ name: 'halls-list' })
              this.loading = false
            })
            .catch(error => {
              let { errors } = error.response.data
              errorHandler(errors, this)
              this.loading = false
            })
        }

      } else {
        this.loading = false
      }
    },
    showModal() {
      this.$modal.show(
        {
          template: `
          <div class="modal-wrapper">
            <div class="modal-wrapper__close btn btn--flex btn--center" @click="$emit(&quot;close&quot;)">
              <div class="btn__icon btn__icon--default icon-close"></div>
            </div>
            <div class='row row--column row--center'>
              <div class='row__el'>
                <h2>${this._('this hall data IS NOT EDITABLE!')}</h2>
              </div>
            </div>
          </div>
          `,
          inheritAttrs: false,
        },
        {},
        {
          width: 320,
          height: 'auto',
          adaptive: true,
          scrollable: true,
        }
      )
    },
    activateLocaly(index, type) {

      // TODO ADD IT ON PRODUCTION
      // if (!this.formData.editable) {
      //   this.showModal()
      //   return
      // }
      // TODO ADD IT ON PRODUCTION
      // TODO comment it ON PRODUCTION
      let hash = index => (window.location.hash = `step${index + 1}`)
      // TODO comment it ON PRODUCTION

      // if (!this.formData.has_schema) {
      //   this.activeStep = type === 'decrease' ? 0 : this.steps.length - 1
      //   this.$refs.activeStep.save()
      //   this.$refs.tab.activate(this.activeStep)
      //   return
      // }

      setTimeout(async () => {
        let result = type ? await this.$refs.activeStep.save() : { valid: true }
        this.$refs.observer.reset()
        if (!result.valid) {
          this.$refs.observer.setErrors({
            server_error: [this._('Unable to save data')]
          })
          return
        }

        if (!this.formData.has_schema) {
          if (type) {
            index = type === 'decrease' ? 0 : this.steps.length - 1
          }
        } else {
          if (type === 'increase') {
            index++
          } else if (type === 'decrease') {
            index--
          }
        }
        this.isStepValid(index)
        this.activeStep = index
        if (index < this.steps.length && index >= 0) {
          setTimeout(() => {
            this.$nextTick(() => {
              // TODO comment it ON PRODUCTION
              hash(index)
              // TODO comment it ON PRODUCTION
              this.$refs.tab.activate(index)
            })
          })
        } else if (this.activeStep < 0) {
          this.activeStep = 0
          // TODO comment it ON PRODUCTION
          hash(0)
          // TODO comment it ON PRODUCTION
        } else if (this.activeStep >= this.steps.length) {
          this.activeStep = this.steps.length - 1
          // TODO comment it ON PRODUCTION
          hash(this.activeStep)
          // TODO comment it ON PRODUCTION
        }
      }, 1)
    },
  },
  async mounted() {

    if (this.$route.params.id) {
      this.formData = await this.fetchHall(this.$route.params.id)
      let status = JSON.parse(JSON.stringify(this.formData.status))
      if (
        this.formData.sectors_file &&
        this.formData.sectors_file.includes('.svg')
      ) {
        this.sectors_file = (await axios.get(this.formData.sectors_file)).data
      }
      if (
        this.formData.seats_file &&
        this.formData.seats_file.includes('.svg')
      ) {
        this.seats_file = (await axios.get(this.formData.seats_file)).data
      }
      if (
        this.formData.elements_file &&
        this.formData.elements_file.includes('.svg')
      ) {
        this.elements_file = (await axios.get(this.formData.elements_file)).data
      }


      this.formData.status = {
        id: status.toLowerCase(),
        title: status,
      }
      this.stepsList.forEach(s => s.disabled = status === 'published')
    }
    EventBus.$on('fetchHall', async () => {
      try {
        this.formData = await this.fetchHall(this.$route.params.id)
        let status = JSON.parse(JSON.stringify(this.formData.status))
        this.formData.status = {
          id: status.toLowerCase(),
          title: status,
        }
        this.stepsList.forEach(s => s.disabled = status === 'published')
      } catch (error) {}
    })

    // TODO comment it ON PRODUCTION
    let hash = window.location.hash
    setTimeout(() => {
      hash = hash.split('#step')[1] - 1 || this.activeStep
      this.activeStep = hash
      this.activateLocaly(hash)
    }, 100)
    // TODO comment it ON PRODUCTION
  },
  // beforeRouteUpdate(to, from, next) {
  // },
}
</script>
