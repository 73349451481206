<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'icon',
  modifierProps: ['size', 'color', 'type', 'variant']
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    
    return createElement(
      'span',
      { ...props.data, class: ['icon', $blockClasses, props.data.staticClass] },
      props.children
    )
  },
  props: {
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Icon name.
     */
    
    name: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * Icon size. Might be a list.
     */
    size: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: null
    },
  }
}

</script>
