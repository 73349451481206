<template lang="pug">
  card
    SecurityAccordion(:active="false")
      template(v-slot:caption='')
        | {{ _('s security settings' )}}
      template(v-slot:content='')
        card-panel
          card-panel(offset='vertical')
            SecurityEmail
          card-panel(variant='footer' offset='top')
            SecurityPassword
          card-panel(offset='top')
            card
              card-panel(offset='top')
                UiTwoFactor
</template>

<script>
// import { ref } from '@vue/composition-api'

import SecurityPassword from '@cabinet/components/settings/SecurityPassword'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import SecurityEmail from '@cabinet/components/settings/SecurityEmail'
import UiTwoFactor from '@cabinet/components/forms/UiTwoFactor'

export default {
  components: {
    SecurityPassword,
    SecurityAccordion,
    SecurityEmail,
    UiTwoFactor,
  },
  setup(props, context) {
    return {

    }
  }
}
</script>