import Api, { editedSlug as provider } from '@cabinet/services'

export default {
  fetchProviderList(payload) {
    console.log(payload)
    return Api().get(`/order/list/`, {
      params: payload
    })
  },
  refund({ id, data }) {
    return Api().post(`/order/${id}/refund/`, data)
  },
  fetchProviderOrder(id) {
    return Api().get(`/order/${id}/receive/`)
  },
  updateCurrent(data) {
    return Api().put(`/order/checkout/`, data.info, {
      headers: {
        provider
      }
    })
  },
  update(data) {
    return Api().put(`/order/update/`, data, {
      headers: {
        provider
      }
    })
  },
  create(data) {
    return Api().post(`/order/create/`, data, {
      headers: {
        provider
      }
    })
  },
  appPay(data) {
    return Api().post('/apple-pay/order/confirmation/local/', data, {
      headers: {
        provider
      }
    })
  },
  printEnvelope(id) {
    return Api().get(`/order/envelope/${id}/?as=pdf`)
  },
  seeEnvelope(data) {
    return Api().get(`/order/envelope/${id}/`, data)
  },
  getShipments() {
    return Api().get(`/order/shipments/`, {
      headers: {
        provider
      }
    })
  },
  getPayments() {
    return Api().get(`/order/payments/`, {
      headers: {
        provider
      }
    })
  },
  updateOrderDelivery({ id, data }) {
    return Api().post(`/order/${id}/update-delivery/`, data)
  },
  createIntent() {
    return Api().get('/order/applepay-intent/create/', {
      headers: {
        provider
      }
    })
  }
}
