<template lang="pug">
include /mixins
loading-wrapper(:loading='loading')
  validation-observer(
    ref='observer',
    v-slot='{ invalid }',
    tag='form',
    @submit.prevent='submit'
  )
    card(variant='tour')
      card-panel(offset='full')
        card-panel(offset='bottom', v-if='shipments.length')
          row(offset)
            cell(cols='12')
              v-caption(size='4', variant='bold') {{ _("Choose delivery method:") }}

            cell(cols='12')
              ValidationProvider(
                v-slot='{ errors }',
                name='shipment',
                rules='required'
              )
                row(offset, space='sm')
                  cell(v-for='method in shipments', :key='method.id')
                    row(alignment='center', space='md', offset)
                      cell
                        .control-input.control-input--variant_radio
                          row(offset, space='5xs')
                            cell(cols='12')
                              row(
                                offset,
                                alignment='center',
                                space='5xs',
                                nowrap
                              )
                                cell(cols='narrow')
                                  +b.LABEL.control-radio
                                    +b.INPUT.control-radio__element(
                                      type='radio',
                                      v-model='formData.shipment',
                                      :value='method.id'
                                    )
                                    +e.label
                                cell(cols='auto')
                                  v-caption(size='6') {{ method.title }}
                  cell(cols='12', v-if='errors[0]')
                    .form__error {{ errors[0] }}

        card-panel(offset='bottom', v-if='payments.length')
          row(offset)
            cell(cols='12')
              v-caption(size='4', variant='bold') {{ _("Choose pay method:") }}
            cell(cols='12')
              ValidationProvider(
                v-slot='{ errors }',
                name='payment',
                rules='required'
              )
                row(offset, space='sm')
                  cell(v-for='method in payments', :key='method.title')
                    row(alignment='center', space='md', offset)
                      cell
                        .control-input.control-input--variant_radio
                          row(offset, space='5xs')
                            cell(cols='12')
                              row(
                                offset,
                                alignment='center',
                                space='5xs',
                                nowrap
                              )
                                cell(cols='narrow')
                                  +b.LABEL.control-radio
                                    +b.INPUT.control-radio__element(
                                      type='radio',
                                      v-model='formData.payment',
                                      :value='method.id'
                                    )
                                    +e.label
                                cell(cols='auto')
                                  v-caption(size='6') {{ method.label }}
                  cell(cols='12', v-if='errors[0]')
                    .form__error {{ errors[0] }}
        card-panel(offset='bottom')
          row(offset)
            cell(cols='6 4-md 6-lg 4-xl')
              ui-input(
                :placeholder='_("c Email")',
                type='email',
                name='email',
                v-model='formData.email',
                bg='relief-3',
                size='lg',
                border='whole',
                validate='required'
              )
            cell(cols='6 4-md 6-lg 4-xl')
              ui-input(
                :placeholder='_("c Phone number")',
                type='tel',
                name='phone',
                v-model='formData.phone',
                bg='relief-3',
                size='lg',
                v-mask='["(###) ###-####"]',
                border='whole',
                validate='required'
              )
        card-panel(offset='bottom')
          row(offset)
            cell(cols='12')
              v-caption(size='4', variant='bold') {{ _("c Billing address:") }}
            cell(cols='12')
              row(offset)
                cell(cols='6 4-md 6-lg')
                  ui-input(
                    :placeholder='_("First name*")',
                    :val='formData.billing_address.first_name',
                    type='search',
                    name='billing address first name',
                    bg='relief-3',
                    size='lg',
                    border='whole',
                    v-model='formData.billing_address.first_name',
                    validate='required|alpha_spaces'
                  )
                cell(cols='6 4-md 6-lg')
                  ui-input(
                    :placeholder='_("Last name*")',
                    :val='formData.billing_address.last_name',
                    type='search',
                    name='billing address last name',
                    bg='relief-3',
                    size='lg',
                    border='whole',
                    v-model='formData.billing_address.last_name',
                    validate='required|alpha_spaces'
                  )

                cell(cols='8 10-md 8-lg 6-xl')
                  ui-input(
                    :placeholder='_("Address *")',
                    :val='formData.billing_address.address_line_1',
                    type='search',
                    name='billing address address',
                    v-model='formData.billing_address.address_line_1',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='4 2-md 4-lg 6-xl')
                  ui-input(
                    :placeholder='_("Unit #")',
                    :val='formData.billing_address.unit',
                    type='search',
                    name='billing address unit',
                    v-model='formData.billing_address.unit',
                    validate='',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 3-xl')
                  ui-input(
                    v-model='formData.billing_address.city',
                    :val='formData.billing_address.city',
                    :placeholder='_("City *")',
                    name='billing address city',
                    type='search',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 3-xl')
                  ui-input(
                    v-model='formData.billing_address.subdivision',
                    :val='formData.billing_address.subdivision',
                    :placeholder='_("State/Province *")',
                    name='billing address state',
                    type='search',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 3-xl')
                  ui-input(
                    :placeholder='_("Postal Code *")',
                    :val='formData.billing_address.zipcode',
                    name='billing address zipcode',
                    type='search',
                    v-model='formData.billing_address.zipcode',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 3-xl')
                  ui-input(
                    v-model='formData.billing_address.country',
                    :val='formData.billing_address.country',
                    :placeholder='_("Country *")',
                    name='billing address country',
                    type='search',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )

        card-panel(offset='bottom')
          row(offset)
            cell(cols='12')
              v-caption(size='4', variant='bold') {{ _("c Delivery address:") }}
            cell(cols='12')
              row(offset)
                cell(cols='6 4-md 6-lg 4-xl')
                  ui-input(
                    :placeholder='_("First name*")',
                    :val='formData.delivery_address.first_name',
                    type='search',
                    name='delivery address first name',
                    bg='relief-3',
                    size='lg',
                    border='whole',
                    v-model='formData.delivery_address.first_name',
                    validate='required|alpha_spaces'
                  )
                cell(cols='6 4-md 6-lg 4-xl')
                  ui-input(
                    :placeholder='_("Last name*")',
                    :val='formData.delivery_address.last_name',
                    type='search',
                    name='delivery address last name',
                    bg='relief-3',
                    size='lg',
                    border='whole',
                    v-model='formData.delivery_address.last_name',
                    validate='required|alpha_spaces'
                  )
                cell(cols='6 4-md 6-lg 4-xl')
                  ui-input(
                    v-model='formData.delivery_address.city',
                    :val='formData.delivery_address.city',
                    :placeholder='_("City *")',
                    name='delivery address city',
                    type='search',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='8 10-md 8-lg 10-xl')
                  ui-input(
                    :placeholder='_("Address *")',
                    :val='formData.delivery_address.address_line_1',
                    type='search',
                    name='delivery address address',
                    v-model='formData.delivery_address.address_line_1',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='4 2-md 4-lg 2-xl')
                  ui-input(
                    :placeholder='_("Unit #")',
                    :val='formData.delivery_address.unit',
                    type='search',
                    name='delivery address unit',
                    v-model='formData.delivery_address.unit',
                    validate='',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 4-xl')
                  ui-input(
                    v-model='formData.delivery_address.subdivision',
                    :val='formData.delivery_address.subdivision',
                    :placeholder='_("State/Province *")',
                    name='delivery address state',
                    type='search',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 4-xl')
                  ui-input(
                    :placeholder='_("Postal Code *")',
                    :val='formData.delivery_address.zipcode',
                    name='delivery address zipcode',
                    type='search',
                    v-model='formData.delivery_address.zipcode',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
                cell(cols='6 4-md 6-lg 4-xl')
                  ui-input(
                    v-model='formData.delivery_address.country',
                    :val='formData.delivery_address.country',
                    :placeholder='_("Country *")',
                    name='delivery address country',
                    type='search',
                    validate='required',
                    bg='relief-3',
                    size='lg',
                    border='whole'
                  )
        card-panel
          row(offset)
            cell(cols='12 6-md 12-lg', v-if='isPayment === "stripe"')
              card
              card-panel(offset='full', :variant='["bordered", "border"]')
                StripeCard.stripe-card(
                  :class='{ complete }',
                  :stripe='token',
                  :options='stripeOptions',
                  @change='complete = $event.complete'
                )
            //- cell(
            //-   cols='12 6-md 12-lg',
            //-   v-if='userData.email === "admin@mail.com"'
            //- )
            //-   div(v-if='token')
            //-     StripeBtn(
            //-       :total-amount='1',
            //-       currency='usd',
            //-       country='US',
            //-       :total-label='"usd"',
            //-       :pk='token'
            //-     )
            cell(cols='12 6-md 12-lg', v-if='isPayment === "authorize"')
              ui-credit-card-view(
                :email='formData.email',
                :number='formData.authorize.number',
                :date='formData.authorize.date',
                :name='formData.billing_address.first_name',
                :surname='formData.billing_address.last_name',
                :code='formData.authorize.code'
              )

            cell(cols='12 6-md', v-if='isPayment === "authorize"')
              ui-input.form__control--styled(
                :placeholder='_("Card number*")',
                :val='formData.authorize.number',
                type='search',
                inputmode='numeric',
                name='authorize number',
                size='lg',
                bg='relief-3',
                v-mask='\'#### #### #### ####\'',
                v-model='formData.authorize.number',
                validate='required|credit'
              )
            cell(cols='6 3-md', v-if='isPayment === "authorize"')
              ui-input.form__control--styled(
                :placeholder='_("month/year*")',
                :val='formData.authorize.date',
                type='search',
                inputmode='numeric',
                name='authorize date',
                v-mask='\'##/##\'',
                size='lg',
                bg='relief-3',
                v-model='formData.authorize.date',
                validate='required|length:5|date'
              )

            cell(cols='6 3-md', v-if='isPayment === "authorize"')
              ui-input.form__control--styled(
                :placeholder='_("CVV2 code*")',
                :val='formData.authorize.code',
                type='search',
                v-digitsonly='',
                inputmode='numeric',
                name='cvv',
                size='lg',
                bg='relief-3',
                v-model='formData.authorize.code',
                validate='required|max:4'
              )
            cell(cols='12 6-md 12-lg')
              row(offset)
                cell(cols='12')
                  ui-input(
                    :placeholder='_("Comment")',
                    type='textarea',
                    size='lg',
                    name='comment',
                    :val='formData.comment',
                    bg='relief-3',
                    border='whole',
                    v-model='formData.comment',
                    validate=''
                  )
                cell(cols='12')
                  row(offset, alignment='center')
                    cell(cols='12 6-sm 12-md 6-xl')
                      checkbox(
                        name='agree',
                        :rules='{ required: { allowFalse: false } }'
                      )
                        template(v-slot='')
                          +b.INPUT.control-checkbox__input(
                            type='checkbox',
                            v-model='agree',
                            size='lg',
                            border='whole',
                            :value='agree'
                          )
                        template(v-slot:text='')
                          slot(name='site-rules')
                            a(href='/')
                              v-caption(size='5') {{ _("I agree with site rules *") }}
                    cell(cols='12 6-sm 12-md 6-xl')
                      v-button(
                        type='submit',
                        variant='success',
                        border='whole'
                      )
                        cell.cols(justification='center')
                          v-button-element(size='lg')
                            v-caption(size='4', variant='bold') {{ _("Go to payment") }}
</template>

<script>
import OrdersService from '@cabinet/services/api/orders'
import Checkbox from '@cabinet/components/forms/blocks/Checkbox'
import { mapActions, mapState } from 'vuex'
import { successHandler, errorHandler } from '@cabinet/util'
import { Card, createToken } from 'vue-stripe-elements-plus'

import { isEmpty, mergeLeft } from 'ramda'

import StripeBtn from '@cabinet/components/forms/StripeBtn'

// import { checkPayment } from '@cabinet/components/forms/blocks/checkStripePaument'
import UiCreditCardView from '@cabinet/components/forms/UiCreditCardView'

export default {
  components: {
    UiCreditCardView,
    Checkbox,
    StripeCard: Card,
    StripeBtn,
  },

  computed: {
    ...mapState('userModule', ['userData']),
    isPayment() {
      let payment = this.payments.find(p => p.id === this.formData.payment)
      return payment && payment.title
    },
    isNotDelivery() {
      let item = this.payments.find(i => i.id == this.formData.payment)
      return item && item.title === 'cash'
    },
    canActivate() {
      let item = this.shipments.find(i => i.id == this.formData.shipment)
      return item && item.is_delivery_field
    },
    token() {
      if (isEmpty(this.formData.payment)) {
        return null
      }
      return this.payments.find(i => i.id == this.formData.payment).public_key
    },
    stripeMethod() {
      return this.payments.filter(i => i.title === 'stripe')[0]
    }
  },
  props: {
    order: {
      required: true
    }
  },
  watch: {
    complete: {
      handler(val) {
        if (val) {
          createToken(this.token)
            .then(data => {
              this.formData.stripe_token = data.token.id
            })
        }
      }
    },
    'formData.shipment': {
      handler(val) {
        const shipment = this.shipments.find(p => p.id == val)
        if (shipment) {
          this.$emit('shipment', shipment.price)
        } else {
          this.$emit('shipment', 0)
        }
      }
    },
    'formData.payment': {
      handler(val) {
        const cash = this.payments.find(p => p.title === 'cash')
        if (cash && val === cash.id) {
          this.$emit('cash-selected', true)
        } else {
          this.$emit('cash-selected', false)
        }
      }
    }
  },
  data() {
    return {
      complete: false,
      stripeOptions: {
        style: {
          fontFamily: 'Montserrat',
          backgroundColor: '#f4f8f9',
        },
        hidePostalCode: true
      },
      shipments: [],
      payments: [],
      loading: false,
      invisible: true,
      invisibleCaptcha: false,
      predictions: [],
      delivery_predictions: [],
      agree: false,
      formData: {
        is_applepay: false,
        authorize: {
          code: '',
          number: '',
          date: ''
        },
        shipment: '',
        payment: '',
        delivery_address: {
          country: '',
          subdivision: '',
          city: '',
          address_line_1: '',
          address_line_2: "",
          unit: "",
          zipcode: "",
          first_name: "",
          last_name: ""
        },
        email: '',
        phone: '',
        billing_address: {
          country: '',
          subdivision: '',
          city: '',
          address_line_1: '',
          address_line_2: "",
          unit: "",
          zipcode: "",
          first_name: "",
          last_name: ""
        },
        comment: '',
      },
    }
  },
  methods: {
    ...mapActions('ordersModule', [
      'updateOrderInfo'
    ]),
    transform(data) {
      let form = { ...data }

      let payment = this.payments.find(p => p.id === this.formData.payment)

      if (payment.title === 'authorize') {
        let date = form.authorize.date.split('/')
        date = [`20${date[1]}`, date[0]].join('-')
        form.authorize.date = date
      } else {
        delete form.authorize
      }
      form.tz = new Date().getTimezoneOffset() / 60
      return form
    },
    async submit() {
      // await store.dispatch('ordersModule/createOrder', this.cartInfo.id)
      if (!this.invisibleCaptcha) {

        this.$refs.observer.validate().then(result => {

          if (result) {
            if (this.formData.payment === 'apple-id') {
              this.formData.is_applepay = true
              this.formData.payment = this.stripeMethod.id
            }
            this.loading = true
            let form = this.transform(JSON.parse(JSON.stringify(this.formData)))



            let data = {
              id: this.order.id,
              info: form

            }
            this.updateOrderInfo(data)
              .then(res => {
                console.log(res)
                // localStorage.setItem('url', '')
                successHandler(res.data)
                this.loading = false
                this.formData.authorize.code = ''
                this.formData.authorize.number = ''
                this.formData.authorize.date = ''
                this.formData.is_applepay = false
                this.$refs.observer.reset()
              })
              .catch(err => {
                //(err)
                this.loading = false
                console.error(err)
                let { data: errors } = err.response
                errorHandler(errors.errors, this.$refs.observer, err.response, this)
              })
          }
        })
      }
    },
    createApplePayment() {
      const paymentMethod = {
        label: 'ApplePay',
        title: 'apple',
        id: 'apple-id'
      }
      const applePayment = mergeLeft(paymentMethod, this.stripeMethod)
      this.payments.push(applePayment)
    }
  },
  async mounted() {
    let script = document.createElement('script')
    script.setAttribute('src', 'https://js.stripe.com/v3/')
    document.head.appendChild(script)
    try {
      this.shipments = (await OrdersService.getShipments()).data.data
    } catch (err) {
      console.error(err)
    }

    try {
      this.payments = (await OrdersService.getPayments()).data.data

    } catch (err) {
      console.error(err)
    }

    // const resultPayement = await checkPayment({ pk: this.stripeMethod.public_key })
    // if (resultPayement) {
    //   this.createApplePayment()
    // }

    if (this.userData.profile) {
      if (this.userData.profile.delivery_address || this.userData.profile.billing_address) {
        this.formData.billing_address = this.userData.profile.delivery_address || this.userData.profile.billing_address
        this.formData.billing_address.zipcode = this.userData.profile.delivery_address ? this.userData.profile.delivery_address.postal_code : this.userData.profile.billing_address.postal_code
      }
      this.formData.first_name = this.userData.profile.first_name
      this.formData.last_name = this.userData.profile.last_name

      if (this.userData.profile.delivery_address) {
        this.formData.phone = this.userData.profile.delivery_address.phone
      } else if (this.userData.profile.billing_address) {
        this.formData.phone = this.userData.profile.billing_address.phone
      } else {
        this.formData.phone = this.userData.profile.phone
      }
      this.formData.email = this.userData.email
    }
  }
}
</script>
