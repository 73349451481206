<template lang="pug">
  include /mixins
  section.section
    card
      card-panel(:offset='["horizontal", "bottom"]')
        v-button(
            
            @click='$router.go(-1)'
            :variant='["clear", "narrow"]' 
            alignment='center'
          )
            template(v-slot:prepend='')
              cell
                v-button-element()
                  icon(
                    type='left-arrow' 
                    color='default'
                    size='xs'
                  )
            cell(cols='auto')
              v-button-element
                v-caption(
                  variant='bold' 
                  size='6'
                  color='default'
                ) {{ _('ag back') }}
      card-panel(offset='horizontal')
        card-panel()
          card(variant='tour')
            card-panel(offset='full')
              row(offset)
                cell(cols='12')
                  row(offset space='lg-md')
                    cell(cols='12 3-md 2-lg')
                      +b.aspect-ratio--ratio_1x1.--cuted.--variant_cover
                        +e.IMG.element( :src='event.preview || "/static/images/poster-stub.png"')
                          
                    cell(cols='12 auto-md')
                      card(variant='offset')
                        card-panel(variant='offset')
                          row(offset justification='between')
                            cell(cols='12 6-2xl')
                              row(offset)
                                cell(cols='12')
                                  row(offset alignment='center' space='5xs')
                                    cell(cols='narrow')
                                      v-tag(type='brand')
                                        row(offset alignment='cener')
                                          cell(cols='narrow')
                                            v-caption(size='6' color='brand-4') {{ event.type ? event.type.title : ''}}
                                    cell(cols='narrow')
                                      v-tag(:type='event.status ? event.status.type : ""')
                                        row(offset alignment='center')
                                          cell(cols='narrow')
                                            icon(:type='event.status ? event.status.icon : ""' size='sm' color='brand-2')
                                          cell(cols='narrow')
                                            v-caption(size='6' color='white') {{ event.status ? event.status.title : ''}}
                                cell(cols='12')
                                  card-panel(variant='offset_bottom')
                                    v-caption(size='4' variant='bold') {{ event.title || _('TAYLOR SWIFT TOUR 2018') }}

                                cell(cols='12')
                                  card-panel(offset='bottom_md-2xl')
                                    row(
                                      offset 
                                      alignment='center'
                                    )
                                      cell(cols='narrow')
                                        row(
                                          offset 
                                          alignment='center' 
                                          space='5xs'
                                        )
                                          cell(cols='narrow')
                                            icon(type='calendar' size='sm' color='warning')
                                          cell(cols='narrow')
                                            v-caption(size='6' color='brand-4') {{ event.start_at | humanDate }} - {{ event.finish_at | humanDate }} 

                                      cell(cols='narrow')
                                        v-caption(size='6' variant='regular') {{_("t Events:") }} {{ counter }}
                                cell(cols='12')
                                  card-panel(:offset='["top", "top_xl-2xl"]')
                                    row(offset)
                                      cell(cols='narrow')
                                        +b.control-box
                                          v-button(
                                            variant='danger'
                                            border='whole'
                                            tag='router-link'
                                            :to='"/account/events/"+$route.params.id+"/add-show"'
                                          )
                                            template(v-slot:prepend='')
                                              cell
                                                v-button-element(
                                                  size='lg'
                                                  variant="warning-lite"
                                                )
                                                  v-caption(variant='bold')
                                                    icon(
                                                      type='add'
                                                      size='lg'
                                                      variant='opaque'
                                                    )
                                            cell(cols='auto')
                                              v-button-element(size='lg') 
                                                v-caption(
                                                  :variant='["bold", "uppercase"]' 
                                                  size='6'
                                                ) {{ _('t Add event') }}
                                      cell(cols='narrow')
                                        +b.control-box
                                          v-button(
                                            variant='success-lite'
                                            border='whole'
                                            @click='editTour'
                                          )
                                            template(v-slot:prepend='')
                                              cell
                                                v-button-element(
                                                  size='lg'
                                                  variant="success"
                                                )
                                                  v-caption(variant='bold')
                                                    icon(
                                                      type='cog'
                                                      size='lg'
                                                      variant='opaque'
                                                    )
                                            cell(cols='auto')
                                              v-button-element(size='lg') 
                                                v-caption(
                                                  :variant='["bold", "uppercase"]' 
                                                  size='6'
                                                ) {{ _('t Edit') }}
                            cell(cols='12 4-2xl' alignment='bottom')
                              row(offset)
                                cell(cols='12')
                                  progress-bar(:bars='[pricePercent]' :amount='event.statistics && "$"+event.statistics.sold_price')
                                    template(v-slot='')
                                      row(justification='between')
                                        cell(cols='narrow')
                                          v-caption(
                                            size='6' 
                                            color='brand-2'
                                            variant='regular'
                                          ) {{ _('t Sold tickets:') }}
                                        cell(cols='narrow')
                                          v-caption(
                                            size='6' 
                                            variant='secondary' 
                                            color='brand-3'
                                          ) 
                                            row(offset space='none')
                                              cell(cols='narrow')
                                                icon(type='dollar' color='brand-3')  
                                              cell(cols='narrow')
                                                span {{ event.statistics && event.statistics.total_price | toUSD }}
                                cell(cols='12')
                                  progress-bar(:bars='[qtyPercent]' :amount='event.statistics && event.statistics.tickets_amount')
                                    template(v-slot='')
                                      row(justification='between')
                                        cell(cols='narrow')
                                          v-caption(
                                            size='6' 
                                            color='brand-2'
                                            variant='regular'
                                          ) {{ _('t Sold tickets (qty):') }}
                                        cell(cols='narrow')
                                          v-caption(
                                            size='6'
                                            variant='secondary' 
                                            color='brand-3'
                                          )
                                            span {{ qtyPercent.toFixed(2) }}%

        card-panel(:offset='["vertical", "vertical-md"]')
          row(offset)
            cell(cols='narrow')
              ui-tabs
                template(v-slot='{ activeItem, activate }')
                  row(offset space='xs')
                    cell(
                      cols='narrow'
                      v-for='(item, index) in [ { type: "active", text: _("Active") }, { type: "archived", text: _("Archived") } ]'
                      :key='index'
                    )
                      v-button(
                        @click='activateTab(index, item.type, activate)'
                        :variant='["clear", "block", "shadow", "tab", item.type !== type ? "opaque": ""]'
                        border='round'
                      ) 
                        v-button-element(size='md')
                          v-caption(
                            size='4'
                            :variant='["bold"]' 
                            :color="item.type === type ? 'warning' : 'default'"
                          ) {{ item.text }}                                          
            cell(cols='12 6-md')
              ui-search(
                @input='send($event)' 
                type='search'
                size='md'
              )
                template(v-slot:activator='{send, data}')  
                  cell(cols='12 narrow-md')
                    v-button(
                      variant='success-lite'
                      size='lg'
                      :border='["top_right", "bottom_right"]'
                      @click='send(data)'
                    )
                      cell(cols='auto')
                        v-button-element(size='regular') 
                          v-caption(
                            :variant='["bold", "uppercase"]' 
                            size='6'
                          ) {{ _('s search') }}
        card-panel(v-if='shows[type].events_row.length')
          card-panel
            row(offset ref='wrapper')
              cell(cols='12')
                loading-wrapper(:loading='loading')
                  row(offset)
                    cell(cols='12 6-md 4-lg 2-10-2xl'
                      v-for='(show, index) in shows[type].events_row'
                      :key='show.id'
                    )
                      ui-show(
                        :show='show'
                        @loading='deleteEvent($event)'
                        )
          
          ui-pagination(
            :from-client='true'
            :offset='pagination.offset'
            :total='pagination.total'
            :limit='pagination.limit'
            v-if='pagination.total > pagination.limit'
            @input='sendData({page: $event})'
          )
        card-panel(v-else)  
          notice
            template(v-slot:icon='')
              icon(
                type='error' 
                color='warning' 
                size='5xl'
              )
            template(v-slot:caption='')
              v-caption(variant='bold') {{ _('List is empty')}}

</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { dateFormatter, formatToUTC } from '@cabinet/util'
import { EventBus } from '@cabinet/components/eventbus/eventbus'

import { eventStatuses } from '@cabinet/components/utils/types'
import { useOpenTourModal } from '@cabinet/composables/use-tour-modal'

import UiTabs from '@cabinet/components/vendor/UiTabs'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import UiSearch from '@cabinet/components/forms/UiSearch'
import UiShow from '@cabinet/components/default/UiShow'

export default {
  components: {
    UiTabs,
    UiPagination,
    UiSearch,
    UiShow,
  },
  data() {
    return {
      loading: true,
      tabs: [
        { type: 'active', text: this._('active') },
        { type: 'archived', text: this._('archived') },
      ],
      event: {
      },
    }
  },
  computed: {
    ...mapState('showsModule', ['type', 'counter', 'shows']),
    ...mapGetters('eventsModule', ['eventStatuses', 'eventTypes']),
    pagination() {
      return this.$store.state.showsModule.shows[this.type].pagination
    },
    humanCase() {
      return this._(this.event.status.split('_').join(' '))
    },
    pricePercent() {
      return this.event.statistics && this.event.statistics.sold_price * 100 / this.event.statistics.total_price
    },
    qtyPercent() {
      return this.event.statistics && this.event.statistics.tickets_amount * 100 / this.event.statistics.seats_amount || 0
    }
  },
  methods: {
    humanDate(val) {
      if (val) {
        val = val.replace(/-/g, '/').split('T')[0].split('/')
        val = [val[1], val[2], val[0]].join('/')
        return val
      }
    },
    send(search) {
      this.sendData(search)
    },
    editTour() {
      useOpenTourModal(this.$route.params.id, this, this.event)
    },
    async activateTab(index, type, fn) {
      fn(index)
      this.setType(type)
      let date = formatToUTC()
      // date = date.substring(0, date.lastIndexOf(":"))
      // date = date.replace('T', ' ')
      this.loading = true
      if (type == 'archived') {
        const query = { finish_at__lt: date}
        await this.fetchShows({ id: this.$route.params.id, query })
      } else {
        const query = { finish_at__gte: date}
        await this.fetchShows({ id: this.$route.params.id, query })
      }
      this.loading = false
    },
    async sendData(data) {
      this.loading = true
      // let query = Object.keys(data).map(k => `${k}=${data[k]}`).join('&')
      await this.fetchShows({ id: this.$route.params.id, query: data })
      
      // let { wrapper } = this.$refs
      // let { top } = wrapper.getBoundingClientRect()
      // window.scrollBy({
      //   top: top - 200,
      //   left: 0,
      //   behavior: 'smooth'
      // })
      this.loading = false
    },
    ...mapActions('showsModule', [
      'setType', 
      'fetchShows', 
      'setCounter', 
      'getShowOptions'
    ]),
    ...mapActions('eventsModule', ['fetchEvent', 'getEventStatuses']),
    // ...mapActions('vendorsModule', ['fetchVendors']),
    async fetchData() {
      this.event = await this.fetchEvent(this.$route.params.id)
      this.event.status = {
        ...this.eventStatuses.find(s => s.title === this.event.status)
      }

      let status = eventStatuses[this.event.status.id]
      this.event.status.type = status.type
      this.event.status.icon = status.icon
      this.event.type = this.eventTypes.find(s => s.id === this.event.type)
    },
    deleteEvent(event) {
      this.loading = event.state
      if (event.resp) {
        this.activateTab(1, this.type, e => {})
      } else {
        return
      }
    }
  },
  // async created() {
    

  // },
  async mounted() {

    this.$store.commit('showsModule/SET_SHOWS', {data: [], meta: {pagination: {}}})
    this.loading = true
    await this.getEventStatuses()
    await this.getShowOptions()
    let date = formatToUTC()
    let query = {
      finish_at__gte: date
    }
    if (this.$route.query.highlight) {

      query = {
        ...query,
        push: this.$route.query.highlight
      }
      this.$store.state.showsModule.type = 'active'
      if (new Date(this.$route.query.date) < new Date()) {
        this.$store.state.showsModule.type = 'archived'
        let date = dateFormatter(new Date(), true)
        query = {
          finish_at__lt: date,
          push: this.$route.query.highlight
        }
      }
    }
    await this.fetchData()
    await this.fetchShows({ id: this.$route.params.id, query})
    
    this.$router.replace({
      query: ''
    })
    
    this.setCounter(this.event.shows.reduce((acc, s) => (acc += 1), 0))
    
    
    this.loading = false

    EventBus.$on('updated', this.fetchData)
  },
}
</script>
