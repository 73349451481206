<template lang="pug">
  include /mixins
  cell(cols='narrow')
    v-caption(size='6' display='sm')
      countdown.checkout-sidebar__watch(
        v-if='!isNaN(localTime)'
        :auto-start='true'
        :time="localTime"
        @progress='transformTime($event)'
        v-slot="{ days, hours, minutes, seconds }"
      )
        row(offset nowrap space='none')
          cell(cols='narrow')
            v-caption(size='6') {{ minutes | counter }}
          cell(cols='narrow')
            v-caption(size='6') :
          cell(cols='narrow')
            v-caption(size='6') {{ seconds | counter }} {{ _('remaining') }}
</template>

<script>

import Countdown from './Countdown'
import UiInfo from '@cabinet/components/modals/UiInfo'
export default {
  components: {
    Countdown
  },
  props: {
    time: {
      required: true
    },
    admin: {
      default: false
    },
    client: {
      default: true
    },
    booking: {
      default: false
    }
  },
  data: () => ({
    localTime: 0
  }),
  methods: {
    log() {
      this.$modal.show(
        UiInfo,
        {},
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }, {
          'before-close': () => {
            location.reload()
          }
        }
      )
    },
    transformTime({ minutes, seconds }) {
      const SECONDS = 60
      const MILLISECONDS = 1000
      const currentTime = minutes * SECONDS * MILLISECONDS + seconds * MILLISECONDS
      this.$store.commit('cartModule/SET_CURRENT_TIMET_VALUE', currentTime)
    }
  },
  watch: {
    time: {
      handler(nval, oval) {
        if (nval != oval && !this.admin) {
          this.localTime = nval
        } else {
          this.localTime = parseFloat(nval) * 1000 - new Date().getTime()
        }
      },
      immediate: true
    }
  }
}
</script>
