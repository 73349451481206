<template lang="pug">
  include /mixins
  +b.form__control(:data-disabled='style.data'
                             :style='style.style')
    label.form__label(:for='$attrs.name')
      .form__label-text(:class='{ "is-active": active }' v-if='$attrs.placeholder') {{ $attrs.placeholder }}
                //- :class='classes'
    multiselect(v-model='checked'
                :allow-empty="taggable ? true : false"
                :options='dropdownData'
                :name='$attrs.name'
                :searchable='true'
                :show-labels='false'
                :close-on-select='true'
                :id='$attrs.name'
                :multiple="multiple"
                :taggable='taggable'
                ref='multiselect'
                track-by='title',
                placeholder=''
                :label='"title" || "id"'
                @open='active = true'
                :max-height='100'
                v-validate='$attrs.validate'
                @tag='addTag'
                style='z-index: 10'
                @select='checkVal'
                @close='proxy.id > -1 || proxy.length || proxy ? active = true : active = false;$emit("input", proxy)')
      span(slot="noResult") {{ _('Oops! No elements found. Consider changing the search query.') }}
      template(v-slot:option="props")
        .option__desc(:title='props.option.tooltip')
          span.option__title {{ props.option.title }}
          //- span.option__small {{ props.option.desc }}
      template(#singleLabel="{ option }")
        span {{ customLabel(option) }}

      template(#tag="{ option, remove }")
        .custom__tag
          +b.row.--center
            +e.el
              p(style='line-height: 1').no-padding {{ option.title || option.id }}
            +e.el
              +b.custom__remove.btn__icon--warning.--regular.icon-close(@click.stop.prevent="remove(option)"
                                                                        style='line-height: 1')
    slot(name='error')
      transition(name='fade')
        .form__error(v-if='errors.has($attrs.name)') {{ errors.first($attrs.name) }}
        
      
</template>

<script>
export default {
  inject: ["$validator"],
  computed: {
    disabledStyles() {
      return this.disabled ? 'pointer-events: none;opacity: .5' : ''
    },
    transform() {
      return this.proxy.length ? 'top:55px;position:relative;' : ''
    },
    style() {
      if (this.relations) {
        return {
          style: {
            'pointer-events': !this.relatedTo ? 'none' : 'all',
          },
          data: !this.relatedTo ? true : false,
        }
      }
      return {
        style: '',
        data: false,
      }
    },
    classes() {
      return 'p-form__input'
    },
    checked: {
      get() {
        this.proxy = this.val
        return this.proxy
      },
      set(val) {
        this.proxy = val
      },
    },
  },
  watch: {
    val: {
      handler(nval, oval) {
        if (nval < -1) {
          this.active = false
        } else {
          this.active = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    disabled: {
      default: null
    },
    taggable: {
      default: false,
    },
    multiple: {
      default: false,
    },
    relations: {
      default: false,
    },
    relatedTo: {
      default: false,
    },
    val: {
      required: true,
      default: () => {},
    },
    dropdownData: {
      required: true,
      type: Array,
    },
    full: {
      default: false
    },
    searchable: {
      default: false
    }
  },

  data() {
    return {
      active: false,
      proxy: '',
    }
  },
  methods: {
    customLabel({ title }) {
      if (title && !this.full) {
        return title.length > 7 ? title.substring(0, 7).concat('...') : title
      }
      return title
    },
    checkVal(event) {
      //(this.proxy)
      if (event && this.proxy && event.title != this.proxy.title) {
        this.$emit('change')
      }
      
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      }
      this.options.push(tag)
      this.checked.push(tag)
    },
  },
}
</script>

