<template lang="pug">
  svg(
    width="165" 
    height="165"
    
    style='transform: rotate(-90deg)'
  )
    
    
    g(
      stroke-width="10"
      stroke-dasharray="999 999"
      stroke-linecap="round"
      stroke='#59bf48'
      fill="none"
    )
      circle(
        :r="r" 
        cx="50%" 
        cy="50%"
      )
    g(
      stroke-width="10"
      :stroke-dasharray="stroke"
      stroke-linecap="round"
      stroke='#dc334a'
      fill="none"
    )
      circle(
        :r="r" 
        cx="50%" 
        cy="50%"
      )
</template>

<script>
export default {
  computed: {
    percent() {
      return this.chartData.active * 100 / (this.chartData.active + this.chartData.archive)
    },
    stroke() {
      let round = 2 * this.r * Math.PI
      return `${this.percent * round / 100} 999`
    }
  },
  props: {
    chartData: {
      required: true,
      default: () => ({ 
        active: 20,
        archive: 40
      })
    },
  },
  data() {
    return {
      r: 77.5
    }
  },
}
</script>