<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card(space="xl")
      card-panel(:offset='["bottom"]')
        row(offset)
          cell(
            cols='12'
            v-for='(ticket, ticket_index) in descriptors'
            :key='`ticket_${ticket.id}`'
          )
            +b.BOOKING-CARD(
              v-for='(descriptor, index) in ticket.sectors'
              :key='`descriptor_${descriptor}`'
              :ticket='descriptor'
              :is-left-tickets='isLeftTickets'
              :add-ticket='addTicket'
            ) 
              template(v-slot:title='')
                | {{ ticket.title }}
              //- template(v-slot:sector-title='')
              //-   | {{ descriptor.sector.title }}
              template(v-slot:label='')
                span {{ _('sold out') }}
              //- template(v-slot:sector='')
              //-   span {{ _('b sector')}}
              template(v-slot:tickets='')
                span {{ _('tickets left:')}} 
              template(v-slot:price='') 
                | $ {{ ticket.price | toUSD }} 
              template(v-slot:counter='') 
                ui-counter(
                  :count='descriptor.booked'
                  :initial='0'
                  :max='maxSeats'
                  @changeCount='checkBooked($event, descriptor)'
                )
                  template(v-slot:count='') 
                    v-caption(size='6 5-md', variant="center") {{ descriptor.booked }}
              template(v-slot:button-text='')
                span {{ _('order now') }}

</template>

<script>
import { mapActions } from 'vuex'
import BookingCard from '@cabinet/components/booking/BookingCard'
import UiCounter from '@cabinet/components/atoms/UiCounter'
import CartService from '@cabinet/services/api/cart'
import { errorHandler } from '@cabinet/util'
export default {
  components: {
    BookingCard,
    UiCounter,
  },
  props: {
    descriptors: {
      type: Array
    },
    isLeftTickets: {
      type: Boolean
    },
    id: {
      required: true
    },
    showId: {
    },
  },
  data() {
    return {
      loading: false,
      is_left_tickets: false,
    }
  },
  computed: {
    maxSeats() {
      return 12
    }
  },
  methods: {
    ...mapActions('cartModule', ['addItem', 'fetchMainCart']),
    ...mapActions('showsModule', ['fetchShow']),
    checkBooked(value, ticket) {
      if (value >= ticket.free_seats) {
        ticket.booked = ticket.free_seats
      } else {
        ticket.booked = value
      }
    },
    addTicket(ticket) {
      if (ticket.booked) {
        let items = new Array(ticket.booked)
          .fill({ type: 'sector', id: ticket.id })
        this.postTickets(items, ticket)
      }
    },
    async mergeShowCart(show) {
      try {
        this.loading = true
        const res = await CartService.mergeShowCart({ id: show })
        if (res.data || res.meta) {
          this.loading = false
        }
        await this.fetchMainCart()
      } catch (err) {
        console.log(err)
        this.loading = false
        let { data: errors } = err.response
        errorHandler(errors.errors, null, err.response, this)
      }
    },
    postTickets(items, ticket) {
      this.loading = true
      this.addItem({ items: { items }, id: this.id })
        .then(res => {
          this.loading = false
          ticket.free_seats -= ticket.booked
          ticket.booked = 0
          // this.$forceUpdate()
          this.mergeShowCart(this.showId)
        })
        .catch(error => {
          let { errors } = error.response.data
          this.loading = false
          errorHandler(errors, this.$refs.observer, error.response, this)
        })
    }
  },
  async mounted() {
    // let { sector_descriptor, is_left_tickets, start_at /* , sale */ } = await this.fetchShow(this.id)
    // this.start_at = start_at
    // this.is_left_tickets = is_left_tickets
    // this.loading = false
  },
}
</script>

