<template lang="pug">
  include /mixins
  div.checkout-sidebar__container
    div.checkout-sidebar__content(v-if='cartItems.length > 0')
      row
        cell(cols='auto')
          v-caption(size='6' display='sm' variant='bold') {{ cartItems.length }} {{ cartItems.length > 1 ? _('tickets') : _('ticket') }}
        cell(cols='auto')
          v-caption(size='6' display='sm')
            countdown.checkout-sidebar__watch(
              v-if='localTime'
              :auto-start='true'
              :time="localTime"
              v-slot="{ days, hours, minutes, seconds }"
            )
              row(offset nowrap space='none')
                cell(cols='narrow')
                  v-caption(size='6') {{ minutes | counter }}
                cell(cols='narrow')
                  v-caption(size='6') :
                cell(cols='narrow')
                  v-caption(size='6') {{ seconds | counter }} {{ _('remaining') }}
    div.checkout-sidebar__content.checkout-sidebar__content--top
      div(v-if='loading')
        .loading-wrapper__overlay(
          :style='loading ? "display: block;" : ""'
        )
        loading.loading-wrapper__indicator(
          :style='loading ? "display: block;" : ""'
          size='xl'
        )
      div.checkout-sidebar__scroll-container
        div.checkout-ticket__container(v-for='ticket in cartItems')
          button.checkout-ticket__remove-btn.checkout-ticket__remove-btn--absolute(@click.prevent='remove(ticket)')
          row(justification='between')
            cell(cols='auto' v-if='ticket.sector')
              v-caption(size='6' display='sm') {{ _('Sector:') }}
              v-caption(size='4' variant='bold') {{ ticket.sector.title }}
            cell(cols='auto' v-if='ticket.row')
              v-caption(size='6' display='sm') {{ _('Row:') }}
              v-caption(size='4' variant='bold') {{ ticket.row }}
            cell(cols='auto' v-else)
              v-caption(size='6' display='sm') {{ _('Type:') }}
              v-caption(size='4' variant='bold') {{ ticket.title }}

            cell(cols='auto' v-if='ticket.seat')
              v-caption(size='6' display='sm') {{ _('Seat:') }}
              v-caption(size='4' variant='bold') {{ ticket.seat.title }}
          div.checkout-ticket__border
          card(:space='["sm"]')
            card-panel(offset='bottom')
              v-caption(size='5' display='sm') {{ ticket.show.title}}
          v-caption(size='5' display='sm' variant='bold') {{ ticket.show.currency }} {{ ticket.price }}
    div.checkout-sidebar__content.checkout-sidebar__content--bottom
      card(:space='["md"]')
        card-panel(offset='bottom')
          v-caption(v-if='cartItems.length === 0' size='5', :variant="['bold', 'center']") {{ _('Select a seat') }}
      card(:space='["sm"]')
        card-panel(offset='bottom')
          row(justify='between')
            cell(cols='auto')
              v-caption(size='4' variant='bold') {{ _('Total:') }}
            cell(cols='narrow')
              v-caption(size='4' variant='bold') {{ currency }} {{ total }}
      card-panel(offset='vertical')
        promocode(:disabled='cartItems.length === 0')
      v-button(
        :variant='["accent"]'
        border='whole'
        :class="{'is-disabled': cartItems.length === 0 || loading}"
        @click.prevent='$emit("redirect")'
      )
        v-button-element(size='lg')
          row(justification='center')
            card(:space='["sm"]')
              card-panel(offset='right')
                icon(type='right-arrow', color='white')
            v-caption(
              size='6'
              :variant='["uppercase", "bold"]'
            ) {{ _('Checkout') }}
</template>

<script>
import { mapActions } from 'vuex'
import Countdown from './../../components/vendor/Countdown.vue'
import Promocode from '@cabinet/components/booking/Promocode'

export default {
  components: {
    Countdown,
    Promocode,
  },
  props: {
    showId: {
      required: true,
    },
    cartItems: {
      type: Array,
    },
    getTime: {
      type: Number,
    },
    localTime: {
      type: Number,
    },
    total: {
      required: true,
      type: String,
    },
    currency: {
      required: false,
      type: String,
      default: 'USD',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions('cartModule', ['fetchCart', 'fetchMainCart', 'removeItem', 'removeMainCartItem', 'fetchFullCart']),
    async remove(ticket) {
      const data = [ticket.id]
      this.loading = true
      try {
        await this.removeMainCartItem({ tickets: data, id: ticket.id, type: ticket.type })
        await this.fetchFullCart()
        if (ticket.sector.title !== 'ga') this.$emit('update', ticket.seat.geom_id)
        this.loading = false
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    },
  },
}
</script>