<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'caption',
  modifierProps: ['size', 'display', 'color', 'variant'],
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      props.props.tag,
      { class: ['caption', $blockClasses, props.data.staticClass], style: props.data.staticStyle },
      props.children
    )
  },
  bem: {
    block: 'caption',
    modifierProps: ['size', 'display', 'color', 'variant']
  },
  props: {
    variant: {
      type: [String, Array],
      default: ''
    },
    color: {
      type: [String, Array],
      default: ''
    },
    display: {
      type: [String, Array],
      default: ''
    },
    size: {
      type: [String, Array],
      default: ''
    },
    tag: {
      type: String,
      default: 'p'
    }
  },
}
</script>
