<template lang="pug">
include /mixins
+b.COMPONENT(
  :is='tag',
  :class='["control-button", $blockClasses]',
  v-bind='$attrs',
  v-on='$listeners',
  :type='type'
)
  row(
    offset,
    space='none',
    :justification='justification',
    :alignment='alignment',
    :nowrap='nowrap'
  )
    slot(name='prepend')
    slot
    slot(name='append')
</template>


<script>
import BemComponentMixin from '@cabinet/components/utils/bem/BemComponentMixin.js'
export default {
  mixins: [BemComponentMixin],
  bem: {
    block: 'control-button',
    modifierProps: ['size', 'variant', 'border', 'hover', 'nowrap']
  },
  props: {
    nowrap: {
      default: false
    },
    hover: {
      default: ""
    },
    tag: {
      default: 'button'
    },
    type: {
      default: 'button'
    },
    variant: {
      type: [String, Array],
      default: ''
    },
    size: {
      type: [String, Array],
      default: ''
    },
    border: {
      type: [String, Array],
      default: ''
    },
    justification: {
      type: [String, Array],
      default: 'center'
    },
    alignment: {
      type: String,
      default: ''
    }
  },
}
</script>
