<template lang="pug">
  include /mixins
  .file-input
    row(column alignment='center' offset)
      cell(cols='')
        +b.control-box()
          v-button(
            variant='success-lite' 
            tag='label' 
            :for='name' 
            border='whole'
            alignment='center'
          )
            template(v-slot:append='')
              cell
                v-button-element(
                  size='lg'
                  variant="success"
                )
                  v-caption(variant='bold')
                    icon(type='upload'
                        size='md')
            cell(cols='auto')
              v-button-element
                v-caption(
                  :variant='["bold", "uppercase"]' 
                  size='6'
                ) {{ _('Upload ') }} {{ field }}
          input(type='file'
                style='display:none'
                accept='image/svg+xml'
                :name='name'
                ref='image'
                :id='name'
                @change='getFile($event.target.files, name);')
          transition(name='fade')
            .form__error(
              v-if='!formData[name] || error'
              style='margin: 0 auto; text-align: center'
            ) {{ error ? _("image is not valid") : _('Field is required')}}
      cell(cols='')
        v-caption(
          size='5'
          :variant='["center", "secondary"]'
          color='brand-4'
        ) {{ _('Size: 1020*980 px') }}
      cell(cols='')
        //- div(v-html='svg'
        //-     v-if='!formData[name]')
        .file-input__img-wrapper
          img.file-input__stub(v-if='!svg' src='/static/images/poster-stub.png')
          div(v-html='svg'
              ref='svg'
              style='position:relative').file-input__svg
      //- img(:src='svg || formData[name] || "/static/images/poster-stub.png"'
      //-     style='position:relative').svg-wrapper
  
</template>

<script>
import axios from 'axios'
import UiButton from '@cabinet/components/forms/blocks/UiButton'
import { mapActions } from 'vuex'
export default {
  components: {
    UiButton,
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  data() {
    return {
      svg: '',
      error: false
    }
  },
  props: {
    formData: {
      required: true,
    },
    name: {
      required: true,
    },
    field: {
      required: true,
    },
  },
  methods: {
    ...mapActions('hallModule', ['getSectorsList', 'deleteSector']),
    svgValidation(svg, value) {
      let obj = {
        'sectors': () => {
          return svg.querySelector('circle') || svg.querySelector('line') 
        },
        'seats': () => {
          return svg.querySelector('polygon')
        },
        'elements': () => {
          return false
        }
      }
      return obj[value]()
    },
    /* removeUseless(svg, value) {
      let obj = {
        'sectors': () => {
          return svg.querySelector('circle') || svg.querySelector('line') 
        },
        'seats': () => {
          return svg.querySelector('polygon')
        },
        'elements': () => {
          return false
        }
      }
    }, */
    getFile(files, model) {
      this.formData[model] = ''
      for (var i = 0; i < files.length; i++) {
        var file = files[i]
        var reader = new FileReader()
        reader.onload = e => {
          let val = e.target.result
          this.formData[model] = val
          let svgS = atob(
            e.target.result.replace(/data:image\/svg\+xml;base64,/, '')
          )
          // svgS = svgS.replace('ï»¿', '')
          this.svg = svgS

          let { svg: svgWrapper } = this.$refs
          this.$nextTick(() => {
            let svg = svgWrapper.querySelector('svg')
            let val = new XMLSerializer().serializeToString(svg)
            this.svg = val
            // this.removeUseless(this.$refs.svg, this.field)
            this.error = this.svgValidation(this.$refs.svg, this.field)
            if (!this.error) {
              this.$emit('validation', {valid: true, model: this.field})
            } else {
              this.$emit('validation', {valid: false, model: this.field})
            }
          })
          if (this.id) {
            this.getSectorsList({ id: this.id }).then(res => {
              res.data.forEach(sector => {
                this.deleteSector(sector.id)
              })
            })
          }
        }
        reader.onerror = () => {
          if (this.formData[model]) {
            this.formData[model] = this.formData[model]
          }
        }
        reader.readAsDataURL(file)
      }
    },
  },
  async created() {
    if (this.formData[this.name] && this.formData[this.name].includes('.svg')) {
      let value = this.formData[this.name]
      this.svg = (await axios.get(value)).data
    }
  },
}
</script>


