<template lang="pug">
  include /mixins
  +b-context('table')
    +e.TBODY.tbody
      +e.TR.row
      +e.TR.row(@click='toggleExpand' style='cursor:pointer')
        +e.TD.cell.--relief-3.--bordered_left_top(:class='!expanded && "table__cell--bordered_left_bottom"')
          v-caption(size='6' variant='bold') {{ letter.id }}
        +e.TD.cell.--relief-3 
          v-caption(size='6' variant='bold') {{ letter.subject }}
        +e.TD.cell.--relief-3 
          v-caption(size='6' variant='bold') {{ letter.status }}
        +e.TD.cell.--relief-3.--bordered_right_top(:class='!expanded && "table__cell--bordered_right_bottom"')
          v-caption(size='6' variant='bold') {{ date }}
      
      transition-group(name='fade' tag='tr' class='table__row')
        +e.TD.cell.--relief-3(
          colspan='1' 
          v-show='expanded' 
          key='1'
        )
          row(offset)
            cell(cols='12')
              v-caption(size='6') {{ _('i to:') }}
        +e.TD.cell--relief-3(
          colspan='3' 
          v-show='expanded' 
          key='2'
        )
          row(offset)
            cell(cols='12')
              v-caption(size='6') {{ letter.recipients }}
      transition-group(name='fade' tag='tr' class='table__row')
        +e.TD.cell.--relief-3(
          colspan='1' 
          v-show='expanded' 
          key='3'
        )
        +e.TD.cell--relief-3(
          colspan='3' 
          v-show='expanded' 
          key='2'
        )
          row(offset)
            cell(cols='12')
              .caption.caption--size_6(v-html='letter.plain') 
      transition-group(name='fade' tag='tr' class='table__row')
        +e.TD.cell.--relief-3.--bordered_left_bottom(
          colspan='1' 
          key='1'
          v-show='expanded' 
        )
          row(offset)
            cell(cols='12')
              v-caption(size='6') {{ _('i Attachment:') }}
        +e.TD.cell--relief-3.--bordered_right_bottom(
          colspan='3' 
          key='2'
          v-show='expanded' 
        )
          row(offset alignment='center')
            cell(
              cols='narrow' 
              v-for='attach in letter.attachments'
              :key='attach.id'
            )
              row(offset space='5xs' alignment='center')
                cell(cols='narrow')
                  icon(type='attach')
                cell(cols='narrow')
                  v-caption(size='6') 
                    a(
                      :href='attach.attachment' 
                      download
                    ).link {{ _('i attachment') }}
      +e.TR.row

</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  props: {
    letter: {
      required: true,
      type: Object
    }
  },
  computed: {
    date() {
      let date = new Date(this.letter.created)
      let d = new Date(date.valueOf() - date.getTimezoneOffset() * 60000)
      return d.toLocaleString('en-US', {timeZone: 'America/New_York', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})
    }
  },
  setup(props, context) {
    const expanded = ref(false)
    const toggleExpand = () => {
      expanded.value = !expanded.value
    }
    return {
      expanded,
      toggleExpand
    }
  }
}
</script>

