<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card
      card-panel(offset='full')
        card-panel(offset='bottom')
          card-panel(offset='bottom')
            row(offset)
              cell(cols='12')
                v-caption(size='4' variant='bold') {{ _('Order history') }}
          card-panel(v-if='ticketsOrderHistory.length')
            row(offset )
              cell(
                v-for='(ticket, ticketIndex) in ticketsOrderHistory'
                cols='12 6-md 12-lg 6-xl'
                :key='ticket.id'
              )
                ticket(:ticket='ticket' is-history)
                  template(v-slot:label='')
                    card-label(:position='["left", "top"]')
                      v-tag(:type='ticket.is_active ? "success" : "brand-4"') {{ ticket.is_active ? _('l active') : _('l passive')}}
                  template(v-slot:download='')
                    v-button(
                      v-if='ticket.shipment_type == "eticket"'
                      :variant='["clear"]' 
                      
                      alignment='center'
                      @click='printETicket(ticket)'
                    )
                      template(v-slot:prepend='')
                        cell(cols='narrow')
                          icon(type='ticket-1' color='default' size='xs')
                      cell(cols='narrow')
                        v-button-element
                          v-caption(
                            color='default'
                            size='6'
                            display="lg"
                            :variant='["uppercase", "bold"]'
                          ) {{ _('e-ticket')}}
                    v-button(
                      v-if='ticket.shipment_type == "mobticket"'
                      :variant='["clear"]' 
                      
                      alignment='center'
                      @click='getMobileTicket(ticket)'
                    )
                      template(v-slot:prepend='')
                        cell(cols='narrow')
                          icon(type='scan' color='default' size='xs')
                      cell(cols='narrow')
                        v-button-element
                          v-caption(
                            color='default'
                            size='6'
                            display="lg"
                            :variant='["uppercase", "bold"]'
                          ) {{ _('mobile ticket')}}
                
          card-panel(v-else)
            notice
              template(v-slot:icon='')
                icon(
                  type='error' 
                  color='warning' 
                  size='5xl'
                )
              template(v-slot:caption='')
                v-caption(variant='bold') {{ _('no ticket orders yet')}}
                    
      transition(name='fade')
        card-panel(
          offset='full'
          v-if='pagination && pagination.total > pagination.limit'
        )
          ui-pagination(
            :from-client='true'
            :offset='pagination.offset'
            :total='pagination.total'
            :limit='pagination.limit'
            
            @input='sendData($event)'
          )
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import Ticket from '@cabinet/components/booking/Ticket'
export default {
  components: {
    UiPagination,
    Ticket,
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('ticketsModule', [
      'pagination', 'ticketsOrderHistory'
    ]),
  },
  methods: {
    ...mapActions('ticketsModule', [
      'fetchHistory'
    ]),
    async sendData(data) {
      let query = `?page=${data}`
      try {
        this.loading = true
        await this.fetchHistory({ query })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    getMobileTicket(data) {

      window.open(`/api/v1/tickets/${data.id}/mob/print/`, '_blank')
    },
    printETicket(data) {
      window.open(`/api/v1/tickets/e/print/?as=pdf&ids=${data.id}`, '_blank')
    },
  },
  mounted() {
    this.fetchHistory({})
  }
}
</script>

