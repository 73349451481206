<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    +b.FORM.form
      +e.control--100
        h2.default {{ _('Are you sure want to turn off two-factor authentication?') }}
        p {{ _('Enter token') }}
        
      +e.control--100.--no-padding
        ui-input(:placeholder="_('Token')"
                  :val='token'
                  type='search'
                  name='token'
                  v-digitsonly='token'
                  autofocus=''
                  v-model.text='token').form__control--styled
      +e.control--100
        +b.btn-wrapper.--auto
          +b.btn.--success(@click='send') 
            +e.text {{ _('SEND') }}
</template>
<script>
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import { successHandler, errorHandler } from '@cabinet/util'
import { mapActions } from 'vuex'
export default {
  name: "UiTokenConfirm",
  components: {
    UiInput
  },
  props: {
    promise: {
      
    }
  },
  data() {
    return {
      token: '' 
    }
  },
  methods: {
    ...mapActions('userModule', ['updateSettings']),
    
    send() {
      this.$validator.validateAll().then(res => {
        if (res) {
          this.updateSettings({token: this.token})
            .then(res => {
              successHandler(res.data, this)
                .then(res => {
                  this.token = ''
                  this.promise(res)
                  this.$emit('close')
                })
            })
            .catch(err => {
              //(err)
              let { data } = err.response
              errorHandler(data.errors, this)
            })
        }
      })
    },
  },
}
</script>
