<template lang="pug">
  include /mixins
  +b.modal-wrapper--draggable(v-drag=''
                              @mousedown='removeHighlight'
                              style="position: absolute;z-index:2000")
    +e.dragger#header(:class='{"modal-wrapper__dragger--highlighted": highlighted}')
      row(alignment='center' justification='center')
        cell()
          span {{ _("You can drag this window") }}
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      autocomplete='off'
    )
      row(offset)
        cell(cols='12')
          row(offset)
            cell(cols='12')
              //- cell(cols='12')
                .row.row--justification_between
                  .cell.cols.cols--3(:style='{display: active  ? "block" : "none"}')
                    ui-button(@click.prevent='toggleBlocks'
                              type='button'
                              class='btn-wrapper--tiny'
                              btn-class='btn--blue')
                      span(:class='mainBlockActive ? "icon-right": "icon-left"').btn__icon
              cell(cols='12' v-show='mainBlockActive')
                row(offset)
                  validation(name='non field errors')
                  cell(cols='12')
                    ui-input(:placeholder="_('Sector name')"
                            type='search'
                            name='sector name'
                            :val='formData.title'
                            v-model='formData.title'
                            bg='relief-3'
                            autocomplete='off'
                            
                            validate='required')
                  cell(cols='12')
                    ui-input(:placeholder="_('Sector capacity')"
                            type='search' v-digitsonly=''
                            name='sector seats'
                            bg='relief-3'
                            :val='formData.total_seats'
                            autocomplete='off'
                            @input='checkVal($event, "total_seats")'
                            :style='pointerEvents'
                            v-model.number='formData.total_seats')
                  cell(cols='12')
                    v-caption(size='5' tag='h5') {{ _('rows list') }} {{ textCondition ? ':' : _('is empty')}}
                  cell(cols='12' v-if='formData.row && formData.row.length')
                    row(offset style='max-height:400px;overflow:scroll')
                      cell(cols='12' v-for='(row, index) in formData.row'
                                                
                                                :key='index')
                        .multiselect__custom-tag.ml-0.mr-0(@click='editRow(row, index)')
                          row(alignment='center')
                            cell(cols='narrow' )
                              p(style='line-height: 1').no-padding {{ row.title }}
                            cell(cols='narrow')
                              +b.multiselect__custom-remove(
                                @click.prevent.stop="remove(row, index)"
                                style='line-height: 1'
                              )
                                icon(type='close' color='warning' size='xs')
                                              
                        hr

                        row(space='5xs')
                          cell(
                            cols='narrow' 
                            v-for='(item, idx) in row.seat'
                            :key='idx'
                          )
                            span {{ item.title }}
                              span(v-if='idx < row.seat.length - 1') {{ ',' }}
              cell(cols='12' v-show="!mainBlockActive")
                ui-row(
                  @add='addRow($event)'
                  @edit='addRow($event)'
                  @cancel='cancel'
                  :seat='seat'
                  :props-item='rowToEdit'
                  :index='index'
                  :row='formData.row'
                )
              transition(name='fade')
                row(offset v-if='mainBlockActive')
                  cell(cols='6')
                    v-button(
                      :variant='["clear", "block"]' 
                      hover='default'
                      @click.prevent='close("cancel")'
                    )
                      v-button-element
                        v-caption(
                          color='default'
                          :variant='["uppercase", "bold"]'
                        ) {{ _('cancel') }}


                  cell(cols='6' :style='styles' )
                    v-button(
                      :variant='["clear", "block"]' 
                      hover='success'
                      @click='save'
                    )
                      v-button-element
                        v-caption(
                          color='success'
                          :variant='["uppercase", "bold"]'
                        ) {{ _('save') }}

                  cell(justification='center' cols='narrow')
                    v-button(
                      :variant='["clear", "block"]' 
                      hover='danger'
                      @click='deleteSector_(formData.id)'
                    )
                      v-button-element
                        v-caption(
                          color='warning'
                          :variant='["uppercase", "bold"]'
                        ) {{ _('delete sector') }}
</template>

<script>
// import { mapState } from 'vuex'
import drag from '@branu-jp/v-drag'
import { EventBus } from '@cabinet/components/eventbus/eventbus'
import UiDelete from '@cabinet/components/modals/UiDelete'
import UiRow from '@cabinet/components/hall/UiRow'
import { mapActions } from 'vuex'
import { errorHandler } from '@cabinet/util'

export default {
  props: {
    hallData: {
      type: Object,
    },
    hallSectors: Array
  },
  directives: {
    drag
  },
  computed: {
    styles() {
      return ''/* this.errors.items.length ? 'pointer-events:none;opacity:0.5' : '' */
    },
    seatsLength() {
      return this.formData.row.reduce((acc, seats) => {
        acc += seats.seat.length
        return acc
      }, 0)
    },
    textCondition() {
      return this.formData.row && this.formData.row.length
    },
    pointerEvents() {
      
      return this.textCondition
        ? 'pointer-events: none;'
        : 'pointer-events: all;'
    },

    // ...mapState('hallModule', ['selections']),
    
  },
  watch: {
    // 'formData': {
    //   deep: true,
    //   handler(val) {
    //     if (!val.row.length && val.total_seats) {
    //       EventBus.$emit('blockEvent', true)
    //     } else {
    //       EventBus.$emit('blockEvent', false)
    //     }
    //   }
    // },
    hallData: {
      immediate: true,
      handler(val) {
        
        
        if (val) {
          val.row.forEach(r => {
            
            r.seat = r.seat.sort((a, b) => parseInt(a.title) - parseInt(b.title))
          })
          
          this.formData = val
          this.initialFormData = JSON.stringify(this.formData)
        }
      },
    },
  },
  components: {
    UiRow,
  },
  directives: {
    drag,
  },
  data() {
    return {
      /**
       * @active state is for toggling which part of UI to show
       */
      active: false,

      /**
       * item is the object representing currently selected row inside 
       * sector and has:
       * @seat array of seats inside this row
       * @title actual row title
       */
      item: {
        seat: [],
        title: '',
      },
      rowToEdit: {},

      formData: {
        title: '',
        row: [],
        total_seats: 0,
      },

      seat: [],
      index: null,
      initialFormData: '',

      /**
       * @highlighted state is for highlighting
       * draggable zone inside this modal
       */
      highlighted: false,

      mainBlockActive: true
    }
  },
  methods: {
    activate() {
      this.active = !this.active
    },
    toggleBlocks() {
      this.mainBlockActive = !this.mainBlockActive
    },
    checkVal(value, model) {
      if (value < 0) {
        this.formData[model] = 0
      }
    },
    ...mapActions('hallModule', ['deleteRow', 'deleteSector']),
    deleteEvent(id) {
      return new Promise((resolve, reject) => {
        if (id) {
          resolve(id)
        }
        reject()
      }).then(id => {
        this.$emit('delete', id)
      }).catch(err => {
        let rows = this.formData.row.filter(r => !r.id)
        if (!this.formData.title || rows.length) {
          rows.forEach((r, index) => {
            this.remove(r, index)
          })
        }
        this.$emit('close')
      })
    },
    deleteSector_(id) {
      return this.$modal.show(UiDelete,
        {
          fn: this.deleteEvent.bind(this),
          itemID: id,
          type: this._('sector'),
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }
      )
    },
    close(condition) {
      if (condition === 'cancel') {
        this.$emit('close')
        return
      }
      if (this.$refs.observer.ctx.valid && this.formData.title) {
        this.$emit('settle', this.formData)
      } else {

        let rows = this.formData.row.filter(r => !r.id)
        if (!this.formData.title || rows.length) {
          rows.forEach((r, index) => {
            this.remove(r, index)
          })
        }
      }
      this.$emit('close')
    },
    clearErrors() {
      this.$refs.observer.reset()
    },
    setErrors(item, errors) {
      
      this.$refs.observer.setErrors({
        [item]: [errors]
      })
    },
    async save() {
      let isValid = await this.$refs.observer.validate()

      let items = this.hallSectors.filter(s => s.title === this.formData.title) 
      

      if (isValid && (!items.length || (items.length && items[0].id === this.formData.id))) {
        this.formData.title = this.formData.title.toLowerCase().trim()
        // this.formData.total_seats = this.textCondition
        //   ? this.seatsLength
        //   : this.formData.row.reduce((acc, r) => acc += r.seat.length, 0)
        this.formData.total_seats = this.textCondition
          ? this.seatsLength
          : this.formData.total_seats
        this.$emit('settle', {
          ...this.formData,
          callback: () => this.initialFormData = JSON.stringify(this.formData)
        })
      } else {
        this.setErrors('sector name', this._('Already has sector with such name'))
      }
    
    },
    removeHighlight() {
      this.highlighted = false
    },
    async remove(row, index) {
      if (row.id) {
        try {
          await this.deleteRow(row.id)
          row.seat.forEach(s => {
            setTimeout(() => {
              let svg = document.getElementsByClassName('driver')[0]
              let element = svg.querySelector(`[data-title='${s.geom_id}']`)
              if (element) {
                element.remove()
              }
            })
          })
      
          this.formData.row.splice(index, 1)
      
          this.$emit('settle', {
            ...this.formData,
            noDismiss: true
          })
        } catch (err) {
          this.$emit('settle', {
            ...this.formData,
            noDismiss: true
          })
        }
        
      }

      // 
      
    },
    editRow(row, index) {
      this.activate()
      EventBus.$emit('removeSelected')
      EventBus.$emit('setSelected', row.seat)
    },
    cancel() {
      this.mainBlockActive = true
      this.index = null
      this.rowToEdit = {}
      
      // this.$emit('close')
    },
    addRow(row) {

      EventBus.$emit('blockEvent', false)
      this.mainBlockActive = true
      if (this.index !== null) {
        // 
        this.formData.row[this.index] = row
        this.index = null
        this.rowToEdit = {}
      } else {
        // 
        this.formData.row.push(row)
      }
    },
    combineData(seat) {
      this.formData.row.forEach((row, index) => {
        if (row.seat.some((s, index) => seat.find(i => i.geom_id.id == s.geom_id) ? true : false)) {
          seat = seat.map(s => {
            const res = row.seat.find(seat => seat.geom_id === s.geom_id || seat.geom_id === s.geom_id.id)
            s.geom_id = s.geom_id.id
            s = { ...res, ...s}
            return s
          })
          this.seat = seat
          this.rowToEdit = { ...row }
          this.index = index
        } 
      })
    }
  },
  

  mounted() {
    this.$refs.observer.reset()
    // TODO turn back when finished
    // window.onbeforeunload = () => {
    //   if (this.initialFormData !== JSON.stringify(this.formData)) {
    //     return 'Are you sure you want to leave? You habe unsaved data'
    //   }
    // }
    // TODO turn back when finished
    EventBus.$on('removeError', () => {
      this.$refs.observer.reset()
    })
    EventBus.$on('err', err => {
      // 
      let { data } = err.response
      errorHandler(data.errors, this)
      // this.errors.clear()
    })
    this.highlighted = true

    EventBus.$on('save-nested', () => this.save())

    EventBus.$on('setSelection', (circles) => {
      this.mainBlockActive = true
      if (circles) {
        let seat = [...circles].map(c => ({
          geom_id: c,
        }))
        this.rowToEdit = {}
        this.seat = []
        this.index = null
        // 
        
        this.combineData(seat)

        if (!this.rowToEdit.seat) {
          this.seat = [...seat]
        } 
        
      }
      setTimeout(() => {
        this.active = true
        this.mainBlockActive = false
      }, 1)
      
    })
  },
}

// [
//   {
//     title: 'A',
//     id: 1,
//     row: [
//       {
//         title: 'A',
//         seat: [
//           {
//             title: 'A',
//             geom_id: ''
//           }
//         ],
//         info: [
//           {
//             title: 
//           }
//         ]
//       }
//     ]
//   }
// ]
</script>




