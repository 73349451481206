import qs from 'querystring'

import Api, {editedSlug as provider} from '@cabinet/services'
export default {
  fetchTicketsData({ id, updated_since }) {
    return Api().get(`/tickets/${id}/list/?${qs.stringify({ updated_since })}`, {
      headers: {
        provider
      }
    })
  },
  fetch(id) {
    return Api().get(`/tickets`)
  },
  patch(id, data) {
    return Api().put(`/tickets/${id}/update/`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  fetchHistory(query) {
    return Api().get(`/tickets/list/${query ? query : ''}`, {
      headers: {
        provider
      }
    })
  },
  refund({ ticket, comment, price }) {
    return Api().post(`/tickets/${ticket}/refund/`, { comment, ticket, price })
  },
  sendTickets(tickets) {
    return Api().post(`/tickets/send/`, { tickets })
  },
  printTickets(data) {
    return Api().get(`/tickets/print/?as=pdf&ids=${data}`)
  },
  createTicket(data) {
    return Api().post(`/tickets/${data.id}/create/`, data.data)
  },

  printHardTickets(data) {
    return Api().post(`/tickets/${data.id}/print/`, data.data)
  },

  showPrintedList({id}) {
    return Api().get(`/tickets/${id}/printed/list/`)
  }


}
