import UserService from '@cabinet/services/api/user'
import { mapState, mapActions } from 'vuex'
const lg = 1200
export const headerMixin = {

  props: {
    user: {
      default: false,
    },
    slug: {
      default: ''
    },
    fixed: {
      default: ''
    },
    isAccount: {
      default: ''
    }
  },
  computed: {
    containerClass() {
      return this.fixed ? 'container--fit_xs-xs container--fit_sm-sm container--fit_md-md container--fit_lg-lg container--fit_xl-xl' : 'container--fit_auto'
    },
    style() {
      return this.reducedLength < 1 ? "pointer-events:none;opacity:0.25" : ""
    },
    reducedLength() {
      if (Array.isArray(this.fullCart) && this.fullCart.length) {
        return this.fullCart.reduce((acc, item) => {
          if ((item.tickets && item.tickets.length) || !item.length) {
            acc += (item.tickets && item.tickets.length) ? item.tickets.length : 1
          } else if (item.length) {
            acc += item.length
          } else {
            acc++
          }
          return acc
        }, 0)
      }
      return 0
    },
    ...mapState('cartModule', ['fullCart', 'end']),
    ...mapState('userModule', ['userData']),
  },
  data() {
    return {
      menuActive: false,
    }
  },

  methods: {
    ...mapActions('cartModule', [
      'clearCart',
    ]),
    goTo() {
      const currentSlug = this.userData?.is_manager ? this.userData.provider_settings.slug : this.slug
      if (!this.reducedLength) {
        return
      }
      location.pathname = `/${currentSlug}/checkout/`
    },
    collapse() {
      if (this.menuActive && window.innerWidth < lg) {
        this.menuActive = false
      }
    },
    toggleMenu() {
      this.menuActive = !this.menuActive
    },
    checkScreenSize() {
      this.menuActive = window.innerWidth > lg ? 1 : 0
    },
    openModal(type, urls, props = {}, type_) {
      if ((this.$route.path === '/' || this.$route.path.includes('/page/')) && !props.index) {
        props.index = 1
      }

      let getModal =
          type == 'UiRegistration'
            ? () => Promise.resolve(import(`@cabinet/components/auth/${type}.vue`))
            : () => Promise.resolve(import(`@cabinet/components/auth/UiLogin.vue`))
      let doLogin = type === 'UiLogin'
      let recovery = type === 'UiRecovery'
      getModal().then(component => {
        this.$modal.show(
          component.default,
          {
            urls,
            doLogin: doLogin,
            recovery,
            type: this.type || type_,
            ...props,
          },
          {
            adaptive: true,
            maxWidth: 330,
            height: 'auto',
            scrollable: true,
          }
        )
      })
    },
    logout() {
      UserService.logout().then(res => {
        // location.reload()
        window.location = res.data.meta.redirect || '/'
      })
    },
  },
  mounted() {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize, { passive: true })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  },
}
