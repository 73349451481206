import Api from '@cabinet/services'

export default {

  charge({price = 1, show = 1} = {}){
    return Api().post(`/providers/charge/`, {
      price, 
      show
    })
  },
  fetchEventsList(id, {
    ...queryData
  }) {
    return Api().get(`/providers/${id}/events/list/`, {
      params: queryData
    })
  },
  getProvidersInfo({id}) {
    return Api().get(`/providers/${id}/info/`)
  },
  getProvidersList(query){
    return Api().get(`/providers/list/`, {
      params: query
    })
  },
  getProvidersTransactions(id, { 
    ...queryData
  }) {
    return Api().get(`/providers/${id}/transactions/`, {
      params: queryData
    })
  },
  fetchTarifs(){
    return Api().get('/tariff/list/')
  },
  activate({tariff}){
    return Api().post('/tariff/select/', {
      tariff
    })
  }
}