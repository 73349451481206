<template lang="pug">

  include /mixins
  +b.timer-wrapper
    +e.timer(@click='toggleActive')
      ui-countdown(
        :time='getTime'
        :booking='true'
      )
      //- v-if='getTime'
    //- transition(name='fade'
    //-            mode='in-out')
      //- +e.hint(v-if='active') {{ _('The reservation is automatically canceled if the ticket is not paid within ') }}{{ timer }}{{ _(' minutes') }}
</template>

<script>
import { mapState } from 'vuex'
import UiCountdown from '@cabinet/components/vendor/UiCountdown'

export default {
  props: {
    getTime: {
      default: 0
    }
  },
  data() {
    return {
      active: true
    }
  },
  computed: {
    ...mapState('cartModule', [
      'timer'
    ])
  },
  components: {
    UiCountdown
  },
  methods: {
    toggleActive() {
      this.active = !this.active
    }
  }
}
</script>

