<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; ')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    row(offset)
      cell(cols='12')
        v-caption(size='2' variant='bold') {{ _('Send SMS') }}
      loading-wrapper(:loading='loading')
        validation-observer(
          ref='observer'
          v-slot='{invalid}'
          tag='form'
          @submit.prevent='send'
        )
          cell(cols='12')
            ui-input(
              :placeholder="_('SMS sending name')"
              bg='relief-3'
              size='lg'
              type='text'
              name='name'
              v-model='formData.name'
              validate='required'
            )
          cell(cols='12')
            ValidationProvider.control-input(
              :rules='`required|max:${smsTextMaxLength}`'
              v-slot='{errors}'
              name='text'
            )
              textarea.simple-textarea.control-input__element(
                :placeholder="_('Text')"
                bg='relief-3'
                size='lg'
                type='textarea'
                name='text'
                v-model='formData.text'
                validate='required'
              )
              slot(name='error')
                +b.control-hint.--styling_default.--variant_error(v-if='errors[0]') {{ errors[0] }}
          cell(cols='12')
            +b.control-box
              v-button(
                variant='success'
                type='submit'
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('Send') }}

</template>

<script>
import UserService from '@cabinet/services/api/user'
import { successHandler, errorHandler } from '@cabinet/util'

export default {
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        name: null,
        text: null,
      },
      smsTextMaxLength: Number(window.smsLength),
    }
  },
  methods: {
    async send() {
      const isValid = await this.$refs.observer.validate() 
      if (isValid) {
        this.loading = true
        const data = JSON.parse(JSON.stringify(this.formData))
        data.receivers = this.selected
        UserService.sendSms(data)
          .then(res => {
            this.loading = false
            successHandler(res.data, this)
            this.$emit('close')
          })
          .catch(err => {
            this.loading = false
            let { data: errors } = err.response
            errorHandler(errors.errors, this.$refs.observer, err.response, this)
          })
      }
    }
  }

}
</script>
