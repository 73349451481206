<template lang="pug">
include /mixins
loading-wrapper(:loading='loading')
  card(variant='tour')
    card-panel(offset='full')
      card-panel(v-if='dataInfo && dataInfo.isApple && dataInfo.clientSecret')
        card-panel(offset='bottom')
          v-caption(size='4', variant='bold') {{ _("ApplePay - GooglePay Pyment Method:") }}
        card-panel(offset='bottom')
          v-caption(size='5') {{ _("c ApplePay description") }}
        row(offset)
          cell(cols='12 6-lg')
            StripeBtn(
              :total-amount='totalPriceConverted',
              :currency='currency',
              country='US',
              :total-label='currency',
              :pk='dataInfo.token',
              :client-secret='dataInfo.clientSecret',
              @success='succesAppleAction'
            )
      card-panel(v-else)
        validation-observer(
          ref='observer',
          v-slot='{ invalid }',
          tag='form',
          @submit.prevent='submit'
        )
          card-panel(v-if='!(dataInfo.payment == "cash" || dataInfo.payment == "complimentary")', offset='bottom')
            row(offset)
              cell(cols='12')
                v-caption(size='4', variant='bold') {{ _("c Billing address:") }}
              cell(cols='12')
                row(offset)
                  cell(cols='6 4-md 6-lg')
                    ui-input(
                      :placeholder='_("First name*")',
                      :val='formData.billing_address.first_name',
                      type='search',
                      name='billing address first name',
                      bg='relief-3',
                      size='lg',
                      border='whole',
                      v-model='formData.billing_address.first_name',
                      validate='required|alpha_spaces'
                    )
                  cell(cols='6 4-md 6-lg')
                    ui-input(
                      :placeholder='_("Last name*")',
                      :val='formData.billing_address.last_name',
                      type='search',
                      name='billing address last name',
                      bg='relief-3',
                      size='lg',
                      border='whole',
                      v-model='formData.billing_address.last_name',
                      validate='required|alpha_spaces'
                    )
                  cell(cols='12 10-md 8-lg 10-xl')
                    validation-provider(
                      rules="required"
                      v-slot="{ errors }"
                      name="billingAddress"
                    )
                      multiselect.autocomplete-select(
                        v-model="billingAddress",
                        label="description",
                        track-by="place_id",
                        :placeholder='_("Address, state, country *")',
                        :options="searchResults",
                        :allow-empty="false"
                        :multiple="false",
                        :searchable="true",
                        :options-limit="1",
                        :show-no-results="false",
                        :hide-selected="false",
                        name="billingAddress"
                        @search-change="autocompleteInput($event)"
                        @select="select($event, 'billing_address')"
                      )
                      p.control-hint.control-hint--styling_default.control-hint--variant_error(v-if="errors.length > 0")
                        span(v-for="error in errors") {{ error }}
                      //- p {{ formData.billing_address.address_line_1 }}
                    validation-provider(
                      :rules="'required'"
                      v-slot="{ errors }"
                      :name="input"
                      :key='`hidden_input_${input}`'
                      v-for="input in hiddenInputs"
                    )
                      input(type="hidden" :name="input" v-model="formData.billing_address[input]")
                      .control-hint.control-hint--styling_default.control-hint--variant_error(v-if="errors.length > 0")
                        span(v-for="error in errors") {{ input }} {{ _('required') }}

                  //- cell(cols='8 10-md 8-lg 6-xl')
                  //-   ui-input(
                  //-     id="autocomplete"
                  //-     :placeholder='_("Address *")',
                  //-     :val='formData.billing_address.address_line_1',
                  //-     type='search',
                  //-     name='billing address address',
                  //-     v-model='formData.billing_address.address_line_1',
                  //-     validate='required',
                  //-     bg='relief-3',
                  //-     size='lg',
                  //-     border='whole'
                  //-   )
                  cell(cols='12 2-md 4-lg 2-xl')
                    ui-input(
                      :placeholder='_("Unit #")',
                      :val='formData.billing_address.unit',
                      type='search',
                      name='billing address unit',
                      v-model='formData.billing_address.unit',
                      validate='',
                      bg='relief-3',
                      size='lg',
                      border='whole'
                    )
                  //- cell(cols='6 4-md 6-lg 3-xl')
                  //-   ui-input(
                  //-     v-model='formData.billing_address.city',
                  //-     :val='formData.billing_address.city',
                  //-     :placeholder='_("City *")',
                  //-     name='billing address city',
                  //-     type='search',
                  //-     validate='required',
                  //-     bg='relief-3',
                  //-     size='lg',
                  //-     border='whole'
                  //-   )
                  //- cell(cols='6 4-md 6-lg 3-xl')
                  //-   ui-input(
                  //-     v-model='formData.billing_address.subdivision',
                  //-     :val='formData.billing_address.subdivision',
                  //-     :placeholder='_("State/Province *")',
                  //-     name='billing address state',
                  //-     type='search',
                  //-     validate='required',
                  //-     bg='relief-3',
                  //-     size='lg',
                  //-     border='whole'
                  //-   )
                  //- cell(cols='6 4-md 6-lg 3-xl')
                  //-   ui-input(
                  //-     :placeholder='_("Postal Code *")',
                  //-     :val='formData.billing_address.zipcode',
                  //-     name='billing address zipcode',
                  //-     type='search',
                  //-     v-model='formData.billing_address.zipcode',
                  //-     validate='required',
                  //-     bg='relief-3',
                  //-     size='lg',
                  //-     border='whole'
                  //-   )
                  //- cell(cols='6 4-md 6-lg 3-xl')
                  //-   ui-input(
                  //-     v-model='formData.billing_address.country',
                  //-     :val='formData.billing_address.country',
                  //-     :placeholder='_("Country *")',
                  //-     name='billing address country',
                  //-     type='search',
                  //-     validate='required',
                  //-     bg='relief-3',
                  //-     size='lg',
                  //-     border='whole'
                  //-   )
          card-panel(offset='bottom', v-if='dataInfo.payment === "cash"')
            v-caption(size='4', variant='bold') {{ _("Cash Method:") }}
          card-panel(offset='bottom', v-if='dataInfo.payment === "complimentary"')
            v-caption(size='4', variant='bold') {{ _("Complimentary Method:") }}
          card-panel(offset='bottom', v-if='dataInfo.payment === "stripe"')
            row(offset)
              cell(cols='12 6-md 12-lg')
                card
                card-panel(offset='full', :variant='["bordered", "border"]')
                  StripeCard.stripe-card(
                    v-if="showStripeCard"
                    :class='{ complete }',
                    :stripe='dataInfo.token',
                    :options='stripeOptions',
                    @change='complete = $event.complete'
                  )
          card-panel(offset='bottom', v-if='dataInfo.payment === "authorize"')
            row(offset)
              cell(cols='12 6-md 12-lg')
                ui-credit-card-view(
                  :email='formData.email',
                  :number='formData.authorize.number',
                  :date='formData.authorize.date',
                  :name='formData.billing_address.first_name',
                  :surname='formData.billing_address.last_name',
                  :code='formData.authorize.code'
                )
              cell(cols='12 6-md')
                ui-input.form__control--styled(
                  :placeholder='_("Card number*")',
                  :val='formData.authorize.number',
                  type='search',
                  inputmode='numeric',
                  name='authorize number',
                  size='lg',
                  bg='relief-3',
                  v-mask='\'#### #### #### ####\'',
                  v-model='formData.authorize.number',
                  validate='required|credit'
                )
              cell(cols='6 3-md')
                ui-input.form__control--styled(
                  :placeholder='_("month/year*")',
                  :val='formData.authorize.date',
                  type='search',
                  inputmode='numeric',
                  name='authorize date',
                  v-mask='\'##/##\'',
                  size='lg',
                  bg='relief-3',
                  v-model='formData.authorize.date',
                  validate='required|length:5|date'
                )

              cell(cols='6 3-md')
                ui-input.form__control--styled(
                  :placeholder='_("CVV2 code*")',
                  :val='formData.authorize.code',
                  type='search',
                  v-digitsonly='',
                  inputmode='numeric',
                  name='cvv',
                  size='lg',
                  bg='relief-3',
                  v-model='formData.authorize.code',
                  validate='required|max:4'
                )

          card-panel(offset='bottom')
            row(justification='end')
              cell(cols='12 6-sm 12-md 6-xl')
                v-button(type='submit', variant='success', border='whole' :disabled='dataInfo.payment === "stripe" && disablePayment')
                  cell.cols(justification='center')
                    v-button-element(size='lg')
                      v-caption(size='4', variant='bold') {{ _("Pay Now") }}
</template>

<script>

import Checkbox from '@cabinet/components/forms/blocks/Checkbox'
import { mapState } from 'vuex'
import { successHandler, errorHandler } from '@cabinet/util'
import { Card, createToken } from 'vue-stripe-elements-plus'
import AutocompleteMixin from './../../../mixin/AutocompleteMixin'
import StripeBtn from '@cabinet/components/forms/StripeBtn'

import OrdersService from '@cabinet/services/api/orders'
import UiSelect from '@cabinet/components/forms/blocks/UiSelect'
import UiCreditCardView from '@cabinet/components/forms/UiCreditCardView'

export default {
  components: {
    UiCreditCardView,
    Checkbox,
    StripeCard: Card,
    StripeBtn,
    UiSelect,
  },
  mixins: [ AutocompleteMixin ],
  props: {
    dataInfo: {
      type: Object
    },
    totalPrice: {
      required: true
    },
    currency: {
      required: false,
      default: 'USD'
    },
  },
  data() {
    return {
      complete: false,
      loading: false,
      stripeOptions: {
        style: {
          fontFamily: 'Montserrat',
          backgroundColor: '#f4f8f9',
        },
        hidePostalCode: true
      },
      formData: {
        billing_address: {
          country: '',
          subdivision: '',
          city: '',
          address_line_1: '',
          address_line_2: "",
          unit: "",
          zipcode: "",
          first_name: "",
          last_name: ""
        },
        authorize: {
          code: '',
          number: '',
          date: ''
        },
        stripe_token: ''
      },
      billingAddress: '',
      showStripeCard: false,
      disablePayment: true,
      /*to validate existance before concatenation*/
      hiddenInputs: ['route', 'streetNumber', 'city', 'subdivision', 'country', 'zipcode'],
    }
  },
  async mounted() {
    let script = document.createElement('script')
    script.setAttribute('src', 'https://js.stripe.com/v3/')
    document.head.appendChild(script)
    script.onload = () => {
      this.showStripeCard = true
    }
    if (this.userData) {
      if (this.userData.profile) {
        if (this.userData.profile.delivery_address || this.userData.profile.billing_address) {
          this.formData.billing_address = this.userData.profile.delivery_address || this.userData.profile.billing_address
          this.formData.billing_address.zipcode = this.userData.profile.delivery_address ? this.userData.profile.delivery_address.postal_code : this.userData.profile.billing_address.postal_code
        }
        if (this.userData.profile.delivery_address) {
          this.formData.phone = this.userData.profile.delivery_address.phone
        } else if (this.userData.profile.billing_address) {
          this.formData.phone = this.userData.profile.billing_address.phone
        } else {
          this.formData.phone = this.userData.profile.phone
        }
      }
      this.formData.email = this.userData.email
    }
    this.formData.payment = this.dataInfo.payment
    this.fillAddress()
  },
  computed: {
    ...mapState('userModule', ['userData']),
    totalPriceConverted() {
      return parseFloat(this.totalPrice) * 100
    }
  },
  watch: {
    complete: {
      handler(val) {
        if (val) {
          this.loading = true
          createToken(this.dataInfo.token)
            .then(data => {
              console.log(data)
              this.loading = false
              if (data.token) {
                this.disablePayment = false
                this.formData.stripe_token = data.token.id
              }
            })
            .catch(() => {
              this.disablePayment = false
            })
        } else {
          this.disablePayment = true
        }
      }
    },
  },
  methods: {
    async submit() {
      if (!this.invisibleCaptcha) {

        this.$refs.observer.validate().then(result => {

          if (result) {
            this.loading = true
            /*if address from autocomplete*/
            if (this.billingAddress.place_id) this.formatAddress()
            let form = this.transform(JSON.parse(JSON.stringify(this.formData)))
            /* delete unnecessary fields in*/
            if (form.billing_address) {
              delete form.billing_address.route
              delete form.billing_address.streetNumber
            }
            OrdersService.updateCurrent({ info: form })
              .then(res => {
                console.log(res)
                successHandler(res.data)
                this.loading = false
                this.formData.authorize.code = ''
                this.formData.authorize.number = ''
                this.formData.authorize.date = ''
                this.$refs.observer.reset()
              })
              .catch(err => {
                //(err)
                this.loading = false
                console.error(err)
                let { data: errors } = err.response
                errorHandler(errors.errors, this.$refs.observer, err.response, this)
              })
          }
        })
      }
    },
    transform(data) {
      let form = { ...data }
      if (form.payment === 'authorize') {
        let date = form.authorize.date.split('/')
        date = [`20${date[1]}`, date[0]].join('-')
        form.authorize.date = date
      } else {
        delete form.authorize
      }
      if (form.payment === 'apple-id') {
        delete form.billing_address
      }
      if (form.payment !== 'stripe') {
        delete form.stripe_token
      }
      if (form.payment === 'cash') {
        form = {}
      }
      return form
    },
    async succesAppleAction(event) {
      try {
        this.loading = true
        const result = (await OrdersService.appPay({ id: event.paymentIntent.id }))
        successHandler(result.data)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error(error)
        let { data: errors } = error.response
        errorHandler(errors.errors, this.$refs.observer, error.response, this)
      }
    },
    fillAddress() {
      const data = this.formData.billing_address
      if (
        data.zipcode
        && data.subdivision
        && data.city
        && data.address_line_1
        && data.country
      ) {
        let addresString = `${data.address_line_1}, ${data.city}, ${data.subdivision}, ${data.country}, ${data.zipcode}`
        this.billingAddress = { description: addresString }
        const address = this.formData.billing_address.address_line_1
        /*to prevent validation error on prefilled address.
        street number and route keep separately*/
        const streetNumberRegExp = /\d{1,}\s/
        const streetNumberEntry = address.match(streetNumberRegExp)
        if (null !== streetNumberEntry && 0 === streetNumberEntry.index) {
          const [streetNumber] = streetNumberEntry
          this.formData.billing_address.streetNumber = streetNumber.trim()
          this.formData.billing_address.route = address.slice(streetNumber.length, address.length)
        } else {
          this.formData.billing_address.route = address
        }
      }
    },
    formatAddress() {
      this.formData.billing_address.address_line_1 = `${this.formData.billing_address.streetNumber} ${this.formData.billing_address.route}`
    }
  }
}
</script>
