<template lang="pug">
  include /mixins
  card-panel
    card-panel
      
      StatsFilter(
        :meta='meta'
        @fetch='fetchEvents($event)'
      )
        template(v-slot:append='' v-if='permissions.report')
          cell(cols='12 6-sm narrow-md')
            +b.control-box
              loading-wrapper(:loading='loading')
                v-button(
                  variant='danger'
                  border='whole'
                  @click.stop.prevent='getEventsStatistics'
                )
                  template(v-slot:prepend='')
                    cell
                      v-button-element(
                        size='lg'
                        variant="warning-lite"
                      )
                        v-caption(variant='bold')
                          icon(type='download'
                              size='md')
                  cell(cols='auto')
                      v-button-element(size='lg') 
                        v-caption(
                          :variant='["bold", "uppercase"]'
                          size='6'
                        ) {{ _('s Get report') }}
            
    card-panel
      
        row(offset)
          cell(cols='12')
            ui-tabs(ref='tabs')
              template(v-slot='{ activeItem, activate }')
                row(offset space='xs')
                  cell(
                    cols='narrow'
                    
                    v-for='(item, index) in [ { type: "active", text: _("Active") }, { type: "archived", text: _("Archived") } ]'
                    :key='index'
                  )
                    v-button(
                      @click='activateTab(index, item.type, activate)'
                      :variant='["clear", "block", "shadow", "tab", item.type !== type ? "opaque": ""]'
                      border='round'
                    ) 
                      v-button-element(size='md')
                        v-caption(
                          size='4'
                          variant='bold'
                          :color="item.type === type ? 'warning' : 'default'"
                        ) {{ item.text }}
          cell(cols='12')
            card
              card-panel(v-if='events.length')
                StatsTable(:events='events')
                  card-panel
                    row(alignment='center' justification='between')
                      cell()
                        row(alignment='center')
                          cell
                            v-caption(size='6' color='success') {{ _('success hint') }}
                          cell
                            v-caption(size='6' color='danger') {{ _('danger hint') }}
                      cell
                        slot(name='time')
              card-panel(v-else)
                notice()
                  template(v-slot:icon='')
                    icon(
                      type='error' 
                      color='warning' 
                      size='5xl'
                    )
                  template(v-slot:caption='')
                    v-caption(variant='bold') {{ _('You have no data yet!')}}

</template>

<script>
import StatsService from '@cabinet/services/api/stats'
import { onMounted, ref } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import usePagination from '@cabinet/composables/use-pagination'
import { mapState } from 'vuex'

import UiTabs from '@cabinet/components/vendor/UiTabs'
import StatsTable from '@cabinet/components/dashboard/StatsTable'
import StatsFilter from '@cabinet/components/dashboard/StatsFilter'

export default {
  computed: {
    ...mapState('userModule', ['userData']),
    permissions() {
      return this.userData.permissions
    }
  },
  components: {
    UiTabs,
    StatsTable,
    StatsFilter,
  },
  setup(props, context) {

    const type = ref('active')
    const events = ref([])
    const meta = ref({})
    const loading = ref(false)
    const tz = new Date().getTimezoneOffset() / 60
    console.log(tz)
    const getEventsStatistics = async (id) => {
      loading.value = true
      StatsService.getFullFinanceReport(tz)
        .then(res => {
          let url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf; base64'}))
          let link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `general-statistics.pdf`)
          document.body.appendChild(link)
          link.click()
          loading.value = false
        })
        .catch(err => {
          loading.value = false
        })
    }
    

    const fetchEvents = async (query) => {
      loading.value = true
      try {
        let res = await useResponse(StatsService.getEventsStatistics(query), context.parent)
        meta.value = usePagination(res.meta.pagination)
        let data = res.data.map(event => {
          let statistics = event.show.reduce((acc, show) => {
            acc.scaned_tickets += show.scaned_tickets
            acc.seats_amount += show.seats_amount
            acc.sold_price += parseFloat(show.sold_price)
            acc.total_price += parseFloat(show.total_price)
            acc.unsold_price += parseFloat(show.unsold_price)
            acc.tickets_amount += show.tickets_amount
            return acc 
          }, {
            scaned_tickets: 0,
            seats_amount: 0,
            sold_price: 0,
            total_price: 0,
            unsold_price: 0,
            tickets_amount: 0,
          })
          event = {
            ...event,
            ...statistics
          }
          return event
        })
        events.value = data
        loading.value = false
      } catch (err) {
        loading.value = false
        console.error(err)
      }
    }

    const activateTab = (index, _type, fn) => {
      fn(index)
      if (_type == 'archived') {
        fetchEvents({ is_archive: true })
      } else {
        fetchEvents()
      }
      type.value = _type
    }

    onMounted(async () => {
      await fetchEvents()
    })

    return {
      meta,
      events,
      loading,
      activateTab,
      type,
      
      fetchEvents,
      getEventsStatistics
    }
  }
}
</script>