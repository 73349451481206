import Vue from 'vue'
import VModal from 'vue-js-modal/dist/ssr.nocss'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
// import { editedSlug as prefix } from '@cabinet/services'
// import NProgress from 'nprogress'
import store from '@cabinet/store'

import Account from '@cabinet/pages/base/Account'
import Dashboard from '@cabinet/pages/dashboard/Dashboard'
import DashboardGeneral from '@cabinet/pages/dashboard/DashboardGeneral'
import DashboardEvent from '@cabinet/pages/dashboard/DashboardEvent'
import FinanceReport from '@cabinet/pages/finance/FinanceReport'
import Providers from '@cabinet/pages/providers/Providers'
import Provider from '@cabinet/pages/providers/Provider'
import SettingsProvider from '@cabinet/pages/settings/SettingsProvider'
// import SettingsUser from '@cabinet/pages/settings/SettingsUser'
import Shows from '@cabinet/pages/vendors/Shows'
import VendorStats from '@cabinet/pages/vendors/VendorStats'
import Events from '@cabinet/pages/event/Events'
import UiCreateEvent from '@cabinet/components/forms/UiCreateEvent'
import EventShows from '@cabinet/pages/event/EventShows'
import Vendors from '@cabinet/pages/vendors/Vendors'
import Vendor from '@cabinet/pages/vendors/Vendor'
import Orders from '@cabinet/pages/orders/Orders'
import OrderDetails from '@cabinet/pages/orders/OrderDetails'
import Users from '@cabinet/pages/users/Users'
import HallsList from '@cabinet/pages/halls/HallsList'
import Hall from '@cabinet/pages/halls/Hall'
import Letters from '@cabinet/pages/settings/Letters'
import Promos from '@cabinet/pages/promos/Promos'
import Ushers from '@cabinet/pages/ushers/Ushers'
import Manual from '@cabinet/pages/manual/Manual'
import ErrorPage from '@cabinet/pages/base/404'
// import PurchaseHistory from '@cabinet/pages/finance/PurchaseHistory'

import ShowBooking from '@cabinet/pages/booking/ShowBooking'

Vue.use(VModal, { dynamicDefaults: { clickToClose: false } })
Vue.use(VueI18n)
Vue.use(VueRouter)

const messages = {
  current: window.django.catalog || {},
}
Vue.prototype._ = function () {
  return this.$t.apply(this, arguments)
}

export const groupBy = (arr, fn) =>
  arr
    .map(typeof fn === 'function' ? fn : val => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i])
      return acc
    }, {})

Vue.prototype.groupBy = groupBy

export const i18n = new VueI18n({
  locale: 'current',
  messages,
  silentTranslationWarn: true,
})

import CreateShow from '@cabinet/pages/event/CreateShow'
import BaseRecovery from '@cabinet/pages/base/Recovery'

import UiLogin from  '@cabinet/components/auth/UiLogin.vue'
import BlankProvider from  '@cabinet/pages/base/BlankProvider'
import BaseAccount from  '@cabinet/pages/base/Account'
import PurchaseHistory from '@cabinet/pages/finance/PurchaseHistory'
import SettingsUser from '@cabinet/pages/settings/SettingsUser'
import LegacyAuth from '@cabinet/pages/legacy/Auth'
import BookingCheckout from '@cabinet/pages/booking/Checkout'

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/reset/',
      component: BaseRecovery,
      redirect: '/reset/confirmation/',
      children: [
        {
          name: 'confirmation',
          path: 'confirmation/*',
          component: UiLogin,
        },
      ]
    },
    {
      path: '/account/',
      component: Account,
      meta: {
        order: 30,
        user: this,
        requiresAuth: true,
      },
      children: [
        {
          path: '/',
          name: 'account',
        },
        {
          path: 'account-dashboard/',
          component: Dashboard,
          name: 'provider-dashboard',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            order: 0,
          },
          redirect: 'account-dashboard/general/',
          children: [
            {
              path: 'general/',
              component: DashboardGeneral,
              name: 'provider-dashboard-general',
              meta: {
                requiresAuth: true,
                requiresProvider: true,
                order: 1,
              },
            },
            {
              path: 'event/:id/',
              component: DashboardEvent,
              name: 'provider-dashboard-event',
              meta: {
                requiresAuth: true,
                requiresProvider: true,
                order: 2,
              },
            }
          ]
        },
        {
          path: 'account-finance-report/',
          component: FinanceReport,
          name: 'provider-finance-report',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            order: 0,
          }
        },
        {
          path: 'providers-list/',
          component: Providers,
          name: 'providers-list',
          meta: {
            requiresAuth: true,
            isSuperAdmin: true,
            order: 2,
          }
        },
        {
          path: 'providers-list/:id/',
          component: Provider,
          name: 'provider',
          meta: {
            requiresAuth: true,
            isSuperAdmin: true,
            order: 2,
          }
        },
        {
          path: 'provider-settings/',
          component: SettingsProvider,
          name: 'provider-settings',
          meta: {
            requiresAuth: true,
            order: 38,
            isAdmin: true,
          },
        },
        {
          path: 'user-settings/',
          component: SettingsUser,
          name: 'user-settings',
          meta: {
            requiresVendor: true,
            order: 38,
          },
        },
        {
          path: 'vendor-shows/',
          component: Shows,
          name: 'vendor-shows',
          meta: {
            requiresAuth: true,
            requiresStaff: true,
            order: 31,
          },
        },
        {
          path: 'vendor-stats/',
          component: VendorStats,
          name: 'vendor-stats',
          meta: {
            requiresAuth: true,
            requiresStaff: true,
            order: 31,
          },
        },
        {
          path: 'events/',
          component: Events,
          name: 'events',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 31,
          },
        },
        {
          path: 'events/create-event/',
          component: UiCreateEvent,
          name: 'create-event',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 34,
          },
        },
        {
          path: 'events/edit-event/:id',
          component: UiCreateEvent,
          name: 'edit-event',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 34,
          },
        },
        {
          path: 'events/:id/all-shows/',
          component: EventShows,
          name: 'view-show-events',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 34,
          },
        },
        {
          path: 'events/:id/add-show',
          component: CreateShow,
          name: 'create-show',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            order: 34,
          },
          props: true,
        },
        {
          path: 'events/:id/edit-show/:showId/',
          component: CreateShow,
          name: 'edit-show',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 34,
          },
          props: true,
        },
        {
          path: 'vendors/',
          component: Vendors,
          name: 'vendors',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 32,
            vendors: true
          },
        },

        {
          path: 'vendors/:id/details',
          component: Vendor,
          name: 'vendor',
          props: true,
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            isAdmin: true,
            order: 32,
            vendors: true
          },
        },
        {
          path: 'orders/',
          component: Orders,
          name: 'orders',
          meta: {
            requiresAuth: true,
            requiresStaff: true,
            order: 33,
          },
        },
        {
          path: 'orders/:id/details',
          component: OrderDetails,
          name: 'order-details',
          meta: {
            requiresAuth: true,
            requiresStaff: true,
            order: 33,
          },
        },
        {
          path: 'users/',
          component: Users,
          name: 'users',
          meta: {
            requiresAuth: true,
            requiresStaff: true,
            order: 33,
          },
        },
        {
          path: 'halls/',
          component: HallsList,
          name: 'halls-list',
          meta: {
            isAuth: true,
            isSuperAdmin: true,
            requiresAuth: true,
            order: 46,
            isAdmin: true,
            user: this,
          },
        },
        {
          path: 'halls/edit/:id/',
          component: Hall,
          name: 'edit-hall',
          meta: {
            isAuth: true,
            isSuperAdmin: true,
            requiresAuth: true,
            order: 46,
            isAdmin: true,
            user: this,
          },
        },
        {
          path: 'halls/create/',
          component: Hall,
          name: 'create-hall',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            order: 37,
          },
        },
        {
          path: 'letters/',
          component: Letters,
          name: 'letters',
          meta: {
            requiresAuth: true,
            requiresStaff: true,
            order: 37,
          },
        },
        {
          path: 'promos/',
          component: Promos,
          name: 'promos',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            order: 37,
          },
        },

        /**
         * Ushers page
         */
        {
          path: 'ushers/',
          component: Ushers,
          name: 'ushers-list',
          meta: {
            requiresAuth: true,
            requiresProvider: true,
            order: 0,
          },
        },
        {
          path: '/account/not-found/',
          component: ErrorPage
        },
        {
          path: 'purchase-history/',
          component: PurchaseHistory,
          name: 'purchase-history',
          meta: {
            requiresAuth: true,
            order: 31,
          },
        },
        {
          path: 'manual',
          component: Manual,
          name: 'manual'
        },
        {
          path: '*',
          redirect: 'not-found/'
        },
      ]
    },

    /**
     * Provider account routes
     *
     */
    {
      path: `/:provider/`,
      component: BlankProvider,
      meta: {
        order: 30,
      },

      props: true,

      children: [
        {
          path: 'account/',
          component: BaseAccount,
          children: [
            {
              path: '/',
              redirect: 'purchase-history/'
            },
            {
              path: 'purchase-history/',
              component: PurchaseHistory,
              name: 'purchase-history',
              meta: {
                requiresAuth: true,
                order: 31,
              },
            },
            {
              path: 'user-settings/',
              component: SettingsUser,
              name: 'user-settings',
              meta: {
                requiresAuth: true,
                order: 38,
              },
            },
            {
              path: 'not-found/',
              component: ErrorPage,
              name: 'provider-redirect'
            },
            {
              path: '*',
              redirect: 'not-found/'
            },
          ]
        },
        {
          name: 'provider-auth',
          path: 'auth/',
          redirect: '/:provider/auth/login/',
          component: LegacyAuth,
          children: [
            {
              name: 'login',
              path: 'login/',
              component: UiLogin,
            },
            // {
            //   path: 'register/',
            //   component: () => import('@cabinet/components/auth/UiRegistration.vue')
            // },

            // {
            //   path: 'restore/',
            //   component: () => import('@cabinet/components/auth/UiRestore.vue')
            // }
          ]
        },
        // {
        //   path: 'booking/',
        //   component: () => import('@cabinet/pages/booking/Booking'),
        //   name: 'booking',
        //   meta: {
        //     order: 10,
        //   },
        // },
        {
          path: 'checkout/',
          component: BookingCheckout,
          name: 'checkout',
          meta: {
            order: 20,
          },
        },
        {
          path: ':id/',
          component: ShowBooking,
          name: 'show-booking',
          meta: {
            order: 20
          },
          props: true
        },
        {
          path: 'not-found/',
          component: ErrorPage,
          name: 'provider-redirect-next'
        },
        // {
        //   path: '*',
        //   redirect: 'not-found/'
        // },
      ],

    },


  ],
})

router.afterEach(async (to) => {
  if (to.name) {
    document.getElementById('loading').classList.add('is-disabled')
  }
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.isAdmin)

  // const requiresProvider = to.matched.some(record => record.meta.requiresProvider)

  const requiresStaff = to.matched.some(record => record.meta.requiresStaff)

  const requiresSuperAdmin = to.matched.some(record => record.meta.isSuperAdmin)

  console.log(to)

  if (!store.state.userModule.userData) {
    try {
      await store.dispatch('userModule/getData')

    } catch (err) {
      console.error(err)
      // next('/auth/login/')
    }

    if (store.state.userModule.userData && store.state.userModule.userData.is_manager) {
      try {
        await store.dispatch('paymentsModule/getPayments')

      } catch (err) {
        console.error(err)
        // next('/auth/login/')
      }
    }
  }


  // if (
  //   requiresProvider &&
  //   !store.getters['paymentsModule/hasPayment']
  // ) {
  //   return next('/account/provider-settings/')
  // }


  if (
    requiresStaff &&
    store.state.userModule.userData &&
    (
      !store.state.userModule.userData.is_vendor &&
      !store.state.userModule.userData.is_staff &&
      !store.state.userModule.userData.is_manager
    )
  ) {
    return next('/account/orders/')
  }

  if (to.name == 'account') {
    if (store.state.userModule.isAuth) {
      if (store.state.userModule.userData && store.state.userModule.userData.is_vendor && !store.state.userModule.userData.profile.billing_address.city) {
        return next('/account/user-settings/')
      }
      if (store.state.userModule.userData && store.state.userModule.userData.is_vendor) {
        return next('/account/orders/')
      }
      if (store.state.userModule.userData && store.state.userModule.userData.is_manager && !store.state.userModule.userData.provider_settings) {
        return next('/account/provider-settings/')
      }
      return next('/account/events/')
    }
    return next('/account/not-found/')
  }



  if (to.name && !store.state.userModule.isAuth && requiresAuth) {
    // window.location.href = '/'
    return next()
  }

  if (requiresAdmin && !store.state.userModule.userData.is_manager) {
    return next('/account/')
  }
  if (requiresSuperAdmin && !store.state.userModule.userData.is_staff) {
    return next('/account/')
  }

  next()
})
