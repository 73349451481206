<template lang="pug">
include /mixins
loading-wrapper(:loading='loading')
  +b.SECTION.section(ref='content')
    +e.el(ref='componentFocusTarget')
    row(justification='center')
      cell(cols='12 8-md')
        card-panel(offset='bottom')
          cell(cols='12')
            v-button(
              :variant='["clear", "block"]',
              hover='danger',
              border='whole',
              tag='a',
              :href='referrer'
            )
              v-button-element(size='lg')
                v-caption(
                  color='warning',
                  :variant='["uppercase", "bold"]'
                ) {{ _("GO back to HOME!!") }}
    card
      card-panel(offset='full')
        row(offset, space='lg')
          cell(cols='12 6-lg')
            card-panel(offset='bottom')
              row(offset)
                cell(
                  cols='12',
                  :key='title',
                  v-for='(obj, title, index) in groupedItems'
                )
                  card(space='md')
                    card-panel
                      card(variant='tour')
                        card-panel
                          card(space='3xs')
                            card-panel
                              row(offset, space='none')
                                cell(cols='4 3-md')
                                  img(:src='obj.show.image')
                                cell(cols='auto')
                                  card(space='md')
                                    card-panel(offset='full')
                                      v-caption(size='2', variant='bold') {{ obj.show.title }}
            card-panel
              card(variant='tour', space='3xs')
                card-panel(offset='full')
                  row(offset)
                    cell(cols='12')
                      +b.TABLE.table--variant_scroll.--space_lg(
                        style='--table-space-horizontal: 10px'
                      )
                        +e.TBODY.tbody
                          +e.TR.row
                            +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                              row(
                                alignment='center',
                                nowrap,
                                offset,
                                space='xs',
                                style='--grid-cell-space-half: 4px'
                              )
                                cell(cols='6 5-sm 6-md 5-xl')
                                  v-caption(
                                    style='white-space: break-spaces',
                                    :size='["6", "4-sm"]',
                                    color='white'
                                  ) {{ _("c tickets:") }}
                                cell(cols='narrow', style='align-self: normal')
                                  div(
                                    style='height: 100%; background-color: #9ca5bd; width: 1px; height: 100%'
                                  )
                                cell(cols='auto')
                                  row(
                                    alignment='center',
                                    offset,
                                    :space='["xs"]',
                                    style='--grid-cell-space-half: 1px',
                                    nowrap,
                                    v-for='(amount, price, index) in detailedPrice',
                                    :key='index'
                                  )
                                    cell
                                      v-caption(
                                        :size='["7", "6-sm"]',
                                        color='white'
                                      ) {{ amount }}
                                    cell
                                      v-caption(
                                        :size='["7", "6-sm"]',
                                        color='white'
                                      ) x
                                    cell
                                      v-caption(
                                        :size='["7", "6-sm"]',
                                        color='white'
                                      ) {{ price }}
                            +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                              v-caption(:size='["6", "4-sm"]') {{ items.length }}

                          +e.TR.row
                            +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                              v-caption(:size='["6", "4-sm"]', color='white') {{ _("c subtotal:") }}
                            +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                              v-caption(:size='["6", "4-sm"]') {{currency}} {{ cartInfo.base_price | toUSD }}

                          template(v-if='!isCash')
                            +e.TR.row(v-for='item in cartInfo.fee')
                              +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                                row(
                                  alignment='center',
                                  nowrap,
                                  offset,
                                  space='xs',
                                  style='--grid-cell-space-half: 4px'
                                )
                                  cell(cols='6 5-sm 6-md 5-xl')
                                    v-caption(
                                      style='white-space: break-spaces',
                                      :size='["6", "4-sm"]',
                                      color='white'
                                    ) {{ item.title }}
                                  cell(
                                    cols='narrow',
                                    style='align-self: normal'
                                  )
                                    div(
                                      style='height: 100%; background-color: #9ca5bd; width: 1px; height: 100%'
                                    )
                                  cell(cols='auto')
                                    row(
                                      alignment='center',
                                      offset,
                                      :space='["xs"]',
                                      style='--grid-cell-space-half: 1px',
                                      nowrap
                                    )
                                      cell
                                        v-caption(
                                          :size='["7", "6-sm"]',
                                          color='white'
                                        ) {{ item.amount }}
                                      cell
                                        v-caption(
                                          :size='["7", "6-sm"]',
                                          color='white'
                                        ) x
                                      cell
                                        v-caption(
                                          :size='["7", "6-sm"]',
                                          color='white'
                                        ) {{currency}} {{ item.price / item.amount | toUSD }}

                              +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                                v-caption(:size='["6", "4-sm"]') {{currency}} {{ item.price | toUSD }}

                          +e.TR.row(v-if='shipment')
                            +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                              v-caption(:size='["6", "4-sm"]', color='white') {{ _("delivery charge") }}
                            +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                              v-caption(:size='["6", "4-sm"]') {{currency}} {{ shipment.toFixed(2) | toUSD }}

                          +e.TR.row(
                            v-if='cartInfo.promo'
                          )
                            +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                              v-caption(:size='["6", "4-sm"]', color='white') {{ _("Promocode") }}
                            +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                              v-caption(:size='["6", "4-sm"]') {{currency}} {{ cartInfo.promo_info.promo.toFixed(2) | toUSD }}

                          //- +e.TR.row
                          //-   +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                          //-     v-caption(:size='["6", "4-sm"]', color='white') {{ _("c discount:") }}
                          //-   +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                          //-     v-caption(:size='["6", "4-sm"]') $ {{ discounts | toUSD }}

                          +e.TR.row
                            +e.TD.cell--bordered_left_top.--bordered_left_bottom.--relief.--variant_checkout
                              v-caption(:size='["6", "4-sm"]', color='white') {{ _("c total:") }}
                            +e.TD.cell--bordered_right_top.--bordered_right_bottom.--relief-3
                              v-caption(:size='["6", "4-sm"]') {{currency}} {{ totalPrice | toUSD }}

          cell(cols='12 6-lg')
            checkout-controller(
              :order='cartInfo',
              :total-price='totalPrice',
              @cash-selected='setCash',
              @shipment='calculateShipment'
              :currency='currency'
            )
            //- checkout-form(
            //-   :order='cartInfo',
            //-   @cash-selected='setCash',
            //-   @shipment='calculateShipment'
            //- )
            //-   template(v-slot:site-rules='')
            //-     v-caption(size='5') {{ _("I agree with ") }}
            //-       a(
            //-         :href='cartInfo.rules_link',
            //-         target='_blank',
            //-         style='text-decoration: underline'
            //-       ) {{ _("site rules *") }}
</template>

<script>
import store from '@cabinet/store'
import { mapState, mapActions } from 'vuex'
import { groupBy } from '@cabinet/router'

import CheckoutController from '@cabinet/components/forms/CheckoutController'
import Ticket from '@cabinet/components/booking/Ticket'
import CheckoutForm from '@cabinet/components/forms/CheckoutForm'
export default {
  props: {
    deliveryCharge: {
      default: '0.00',
    },
    discount: {
      default: '0.00',
    },
  },
  components: {
    Ticket,
    CheckoutForm,
    CheckoutController,
  },
  data() {
    return {
      loading: true,
      shipment: 0,
      isCash: false
    }
  },
  computed: {
    ...mapState('userModule', ['userData']),
    ...mapState('cartModule', ['cartInfo', 'cartData']),
    ...mapState('ordersModule', ['order']),
    detailedPrice() {
      return this.items.reduce((acc, item) => {
        acc[`${item.show.currency} ${item.base_price}`] = acc[`${item.show.currency} ${item.base_price}`] || 0
        acc[`${item.show.currency} ${item.base_price}`]++
        return acc
      }, {})
    },
    totalPrice() {
      return this.isCash ? this.cartInfo.promo_info.base_price : this.cartInfo.promo_info && this.cartInfo.promo_info.price
    },
    groupedItems() {
      const grouped = groupBy(this.items, item => item.show.title)
      let innerGroup = Object.entries(grouped).reduce((acc, [title, items]) => {
        acc[title] = {
          show: items[0].show,
          items: items
        }
        return acc
      }, {})
      return innerGroup
    },
    currency() {
      const currencies = Object.keys(this.groupedItems).reduce((acc, title) => {
        acc.push(this.groupedItems[title].show.currency)
        return acc
      }, [])  
      return currencies[0]
    },
    // discounts() {
    //   return this.cartData.reduce((acc, { tickets }) => {
    //     return acc += tickets.reduce((acc, { discount }) => acc += discount, 0)
    //   }, 0)
    // },
    feesItems() {
      return this.cartInfo.fee ? this.cartInfo.fee.reduce((acc, fee) => {
        acc[fee.title] += fee.price
        return acc
      }, {

      }) : 0
    },
    fees() {
      return this.cartInfo.fee ? this.cartInfo.fee.reduce((acc, fee) => acc += fee.price, 0) : 0
    },
    items() {
      return this.cartData.map(c => {
        return c.tickets
      }).flat(Infinity)
    },
    isShown() {
      return this.isCash
    },
    referrer() {
      const url = localStorage.getItem('url')
      return url
    },
  },
  methods: {
    setCash(val) {
      this.isCash = val
    },
    calculateShipment(value) {
      if (parseFloat(value)) {
        this.shipment = parseFloat(value)
        return
      }
      this.shipment = 0
    },
    ...mapActions('cartModule', [
      'fetchCart', 'removeItem', 'fetchFullCart'
    ]),
    async _removeTicket(item, index) {

      try {
        await this.removeItem({ tickets: { tickets: [item.id] } })

        await this.fetchFullCart()
      } catch (err) {
        console.error(err)
      }
    },
  },
  async mounted() {
    try {
      await store.dispatch('ordersModule/createOrder')
      this.loading = false
    } catch (error) {
      if (error.response.data.errors[0].message === 'Cart is empty') {
        window.location = '/'
      }
      this.loading = false
    }
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 390)
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('cartModule/fetchFullCart', 2)
    store.dispatch('cartModule/fetchCart', 2)
    next()
  },
}
</script>
