<template lang="pug">
  include /mixins
  card(:variant='["white"]')
    card-panel(
      :offset='["full_md"]'
    )
      div(v-if="listFaqs && listFaqs.length")
        div(
          v-for="faq in listFaqs"
          :id="faq.id"
          )
          ui-accordion(
            :key="faq.id"
            :id="faq.id"
          )
            template(v-slot='{activeState, toggleActive, collapse}')
              ui-click-outside(:do='collapse')
                row(offset)
                  cell(cols='12')
                    v-button(
                      @click='toggleActive' 
                      justification='between'
                      alignment='center'
                    )
                      template(v-slot:prepend='')
                        cell
                          v-button-element(size='lg')
                            v-caption(
                              size='5' 
                              variant='bold'
                            ) {{ faq.question }}
                              
                      cell()
                        v-button-element(size='lg')
                          icon(
                            :type='activeState ? "minus" : "add"' 
                            color='warning'
                            size='sm'
                          )



                  cell(cols='12' v-if='activeState')
                    card(:variant='["white"]')
                      card-panel(
                        :offset='["full_md"]'
                      )
                        .typography(v-html="faq.answer")
                          
      v-caption(v-else="listFaqs", size='5' color='default' variant='bold') Empty
</template>

<script>

import ManualService from '@cabinet/services/manual'

export default {
  data() {
    return {
      listFaqs: null
    }
  },
  async mounted() {
    this.$emit('loading', false)
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        const { data } = (await ManualService.getManual())
        this.listFaqs = data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>