<template lang='pug'>
  include /mixins
  +b.modal-wrapper
    +e.close(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(:loading='isLoading')
      ValidationObserver(
        ref='observer'
        tag='form'
        v-slot='{invalid}'
      )
        +b.row--offset
          +b.cols--auto.cell
            //- v-on='$listeners'
            v-select(
              :options='shows'
              :multiple='true'
              @select='addItem'
              bg='relief-3'
              :placeholder='_("select shows")'
              :allow-empty='true'
              :val='items'
              name='show'
              :loading='loading'
              @open='getList(null)'
              :searchable='true'
              @search-change='$event => getList(null, $event)'
            )
              //- :searchable='true'
              template(v-slot:action='{remove, option}')
                span(@click.stop.prevent='removeShow(option, remove)')
                  icon(
                    type='close' 
                    color='warning'
                    size='xs'
                  )
              template(#afterList='')
                div(
                  v-observe-visibility="visible => getList(visible)" v-if="shows_pagination.total > (shows_pagination.limit + shows_pagination.offset)"
                )
          +b.cell.cols--12
            +b.control-box
              v-button(
                
                variant='success'
                @click='save'
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('save data') }}



</template>

<script>
import Preloader from '@cabinet/components/vendor/Preloader'
import UshersService from '@cabinet/services/api/ushers'
import { useResponse } from '@cabinet/composables/use-response'
import ShowsService from '@cabinet/services/api/shows'

export default {
  components: {
    Preloader
  },
  props: {
    model: {
      required: true
    },
    id: {
      required: true
    },
    service: {
      default: () => UshersService
    },
    vendors: {
      default: false
    },
    callback: {
      default: () => {}
    },
    slug: {},
    query: {},
  },
  data() {
    return {
      items: [],
      isLoading: false,
      shows: [],
      shows_pagination: {},
      loading: false,
      page: 1,
      search: '',
    }
  },
  // computed: {
  //   listeners() {
  //     return {
  //       select: ($event) => this.
  //     }
  //   }
  // },
  created() {
    this.items = [...this.model]
  },
  methods: {
    async getList(visible, query) {
      if (false === visible) return
      let page = Math.floor(this.shows_pagination.offset / this.shows_pagination.limit) + 2
      if (this.page == page) return
      if (!visible) page = 1
      if (visible) this.page = page

      this.search = query

      this.loading = true
      const { data: { data, meta: { pagination } } } = (await ShowsService.getAllShows({...this.query, page: page, search: this.search }, this.slug))
      let d = data.map(({ id, title }) => ({id, title}))
      if (visible) {
        this.shows.push(...d)
      } else {
        this.shows = d
      }
      this.shows_pagination = pagination
      this.loading = false
    },
    async addItem(show) {
      this.items.push({ id: show.id, title: show.title })
      
    },
    async save() {
      try {
        this.isLoading = true
        console.log(this.items)
        const data = {
          ushers: [this.id],

          shows: this.items.filter(i => !this.model.find(m => m.id === i.id)).map(s => s.id),
        }
        if (this.vendors) {
          delete data.ushers
          data.vendors = [this.id]
        }
        await useResponse(this.service.addShow(data), this, this.$refs.observer)
        
        if (this.vendors) {
          this.callback()
        } else {
          this.$emit('close')
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    async removeShow(option, remove) {
      try {
        const data = {
          ushers: [this.id],
          show: option.id,
        }

        if (this.vendors) {
          delete data.ushers
          data.vendors = [this.id]
        }
        if (this.model.find(e => e.id === data.show)) {
          this.modalTrigger(data, remove)
        } else {
          const index = this.items.findIndex(i => i.id === data.show)
          this.items.splice(index, 1)
        }
        
      } catch (error) {
        console.log(error)
      }
    },
    async removeItem(data) {
      console.log(data)
      if (data) {
        this.isLoading = true
        let res = await useResponse(this.service.removeShow(data), this, this.$refs.observer)
        if (res.data) {
          // remove ? remove(option) : () => null
          if (this.vendors) {
            this.callback()
          } else {
            const index = this.items.findIndex(i => i.id === data.show)
            this.items.splice(index, 1)
            this.$emit('close')
          }
        }
        this.isLoading = false
      } else {
        this.isLoading = false
        return Promise.reject()
      }
    },
    modalTrigger(id) {
      import('@cabinet/components/modals/UiDelete').then(res => {
        this.$modal.show(res.default,
          {
            fn: this.removeItem,
            itemID: id,
            type: this._('aa show'),
            
          },
          {
            height: 'auto',
            adaptive: true,
            width: 310,
          }
        )
      })
    }, 
  },
  
}
</script>