export const ID = function () {
  return '_' + Math.random().toString(36).substr(2, 9)
}

export const addClass = (i, klass) => i.classList.add(klass)
export const removeClass = (i, klass) => Array.isArray(klass) ? klass.forEach(k => i.classList.remove(k)) : i.classList.remove(klass)
export const getItemFromDOM = i => document.getElementById(i) || document.querySelectorAll(i)
export const hasClass = (i, klass) => i.classList.contains(klass)

export const cases = (_case, styleType) => {
  const cases = {
    'humor show': 'success',
    'concert': 'warning',
    'show': 'blue',
    'upcomming': 'blue'
  }
  return {
    color: cases[_case],
    styleType: styleType == 'bg' ? 'background-color' : 'color'
  }
}

const redirect = url => window.location = url

import ModalSample from '@cabinet/components/modals/ModalSample'

export const successHandler = (data, $this, action = () => null) => {
  return new Promise(resolve => {
    if (data) {
      if ((data.redirect || data.meta.redirect) && !$this) {
        setTimeout(() => {
          // history.replaceState({}, "page 2", data.redirect || data.meta.redirect)
          window.location.assign(data.redirect || data.meta.redirect)
        }, 500)
      }
      if ($this) {
        if (typeof data.meta == 'object' && data.meta.title ) {
          let { title, content } = data.meta
          $this.$modal.show(ModalSample, {
            title: title,
            content: content,
            redirect: data.redirect || data.meta.redirect,
            status: data.data.status || null,
            button: data.button,
            buttonText: data.buttonText,
          }, {
            width: 320,
            height: 'auto',
            adaptive: true,
            scollable: true
          }, {
            'before-close'() {
              if (data.redirect || data.meta.redirect) {
                redirect(data.redirect || data.meta.redirect)
              } else {
                action()
              }
            }
          })
        }
      }
    }
    resolve()

  })
}

export const errorHandler = (errors, observer, response, vue) => {
  if (response.status === 500) {
    vue.$modal.show({
      template:
        `
        <div class="modal-wrapper">
          <div class="modal-wrapper__close" @click="$emit(&quot;close&quot;)">
            <icon type='close' color='warning'/>
          </div>
          <notice>
            <template v-slot:icon="">
              <icon type="error" color="warning" size="5xl">
              </icon>
            </template>
            <template v-slot:caption="">
              <v-caption variant="bold">
                something went wrong
              </v-caption>
            </template>
          </notice>
        </div>
        `,
      inheritAttrs: false

    }, {
    }, {
      width: 320,
      height: 'auto',
      adaptive: true,
      scollable: true
    })
    throw errors
  }
  
  if (response.status == 400 || response.status === 404 || response.code === 400 || errors.status == 404 || errors.code == 400 ) {
    let text = ''
    if (errors.data) {
      text = errors.data.errors[0].message
    } else if (errors && errors[0]) {
      text = errors[0].message
      if (errors[0].state && errors[0].state.non_field_errors) {
        text = `${text} ${errors[0].state.non_field_errors.message}`
      }
    } else if (errors.errors) {
      text = errors.errors[0].message
      if (errors.errors[0].state && errors.errors[0].state.non_field_errors) {
        text = `${text}. ${errors.errors[0].state.non_field_errors.message}`
      }
    } else {
      text = errors.errors[0].message
    }

    vue.$modal.show({
      template:
        `
        <div class="modal-wrapper">
          <div class="modal-wrapper__close" @click="$emit(&quot;close&quot;)">
            <icon type='close' color='warning'/>
          </div>
          <notice>
            <template v-slot:icon="">
              <icon type="error" color="warning" size="5xl">
              </icon>
            </template>
            <template v-slot:caption="">
              <v-caption variant="bold">
                ${text}
              </v-caption>
            </template>
          </notice>
        </div>
        `,
      inheritAttrs: false

    }, {
    }, {
      width: 320,
      height: 'auto',
      adaptive: true,
      scollable: true
    })
  }
  // if (typeof errors == 'string') {
  let err = errors.reduce((acc, error) => {
    if (error.state) {
      Object.keys(error.state).map(e => {
        let err = e.replace(/_{1,2}/g, " ")
        console.log(err)
        acc[err] = acc[err] || []
        console.log(error.state)
        console.log(e)
        acc[err].push(error.state[e].message || error.state[e][0].message)
      })
    } else {
      acc[err] = acc[err] || []
      acc[err].push(error.message)
    }

    return acc
  }, {})
  if (observer) {
    observer.setErrors(err)
  }
  throw err

}


export const formatToUTC = () => {
  let date = new Date().toISOString()
  date = date.substring(0, date.lastIndexOf(":"))
  date = date.replace('T', ' ')
  date = date.replace('+', ' ')
  return date
}

export const dateFormatter = (dateString, isTime) => {
  const date = new Date(dateString)
  let month, day


  // if (date.getMonth() < 10) {
  month = date.getMonth() + 1
  day = date.getDate()
  if (month < 10) {
    month = `0${month}`
  }
  if (day < 10) {
    day = `0${day}`
  }

  if (isTime) {
    let hours = date.getHours()
    let minutes = date.getMinutes()
    // let offset = getTimezoneOffset(date.getTimezoneOffset())
    return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}`
    // ${offset}
  }

  return date.getFullYear() + '-' + month + '-' + day
}

export function getTimezoneOffset() {
  function z(n) { return (n < 10 ? '0' : '') + n }
  let offset = new Date().getTimezoneOffset()
  let sign = offset < 0 ? '+' : '-'
  offset = Math.abs(offset)
  return sign + z(offset / 60 | 0) + ':' + z(offset % 60)
}


export const difference = (arr1, arr2) => arr1.filter(x => !arr2.includes(x))


export function comparer(otherArray) {
  return function (current) {
    return otherArray.filter(function (other) {
      return other.id == current.id
    }).length == 0
  }
}


export function fileLoader(files) {
  return (cb) => {

    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let reader = new FileReader()
      reader.onload = e => {
        console.log(e.target.result) 
        cb(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }
}


export const transformArrayToObject = (array) => {
  return array.map(([id, title]) => ({id, title}))
}



export const getYoutubeId = (url) => {
  let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  let match = url.match(regExp)
  if (match && match[2].length == 11) {
    return match[2]
  }
  return 'error'

}


export const getYoutubeImg = (link) => {
  if (link !== null) {
    let youtubeFoto = link.split("=")
    let newSrc = `https://img.youtube.com/vi/${youtubeFoto[1]}/0.jpg`
    return newSrc
  }
}

