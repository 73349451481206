<template lang="pug">
  include /mixins
  +b.scrollable-wrapper--till-2xl.--full
    +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
      +e.THEAD.thead
        +e.TR.row--relief
          +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
            v-caption(color='white') {{ _('p Name') }}
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('p Reg.date') }}
          +e.TH.cell--variant_bold.--head(w idth='20%') 
            v-caption(color='white') {{ _('p Balance') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('p Shows') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('p Sold tickets (qty)') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('p Tariff plan') }}
          +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom
            v-caption(color='white') {{ _('p Commission') }}
      +e.TBODY.tbody
        ProvidersRow(
          v-for='provider in providers' 
          :provider='provider'
          :key='provider.id'
        )
</template>

<script>
import ProvidersRow from '@cabinet/components/providers/ProvidersRow'

export default {
  components: {
    ProvidersRow,
  },
  props: {
    providers: {
      required: true,
      type: Array
    }
  },
}
</script>