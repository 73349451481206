import EventsService from '@cabinet/services/api/events'

export default {
  namespaced: true,
  state: {
    type: 'active',
    finish_at__lt: '',
    events: {
      pagination: {},
      events_row: []
    },
    error: '',
    event: {},
    statuses: [],
    types: []
  },
  getters: {
    eventStatuses(state) {
      return state.statuses.map(s => ({title: s[1], id: s[0]}))
    },
    eventTypes(state) {
      return state.types.map(s => ({title: s[1], id: s[0]}))
    },
  },
  
  actions: {
    getEventStatuses(state, data) {
      return EventsService
        .getEventStatuses()
        .then(res => {
          let { data } = res
          state.commit('SET_VENDORS', data)
        })
    },
    createEvent(state, data) {
      return EventsService.createEvent(data)
    },
    updateEvent(state, data) {
      return EventsService.updateEvent(data)
    },
    setType({ commit }, { type, finish_at__lt }) {
      commit('SET_TYPE', { type, finish_at__lt })
    },
    fetchEvents(state, meta) {
      return EventsService
        .fetch(meta)
        .then(res => {
          let { data } = res
          state.commit('FETCH_EVENTS', data)
        })
        .catch(res => {
          state.error = 'Something went wrong'
        })
    },
    fetchEvent(state, payload) {
      return EventsService
        .fetchOne(payload)
        .then(res => {
          let { data } = res
          state.commit('SET_EVENT', data.data)
          return data.data
        })
        .catch(err => {
          state.error = 'Something went wrong'
        })
    },
    async deleteEvent({ commit }, payload) {
      return EventsService.deleteEvent(payload)
        .then(res => {
          return res
        })
        .catch(err => {
          console.log(err)
          throw err
        })
    }
  },
  mutations: {
    SET_TYPE(state, payload) {
      state.type = payload.type
      state.finish_at__lt = payload.finish_at__lt 
    },
    FETCH_EVENTS(state, payload) {
      state.events.events_row = payload.data
      state.events.pagination = payload.meta.pagination
    },
    SET_EVENT(state, payload) {
      state.event = payload
    },
    DELETE_EVENT(state, id) {
      let index = state.events.events_row.findIndex(e => e.id === id)
      state.events.events_row.splice(index, 1)
    },
    SET_VENDORS(state, data) {
      state.statuses = data.statuses
      state.types = data.types
    }

  },
}
