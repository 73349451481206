<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'card__label',
  modifierProps: ['position', 'offset', 'index', 'border']
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      'div',
      { ...props.data, class: ['card__label', $blockClasses, props.data.staticClass] },
      props.children
    )
  },
  props: {
    position: {
      type: [Array, String]
    },
    offset: {
      type: [Array, String]
    },
    border: {
      type: [Array, String]
    },
    index: {
      type: String,
      default: '1'
    }
  }
}
</script>