<template lang="pug">
  card.card--variant_tour(:variant='cardVariant')
    card-panel(
      :offset='["full", "vertical_xl-lg"]' 
      :variant='variant'
      
    )
      row(
        column 
        offset 
        alignment='center'
      )
        cell(cols='narrow')
          slot(name='title')
        cell(cols='narrow')
          slot(name='subtitle')
        cell(cols='narrow')
          card-panel(offset='vertical')
            slot(name='price')
        cell(cols='12 10-md 12-xl 10-2xl')
          card-panel(offset='bottom')
            row(
              offset 
              justification='center' 
              column
            )
              slot(name='features')
        cell(cols='12')
          row(offset justification='center')
            cell(cols='12 10-md 12-xl 10-2xl')
              slot(name='order')
            cell(cols='12 10-md 12-xl 10-2xl')
              slot(name='learn')
</template>

<script>
export default {
  props: {
    bordered: {
      default: false,
      type: Boolean
    },
    variant: {
      default: '',
      
    },
    cardVariant: {
      default: ''
    }
  }
}
</script>