import {
  elementModifiers,
  elementStates,
} from '@cabinet/components/utils/bem/functions'

import pick from 'ramda/src/pick'

function $modifierClasses(element, modifiers) {
  if (!element) return []

  return elementModifiers(element, modifiers)
}

function $elementClasses(element, modifiers = {}, states = []) {
  return [].concat(
    $modifierClasses(element, modifiers),
    elementStates(states)
  )
}

export function useClassesComposition(props, bem) {
  function $blockClasses() {
    const opts = bem
    return $elementClasses(
      opts.block,
      pick(opts.modifierProps || [], props),
      props.states
    )
  }
  return $blockClasses()
}
