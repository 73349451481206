<template lang="pug">
  include /mixins
  countdown(
    :time="localTime" 
    v-slot="{ days, hours, minutes, seconds }"
    @end='log'
  )
    row(offset)
      cell(cols='4')
        v-caption(size='6' variant='bold' color='white') {{ minutes | counter }}
      cell(cols='narrow')
        v-caption(size='6' variant='bold' color='white') :
      cell(cols='4')
        v-caption(size='6' variant='bold' color='white') {{ seconds | counter }}
</template>

<script>

import Countdown from './Countdown'
import UiInfo from '@cabinet/components/modals/UiInfo'

export default {
  components: {
    Countdown
  },
  props: {
    time: {
      required: true
    },
    admin: {
      default: false
    },
    client: {
      default: true
    },
    booking: {
      default: false
    }
  },
  data: () => ({
    localTime: 0
  }),
  methods: {
    log() {
      this.$modal.show(
        UiInfo,
        
        {},
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }, {
          'before-close': () => {
            location.reload()
          }
        }
      )
    }
  },
  watch: {
    time: {
      handler(nval, oval) {
        if (nval != oval && !this.admin) {
          this.localTime = nval
        } else {
          this.localTime = parseFloat(nval) * 1000 - new Date().getTime()
        }
      },
      immediate: true
    }
  }
}
</script>
