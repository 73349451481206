<template lang="pug">
  include /mixins
  //- loading-wrapper(:loading='loading')
  card()
    card-panel(offset='full')
      card-panel
        card-panel(offset='bottom')
          row(
            offset 
            alignment='center'
          )
            cell(cols='narrow')
              row(offset)
                cell(cols='narrow')
                  v-caption(
                    size='5' 
                    variant='bold'
                  ) {{ _('Providers balance:') }}
                cell(cols='narrow')
                  v-caption(
                    size='5'
                    variant='bold'
                    color='success'
                  ) $ {{ balance | toUSD }}


            cell(cols='12 8-md 7-lg 6-xl 5-2xl')
              ui-search(
                @input='send($event)'
                type='search'
              )
                template(v-slot:activator='{send, data}')  
                  cell(cols='12 narrow-md')
                    v-button(
                      variant='success-lite'
                      size='lg'
                      :border='["top_right", "bottom_right"]'
                      @click='send(data)'
                    )
                      cell(cols='auto')
                        v-button-element(size='lg') 
                          v-caption(
                            :variant='["bold", "uppercase"]' 
                            size='6'
                          ) {{ _('s search') }}
            cell(cols='narrow' justification='right')
              
        card-panel(v-if='providers.length')
          card(space='md')
            card-panel(offset='bottom')
              ProvidersList(:providers='providers')
            card-panel
              ui-pagination(
                :limit='meta.limit' 
                :offset='meta.offset' 
                :total='meta.total' 
                @input='send({...search, page: $event })'
              )
        
      card-panel(
        v-if='!providers.length'
        variant='border' 
        offset='full'
      )
        notice()
          template(v-slot:icon='')
            icon(
              type='error' 
              color='warning' 
              size='5xl'
            )
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('you have no providers matching query') }}
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {useResponse} from '@cabinet/composables/use-response'
import usePagination from '@cabinet/composables/use-pagination'
import ProvidersService from '@cabinet/services/api/providers'
import UiSearch from '@cabinet/components/forms/UiSearch'
import ProvidersList from '@cabinet/components/providers/ProvidersList'
import UiPagination from '@cabinet/components/vendor/UiPagination'
export default {
  components: {
    UiSearch,
    ProvidersList,
    UiPagination,
  },
  setup(p, c) {
    const loading = ref(false)
    const providers = ref([])
    const meta = ref({})
    const balance = ref(null)
    const search = ref({})

    const send = async (query) => {
      loading.value = true
      search.value = query
      let res = await useResponse(ProvidersService.getProvidersList(query), c.root)
      meta.value = usePagination(res.meta.pagination)
      balance.value = res.meta.general_balance
      providers.value = res.data
      loading.value = false
    }

    onMounted(async () => {
      await send()
    })
    
    return {
      loading,
      providers,
      meta,
      balance,
      search,
      send
    }
  },
}
</script>