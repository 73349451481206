<template lang="pug">
  include /mixins
  row(
    offset 
    alignment='center' 
    justification='center'
    space="2xs"
    nowrap
  )
    cell(cols='narrow')
      v-button(
        @click.prevent='deleteMore'
        variant='clear'
      )
        v-button-element
          icon(type='minus' color='danger', size="sm")
    cell(cols='4')
      slot(name='count')
        ui-input(
          v-model.number='quantity' 
          :val='quantity'
          @input='emit'
          style='text-align:center'
        )
    cell(cols='narrow')
      v-button(
        @click.prevent='addMore'
        variant='clear'
        :disabled='disabled'
        :style='{"pointer-events": disabled ? "none" : "all"}'
      )
        v-button-element
          icon(type='add' color='danger', size="sm")
</template>
<script>
export default {
  name: 'UiCounter',
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    count: {
      default: 0
    },
    active: {
      type: Boolean,
      default: true,
    },
    initial: {
      type: Number,
    },
    max: {
      type: Number
    }
  },
  watch: {
    count: {
      immediate: true,
      handler(val) {
        if (val) {
          this.quantity = val
        }
      }
    }
  },
  data() {
    return {
      quantity: 0,
    }
  },
  methods: {
    emit() {
      this.$emit("changeCount", this.quantity)
      this.$emit("input")
      
    },
    deleteMore() {
      this.quantity -= 1
      this.quantity = this.quantity <= this.initial ? this.initial : this.quantity
      this.$emit('changeCount', this.quantity)
      this.$emit("input")
      this.$emit('reduce')
    },
    addMore() {
      this.quantity += 1
      if (this.quantity >= this.max) {
        this.quantity = this.max
      }
      this.$emit('changeCount', this.quantity)
      this.$emit("input")
      this.$emit('add')
    },
  },
  // mounted() {
  //   this.quantity = 0
  // }
}
</script>

