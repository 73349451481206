<template lang="pug">
  include /mixins
  +b.ROW.nav(offset :space='["xs", "none-md"]' justification='between' nowrap='till-xl' scroll)
    cell(cols='narrow 12-xl'  v-if='isAdmin && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/account-dashboard/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='dashboard' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('n Dashboard') }}


    cell(cols='narrow 12-xl' v-if='isAdmin && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/account-finance-report/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='report' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('n finance report') }}

    cell(cols='narrow 12-xl'  v-if='isAdmin && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/events/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='tours' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('n My tours') }}


    cell(cols='narrow 12-xl' v-if='isSuperAdmin')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/providers-list/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='providers' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('n My providers') }}

              //- 
    cell(cols='narrow 12-xl' v-if='isAdmin && permissions.agents && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/vendors/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='tie' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('My vendors') }}

    //- cell(cols='narrow 12-xl' v-if='isAdmin && permissions.agents && legal && hasPayment')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/online-agents/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(
                type='seller'
                size='lg'
                color='brand-5'
              )
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('My online Agents') }}

    cell(cols='narrow 12-xl'  v-if='(isAdmin && legal)')
      v-button(
        variant='clear'
        tag='router-link'
        :to='"/account/promos/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='tours' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('Promos') }}

    cell(cols='narrow 12-xl'  v-if='isStaff && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='"/account/vendor-shows/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='tours' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('My Shows') }}

    cell(cols='narrow 12-xl'  v-if='isStaff && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='"/account/vendor-stats/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='list' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('My Stats') }}

    cell(cols='narrow 12-xl'  v-if='(isAdmin && legal) || (isStaff && legal)')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/orders/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='orders' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('My Orders') }}


    cell(cols='narrow 12-xl'  v-if='(isAdmin && legal) || (isStaff && legal)')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/users/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='customers' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('Customers') }}


    cell(
      cols='narrow 12-xl'
    )
      v-button(
        variant='clear'
        tag='router-link'
        :to='`${prefix}/account/purchase-history/`'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='cart' size='md' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('Purchase history') }}


    cell(cols='narrow 12-xl' )
      v-button(
        variant='clear'
        tag='router-link'
        :to='link'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='settings' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('Settings') }}


    cell(cols='narrow 12-xl'  v-if='isAdmin && legal')
      v-button(
        variant='clear'
        tag='router-link'
        :to='prefix+"/account/ushers/"'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'

          )
            cell
              icon(type='scan' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('show scan') }}

    cell(cols='narrow 12-xl' v-if='isSuperAdmin')
      v-button(
        variant='clear'
        tag='router-link'
        to='/account/halls/'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='chair' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('seating charts') }}


    cell(
      cols='narrow 12-xl'
    )
      v-button(
        variant='clear'
        tag='router-link'
        to='/account/manual/'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='orders' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('Manual') }}


    cell(
      cols='narrow 12-xl'
      v-if='(isAdmin && legal) || (isStaff && legal)'
    )
      v-button(
        variant='clear'
        tag='router-link'
        to='/account/letters/'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='letters' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('Letters') }}


    cell(cols='narrow 12-xl' )
      v-button(
        variant='clear'
        @click.prevent='logout'
      ).nav__link
        v-button-element(:size='["md", "lg-md"]')
          row(
            :justification="justification"
            alignment='center'
          )
            cell
              icon(type='sign_out' size='lg' color='brand-6')
            cell(v-if='!collapsed')
              v-caption(
                size='6'
                variant='regular'
                color='brand-6'
              ) {{ _('sign out') }}


    //- router-link(to='/acccount/events/') {{ _('My Events') }}
    //-   +e.icon.icon-events_list
</template>

<script>
import UserService from '@cabinet/services/api/user'
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState('userModule', ['isAuth', 'isAdmin', 'isSuperAdmin', 'prefix', 'userData']),
    ...mapGetters('paymentsModule', ['hasPayment']),
    justification() {
      return this.collapsed ? 'center' : ''
    },
    space() {
      return this.collapsed ? 'xl' : ''
    },
    permissions() {
      return this.userData.permissions
    },
    isStaff() {
      return this.userData.is_vendor
    },
    legal() {
      let legal = false

      if (this.userData.is_vendor) {
        // eslint-disable-next-line no-unused-vars
        const { unit, ...rest } = this.userData.profile.billing_address
        legal = Object.values(rest).every(i => i)
      } else {
        legal = this.userData.provider_settings !== null
      }

      return legal
    },
    isTariffPayed() {
      return this.userData.tariff && this.userData.tariff.status === 'paid'
    },
    link() {
      if (this.prefix) {
        return `${this.prefix}/account/user-settings/`
      } else if (this.userData.is_vendor) {
        return `/account/user-settings/`
      }
      return `/account/provider-settings/`
    }
  },
  props: {
    collapsed: {
      default: false
    }
  },
  methods: {
    logout() {
      UserService.logout().then(res => {
        window.location = res.data.meta.redirect || '/'
      })
    },
  },
}
</script>
