<template lang="pug">
  ValidationProvider.control-input(:rules='$attrs.validate' v-slot='{errors}')
    .form__control
      label.form__label.form__label--selector
        row(alignment='center' offset)
          cell(v-if='$scopedSlots.prepend')
            slot(name="prepend") 
          cell(cols='narrow')
            input.form__input.form__input--toggler(
                              v-bind="$attrs"
                              :value="val"
                              :name='$attrs.name'
                              type='checkbox'
                              v-model="checked"
                              @change="onChange"
                              )
            .form__toggler
          cell(cols='narrow')
            slot(name="append") 

      slot(name='error')
        transition(name='fade')
          .form__error(v-if='errors[0]') {{ errors[0] }}
</template>

<script>
export default {
  props: ["value", "val", "label"],
  data() {
    return {
      checkedProxy: false
    }
  },
  computed: {
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.checkedProxy = val
      }
    }
  },
  methods: {
    onChange(e) {
      this.$emit("input", this.checkedProxy)
    }
  }
}
</script>