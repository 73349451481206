import Api, {editedSlug as provider} from '@cabinet/services'

export default {
  fetchBilling() {
    return Api().get('/settings/billing/receive/', {
      
    })
  },

  fetchDelivery() {
    return Api().get('/settings/delivery/receive/', {
      
    })
  },

  postBilling(data) {
    return Api().post('/settings/billing/update/', data,  {
      
    })
  },

  fetchProviderData() {
    return Api().get('/settings/provider/receive/')
  },

  patchProviderData(data) {
    return Api().patch('/settings/provider/update/', data)
  },

  postProviderData(data) {
    return Api().post('/settings/provider/create/', data)
  },
  makeTestPayment() {
    return Api().post('/settings/billing/test/',  {
      
    })
  },
  requestHall(data) {
    return Api().post('/places/hall/request/create/',  data)
  }
}