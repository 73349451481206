<template>
  <div>
    <slot :link="link"></slot>
  </div>
</template>

<script>
export default {
  name: 'localstorage-link',
  computed: {
    link() {
      return localStorage.getItem('url')
    }
  }
}
</script>
