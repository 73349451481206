<template lang="pug">
include /mixins
//- loading-wrapper(:loading='loading')
row(offset)
  //- used for withdraw and discounts only
  cell(v-if='withdraw || discount', cols='12 6-sm 3-md')
    v-select(
      v-model='seat.descriptor',
      :val='seat.descriptor',
      :options='descriptors',
      :placeholder='_("Descriptor")',
      :allow-empty='false',
      @input='resetAllTicketData(idx, ticket)',
      :name='"seat" + idx + "sector"',
      bg='relief-3'
    )
  //- used for withdraw and discounts only

  cell(:cols='withdraw || discount ? "12 6-sm 3-md" : "12 3-md"')
    v-select(
      v-if='(seat.descriptor && (sectors.length || seat.descriptor.sectors)) || (!withdraw && !discount)',
      v-model='sectorId',
      :full='true',
      :val='sectorId',
      :options='sectors',
      :allow-empty='false',
      :placeholder='_("sector")',
      @input='setSector($event, idx, ticket, index)',
      bg='relief-3',
      :name='index + "seat" + idx + "sector"'
    )

  //- used for withdraw only
  cell(
    :cols='withdraw && !discount ? "12 6-sm auto-md" : "12 narrow-md"',
    v-if='(withdraw || (permissions && isAdmissionActive)) && seat.sector && seat.sector.type == "no_seats"'
  )
    row(alignment='center', offset, nowrap)
      cell(cols='auto')
        ValidationProvider(
          :name='"sectors " + idx + " non field errors"',
          v-slot='{ errors }'
        )
          ui-counter(
            :count='count',
            @changeCount='applyCount($event)',
            :initial='0',
            :disabled='parseInt(seat.amount) > parseInt(seat.sector.available_places) || parseInt(seat.sector.available_places) > parseInt(seat.sector.available_places)'
          )
      cell(cols='narrow', v-if='withdraw')
        row(nowrap)
          cell
            v-caption {{ seat.sector.available_places }}
          cell
            v-caption {{ _("left") }}
  //- used for withdraw only

  template(v-if='seat.sector.type != "no_seats"')
    cell(:cols='withdraw || discount ? "6 2-md" : "12 3-md"')
      v-select(
        v-model='seat.row',
        :full='true',
        :val='seat.row',
        :allow-empty='false',
        :options='getRows(seat.sector.id, false) || []',
        :placeholder='_("row")',
        :style='seat.sector.row && !seat.sector.row.length ? "pointer-events:none; opacity: 0.5" : ""',
        @input='setRow($event, idx, index, seat.sector.id, ticket, sectors)',
        bg='relief-3',
        :name='index + "seat" + idx + "row"'
      )
    cell(:cols='withdraw || discount ? "3 auto-md" : "12 2-md"')
      v-select(
        v-model='seat.seats_from',
        :full='true',
        :val='seat.seats_from',
        :allow-empty='false',
        :options='seat.row.id && seat.sector.id ? getSeats(seat.row, seat.sector.id) : []',
        :disabled='!seat.row',
        :style='!seat.row ? "pointer-events:none; opacity: 0.5" : ""',
        :placeholder='_("seats from")',
        @input='setSeatsInDescriptorUnit(ticket, idx, index, sectors, seat)',
        bg='relief-3',
        :name='index + "seat" + idx + "seats_from"'
      )
    cell(:cols='withdraw || discount ? ["3", "auto-md"] : "10 2-md"')
      v-select(
        v-model='seat.seats_to',
        :full='true',
        :val='seat.seats_to',
        :allow-empty='false',
        :options='(seat.seats_from && seat.seats_from.id == "all") || !seat.row ? [] : getSeats(seat.row, seat.sector.id, sectors) || []',
        :style='(seat.seats_from && seat.seats_from.id == "all") || !seat.row ? "pointer-events:none; opacity: 0.5" : ""',
        :disabled='(seat.seats_from && seat.seats_from.id == "all") || !seat.row',
        :placeholder='_("seats to")',
        @input='(seat.seats_from && seat.seats_from.id == "all") || !seat.row ? () => {} : setSeatsInDescriptorUnit(ticket, idx, index, sectors, seat)',
        bg='relief-3',
        :name='index + "seat" + idx + "seats_to"'
      )
  cell(cols='narrow', v-if='withdraw || !withdraw', justification='right')
    card
      card-panel
        v-button(
          @click='seat.sector.type === "no_seats" ? removeSector(ticket, idx, index, seat) : removeTicket(ticket, idx, index, seat)',
          :variant='["clear"]',
          type='button'
        )
          template
            v-button-element(size='lg')
              icon(type='close', color='danger', size='xs')

  cell(
    cols='2',
    v-if='$scopedSlots.default && (withdraw || discount) && seat.sector && seat.sector.type == "no_seats"'
  )
    slot(:index='idx')
  cell(
    cols='12',
    v-if='parseInt(count) > parseInt(seat.sector.available_places)'
  )
    v-caption.form__error {{ _("selected amount of seats is too high") }}
</template>

<script>
/*eslint-disable indent*/
import ShowsService from '@cabinet/services/api/shows'
import { mapState } from 'vuex'
import { useResponse } from '@cabinet/composables/use-response'

export default {
  watch: {
    isAdmissionActive: {
      handler(val) {
        if (!val) {
          this.setCount(this.seat.sector.total_seats)
          this.applyCount()
        }
      }
    },
    seat: {
      deep: true,
      /**
      * Глупая заглушка на обнуление списка секторов компонента
      */
      handler(nval, oval) {
        this.sectors_ = []
        if (nval.descriptor) {
          const id = nval.descriptor.id
          this.sectors_ = nval.descriptor.sectors.map(s => {
            return {
              ...s,
              descriptor_id: id,
              sector_descriptor_id: s.descriptor_id,
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapState('userModule', ['userData']),
    permissions() {
      this.userData.permissions.admission = true
      return this.userData.permissions
    },
    sectors() {
      return this.sectors_.length ? this.sectors_.filter(s => s.row.length || s.descriptor_id) : this.sectorsFromProps
    },
    getRows() {
      /**
       * @param { int } id - ид сектора из которого нужно достать ряды
       * @param { boolean } [ withSeats = false ] стоячий сектор или нет
       * @return { Array | Object } все ряды в секторе или же конкретный
       * */
      return (id, withSeats = false) => {
        if (this.sectors.length) {
          if (withSeats) {
            return this.sectors.find(s => s.id === id)
          }
          if (id) {
            return this.sectors.find(s => s.id === id).row
          }
        }
        return []
      }
    },
    getSeats() {
      /**
       * @param { Object } initialRow - текущий выбраный ряд
       * @param { int } sectorId - ид текущего сектора
       * @return { Array } - список всех сидений в данном ряде
       * */
      return (initialRow, sectorId) => {
        if (initialRow && initialRow.id && this.sectors.length && sectorId) {
          if (typeof this.getSeats.cache == 'undefined') {
            this.getSeats.cache = {}
          }
          if (!this.getSeats.cache[initialRow.id]) {
            let { row } = this.sectors.find(s => s.id === sectorId)
            let { seat } = row.find(r => r.id === initialRow.id)
            this.getSeats.cache[initialRow.id] = seat
          }
          return this.getSeats.cache[initialRow.id]
        }
        return []
      }
    },
  },
  props: {
    seat: {
      /**
       * тип данных объект 
       * каждая конкретная строчка ценового дескриптора,
       * в которой находится сектор, 
       * если сектор не стоячий, 
       * то в нем есть ряды, 
       * а рядах места.
      */
      default: () => { },
    },
    index: {
      /**
       * индекс дескриптора в массиве данных. 
      */
      default: () => { },
    },
    idx: {
      /**
      * индекс seat в массиве c групп в данном дескрипторе(ticket'e п.6).
      */
      default: () => { },
    },
    withdraw: {
      /**
      * "булка" идентифицируящая используется ли данный компонент
      * для списания билетов или же только для назначения цен.
      */
      default: false,
    },
    discount: {
      /**
      * "булка" идентифицируящая используется 
      * ли данный компонент для назначения 
      * дисконтов или же только для назначения цен.
      */
      default: false,
    },
    sectorsFromProps: {
      /**
      * массив-запаска, если сектора не назначены, 
      * исторически сложилось, 
      * не помню, м.б. где-то ломалось
      */
      default: () => [],
    },
    ticket: {
      /**
      * сущность которая идентифицирует весь дескриптор в целом, 
      * со всеми данными в нем, 
      * используется для удаления данных "не лету" 
      * без emit данных вверх
      */
      default: () => { },
    },
    observer: {
      /**
      * используется для добавления ошибок в случае, 
      * если мы не можем удалить seat
      */
      default: () => { },
    },
    isAdmissionActive: {
      /**
       * "булка" используемая для определения, 
       * активен ли тип декскриптора ga
       * ga - это тип дескриптора, 
       * в котором могут быть ТОЛЬКО стоячие места
       * и он(сектор) может быть разбит на группы 
       */
      default: false,
      type: Boolean
    },
    descriptors: {
      /**
       * Работает только для списания или назначения дисконтов по билетам
       */
      type: Array
    }
    // errors: {
    //   default: () => {},
    // },
  },
  data() {
    return {
      sectors_: [],
      sectorId: null,
      loading: false,
      count: 0,
    }
  },
  methods: {
    setCount(value) {
      this.count = value
    },
    /**
     * @param { int } v количество сидений для назначения, списания 
     * (в дисконте не принимает участия)
     * @return { function } вызов метода для назначения мест в данном дескрипторе, 
     * объекте списания
     * */
    applyCount(v) {
      if (this.withdraw) {
        /**
         * если срабатывает списание, то выполняется 
         * назначение количества билетов (amount) в текущем рядке
         * */
        this.$set(this.seat, 'amount', parseFloat(v))
      } else {
        /**
         * во всех других случаях мы просто назначаем 
         * кол-во билетов в текущем рядке
         * */
        this.$set(this.seat.sector, 'totals_seats', parseFloat(v))
      }
      return this.setSectorCount()
    },
    /**
     * @fires input $emit c передачей количества мест для списания или назначения
     */
    setSectorCount() {
      this.$emit('validate', this.seat)
    },
    /**
     * сбрасывает цвет дом ноды(circle, polygon) на дефолтный
     * @param { Node.ELEMENT_NODE } seat - дом нодa(circle, polygon)
     * */
    resetColor(seat) {
      if (seat) {
        seat.style.fill = '#1dace0'
      }
    },
    /**
     * @param { Node.ELEMENT_NODE } sectorOnMap - дом нодa(circle, polygon)
     * @param { Object } ticket - ref props.ticket
     * @param { int } index - ref props.ticket
     * @fires input $emit c передачей количества мест для списания или назначения
     **/
    withdrawSector(sectorOnMap, ticket, index) {
      this.resetColor(sectorOnMap)
      ticket.info.splice(idx, 1)

      ticket.sectors.splice(idx, 1)

      this.$emit('validate', {
        sectors: ticket.sectors,
        index,
      })
    },
    /**
     * Если наш сектор существует в бд то удаляем оттуда, 
     * иначе просто удаляем из локальной памяти
     * Так же сбрасывается цвет сектора на дефолтный
     * @param { Object } ticket - ref props.ticket
     * @param { int } idx - ref props.idx
     * @param { int } index - ref props.index
     * @param { Object } seat - ref props.seat
     * @fires input $emit c передачей количества мест в секторе
    */
    async deleteSector(ticket, idx, index, seat, sectorOnMap) {
      this.loading = true

      const sector = ticket.sectors.find(s => s.sector === seat.sector.id)

      const info = [...ticket.info]
      const infoblock = info.splice(idx, 1)[0]

      if (sector && sector.id) {
        let data = {
          descriptorId: ticket.id,
          sectors: [sector.id],
          info: info,
        }
        try {
          await useResponse(ShowsService.deleteSector(data), this, this.observer)
          this.resetColor(sectorOnMap)
          const sectors = [...ticket.sectors]
          sectors.splice(idx, 1)
          this.$emit('set-data', {
            sectors: sectors
          })
          this.loading = false
          this.$emit('set-info', info)

        } catch (error) {
          info.splice(idx, 0, infoblock)
          this.loading = false
        }
      } else {
        this.$emit('set-info', info)
        this.resetColor(sectorOnMap)
      }
      this.$emit('validate', {
        sectors: ticket.sectors,
        index,
      })
      this.loading = false

    },
    /**
     * @param { Object } ticket - ref props.ticket
     * @param { int } idx - ref props.idx
     * @param { int } index - ref props.index
     * @param { Object } seat - ref props.seat
     **/
    async removeSector(ticket, idx, index, seat) {
      let sectorOnMap = document.querySelector(
        `[data-title="${seat.sector.title}"]`
      )
      if (this.withdraw) {
        this.withdrawSector(sectorOnMap, ticket, index)
      } else if (!this.withdraw) {
        this.deleteSector(ticket, idx, index, seat, sectorOnMap)
      }
    },
    /**
     * фильтрует массив всех сидений 
     * в данном дескрипторе на предмет пересечений
     * если они есть, то удаляет 
     * @param { Object } ticket - ref props.ticket
     * @param { int } idx - ref props.idx
     * @param { int } index - ref props.index
     * @param { Object } seat - ref props.seat
     **/
    async removeTicket(ticket, idx, index, seat) {
      let currentIndex = idx
      if (ticket.seats && ticket.seats.length) {
        let values = []
        let valuesToRemove = []
        let hasDupes = false
        // происходит проверка на то, есть ли в текущем дескрипторе
        // задублированые места, если они есть то в текущие места билета
        // записываются места без дубликатов
        // а иначе все текущие места
        if (ticket.info[currentIndex].seats.length) {
          let v = ticket.info[currentIndex].seats.map(({ id }) => id)
          valuesToRemove = ticket.seats.filter(s => (new Set(v).has(s)))
          hasDupes = valuesToRemove.length != new Set(valuesToRemove).size
          if (hasDupes) {
            values = [...new Set(valuesToRemove)]
          } else {
            values = v
          }
        }
        ticket.seats = values
        this.loading = true
        // записываем все места в группе, которые должны удалить
        // необходимо для того, чтобы очистить их цвета 
        // на свг после удачного удаления
        // или же вернуть их обратно в массив данных
        // если мы не можем их удалить из БД
        const info = [...ticket.info]
        const infoblock = info.splice(currentIndex, 1)
        try {
          // Если у нас нет дупликатов и есть сидения в текущем инфо, и так де если у нас не списание билетов
          // То мы удаляем пробуем удалить билеты из БД
          // hasDupes - приоритетная "булка", проблемное удаление 
          // если у нас в одном дескрипторе есть билеты на определенный ряд в бд и так же
          // были добавлены билеты на тот же ряд то всегда, что бы мы не нажали - удаляется сперва тот которого нет в бд
          // Не нашел решения, по этому оно так работает
          if (!hasDupes && ticket.info[currentIndex].seats.length && !this.withdraw && ticket.id) {
            this.$emit('set-info', info)
            ticket.info.splice(currentIndex, 1)
            await useResponse(ShowsService.deleteSeats(ticket.id, {
              seats: valuesToRemove,
              info: info,
            }), this, this.observer)

            infoblock[0].seats.forEach(s => {
              let seatOnMap = document.getElementById(s.geom_id)
              this.resetColor(seatOnMap)
            })

            this.loading = false
            // Иначе просто удаляется из списка текущих билетов
          } else {

            infoblock[0].seats.forEach(s => {
              let seatOnMap = document.getElementById(s.geom_id)
              this.resetColor(seatOnMap)
            })
            await this.$nextTick()
            this.$emit('set-info', info)
            this.$emit('validate', {
              seats: ticket.seats,
              idx: currentIndex,
              index: index,
            })
            this.loading = false
          }
        } catch (error) {
          // В случае, Если мы не можем удалить рядок то все возвращаем на свои места
          // удаление ЗАПРЕЩЕНО, если в данном рядке есть уже проданые и списаные билеты
          console.error(error)
          info.splice(currentIndex, 0, ...infoblock)
          this.$emit('set-data', info)
          this.$emit('set-seats', [...ticket.seats, ...valuesToRemove])
        }
      } else {
        const info = [...ticket.info]
        info.splice(idx, 1)
        this.$emit('set-info', info)
      }

    },
    /** 
     * если сектор уже назначен то мы не имеем возможности его изменить -
     * влечет за собой дорогостоящие манипуляции, т.к. в данной группе уже
     * могут быть назначены места и нам необходимо будет удалять группу из БД
     * 
     * удаляет из ref props.ticket ряд и места, 
     * @param { Object } sector - выбраный сектор
     * @param { int } idx - ref props.idx
     * @param { Object } [this.ticket] - ref props.ticket
     * @param { int } index - ref props.index
    */
    async setSector(sector, idx, ticket = this.ticket, index) {
      console.log(sector)
      console.log('setSector')
      const prevData = JSON.parse(JSON.stringify(this.seat))
      console.log('this Seat', this.seat)
      // JSON.parse(JSON.stringify(this.seat))
      // предотвращает изменение сектора
      if (prevData.sector.id && sector.id !== prevData.sector.id) {
        this.sectorId = prevData.sector
        this.$emit('set-data', {
          sector: this.sectorId
        })
        return
      }
      // Если тип сектора - стоячий то к списку существующих секторов 
      // добаляем текущий
      if (sector.type === 'no_seats') {
        let sectors = ticket.sectors
        if (!ticket.sectors) {
          sectors = []
        }
        this.$emit('set-sectors', sectors.concat(sector))
        this.$nextTick(() => {
          console.log('if', sector)
          this.$emit('set-data', {
            sector: sector,
          })
          let sectorOnMap = document.querySelector(`[data-title="${sector.title}"]`)
          if (sectorOnMap) {
            sectorOnMap.style.fill = '#1dace0'
          }
          this.$emit('validate', {
            sector: true,
            idx,
          })
        })
        // Иначе в инфо рядка дескриптора записываем сектор, 
        // чтобы можно было дальше работать с выбором ряда
      } else {
        console.log('else', sector)
        this.$emit('set-data', {
          sector: sector,
        })
      }

    },
    /**
     * простая проверка на предмет пересечений данных в двух массивах 
     * @param { Array } a - массив данных
     * @param { Array } b - массив данных
     * @param { String } [optionalKey] - опциональный ключ для сравнения
    */
    intersection(a, b, optionalKey) {
      const s = new Set(b)
      if (optionalKey) {
        return a.filter(x => s.has(x[optionalKey]))
      }
      return a.filter(x => s.has(x))
    },
    /**
     * сбрасывает сектор, ряд и сидения в ref props.seat
     * @param { int } idx - ref props.idx
     * @param { Object } ticket - ref props.ticket
     */
    async resetAllTicketData(idx, ticket) {
      try {
        this.resetSector(idx, ticket)
        await this.$nextTick()
        this.resetRow(idx, ticket)
        await this.$nextTick()
        this.resetSeats(idx, ticket)
      } catch (error) {

      }
    },
    /**
     * сбрасывает сектор в ref props.seat
     * @param { int } idx - ref props.idx
     * @param { Object } ticket - ref props.ticket
     */
    resetSector(index, ticket) {
      this.sectorId = null
      if (ticket.info[index].descriptor) {
        this.$set(ticket.info[index], 'sector', {})
      }
    },
    /**
     * сбрасывает ряд в ref props.seat
     * @param { int } idx - ref props.idx
     * @param { Object } ticket - ref props.ticket
     */
    resetRow(idx, ticket) {
      this.$emit('set-data', {
        row: {}
      })
    },
    /**
     * сбрасывает сиденья в ref props.seat
     * @param { int } idx - ref props.idx
     * @param { Object } ticket - ref props.ticket
     */
    resetSeats(idx, ticket) {
      this.$emit('set-data', {
        seats_from: {},
        seats_to: {},
        seats: []
      })
    },
    /**
     * подготавливает массив сидений предварительно полчив данные по ряду 
     * для того, чтобы их записать потом в инфо
     * передает полученый ряд в ref this.assignRow
     * @param { Object } id - деструктуризация ряда, и получение его id
     * @param { int } idx - ref props.idx
     * @param { int } index - ref props.index
     * @param { int } sectorId - id выбраного сектора
     * @param { Object } ticket - ref props.ticket
     * @param { Array } sectors - ref computed.sectors
    */
    setRow(
      { id },
      idx,
      index,
      sectorId,
      ticket = this.ticket,
      sectors = this.sectors
    ) {
      const sector = this.getRows(sectorId, true, sectors)
      let row = sector.row.filter(row =>
        id == 'all' ? row.id !== id : row.id == id
      )

      let info = ticket.info.splice(idx, 1)[0]
      if (info.seats && info.seats.length) {
        info.seats.forEach(s => {
          let seat = document.getElementById(s.geom_id)
          this.resetColor(seat)
        })
      } else if (info.sector.type === 'no_seats') {
        let sector = document.getElementById(info.sector.geom_id)
        this.resetColor(sector)
      }


      const data = row.reduce((acc, row) => {
        return acc.concat(this.assignRow(row, sector, ticket))
      }, ticket.info)
      this.$emit('set-info', data)
      this.$nextTick().then(res => {
        this.setSeatInDescriptor(ticket, idx, index)
      })
    },
    /**
     * выбирает все места в данном ряде, так же присваивает 
     * значения рада и сектора в текущий рядок
     * @param { Object } row - деструктуризация ряда, и получение его id, title, seat
     * @param { Object } sector - сектор из списка доступных секторов в данном зале
     * @param { Object } ticket - ref props.ticket
     * */
    assignRow({ id, title, seat }, sector, ticket = this.ticket) {
      const seats = seat
        .filter(s => s.id !== 'all')
        .map(({ geom_id, id, title }) => ({
          geom_id,
          id,
          title,
        }))
      this.observer.reset()

      let val = {
        sector: {
          id: sector.id,
          title: sector.title,
        },
        row: {
          id: id,
          title: title,
        },
        seats: seats,
        seats_from: {
          id: 'all',
          title: 'all',
        },
        seats_to: {},
      }

      if (this.seat.descriptor) {
        val.descriptor = this.seat.descriptor
      }

      return val
    },
    /**
     * Переопределяет верный порядок мест если from > to
     * @param { Object } ticket - ref props.ticket
     * @param { int } idx - ref props.idx
     * @param { int } index - ref props.index
     * @param { Object } seat - ref props.seat
    */
    async setSeatsInDescriptorUnit(ticket, idx, index, seat) {
      let sector = ticket.info[idx].sector
      let row = ticket.info[idx].row
      let seats_from = ticket.info[idx].seats_from
      let seats_to = ticket.info[idx].seats_to
      // если название стартового сидения выше чем название 
      // конечного сидения, то меняет их местами.
      if (seats_to.title && parseFloat(seats_from.title) > parseFloat(seats_to.title)) {
        const seats_from_copy = seats_from
        const seats_to_copy = seats_to
        ticket.info[idx].seats_from = seats_to_copy
        ticket.info[idx].seats_to = seats_from_copy
      }
      await this.$nextTick()
      let rowSeats = this.getSeats(row, sector.id)

      // this.resetColor(rowSeats)
      rowSeats.forEach(s => {
        let seatOnMap = document.getElementById(s.geom_id)
        this.resetColor(seatOnMap)
      })
      // 
      if (ticket.info[idx].seats_from.id || ticket.info[idx].seats_to.id) {
        let indexOfStart = rowSeats.findIndex(i => ticket.info[idx].seats_from.id == i.id)
        let indexOfFinish = rowSeats.findIndex(i => ticket.info[idx].seats_to.id == i.id)
        if (indexOfStart > -1 && indexOfFinish > -1) {
          // получение количества нужных мест для среза их из массива всех мест в ряде
          this.getProperSeats(ticket, rowSeats, indexOfStart, indexOfFinish + 1, idx).then(
            ticket => this.setSeatInDescriptor(ticket, idx, index)
          )
        }
      }
    },
    /**
     * @param { Object } ticket - ref props.ticket
     * @param { Array } rowSeats - все сиденья в текушем ряде
     * @param { int } startIndex - стартовый индекс для среза
     * @param { int } endIndex - конечный индекс для среза
     * @param { int } idx - ref props.idx
     * @fires - изменение info текущего дескриптора 
     * @returns { Promise }
     * */
    getProperSeats(ticket, rowSeats, startIndex, endIndex = 1, idx) {
      this.$emit('set-data', {
        ...ticket.info[idx],
        seats: rowSeats.slice(startIndex, endIndex),
      })
      return Promise.resolve(ticket)
    },
    /**
     * @param { Object } ticket - ref props.ticket
     * @param { int } idx - ref props.idx
     * @param { int } index - ref props.index
     * @fires - изменение списка сидений в текущем дискрипторе 
     * @fires - валидацию пересечений мест в дескрипторах
    */
    setSeatInDescriptor(ticket, idx, index) {
      this.observer.reset()
      this.$emit('set-seats', ticket.info
        .reduce((acc, info) => {
          if (info.seats) {
            acc.push(...info.seats.map(s => s.id))
          }
          return acc
        }, [])
      )

      this.$nextTick().then(res => {
        this.$emit('validate', {
          seats: ticket.seats,
          idx,
          index,
        })
      })
    },
  },
  mounted() {
    if (this.seat.sector && this.seat.sector.id) {
      this.sectorId = { ...this.seat.sector }
      this.count = !this.withdraw ? this.seat.sector.totals_seats : 0
    }
  }
}
</script>

