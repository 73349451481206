<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card()
      card-panel(offset='full')
        card-panel
          card-panel(offset='bottom')
            FinanceFilter(
              :meta='meta' 
              :types='types'
              @fetch='fetchReports($event)'
            )
          card-panel(v-if='reports.length')
            FinanceList(:items='reports')
          
        card-panel(
          v-if='!reports.length'
          variant='border' 
          offset='full'
        )
          notice()
            template(v-slot:icon='')
              icon(
                type='error' 
                color='warning' 
                size='5xl'
              )
            template(v-slot:caption='')
              v-caption(variant='bold') {{ initial ? _('you have no reports yet') : _('f 0 reports are matching this query!')}}

</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import ReportsService from '@cabinet/services/api/reports'
import { useResponse } from '@cabinet/composables/use-response'
import usePagination from '@cabinet/composables/use-pagination'

import FinanceFilter from '@cabinet/components/finance/FinanceFilter'
import FinanceList from '@cabinet/components/finance/FinanceList'

export default {
  components: {
    FinanceFilter,
    FinanceList,
  },
  setup(props, context) {
    const loading = ref(false)
    const reports = ref([])
    const meta = ref({})
    const types = ref([])
    const initial = ref(true)

    const fetchReports = async (query, initialLoad) => {
      try {
        
        initial.value = initialLoad
        loading.value = true
  
        let res = await useResponse(ReportsService.fetch(query), context.root)
  
        meta.value = usePagination(res.meta.pagination)
  
        types.value = res.meta.types.map(([id, title]) => ({id, title})) 
        reports.value = res.data
        
        loading.value = false
      } catch (error) {
        loading.value = false
      }
      
    }
    onMounted(async () => {
      await fetchReports(null, true)
    })
    return {
      loading,
      initial,
      reports,
      types,
      meta,

      fetchReports
    }
  }
}
</script>