<template lang='pug'>
include /mixins
.control-input.control-input--variant_radio(
  
)
  row(offset space='5xs' )
    cell(cols='12')
      row(offset alignment='center' space='5xs' nowrap)
        cell(cols='narrow')
          +b.LABEL.control-radio
            slot(name='input')
            +e.label
        cell(cols='narrow')
          slot(name='text')
</template>

<script>
export default {
  
}
</script>


