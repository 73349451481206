import Api from '@cabinet/services'
import { AxiosPromise } from 'axios';


export default {
  fetch({params}) {
    return Api().get('/postie/letters/list/', {
      params
    })
  },
  fetchTemplates() {
    return Api().get('/postie/templates/list/')
  },
  fetchTemplate({id}) {
    return Api().get(`/postie/templates/provider/receive/${id}/`)
  },
  createTemplate({data}) {
    return Api().post(`/postie/templates/provider/create/`, data)
  },
  updateTemplate({id, data}) {
    return Api().patch(`/postie/templates/provider/update/${id}/`, data)
  }
}