<template lang='pug'>
  include /mixins
  //- fetcher(:service='service')
  //-   template(v-slot='{data, meta, isLoading, fetch}')
  +b.card
    +e.panel--offset_full
      +e.panel
        //- preloader(v-if='isLoading',
        //-           class="preloader--absolute")
        validation-observer(
          ref='observer'
          v-slot='{invalid}'
          tag='form'
          autocomplete='off'
          @submit.prevent='dispatchAction'
        )
          row(
            offset
            alignment='center'
            column="till-sm"
          )
            cell(cols='12 narrow-sm')
              v-select(
                :options='options'
                :val='action'
                :placeholder='_(`u Action`)'
                :allow-empty='true'
                v-model='action'
                size='lg'
              ).multiselect--width_fixed-sm
            cell(cols='12 narrow-sm')
              +b.control-box
                v-button(
                  variant='success'
                  type='submit'
                )
                  v-button-element(size='lg')
                    v-caption(
                      :variant='["bold", "uppercase"]' 
                      size='6'
                    ) {{ _('u Go')}}

            cell(cols='12 narrow-sm')
              +b.control-box
                v-button(
                  variant='danger'
                  border='whole'
                  @click='manageUsher(null)'
                )
                  template(v-slot:prepend='')
                    cell
                      v-button-element(
                        size='lg'
                        variant="warning-lite"
                      )
                        v-caption(variant='bold')
                          icon(
                            type='add'
                            variant='opaque'
                            size='md'
                          )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ _('Add new usher') }}
            cell(cols='12 8-md 5-lg')
              ui-search(
                @input='fetch($event)' 
                type='search'
                cols='7 9-md 8-lg'
              )
                template(v-slot:activator='{send, data}')  
                  cell(cols='auto')
                    v-button(
                      size='lg'
                      :border='["top_right", "bottom_right"]'
                      @click='send(data)'
                    )
                      cell(cols='auto')
                        v-button-element(size='lg') 
                          v-caption(
                            :variant='["bold", "uppercase"]' 
                            size='6'
                          ) {{ _('s search') }}

            
           
      loading-wrapper(:loading='isLoading')
        card-panel(offset='vertical')
          card(space='md')
            card-panel
              +b.scrollable-wrapper--till-2xl.--full(style='min-height: initial; max-height: initial')
                +b.TABLE.table--variant_scroll.--space_lg
                  +e.THEAD.thead
                    +e.TR.row--relief
                      +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
                        row(alignment='center' space='md' nowrap)
                          cell
                            v-checkbox
                              +b.INPUT(type="checkbox"
                                      :value='allSelected'
                                      @input='allSelected = !allSelected;selectItems(data, allSelected)').control-checkbox__input
                          cell
                            v-caption(color='white'
                                      size='6') {{ _('u Name') }}
                      +e.TH.cell--variant_bold.--head 
                        v-caption(color='white') {{ _('u Active shows') }}
                      +e.TH.cell--variant_bold.--head 
                        v-caption(color='white') {{ _('u Country') }}
                      +e.TH.cell--variant_bold.--head 
                        v-caption(color='white') {{ _('u State/province') }}
                      +e.TH.cell--variant_bold.--head 
                        v-caption(color='white') {{ _('u City') }}
                      +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom
                        v-caption(color='white') {{ _('u Manage shows') }}
                  +e.TBODY.tbody
                    usher-item(v-for='usher in data'
                                :key='usher.id'
                                :usher='usher')
                      template(v-slot:work='')
                        +b.control-button--variant_clear(@click='addShow(usher.id)')
                          icon(type='pencil'
                              size='lg'
                              color='success')
            card-panel(offset='top')
              ui-pagination(
                :offset='meta.offset'
                :total='meta.total'
                :limit='meta.limit'
                @input='fetch({ page: $event })'
              )
</template>

<script>
// import UsherService from ''
import UshersService from '@cabinet/services/api/ushers'
// import ShowsService from '@cabinet/services/api/shows'
import { dateFormatter} from '@cabinet/util'
import { mapState } from 'vuex'
import UiSearch from '@cabinet/components/forms/UiSearch'
import Fetcher from '@cabinet/components/vendor/Fetcher'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import UsherItem from '@cabinet/components/ushers/UsherItem'
import ShowsManipulationsList from '@cabinet/components/default/ShowsManipulationsList'
import UsherForm from '@cabinet/components/forms/UsherForm'
export default {
  
  components: {
    UiSearch,
    Fetcher,
    UiPagination,
    UsherItem,
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        let fewItemsSelected = val.filter(usher => usher.isSelected).length > 1
        if (fewItemsSelected) {
          
          if (this.action.id === 1) {
            this.action = fewItemsSelected ? '' : this.action
          }
        } 
        this.options[0].$isDisabled = fewItemsSelected
      }
    }
  },
  data() {
    return {
      paginated: {},
      data: [],
      meta: {},
      isLoading: false,
      service: UshersService,
      allSelected: false,
      options: [
        {
          id: 1,
          title: this._('u Edit usher'),
          $isDisabled: false
        },
        {
          id: 2,
          title: this._('u Remove usher(s)')
        }
      ],
      action: ''
    }
  },
  computed: {
    ...mapState('userModule', ['userData']),
  },
  methods: {
    addShow(id) {
      let date = dateFormatter(new Date(), true)
      const slug = this.userData.provider_settings.slug
      const query = {
        finish_at__gt: date
      }
      UshersService.getUsherShowList(id).then(res => {
        return res.data.data.map(({id, title}) => ({id, title}))
      }).then(ushers => {
        this.showModal(ushers, slug, id, query)
      })
        .catch(err => {
          console.error(err)
        })

    },
    showModal(ushers, slug, id, query) {
      this.$modal.show(
        ShowsManipulationsList,
        {
          model: ushers,
          id,
          slug,
          query,
        },
        {
          adaptive: true,
          height: 'auto',
          maxWidth: 330,
          classes: ['v--modal', 'is-overflowed']
        }, {
          'before-close': () => this.fetch()
        }
      )
    },
    removeShow(id) {
    },
    selectItems(ushers, value) {
      ushers.forEach(usher => this.$set(usher, 'isSelected', value))
    },
    manageUsher(usher = this.data.find(u => u.isSelected)) {
      this.$modal.show(
        UsherForm,
        {
          usher: usher,
          callback: this.fetch
        },
        {
          adaptive: true,
          height: 'auto',
          maxWidth: 430,
          scrollable: true,
          classes: ['v--modal']
        }
      )
    },

    async deleteUshers() {
      try {
        this.isLoading = true
        let ushers = {
          ushers: this.data.filter(s => s.isSelected).map(({id}) => id)
        }
        await UshersService.killUsher(ushers)
        this.isLoading = false
        this.fetch()
        this.options[0].$isDisabled = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    async fetch(url) {
      this.isLoading = true
      try {
        let { data, meta } = (await UshersService.fetch(url)).data
        this.data = data
        this.meta = meta.pagination
        this.paginated = meta.pagination
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.isLoading = false
      }
    },
    async dispatchAction() {
      const isValid = await this.$refs.observer.validate() 
      if (!isValid) {

      } else {
        if (this.action.id === 1) {
          this.manageUsher()
        } else if (this.action.id === 2) {
          this.deleteUshers()
        }
      }
    }
  },
  created() {
    // this.fetch()
  }
}
</script>