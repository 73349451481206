<template lang="pug">
  include /mixins
  +b.modal-wrapper--bg-contrast
    +e.close(@click='$emit("close")') 
      icon(type='close')
    card
      card-panel
        +b.FORM.form
          row(offset)
            cell(cols='12')
              +b.UI-TABS.tabs.mt-1(ref='tab')
                template(v-slot='{ activeItem,activate: activateTab }')
                  +b.tab-list
                    +e.body
                      +e.toggle--variant_default.tab-list__item(
                        @click='activate(index);activateTab(index)'
                        v-for='(item, index) in stepsList'
                      )
                        v-caption(
                          :color="activeItem === index ? 'default' : 'brand-5' "
                        ) {{ item.name }}
      card(variant='section' stacked='top')
        card-panel(offset='vertical')
          loading-wrapper(:loading='loading' )
            row(offset) 
              cell(cols='12')
                card(:variant='["offset"]')
                  card-panel(offset='horizontal')
                    transition-group(name='fade')
                      ui-ushers-list(
                        v-if='stepsList[0].active'
                        :id='id'
                        key='2'
                      )
                      ui-ushers-form(
                        v-if='stepsList[1].active'
                        :id='id'
                        key='1'
                      )
                      

</template>

<script>
  // import { mapActions } from 'vuex'
  import UiTabs from '@cabinet/components/vendor/UiTabs'
  import UiUshersList from '@cabinet/components/show/modals/ushers/UiUshersList'
  import UiUshersForm from '@cabinet/components/show/modals/ushers/UiUshersForm'

  export default {
    components: {
      UiTabs,
      UiUshersList,
      UiUshersForm,
    },
    props: {
      id: {
        required: true
      }
    },
    data() {
      return {
        stepsList: [
          {
            name: this._('s Assign ushers'),
            active: true
          },
          {
            name: this._('s Manage ushers'),
            active: false
          },
        ],
        loading: false
      }
    },
    methods: {
      activate(index) {
        this.stepsList.forEach(s => s.active = false)
        this.stepsList[index].active = true
      },
      
    },
  }
</script>