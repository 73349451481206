<template lang="pug">
  include /mixins
  +b.SECTION.section--main(ref='content')
    +e.el(ref='componentFocusTarget')
    row(offset justification='center' alignment='center' style='min-height: 100vh;')
      cell(cols='12 8-sm 6-md 4-lg 3-xl 2-2xl')
        router-view.child-view(v-bind='$props')
</template>

<script>
export default {
  props: {
    recovery: {
      default: false
    },
    doLogin: {
      default: false
    },
    next: {
      default: ''
    },
    uid: {
      default: ''
    },
    token: {
      default: ''
    }
  },
}
</script>