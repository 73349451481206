export default {
  data() {
    return {
      searchResults: [],
      service: null,
      timer: 200,
    }
  },
  mounted() {
    new google.maps.places.AutocompleteService(
      this.service = new window.google.maps.places.AutocompleteService()
    )
  },
  methods: {
    autocompleteInput(searchText) {
      const timeout = 200
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.service.getPlacePredictions({
          input: searchText,
          componentRestrictions: { country: ["us", "ca"] },
          types: ['address'],
        }, this.displaySuggestions)
      }, timeout)
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions
    },
    select(selected, addressKey) {
      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId: selected.place_id,
            fields: ["address_components"],
          },
          details => {
            this.hiddenInputs.forEach(input => { this.formData[addressKey][input] = ''})
            details.address_components.forEach(addressItem => {
              if (addressItem.types.includes('postal_code')) {
                this.formData[addressKey].zipcode = addressItem.long_name
              }
              if (addressItem.types.includes('country')) {
                this.formData[addressKey].country = addressItem.long_name
              }
              if (addressItem.types.includes('administrative_area_level_1')) {
                this.formData[addressKey].subdivision = addressItem.long_name
              }
              if (addressItem.types.includes('locality') || addressItem.types.includes('sublocality')) {
                this.formData[addressKey].city = addressItem.long_name
              }
            })
            const route = details.address_components.find(addressItem => addressItem.types.includes('route'))
            const streetNumber = details.address_components.find(addressItem => addressItem.types.includes('street_number'))
            this.formData[addressKey].route = route?.long_name || ''
            this.formData[addressKey].streetNumber = streetNumber?.long_name || ''
          }
        )
      } catch (e) {
        console.error('Error occured')
      }
    }
  },
}
