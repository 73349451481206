import { render, staticRenderFns } from "./ProvidersRow.vue?vue&type=template&id=6f12517c&lang=pug&functional=true&"
import script from "./ProvidersRow.vue?vue&type=script&lang=js&"
export * from "./ProvidersRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports