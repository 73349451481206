<template lang="pug">
loading-wrapper(
  :loading='loading'
)
  card
    card-panel(offset='full')
      SecurityAccordion
        template(v-slot:caption='')
          | {{ _('m Mail settings' )}}
        template(v-slot:content='')
          card-panel(offset='top' v-if='settings')
            card-panel()
              row(offset)
                cell(cols='12')
                  ui-toggler(
                    type='checkbox'
                    v-model='settings.is_active'
                    size='lg'
                    border='whole'
                    bg='relief-3'
                    :val='settings.is_active'
                    name='is active'
                    @input='activate'
                  )
                    template(v-slot:prepend='') 
                      v-caption(
                        size='6' 
                        color='brand-2'
                        variant='regular'
                      ) {{ _('Use services account (if you don\'t have  a personal account)') }}
                    template(v-slot:append='') 
                      v-caption(
                        size='6' 
                        color='brand-2'
                        variant='regular'
                      ) {{ _('Use personal account') }}
                cell(cols='12' v-if='settings.is_active')
                  validation-observer(
                    ref='observer'
                    tag='form'
                    v-slot='{invalid}'
                    autocomplete='off'
                    @submit.prevent='send'
                  )
                    row(offset)
                      cell(cols='6 4-sm 3-md 4-lg')
                        ui-input(
                          :placeholder="_('m Email Host')"
                          :val='settings.host'
                          type='search'
                          name='host'
                          size='lg'
                          autocomplete='off'
                          bg='relief-3'
                          border='whole'
                          v-model='settings.host'
                          validate='required'
                        )
                      cell(cols='6 4-sm 3-md 4-lg')
                        ui-input(
                          :placeholder="_('m Email port')"
                          :val='settings.port'
                          type='search'
                          name='port'
                          size='lg'
                          autocomplete='off'
                          bg='relief-3'
                          border='whole'
                          v-model='settings.port'
                          validate='required'
                        )

                      cell(cols='6 4-sm 3-md 4-lg')
                        ui-input(
                          :placeholder="_('m Default From Email')"
                          :val='settings.from_email'
                          type='search'
                          name='from email'
                          autocomplete='off'
                          size='lg'
                          bg='relief-3'
                          border='whole'
                          v-model='settings.from_email'
                          validate='required'
                        )
                      cell(cols='6 4-sm 3-md 4-lg')
                        ui-input(
                          :placeholder="_('m Email Authentication Username')"
                          :val='settings.username'
                          type='search'
                          name='username'
                          autocomplete='off'
                          size='lg'
                          bg='relief-3'
                          border='whole'
                          v-model='settings.username'
                          validate='required'
                        )

                      cell(cols='6 4-sm 3-md 4-lg')
                        ui-input(
                          :placeholder="_('m Email Authentication Password')"
                          :val='settings.password'
                          type='password'
                          name='password'
                          size='lg'
                          bg='relief-3'
                          border='whole'
                          v-model='settings.password'
                          validate='required'
                        )

                      cell(cols='6 4-sm 3-md 4-lg' justification='right')
                        v-button(
                          variant='success'
                          type='submit'
                        )
                          v-button-element(size='lg') 
                            v-caption(
                              :variant='["bold", "uppercase"]' 
                              size='6'
                            ) {{ _('m Save') }}


</template>

<script>
/* eslint-disable no-unused-vars */
import { ref, onMounted } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import PostieService from '@cabinet/services/api/postie'
import SecurityAccordion from  '@cabinet/components/settings/SecurityAccordion'

export default {
  components: {
    SecurityAccordion,
  },
  setup(props, context) {
    
    const loading = ref(false)
    const active = ref(false)

    const settings = ref({})

    const toggleActive = (activate) => {

      if (activate) {
        return PostieService.activate()
      }

      settings.value.is_active = true
      return Promise.reject(false)

      
    }

    const activate = () => {


      if (settings.value.from_email) {
        if (!settings.value.is_active) {
          openModal()
        } else {
          toggleActive(true)
        }
      }
      // active.value = !active.value
    }


    


    function openModal() {
      return import('@cabinet/components/modals/UiDelete').then(res => {
        return context.root.$modal.show(res.default,
          {
            fn: toggleActive,
            customText: context.root._('Are you sure want to disable?'),
            itemID: settings.value.id
          },
          {
            height: 'auto',
            adaptive: true,
            width: 310,
          }
        )
      })
    }

    const send = async () => {
      const type = settings.value.id ? 'update' : 'create'

      try {
        const res = await useResponse(PostieService[type](settings.value), context.root, context.refs.observer)
      } catch (error) {
        console.error(error)
      }
    }

    const fetch = async () => {
      loading.value = true
      try {
        settings.value = (await useResponse(PostieService.fetch(), context.root, context.refs.observer)).data
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
      loading.value = false
    }

    onMounted(() => {
      fetch()
    })

    return {
      loading,
      active,
      settings,

      activate,
      send
    }
  }
}
</script>