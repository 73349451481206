import Api from '@cabinet/services'


export default {
  fetch() {
    return Api().get('/fee/list/')
  },
  create(data) {
    return Api().post('/fee/create/', data)
  },
  getCreateOptions() {
    return Api().options('/fee/create/')
  },
  update(data, id) {
    return Api().put(`/fee/${id}/update/`, data)
  },
  delete(id) {
    return Api().delete(`/fee/${id}/delete/`)
  },



  fetchShowFees(id) {
    return Api().get(`/fee-description/${id}/list/`)
  },
  createShowFee(data) {
    return Api().post(`/fee-description/edit/`, data)
  },
  deleteFee(id) {
    return Api().delete(`/fee-description/${id}/delete/`)
  },
  updateFee({ id, data }) {
    return Api().patch(`/fee-description/${id}/update/`, data)
  }
}