
import { pathDataToPolys } from 'svg-path-to-polygons'
// import store from '@cabinet/store'

import panzoom from 'panzoom/dist/panzoom'
import Hammer from 'hammerjs'
import Select from './selectable'

export const filterSort = (items) => {
  let filtered = items
    .filter(s => {
      return s.tagName === 'circle'
    })
    .sort((a, b) => {
      if (a.id.split('_').length > 1 && b.id.split('_').length > 1) {
        let aId = a.id.split('_')[1].replace('s', '')
        let bId = b.id.split('_')[1].replace('s', '')
        return bId - aId
      }
    })
  return filtered
}

export const getUniques = (items) => {
  let uniques = new Set()
  items
    .forEach(s => {
      if (!uniques.has(s)) {
        uniques.add(s)
      }
      s.classList.add('is-selected')
      s.classList.remove('is-danger')
      
    })
  return uniques
}

export const inside = (point, vs) => {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research
  // /Short_Notes/pnpoly.html
  let x = point[0],
    y = point[1]

  let inside = false
  if (vs) {
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1]
      let xj = vs[j][0],
        yj = vs[j][1]
  
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }
  }
  return inside
}



const initControl = (className, instance) => {
  
}








export class SvgManipulationsHall {
  constructor(image, options) {
    this.image = document.getElementById(image) || document.querySelector(image)
    this.imageContainer = image
    let svg = [...this.image.querySelectorAll('svg')]
    this.imageWrapper = this.image
    this.sectors = svg[0]
    this.seat = svg[1]
    this.circles = []
    this.polies = []
    this.polygons = []
    this.panzoomThumb = null
    this.panzoom = undefined
    this.options = document.getElementById(options) || document.querySelector(options)
  }
  removeClass() {
    this.seat.classList.add('is-hidden')
  }
  center(item) {
    let center = item.dataset.center.split(',')
    return [parseFloat(center[0]), parseFloat(center[1])]
  }
  setTransform(e, ratio = 5.5) {
    let mainVal = e.getTransform()
    let getScopeTransform = this.panzoomThumb.getTransform

    let thumbVal = getScopeTransform()

    let panSvg = this.options
    let image = document.getElementsByClassName('image-driver')[0]

    let { width: mainWidth, height: mainHeight } = image.getBoundingClientRect()

    let { width: panWidth, height: panHeight } = panSvg.getBoundingClientRect()


    let Xmultiplier = mainWidth / panWidth
    let Ymultiplier = mainHeight / panHeight

    // wrapper.style.height = `${mainHeight / Ymultiplier}px`

    let mainPanX = mainVal.x,
      mainPanY = mainVal.y,
      mainZoom = mainVal.scale,
      thumbPanX = thumbVal.x,
      thumbPanY = thumbVal.y,
      thumbZoomX = thumbVal.scale / Xmultiplier,
      thumbZoomY = thumbVal.scale / Ymultiplier

    let thumByMainZoomRatioX = thumbZoomX / mainZoom
    let thumByMainZoomRatioY = thumbZoomY / mainZoom

    let scopeX = thumbPanX - mainPanX * thumByMainZoomRatioX
    let scopeY = thumbPanY - mainPanY * thumByMainZoomRatioY
    scope.setAttribute('x', scopeX - 1)
    scope.setAttribute('y', scopeY + 1)

    let scopeWidth = mainWidth * thumByMainZoomRatioX
    let scopeHeight = mainHeight * thumByMainZoomRatioY
    scope.setAttribute("width", scopeWidth)
    scope.setAttribute("height", scopeHeight)
  }
  initPan() {
    this.panzoom = panzoom(this.image, {
      maxZoom: 10,
      minZoom: 1,
      zoomSpeed: window.innerWidth < 1200 ? 0.02 : 0.065,
      onTouch(e) {
        e.stopPropagation()
        return false // tells the library to not preventDefault.
      },
    })

    initControl('controls__item', this.panzoom)

    if (this.options) {
      this.panzoomThumb = panzoom(this.options, {
        maxZoom: 1,
        minZoom: 1
      })

      this.panzoomThumb.pause()
      this.panzoom.on('zoom', (e) => {
        this.setTransform(e)
      })
      this.panzoom.on('pan', (e) => {
        this.setTransform(e)
      })
    }

    this.hammer = Hammer(this.image, {
      inputClass: Hammer.SUPPORT_POINTER_EVENTS ?
        Hammer.PointerEventInput : Hammer.TouchInput
    })
    // Enable pinch
    this.hammer.get('pinch').set({
      enable: true
    })
    // Handle double tap
    this.hammer.on('doubletap', function (ev) {
      // instance.zoomIn()
    })

    this.hammer.on('pinchstart pinchmove', function (ev) {
      
    })
    window.panzoom = this.panzoom
  }
  getCircles() {
    this.circles = [...this.seat.querySelectorAll('circle')]
    // let g = this.seat.querySelector('g')
    if (typeof process === 'object') {
      this.circles
        .forEach((c, id) => {
          c.dataset.center = [
            parseFloat(
              c.cx.baseVal.value
            ),
            parseFloat(
              c.cy.baseVal.value
            ),
          ]
          let items = [...c.closest('g').querySelectorAll('circle')]
          c.onmouseover = (e) => {
            // if (!window.isDragging) {
            items.forEach(item => {
              item.classList.add('is-highlighted')
            })
            // } else {
            //   c.classList.add('is-highlighted')
            // }
          }
          c.onmouseout = (e) => {
            // if (window.isDragging) {
              
            // } else {
            items.forEach(item => {
              item.classList.remove('is-highlighted')
            })
            // }
          }
        })
        
    }
  }
  getPolies() {
    this.polies = [...this.sectors.querySelectorAll('polygon')]
    if (!this.polies.length) {
      this.polies = [...this.sectors.querySelectorAll('path')]
    }
    return this.polies

  }
  // untestable
  getItems(p) {
    let items = []
    for (let i = 0; i < p.points.numberOfItems; i++) {
      items.push(p.points.getItem(i))
    }
    return items
  }
  getPolygonsData() {
    this.polygons = this.polies.map((p, id) => {
      p.id = id
      if (typeof process === 'object') {
        if (p.points) {
          // let { numberOfItems, length, ...rest} = p.points
  
          let items = this.getItems(p)
          return items.map(point_ => {
            return [point_.x, point_.y]
          })
        } else if (p.getAttribute('d').length) {
          let points = p.getAttribute('d')
          let pathData = points
          points = pathDataToPolys(pathData, { tolerance: 4, decimals: 3 })
          return points
        }
        return []
      
        }
    })
  }
  removeCircles() {
    this.sectors.classList.remove('is-hidden')
    this.sectors.classList.add('is-active')
    this.sectors.querySelectorAll('polygon').forEach(i => {
      i.classList.remove('is-hidden')
    })
    this.seat.classList.add('is-hidden')
    this.seat.classList.remove('is-active')
    this.circles.forEach((c, id) => {
      c.classList.remove('is-active')
      c.classList.remove('is-selected')
      c.classList.remove('is-danger')
      c.classList.add('is-hidden')
    })
  }
  detectIntersection(index) {
    // this.sectors.classList.add('is-hidden')
    // this.sectors.classList.remove('is-active')
    // this.seat.classList.remove('is-hidden')
    let sectorData = []
    let counter = 0
    
    const { selectable } = new Select('circle').init()

    // ugly crutch
    window.selectable = selectable
    // ugly crutch
    selectable.disable()
    // let closest = [...this.circles][0].closest('g')
    this.circles.forEach((c, id) => {
      c.onmouseenter = () => {
        this.panzoom.pause()
        selectable.enable()
      }
      c.onmouseleave = () => {
        // if (!window.isDragging) {
        this.panzoom.resume()
        selectable.disable()
        // }
      }
      c.classList.remove('is-active', 'is-hidden')
      c.classList.add('is-passive')
      let data = c.dataset.center.replace(/"/g, '').split(',')
      let circle = [parseFloat(data[0]), parseFloat(data[1])]
      
      let isInside = inside(circle, this.polygons[index])

      if (isInside) {
        counter++
        c.classList.remove('is-passive')
        c.classList.add('is-active')

        if (!c.id.includes('_s')) {
          c.id = `s${index}_s${id}`
          this.polies[index].dataset.sector = `sector${index}`

          // let text = [...document.getElementsByClassName(`s${index}_s${id}`)][0]
          // if (!text) {
          //   let textElement = document.createElementNS(
          //     'http://www.w3.org/2000/svg',
          //     'text'
          //   )
          //   textElement.classList.add(`s${index}_s${id}`)
          //   closest.appendChild(textElement)
          //   textElement.setAttribute('fill', '#fff')
          //   // let { height: textHeight, width: textWidth } = textElement.getBBox()
          //   textElement.setAttribute('x', this.center(c)[0])
          //   textElement.setAttribute('y', this.center(c)[1])
          //   textElement.setAttribute('font-size', 1)
          // }
        }
        sectorData.push(c)
      }
    })
    if (sectorData.length || counter === this.circles.length) {
      return new Promise(resolve => {
        resolve(sectorData)
      }).then(_ => {
        return sectorData
      })
    }
    return Promise.reject('No data found')
  }
  async init(condition = true) {
    this.image.style.width = '100%'
    this.initPan()
    let polies = this.getPolies()
    this.removeClass()
    if (condition) {
      this.getCircles()
    }
    this.getPolygonsData()
    window.panzoom = this.panzoom
    return polies
  }
}

