<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%;')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")')
      icon(type='close')
    loading-wrapper(:loading='loading')
      card()
        card-panel(
          :offset='["left", "right", "bottom"]'
        )
          row(offset)
            cell(
              cols='12'
              v-for='item in localitems'
              :key='item.id'
            )
              BookedItem(
                :is-left-tickets='isLeftTickets'
                :item='[item]'
                :ga='true'
                :fn='fn'
                :deletable='false'
                :has-wrapper='true'
                @close='$emit("close")'
              )
          row(offset ref="flyButton")
            cell(cols='12' v-if='!loading')
              v-button(
                ref="flyButton"
                :variant='["success", "block"]'
                @click='resolve()'
                :state="[loading && 'disabled']"
                border='whole'
              )
                v-button-element(size='lg')
                  v-caption(
                    size='5'
                    :variant='["uppercase", "bold"]'
                  ) {{ _('Add ticket') }}

              //- cell(cols='12')
              //-   v-button(
              //-     :variant='["clear", "block"]'
              //-     hover='danger'
              //-     border='whole'
              //-     @click='reject'
              //-   )
              //-     v-button-element(size='lg')
              //-       v-caption(
              //-         color='warning'
              //-         size='5'
              //-         :variant='["uppercase", "bold"]'
              //-       ) {{ _('decline') }}
</template>

<script>
import { groupBy } from '@cabinet/router'
import FlyTicket from '../../mixin/flyTicket'
import BookedItem from '@cabinet/components/booking/BookedItem'
import { get } from 'http'

export default {
  components: {
    BookedItem,
  },
  mixins: [FlyTicket],
  props: {
    fn: {
      required: true,
      type: Function
    },
    items: {
      required: true,
      type: Array
    },
    isLeftTickets: {
      type: Boolean,
      required: false
    },
    booked: {
      required: false
    },
    onAdd: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    localitems: {
      get() {
        const grouped = groupBy(this.booked, ticket => ticket.sector_descriptor)
        const arr = this.items
        arr.forEach((i, idx) => {
          const sector = grouped[i.id]

          i.booked = i.booked || 0
          this.$set(arr[idx], 'booked', i.booked || 0)
          this.$set(arr[idx], 'free_seats', sector
            ? i.totals_seats - sector.length
            : i.totals_seats
          )

        })
        return arr
      },
      set(e) {
        console.log(e, 'SET===========e');
      },
      deep: true,
    },
  },
  methods: {
    resolve() {
      const items = this.localitems.reduce((acc, item) => {
        const _items = new Array(parseInt(item.count)).fill({ type: 'sector', id: item.id })
        acc.push(..._items)
        return acc
      }, [])

      if (!items.length) {
        return
      }

      this.loading = true
      console.log(items);
      return this.fn(items)
        .then(res => {
          let timeout = 1000
          //this.initFlyTicket()
          setTimeout(() => {
            this.$emit('close')
          }, timeout)
          this.onAdd()
        })
        .catch(err => this.loading = false)
    },
    reject() {
      this.$emit('close')
    },
  },
  mounted() {
    // const grouped = groupBy(this.booked, ticket => ticket.sector_descriptor)

    // this.items.forEach((i, idx) => {
    //   const sector = grouped[i.id]

    //   i.booked = i.booked || 0
    //   this.$set(this.items[idx], 'booked', i.booked || 0)
    //   this.$set(this.items[idx], 'free_seats', sector
    //     ? i.totals_seats - sector.length
    //     : i.totals_seats
    //   )

    // })
    // console.log(this.items, '------------------');
  }

}
</script>
