import HelpersService from './../services/api/helpers'
export default {
  namespaced: true,
  state: {
    types: [],
    tags: [],
    statuses: [],
    countries: [],
    states: [],
    cities: [],
    locations: [],
    fees: [],
    colors: [],
    hall_statuses: [],
    currencies: [],
    sector_statuses: [],
    show_statuses: [],
    baseTimezone: null
  },
  actions: {
    fetchVendors({commit}) {
      return HelpersService.fetch().then(res => {
        commit('SET_VENDORS', res.data.data)
      })
    },
    fetchCountries(store) {
      if (!store.state.countries.length) {
        return HelpersService.fetchCountries().then(res => {
          store.commit('SET_COUNTRIES', res.data.data)
        })
      }
    },
    fetchStates({commit}, id) {
      return HelpersService.fetchStates(id).then(res => {
        commit('SET_STATES', res.data.data)
      })
    },
    fetchCities({commit}, id) {
      return HelpersService.fetchCities(id).then(res => {
        commit('SET_CITIES', res.data.data)
      })
    },
    fetchLocations({commit}, data) {
      return HelpersService.fetchLocations(data).then(res => {
        commit('SET_LOCATIONS', res.data.data)
      })
    }
  },
  getters: {
    getStatuses(state) {
      return state.statuses.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
    getHallStatuses(state) {
      return state.hall_statuses.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
    getShowStatuses(state) {
      return state.show_statuses.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    }
  },
  mutations: {
    SET_VENDORS(state, payload) {
      state.types = payload.types
      state.colors = payload.colors
      state.tags = payload.tags
      state.statuses = payload.statuses
      state.hall_statuses = payload.hall_statuses
      state.currencies = payload.currencies
      state.sector_statuses = payload.sector_statuses
      state.fees = payload.fees
      state.show_statuses = payload.show_statuses
      state.baseTimezone = payload.base_timezone
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload
    },
    SET_STATES(state, payload) {
      state.states = payload
    },
    SET_CITIES(state, payload) {
      state.cities = payload
    },
    SET_LOCATIONS(state, payload) {
      state.locations = payload
    }

  }
}
