<template lang="pug">
  loading-wrapper(:loading='loading')
    card
      card-panel(offset='bottom')
        v-caption(size='3' variant='bold') {{ _('Tarif plans')}}
      card-panel(offset='bottom')
        v-caption(size='6') {{ _('Lorem tarif description')}}
      card-panel(offset='bottom')
        row(offset)
          cell(
            cols='12 6-sm 4-xl 3-2xl'
            v-for='tarif in activeTarifs'
            :key='tarif.id'
          )
            ProviderTarif(
              :variant='["border", "bordered"]'
              :card-variant='tariff.tariff && tariff.tariff.id === tarif.id && tariff.status !== "paid" ? "tour" : ""'
            )
              template(v-slot:title='')
                v-caption(
                  size='2' 
                  :variant='["bold", "center"]'
                ) {{ tarif.title }}

                card-label(
                  :position='["top","right"]'
                )
                  v-tag(type='success') {{ _('paid') }}
              template(v-slot:subtitle='')
                v-caption(
                  size='5' 
                  :variant='["bold", "center"]'
                ) {{ tarif.description }}

              template(v-slot:price='')
                v-caption(v-if="!tarif.commission_percent", size='4' variant='bold') $ {{ tarif.price | toUSD }} {{ _("per sold tiket") }}
                v-caption(v-else, size='4' variant='bold') $ {{ tarif.price | toUSD }} + {{ tarif.commission_percent }} % {{ _("per sold tiket") }}
              //- template(
              //-   v-slot:order='' 
              //-   v-show='tariff.tariff && tariff.tariff.id === tarif.id && tariff.status === "paid"'
              //-   v-if='tariff.tariff && tariff.tariff.id === tarif.id && tariff.status == "paid" ? false : true'
              //- )
              //-   v-button(
              //-     variant='success'
              //-     @click='showModal(tarif)'
              //-   )
              //-     cell
              //-       v-button-element(size='lg')
              //-         v-caption(
              //-           size='5' 
              //-           variant='bold'
              //-         ) {{ tariff.tariff && tariff.tariff.id === tarif.id && tariff.status !== "paid" ? _('Pay') : _('Start')}}
                    //- pay not pay

              template(v-slot:learn='')
                v-button(
                  tag='a'
                  :href='tarif.link',
                  v-if="tarif.link"
                )
                  cell()
                    v-button-element(size='lg')
                      v-caption(
                        size='5' 
                        variant='bold'
                      ) {{ _('LEARN MORE') }}

</template>

<script>

import { ref, onMounted, computed } from '@vue/composition-api'

import { useResponse } from '@cabinet/composables/use-response'
import ProvidersSettingsService from '@cabinet/services/api/providers'
import ProviderTarif from '@cabinet/components/settings/ProviderTarif'
import UiDelete from '@cabinet/components/modals/UiDelete'
export default {
  
  components: {
    ProviderTarif,
  },
  setup(props, context) {
    const tarifs = ref([])
    const loading = ref(false)
    const tariff = ref(context.root.$store.state.userModule.userData.tariff)
    if (!tariff.value) {
      tariff.value = {
        id: null
      }
    }
    

    const fetchTarifs = async () => {
      try {
        loading.value = true
        const res = await useResponse(ProvidersSettingsService.fetchTarifs(), context.root)
        tarifs.value = res.data
        loading.value = false
      } catch (error) {
        loading.value = false
        console.error(error)
      }
    }
    const activeTarifs = computed(() => tarifs.value.filter(tarif => tariff.value.tariff && tariff.value.tariff.id === tarif.id && tariff.value.status === "paid"))

    const activate = async (id) => {

      if (id) {
        loading.value = true

        try {
          const res = await useResponse(ProvidersSettingsService.activate({ tariff: id }), context.root)
          if (res.data) {
            await context.root.$store.dispatch('userModule/getData')  
            tariff.value = context.root.$store.state.userModule.userData.tariff
            loading.value = false
            // fetchTarifs()
          }
        } catch (error) {
          console.error(error)
          loading.value = false
        }
        return Promise.resolve(false)
      } 
      return Promise.reject()
      
    }

    const showModal = tariff => {
      return context.root.$modal.show(
        UiDelete,
        {
          fn: activate,
          itemID: tariff.id,
          customText: context.root._('Are you sure want to change tariff?\n You may be charged for this changes.')
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }
      )
    }
    onMounted(() => {
      fetchTarifs()    
    })

    const showButton = (tarif) => {
      return tariff.tariff && tariff.tariff.id === tarif.id && tariff.tariff.status !== 'paid' ? false : true
    }

    return {
      tariff,
      tarifs,
      loading,
      showButton,
      showModal,
      activeTarifs
    }
  }
  
}
</script>