import Api from '@cabinet/services/'
import { AxiosPromise } from 'axios'

export default {
  fetchTypes() {
    return Api().get('/payments/type/list/')
  },
  fetch() {
    return Api().get('/payments/list/')
  },
  activate(data) {
    return Api().post('/payments/activate/', data)
  },
  customize({ id, data }) {
    return Api().patch(`/payments/customize/${id}/`, data)
  },
  deactivate(id) {
    return Api().post(`/payments/disable/${id}/`)
  },
  testPay({ id, type, data }) {
    return Api().post(`/payments/${type}/${id}/`, data)
  },
  getCurrenciesList() {
    return Api().get(`/payments/currencies/list/`)
  }
}