<template lang="pug">
loading-wrapper(
  :loading='loading'
)
  card
    SecurityAccordion
      template(v-slot:caption='')
        | {{ _('m Payment method' )}}
      template(v-slot:content='')
        card-panel(offset='top')
          row(offset)
            cell(cols='12')
              slot(name='hint')
            cell(
              cols='12 6-md 12-lg 6-xl' 
              v-for='payment in types'
              :key='payment.initial_id'
            )
              ProviderPayment(
                :payment='payment'
                @fetch='fetchData(true)'
              )
</template>

<script>

import { transformArrayToObject } from '@cabinet/util'
import { ref, onMounted } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import PaymentsService from '@cabinet/services/api/payments'

import { useStoreState } from '@cabinet/composables/use-store'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import ProviderPayment from '@cabinet/components/settings/ProviderPayment'

const addImage = (items) => {
  const images = {
    authorize: '/static/images/authorize.png',
    stripe: '/static/images/stripe.png',
    paypal: '/static/images/paypal.png',
    cash: ''
    // /static/images/cash.png
  }
  items.forEach(item => {
    item.image = images[item.id]
  })
  return items
}
export default {
  components: {
    SecurityAccordion,
    ProviderPayment,
  },
  setup(props, context) {

    const loading = ref(false)
    const types = ref([])
    const payments = ref([])

    const fetchTypes = async () => {
      loading.value = true
      try {
        const res = await useResponse(PaymentsService.fetchTypes(), context.root)
        types.value = addImage(transformArrayToObject(res.data))
        loading.value = false
      } catch (error) {
        loading.value = false
      }
      loading.value = false
    }

    const fetch = async () => {
      loading.value = true
      try {
        
        const payments = useStoreState(context.root.$store.state, 'paymentsModule').payments

        if (payments) {
          types.value = types.value.map(type => {
            const payment = payments.find(p => p.variant === type.id || p.variant === type.initial_id)
            type.initial_id = type.id
            type = { ...type, ...payment}
            return type
          })

        }
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
      loading.value = false
    }

    const fetchData = async (condition) => {
      if (condition) {
        try {
          await context.root.$store.dispatch('paymentsModule/getPayments')  

        } catch (err) {
          console.error(err)
        }

      }
      await fetchTypes()
      await fetch()
    }
    onMounted(async () => {
      fetchData()
      
    })

    return {
      loading,
      fetch,
      fetchData,

      payments,
      types,
    }
  }
}
</script>