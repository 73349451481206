import { render, staticRenderFns } from "./ControlDescriptor.vue?vue&type=template&id=e4e8e012&functional=true&lang=pug&"
import script from "./ControlDescriptor.vue?vue&type=script&lang=js&"
export * from "./ControlDescriptor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports