<script>
export default {
  props: {
    url: {
      required: false,
    },
    service: {
      required: true,
    },
    propsData: {
      required: false,
    }
  },
  data() {
    return {
      data: [],
      meta: [],
      isLoading: false,
    }
  },
  methods: {
    async fetch(url) {
      this.isLoading = true
      try {
        let { data, meta } = (await this.service.fetch(url)).data
        this.data = data
        this.meta = meta
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.isLoading = false
      }
    }
  },
  async created() {
    this.fetch()
  },
  render(h) {
    const slot = this.$scopedSlots.default({
      isLoading: this.isLoading,
      data: this.data,
      meta: this.meta,
      fetch: this.fetch
    })
    if (!this.data.length) {
      return h('span', this._('No data found'))
    }
    return Array.isArray(slot) ? slot[0] : slot
  },
}
</script>