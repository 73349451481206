<template lang="pug">
v-select(
  v-model='currency'
  :val='currency'
  :options='currencies'
  :placeholder="_('Currency')"
  :searchable='false'
  :name='"country"'
  label='caption'
  @input='$emit("input", currency)'
  v-bind='$attrs'
  :allow-empty="false"
)
</template>

<script>

import PaymentsApi from '@/cabinet/services/api/payments'

export default {
  name: 'CurrenciesList',
  props: ['value'],
  data () {
    return {
      currencies: [],
      currency: '',
    }
  },
  watch: {
    value(nval, oval) {
      if (nval === oval) return
      this.currency = nval
      this.$emit('input', this.currency)
    }
  },
  async created() {
    await this.getCurrencies()
    this.currency = this.currencies[0]
    this.$emit('input', this.currency)
  },
  methods: {
    async getCurrencies () {
      const { data } = (await PaymentsApi.getCurrenciesList())
      this.currencies = data
    }
  },
}
</script>