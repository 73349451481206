<template lang='pug'>
  include /mixins
  +b.loading-wrapper(:class="{'is-loading': loading}")
    +e.overlay
    +e.LOADING.indicator(
      :size='size'
      :duration='duration'
    )
    +e.title
      slot(name='loading-title')
    slot
    

</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: 'xl'
      },
      loading: {
        default: false
      },
      duration: {
        type: String
      }
    },
  }
</script>



