<template lang="pug">
  .notice(:class='[$blockClasses]')
    .notice__icon(v-if='$scopedSlots.icon')
      slot(name='icon')
    .notice__caption(v-if='$scopedSlots.caption')
      slot(name='caption')
    .notice__description(v-if='$scopedSlots.description')
      slot(name='description')
</template>

<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'notice',
  modifierProps: ['type', 'variant']
}
export default {

  computed: {
    $blockClasses() {
      return useClassesComposition(this.$props, bem)
    }
  },
  props: {
    variant: {
      type: [String, Array],
      default: ''
    },
    type: {
      type: [String, Array],
      default: ''
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
}
</script>
