<template lang="pug">
  include /mixins
  card
    card-panel(offset='full')
      card-panel(offset='bottom')
        row(
          offset
          alignment='center'
        )
          cell(cols='12 narrow-sm')
            +b.control-box
              v-button(
                variant='danger'
                border='whole'
                @click='addAgent'
              )
                template(v-slot:prepend='')
                  cell
                    v-button-element(
                      size='lg'
                      variant="warning-lite"
                    )
                      v-caption(variant='bold')
                        icon(
                          type='add'
                          size='md'
                          variant='opaque'
                        )
                cell(cols='auto')
                  v-button-element(size='lg') 
                    v-caption(
                      :variant='["bold", "uppercase"]' 
                      size='6'
                      
                    ) {{ _('Create vendor') }}
          cell(cols='12 8-md 7-lg 5-2xl')
            ui-search(
              @input='fetchGeneral($event)'
              type='search'
            )
              template(v-slot:activator='{send, data}')  
                cell(cols='12 narrow-md')
                  v-button(
                    size='lg'
                    :border='["top_right", "bottom_right"]'
                    @click='send(data)'
                    variant='success'
                  )
                    cell(cols='auto')
                      v-button-element(size='lg') 
                        v-caption(
                          :variant='["bold", "uppercase"]' 
                          size='6'
                        ) {{ _('s search') }}
          cell(justification='right-sm')
            pagination(
              :pagination='meta' 
              @set='type === "list" ? fetchAgents({page: $event, search: search || ""}) : fetchFinancialData({page: $event, search: search || ""}) '
            )
      card-panel(offset='bottom')
        ui-tabs(ref='tabs')
          template(v-slot='{ activeItem, activate }')
            row(offset space='xs')
              cell(
                cols='narrow'
                
                v-for='(item, index) in [ { type: "list", text: _("general list") }, { type: "report", text: _("financial list") } ]'
                :key='index'
              )
                v-button(
                  :variant='["clear", "block", "shadow", "tab", item.type !== type ? "opaque": ""]'
                  border='round'
                  @click='activateTab(item.type, index, activate)'
                ) 
                  v-button-element(size='md')
                    v-caption(
                      size='4'
                      variant='bold'
                      :color="item.type === type ? 'warning' : 'default'"
                    ) {{ item.text }}
      card-panel(offset='bottom')
        loading-wrapper(:loading='isLoading')
        notice(v-if='!data.length')
          template(v-slot:icon='')
            icon(
              type='error' 
              color='warning' 
              size='5xl'
            )
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('No data found')}}
        
        component(
          :is='activeComponent'
          :items='data'
          @fetch='fetchData'
          v-else
        )
        
        
                

</template>
<script>
import usePagination from '@cabinet/composables/use-pagination'
import VendorsService from '@cabinet/services/api/vendors'
import UiSearch from '@cabinet/components/forms/UiSearch'
import Pagination from '@cabinet/components/vendor/Pagination'
import UiTabs from '@cabinet/components/vendor/UiTabs'
import VendorsTable from './VendorsTable'
import VendorsFinanceReport from './VendorsFinanceReport'
import UiCreateVendor from './UiCreateVendor'
export default {
  components: {
    UiSearch,
    Pagination,
    UiTabs,
    VendorsTable,
    VendorsFinanceReport
  },
  data() {
    return {
      isLoading: false,
      data: [],
      meta: {
        total: 0,
        limit: 0
      },
      search: '',
      type: 'list'
    }
  },
  computed: {
    activeComponent() {
      return this.type === 'list' ? 'VendorsTable' : 'VendorsFinanceReport'
    }
  },
  methods: {

    async activateTab(type, index, cb) {
      cb(index)
      this.type = type
      try {
        this.type === 'list' ? await this.fetchGeneral() : await this.fetchFinancial()
      } catch (error) {
        throw error
      }
    },
    fetchGeneral(query = {}) {
      this.data = []
      this.search = query.search
      this.fetchAgents(query)
    },
    fetchFinancial(query = {}) {
      this.data = []
      this.search = query.search
      this.fetchFinancialData(query)
    },
    addAgent(agent) {
      return this.$modal.show(
        UiCreateVendor,
        
        {
          agent
        },
        {
          height: 'auto',
          adaptive: true,
          width: 330,
          scrollable: true
        },
        {
          'before-close': () => this.type === 'list' && this.fetchData()
        }
      )
    },
    async fetchFinancialData(search) {
      this.isLoading = true
      try {
        const res = await VendorsService
          .getGeneralStatistics(search)
        this.data = res.data.data
        this.meta = usePagination(res.data.meta.pagination)
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    async fetchAgents(search) {
      this.isLoading = true
      try {
        const res = await VendorsService
          .getAll(search)
        this.data = res.data.data
        this.meta = usePagination(res.data.meta.pagination)
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
      
    },
    async fetchData() {
      this.type === 'list' ? await this.fetchGeneral() : await this.fetchFinancial()
    }
  },
  async created() {
    this.fetchData()
  },
}
</script>

