<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    autocomplete='off'
  ) 
    row(offset)
      cell(cols='8')
        v-caption(size='4' variant='bold') {{ _('Set sectors:') }}
      cell(cols='12')
        row(offset)
          cell(cols='narrow')
            v-button(
              @click='addSector'
              type='button'
              variant='clear'
            )
              cell(cols='narrow')
                v-button-element() 
                  v-caption(
                    size='4' 
                    :variant='["bold"]'
                    color='default'
                  ) {{ _('add sector') }}
              template(v-slot:append='')
                cell(justification='center')
                  v-button-element 
                    icon(
                      type='add'
                      color='success'
                    )      
      cell(cols='12')
        validation(name='non field errors')
      cell(cols='12')
        row(offset v-for='(sector, index) in hallData' :key='index')
          cell(cols='12 6-md')
            row(offset)
              cell(cols='4')
                ui-input(
                  :placeholder="_('Sector name')"
                  :val='sector.title'
                  type='search'
                  border='whole'
                  bg='relief-3'
                  size='lg'
                  name='sector name'
                  autocomplete='off'
                  v-model='sector.title'
                  validate='required'
                )
              cell(cols='4')
                ui-input(
                  :placeholder="_('Sector capacity')"
                  :val='sector.total_seats'
                  type='search' v-digitsonly=''
                  border='whole'
                  bg='relief-3'
                  size='lg'
                  name='sector seats'
                  v-model.number='sector.total_seats'
                )
              cell(cols='narrow')
                v-button(
                  variant='clear'
                  type='button'
                  @click='deleteSector_(index)'
                )
                  v-button-element(size='lg') 
                    icon(type='close' color='danger')
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    hallData: {
      default: () => {}
    }
  },
  methods: {
    ...mapActions('hallModule', [
      'getSectorsList',
      'deleteSector',
    ]),
    addSector() {
      this.hallData.push({
        title: '',
        total_seats: '',
        type: 'no_seats'
      })
    },
    deleteSector_(index) {
      let { id } = this.hallData[index]
      if (id) {
        this.deleteSector(id)
          .then(res => {
            this.hallData.splice(index, 1)
          })
          .catch(err => {
          })
      } else {
        this.hallData.splice(index, 1)
      }
    },
    
  },
}
</script>

