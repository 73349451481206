


<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'card__panel',
  modifierProps: ['variant', 'offset', 'fullheight']
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      props.props.tag,
      { ...props.data, class: ['card__panel', $blockClasses, props.data.staticClass] },
      props.children
    )
  },
  props: {
    variant: {
      type: [Array, String]
    },
    offset: {
      type: [Array, String]
    },
    fullheight: {
      type: [Array, String, Boolean]
    },
    tag: {
      default: 'div'
    },
  }
}
</script>