<template lang="pug">
  include /mixins
  card

    loading-wrapper(:loading='isLoading')
    
      card-panel
        ui-accordion(
          :collapsable='true', 
          :menu='true'
        )
          template(v-slot='{activeState, toggleActive, collapse}')
            .expander()
              template
                A.expander__title.pb-1(@click.prevent='toggleActive')

                  row(offset space='2xs')
                    cell(cols='narrow')
                      v-caption(
                        size='4'
                        variant='bold'
                      ) {{ item.id }}. {{ item.title }}
                    cell(cols='narrow')
                      icon(
                        :type='activeState ? "uparrow" : "downarrow"' 
                        size="3xs"
                        color='warning'
                      )
                transition(name='fade')
                  .expander__content(v-if='activeState')
                    card-panel(offset='vertical')
                      card
                        card-panel
                          validation-observer(
                            ref='observer1'
                            tag='form'
                            autocomplete='off'
                          )
                            +b.scrollable-wrapper--till-2xl.--full
                              +b.TABLE.table--space_lg.--variant_scroll
                                +e.THEAD.thead
                                  +e.TR.tr--relief
                                    +e.TH.cell--variant_bold.--head.--relief.--bordered_left_top.--bordered_left_bottom 
                                      v-caption(size='4' color='white') {{ _('Price') }}
                                    +e.TH.cell--variant_bold.--head.--relief 
                                      v-caption(size='4' color='white') {{ _('Gross tickets') }}
                                    +e.TH.cell--variant_bold.--head.--relief 
                                      v-caption(size='4' color='white') {{ _('Total') }}
                                    +e.TH.cell--variant_bold.--head.--relief 
                                      v-caption(size='4' color='white') {{ _('Remaining tix') }}
                                    +e.TH.cell--variant_bold.--head.--relief 
                                      v-caption(size='4' color='white') {{ _('Total') }}
                                    +e.TH.cell--variant_bold.--head.--relief 
                                      v-caption(size='4' color='white') {{ _('Sold tickets') }}
                                    +e.TH.cell--variant_bold.--head.--relief.--bordered_right_top.--bordered_right_bottom 
                                      v-caption(size='4' color='white') {{ _('Total') }}
                                +e.TBODY.tbody
                                  +e.TR.tr(
                                    v-for='(ticket,index) in item.tickets'
                                    :key='index'
                                  ) 
                                    +e.TD.cell 
                                      v-caption(size='4') $ {{ ticket.price | toUSD }}
                                    +e.TD.cell 
                                      v-caption(size='4') {{ ticket.amount }}
                                    +e.TD.cell 
                                      v-caption(size='4') $ {{ calcTotal(ticket) | toUSD }}
                                    +e.TD.cell 
                                      ui-input(
                                        v-model.number='ticket.remaining'
                                        :val='ticket.remaining'
                                        type='search' v-digitsonly=''
                                        :key='ticket.id+" "+index'
                                        :name='"tickets remaining"'
                                        :validate='"max_value:"+ticket.amount'
                                      )
                                    +e.TD.cell 
                                      v-caption(size='4') $ {{ calcTicket(ticket).remainingPrice | toUSD }}
                                    +e.TD.cell 
                                      v-caption(size='4') {{ parseInt(ticket.amount) - ticket.remaining }}
                                    +e.TD.cell 
                                      v-caption(size='4') $ {{ calcTicket(ticket).soldPrice | toUSD }}

                                +e.TFOOT.tfoot
                                  +e.TR.tr
                                    +e.TD.cell--relief 
                                    +e.TD.cell--relief(v-for='i in 6') 
                                      v-caption(size='4' color='white') {{ _('Total') }}
                                  +e.TR.tr
                                    +e.TD.cell  
                                      v-caption(size='4') $ {{ total.price | toUSD }}
                                    +e.TD.cell  
                                      v-caption(size='4') {{ total.amount }}
                                    +e.TD.cell  
                                      v-caption(size='4') $ {{ total.total_price | toUSD }}
                                    +e.TD.cell  
                                      v-caption(size='4') {{ total.remaining }}
                                    +e.TD.cell  
                                      v-caption(size='4') {{ total.remaining_total }}
                                    +e.TD.cell  
                                      v-caption(size='4') {{ total.tickets_sold }}
                                    +e.TD.cell  
                                      v-caption(size='4') $ {{ total.total_average | toUSD }}

                        card-panel(offset='top')
                          row(offset justification='end')
                            cell(cols='narrow')
                              v-button(
                                variant='success'
                                @click='saveSummary'
                              )
                                v-button-element(size='lg') 
                                  v-caption(
                                    :variant='["bold", "uppercase"]' 
                                    size='6'
                                  ) {{ _('save summary') }}
                                    
                        card-panel(offset='vertical')
                          v-caption(
                            size='4'
                            variant='bold'
                          ) {{ _('a expences') }}
                        card-panel
                          validation-observer(
                            ref='observer'
                            tag='form'
                            autocomplete='off'
                          )
                            row(offset)
                              cell(cols='12 8-xl')
                                validation(
                                  name='items title'
                                )
                                row(
                                  offset 
                                  v-for='(expences_, index) in expences'
                                  :key='index'
                                  alignment='center'
                                )
                                  cell(cols='6')
                                    ui-input(
                                      bg='relief-3'
                                      v-model='expences_.title'
                                      type='search'
                                      :val='expences_.title'
                                      size='lg'
                                      :placeholder='_("aa expences")'
                                      validate=''
                                    )
                                  cell(cols='6')
                                    row(offset alignment='center')
                                      cell(cols='8')
                                        ui-input(
                                          bg='relief-3'
                                          v-model.number='expences_.price'
                                          :val='expences_.price'
                                          v-money="money"
                                          size='lg'
                                          validate=''
                                          :placeholder='_("aa price")'
                                        ).form__control--styled.--expences
                                      cell(cols='4')
                                        row(offset alignment='center')
                                          cell(cols='6')
                                            +b.control-button--variant_clear(
                                              @click='addExpences'
                                            )
                                              icon(
                                                type='add'
                                                color='success'
                                              )
                                          cell(cols='6' v-if='expences.length > 1')
                                            +b.control-button--variant_clear(
                                              @click='removeExpences(index, expences_)'
                                            )
                                              icon(
                                                type='close'
                                                color='danger'
                                              )
                              cell(cols='12 4-xl')
                                +b.TABLE.table--space_lg
                                  +e.THEAD.thead
                                    +e.TR.tr--relief
                                      +e.TH.cell--variant_bold.--head.--relief.--bordered_left_top.--bordered_left_bottom.--bordered_right_top.--bordered_right_bottom 
                                        v-caption(size='4' color='white') {{ _('aa Total') }}
                                  +e.TBODY.tbody
                                    +e.TR.tr() 
                                      +e.TD.cell  
                                        v-caption(size='4') $ {{ expencesCost | toUSD }}
                            row(offset justification='end')
                              cell(cols='narrow')
                                v-button(
                                  variant='success'
                                  @click='saveExpences'
                                )
                                  v-button-element(size='lg') 
                                    v-caption(
                                      :variant='["bold", "uppercase"]' 
                                      size='6'
                                    ) {{ _('save data') }}



</template>


<script>
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import UiAgentExpences from '@cabinet/components/agent/UiAgentExpences'
import { VMoney } from 'v-money'
import UiButton from '@cabinet/components/forms/blocks/UiButton'
import AgentsService from '@cabinet/services/api/agents'
import { successHandler, errorHandler } from '@cabinet/util'
function splitString(priceString) {
  const price = priceString.split('$ ') || 0
  const cost = price[1] ? price[1].replace(/,/g, '') : price
  return cost
}
export default {
  directives: {
    money: VMoney,
  },
  data() {
    return {
      isLoading: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
      },
      expences: [{price: '', title: ''}]
    }
  },
  components: {
    UiInput,
    UiAgentExpences,
    UiButton
  },
  props: {
    item: {
      required: true,
    },
    agentId: {
      required: true,
    }
    
  },
  computed: {
    total() {
      return this.item.tickets.reduce(
        (acc, item) => {
          acc.price += parseFloat(item.price)
          acc.amount += parseFloat(item.amount)
          acc.total_price += parseFloat(this.calcTotal(item))
          acc.remaining += parseFloat(item.remaining) || 0
          acc.remaining_total += parseFloat(
            this.calcTicket(item).remainingPrice
          )
          acc.tickets_sold += parseFloat(this.calcTicket(item).soldTickets)
          acc.total_average += parseFloat(this.calcTicket(item).soldPrice)
          return acc
        },
        {
          price: 0,
          amount: 0,
          total_price: 0,
          remaining: 0,
          remaining_total: 0,
          tickets_sold: 0,
          total_average: 0,
        }
      )
    },
    expencesCost() {
      let expencesCost = this.expences.reduce((total, expences) => {

        const cost = splitString(expences.price)
        

        console.log(cost)
        return total += parseFloat(cost) || 0
      }, 0)
      return this.total.total_average - expencesCost
    }
  },
  
  
  methods: {
    saveSummary() {
      const items = this.item.tickets.map(i => {
        return {
          ...i,
          amount: i.remaining,
          vendor: this.agentId,
          show: this.item.id
        }
      })
      AgentsService.saveSummary({ items: items }).then(res => {
        this.isLoading = false
        successHandler(res.data, this)
      })
        .catch( err => {
          let { data } = err.response
          errorHandler(data.errors, this.$refs.observer, err.response, this)
          this.isLoading = false
        })
    },
    addExpences() {
      this.expences.push({
        price: '',
        title: ''
      })
    },
    removeExpences(index, item) {
      if (item.id) {
        return AgentsService.deleteExpences({ id: item.id }).then(res => this.expences.splice(index, 1))
      }
      return this.expences.splice(index, 1)
    },
    calcTotal(ticket) {
      return ticket.price * ticket.amount
    },
    calcTicket(ticket) {
      let soldTickets = ticket.amount - ticket.remaining
      let remainingPrice = ticket.remaining * ticket.price
      let soldPrice = soldTickets * ticket.price
      return {
        soldTickets,
        remainingPrice,
        soldPrice,
      }
    },
    getExpences() {
      AgentsService.getExpences(this.agentId, this.item.id).then(res => {
        console.log(res)
        this.expences = res.data.data.length ? res.data.data : this.expences
      })
    },
    saveExpences() {
      const items = this.expences.filter(i => splitString(i.price) != '0.00').map(e => {
        return {
          ...e,
          price: splitString(e.price),
          show: this.item.id,
          vendor: this.agentId
        }
      })
      return this.createExpences(items)
    },
    createExpences(items) {
      
      const { update, create} = items.reduce((acc, item) => {
        item.id ? acc['update'].push(item) : acc['create'].push(item)
        return acc
      }, {
        update: [],
        create: []
      })
      console.log(update, create)
      if (update.length) {
        this.isLoading = true
        AgentsService.updateExpences({ data: { items: update } }).then(res => {
          this.isLoading = false
          successHandler(res.data, this)
        })
          .catch( err => {
            let { data } = err.response
            errorHandler(data.errors, this.$refs.observer, err.response, this)
            this.isLoading = false
          })
      } 
      if (create.length) {
        this.isLoading = true
        AgentsService.createExpences({ data: { items: create } }).then(res => {
          this.isLoading = false
          successHandler(res.data, this)
        })
          .catch( err => {
            let { data } = err.response
            errorHandler(data.errors, this.$refs.observer, err.response, this)
            this.isLoading = false
          })
      }
    },
  },
  created() {
    
    this.getExpences()
  }
}
</script>
