<template lang='pug'>
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    @submit.prevent='() => {}'
    autocomplete='off'
  )
    card
      card-panel()
        row(offset)
          cell(cols='12')
            validation(name='sectors')
          cell(cols='12')
            row(offset)
              cell(cols='12 6-md 5-xl')
                row(offset)
                  cell(cols='12')
                    v-caption(size='4' variant='bold') {{ _('Choose sectors:') }}
                  cell(cols='12' v-if='isInvalid')
                    +b.control-hint.--styling_default.--variant_error {{ _('Error happend in one of your sectors, seems like you have assigned tickets in it')}}
                  cell(cols='12')
                    +b.table-wrapper(v-if='form.sectors.length')
                      +b.TABLE.table--variant_scroll.--space_sm(style='min-width:initial')
                        +b.THEAD.thead
                          +e.TR.row--relief
                            +e.TH.cell--variant_bold
                              v-caption {{ _('Show') }}
                            +e.TH.cell--variant_bold
                              v-caption {{ _('Sectors:') }}
                            +e.TH.cell--variant_bold
                              v-caption {{ _('Seats:') }}
                        transition-group(tag='tbody' name='fade')
                          +e.TR.row(key='tr')
                            +e.TD.cell
                              row(alignment='center' space='md' nowrap)

                                cell(cols='narrow')
                                  v-checkbox
                                    +b.INPUT(type='checkbox'
                                            v-model='select_all'
                                            @input='selectAll(!select_all)'
                                            :disabled='form.sectors.every( s => s.disabled)'
                                            name='all').control-checkbox__input
                                cell(cols='narrow')
                                  v-caption
                                    span {{ _('all') }}

                            +e.TD.cell
                              ui-input(:placeholder="_('search')"
                                      :val='query'
                                      type='search'
                                      name='query'
                                      bg='relief-3'
                                      v-model='query'
                                      validate='').form__control--tiny.form__control--short.form__control--search
                            +e.TD.cell
                              v-caption(variant='center') {{ totalSeats }}
                          +e.TR.row(v-for='(sector, index) in filtered'
                              :key='sector.id')
                            +e.TD.cell
                              row(alignment='center' space='md')

                                cell(cols='narrow')
                                  v-checkbox(:name='"sector_"+index')
                                    +b.INPUT(type="checkbox"
                                            :name='"sector_"+index'
                                            v-model='sector.checked'
                                            :disabled='sector.disabled'
                                            :value='sector.checked'
                                            @input='setData(sector, !sector.checked, index)').control-checkbox__input
                            +e.TD.cell
                              cell(cols='narrow')
                                v-caption
                                  span {{ sector.title }}

                                //- ui-checkbox(type='checkbox'
                                //-             v-model='sector.checked'
                                //-             :val='sector.id'
                                //-             @input='setData(sector, sector.checked, index)'
                                //-             :name='"sector_"+index'
                                //-             style='display:flex').form__control--white
                                //-   template(v-slot:text='')
                                //-     span(v-html='sector.title')
                                //- transition(name='fade')
                                //-   cell(cols='narrow')
                                //-     .form__error(v-if='errors.has("item"+index)') {{ errors.first("item" + index) }}
                            +e.TD.cell
                              v-caption(variant='center') {{ sector.total_seats }}
                    notice(v-else)
                      template(v-slot:caption='')
                        v-caption(variant='bold') {{ _('No sectors in list')}}

              cell(cols='12 6-md 7-xl')
                row(offset)
                  cell(cols='12')
                    v-caption(size='4' variant='bold') {{ formData.title }}
                  cell(cols='12')
                    .file-input__img-wrapper
                      +b.file-input__svg--fullheight(ref='svgwrapper', style='max-height: 100%' :style='{height: "400px", width: "100%"}').svg.driver




</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
  components: {
  },
  props: {
    form: {
      required: true,
    },
  },
  watch: {
    'form.sectors': {
      deep: true,
      handler(v) {
        const unchecked = v.filter(s => !s.checked).map(({id}) => id)
        const sectors = [...new Set([...this.form.price_descriptor.map(d => d.info.map(i => i.sector.id)).flat(Infinity)])]
        this.isInvalid = sectors.some(s => unchecked.some(u => u == s))
        this.$emit('set-invalid', this.isInvalid)

      }
    }
  },
  computed: {
    totalSeats() {
      if (this.form.sectors) {
        return this.form.sectors.reduce((total, { total_seats }) => {
          return (total += total_seats)
        }, 0)
      }
      return 0
    },
    filtered() {
      if (this.form.sectors.length) {
        return (
          this.form.sectors.filter(s => s.title.includes(this.query)) ||
          this.form.sectors
        )
      }
      return []
    },
  },

  data() {
    return {
      // localSectors: [],
      isInvalid: false,
      sectors: [],
      copy: [],
      query: '',
      height: '200',
      select_all: false,
      formData: {},
    }
  },
  methods: {
    setData(sector, value, index) {

      let el = document.querySelector(
        `polygon[data-title="${sector.title}"]`
      )
      if (el) {
        value ? el.classList.add('is-selected') : el.classList.remove('is-selected')
      }

    },
    selectAll(val) {
      this.select_all = val
      this.form.sectors.forEach((s, index) => {
        if (!s.disabled) {
          s.checked = val
          this.setData(s, s.checked, index)
        }
      })
    },
    filter() {
      let copy = JSON.parse(JSON.stringify(this.copy))
      this.form.sectors = this.query
        ? this.form.sectors.filter(s => s.title.includes(this.query))
        : copy
    },
    ...mapActions('hallModule', ['getSectorsList', 'fetchHall']),
    activate(index) {
      this.form.sectors[index].disabled = false
      setTimeout(() => {
        this.$refs.input[index].$el.querySelector('input').focus()
      })
    },
    async placeSvg(sector, seats) {
      let { sectors_file, seats_file } = this.formData
      if (sectors_file && seats_file) {
        let [sectorData, seatsData] = await Promise.all([
          axios.get(sectors_file),
          axios.get(seats_file),
        ])
        let sectorSvg = sectorData.data
        let seatsSvg = seatsData.data
        // seatsSvg
        return {
          sectorSvg,
          seatsSvg,
        }
      }
    },
    async save() {
      let isValid = await this.$refs.observer.validate()
      if (this.form.sectors.every(s => !s.checked)) {
        isValid = false
        this.$refs.observer.setErrors({
          'sectors': this._('Please, select at least one sector')
        })
      }
      
      if (isValid) {
        return Promise.resolve({
          valid: true,
          msg: `${this._('You shall pass!!!')}`,
        })
      }
      return Promise.reject({
        valid: false,
        msg: `${this._('You shall not pass!!!')}`,
      })
    },
  },
  async created() {
    try {
      this.$emit('loading-process', true)
      this.formData = await this.fetchHall(this.form.hall.id)

      await this.getSectorsList({ id: this.form.hall.id }).then(async res => {
        if (!this.form.sectors.length) {
          this.form.sectors = res.data.map(s => {
            this.$set(s, 'checked', s.checked)
            this.$set(s, 'disabled', s.disabled)
            return s
          })
        }
      })
      if (this.formData.sectors_file && this.formData.seats_file) {
        let { sectorSvg } = await this.placeSvg()
        this.$refs.svgwrapper.insertAdjacentHTML('beforeend', sectorSvg)
        this.height = this.$refs.svgwrapper
          .querySelector('svg')
          .getBoundingClientRect().height
  
  
        this.form.sectors.forEach((s, i) => this.setData(s, s.checked, i))
        this.select_all = this.form.sectors.every(s => s.checked)
      }
    } catch (er) {
      console.error(er)
      this.$emit('loading-process', false)

    }
    this.$emit('loading-process', false)
    this.selectAll(true)
  },
}
</script>
