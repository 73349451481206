import AgentsService from '@cabinet/services/api/agents'
import VendorsService from '@cabinet/services/api/vendors'

export default {
  namespaced: true,
  state: {
    agents: {
      pagination: {},
      agents_row: [
      ]
    },
    agent: {},
    error: '',
    agent_details: [],
    type: 'shows',
    shows: {
      pagination: {},
      row: {}
    },
    invoices: {
      pagination: {},
      row: {}
    },
    fees: [],
    summary: {
      row: {},
      meta: {}
    },
    expences: []
  },
  actions: {
    async fetchFees({ commit }) {
      let { data } = (await AgentsService.fetchFees()).data
      commit('SET_FEES', data)
    },
    async postFee({ commit }, obj) {
      return AgentsService.postFee(obj).then(res => {
        commit('ADD_FEE', res.data.data)
        return res
      })
    },
    async deleteFee({ commit, state }, id) {
      try {
        let { data } = (await AgentsService.deleteFee(id))
        let index = state.fees.findIndex(i => i.id === id)
        state.fees.splice(index, 1)
        return data
      } catch (err) {
        return err
      }
    },
    setType({commit}, data) {
      commit('SET_TYPE', data)
    },
    
    fetchAgents(state, data) {
      return VendorsService
        .getAll(data)
        .then(res => {
          let { data } = res
          state.commit('FETCH_AGENTS', data)
        })
        .catch(res => {
          state.error = 'Something went wrong'
        })
    },
    
    addWithdraw(state, payload) {
      return AgentsService.withdraw(payload)
    },
    refundVendorList(state, payload) {
      return VendorsService.refundVendorList(payload)
    },
    deleteVendorTicket(state, payload) {
      return VendorsService.deleteVendorTicket(payload)
    },
    getVendorShows(state, payload) {
      console.log(payload)
      return AgentsService.getVendorShows(payload)
        .then(res => {
          state.commit('SET_AGENTS_SHOWS', res.data)
          return res.data
        })
    },
    getAgentsInvoices(state, payload) {
      AgentsService.fetchOneInvoices(payload)
        .then(res => {
          state.commit('SET_AGENT_INVOICES', res.data)
        })
    },
    updateInvoice(state, payload) {
      return AgentsService.updateInvoice(payload)
        .then(res => {
          state.commit("UPDATE_INVOICES", res.data.data)
        })
    },
    getInfoForSummaryTable(state, payload) {
      return AgentsService.getSummaryInfo(payload).then(res => {
        state.commit("SET_AGENTS_SUMMARY", res.data)
      })
    },
    // getAgentsExpences(state, payload) {
    //   return AgentsService.getExpences(payload).then(res => {
    //     state.commit('SET_AGENTS_EXPENCES', res.data.data)
    //   })
    // }
  },
  mutations: {
    ADD_FEE(state, payload) {
      state.fees.push(payload)
    },
    SET_FEES(state, payload) {
      state.fees = payload
    },
    FETCH_AGENTS(state, payload) {
      state.agents.agents_row = payload.data
      state.agents.agents_row.forEach(el => {
        el.title = `${el.first_name} ${el.last_name}`
      })
      state.agents.pagination = payload.meta.pagination
    },
    SET_AGENT(state, payload) {
      if (Number.isInteger(parseInt(payload))) {
        let index = state.agents.agents_row
          .map(a => a.id).indexOf(parseInt(payload))
        let agent = state.agents.agents_row[index]
        state.agent = agent
      } else {
        state.agent = payload
      }
    },
    DELETE_AGENT(state, payload) {
      let index = state.agents
        .agents_row.map(a => a.id).indexOf(payload)
      state.agents.agents_row.splice(index, 1)
    },
    ADD_AGENT(state, payload) {
      state.agents.agents_row.push(payload)
    },
    SET_TYPE(state, payload) {
      state.type = payload
    },
    SET_AGENTS_SHOWS(state, payload) {
      state.shows.row = payload.data
      state.shows.pagination = payload.meta
    },
    SET_AGENT_INVOICES(state, payload) {
      state.invoices.row = payload.data
      state.invoices.pagination = payload.meta.pagination
    },
    UPDATE_INVOICES(state, payload) {
      let index = state.invoices.row
        .map(a => a.id).indexOf(payload.id)
      state.invoices.row.splice(index, 1, payload)

    },
    SET_AGENTS_EXPENCES(state, payload) {
      state.expences = payload
    },
    SET_AGENTS_SUMMARY(state, payload) {
      state.summary.row = payload.data
      state.summary.pagination = payload.meta.pagination
    },
    REMOVE_AGENT(state, payload) {
      state.agents.agents_row.splice(payload, 1)
    }

  },
}
