<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card(:variant='["offset", "section"]')
      card-panel(:offset='["full", "top_md", "bottom_md"]')
        row(offset)
          cell(cols='10')
            v-button(
              tag='a'
              :href='show.absolute_url'
              variant='clear'
              target='_blank'
            )
              cell(cols='12')
                v-caption(
                  size='4'
                  color='default'
                  variant='bold'
                ) {{ show.title }}
        card-label(
          :position='["top", "right"]'
          :offset='["top", "right"]'
          index='2'
          v-if='!agent'
        )
          v-button(
            :disable='loading'
            variant='clear'
            @click='toggleActive'
          )

            icon(
              type='menu_vertic'
              :style='{"transform": menuActive ? "" : "rotate(90deg)"}'
              style='transform-origin: 100% 25%'
              color='brand-4'
              size='xl'
            )
        card-label(
          :position='["top", "right"]'
          v-if='menuActive'
          index='1'
        )

          ui-show-menu(
            style='width: 225px'
            :show='show'
            :descriptors='descriptors'
            @fetch='mapSectors'
            :sectors='sectors'
          )



      card-panel(variant='footer')
        row(offset space='none')
          +b.cols--5
            v-tag(:type='show.status.type ? show.status.type : show.status === 1 ?"success" : "brand-6"')
              row(
                offset
                alignment='center'
                space='xs'
              )
                cell(cols='narrow')
                  icon(:type='show.status.icon ? show.status.icon : show.status === 1 ?"published" : "draft"' size='sm' color='brand-2')
                cell(cols='narrow')
                  v-caption(
                    size='6'
                    variant='secondary'
                    color='white'
                  ) {{ show.status.title ? show.status.title : show.status === 1 ?"Published" : "Draft"}}
          +b.cols--7
            v-tag
              row(
                offset
                alignment='center'
                space='xs'
              )
                cell(cols='narrow')
                  icon(type='calendar' size='sm' color='warning')
                cell(cols='narrow')
                  v-caption(
                    size='6'
                    color='brand-4'
                    variant='secondary'
                  ) {{ show.date_at_display }} {{ show.time_at_display }}
      card-panel(:offset='["full", "top_md", "bottom_xl"]')
        row(offset)
          cell(cols='12')
            row(offset alignment='center' style='height: 65px;overflow: hidden')
              cell
                v-caption(size='6' color='brand-4' variant='secondary')
                  icon(type='pin' color='brand-1' size='sm' style='display: inline-block')
                  | &nbsp; {{ show.hall.title }}
          cell(cols='12')
            row(
              offset
              alignment='center'
              space='4xs'
            )
              cell(cols='narrow')
                icon(type='percent' color='brand-1' size='sm')
              cell(cols='narrow')
                v-caption(size='6' color='brand-4' variant='secondary') {{ _('t Discounted tickets:')}}
              cell(cols='narrow' justification='right')
                v-caption(size='6' color='brand-4' variant='secondary') {{ show.discount }}
      card-panel(variant='footer' v-if='!agent')
        row(offset space='none' alignment='center')
          +b.cols--5
            v-button(
              :disable='loading'
              :variant='["clear"]'
              hover='success'
              alignment='center'
              @click='getTickets'
            )
              template(v-slot:prepend='')
                cell(cols='narrow')
                  v-button-element
                    icon(
                      type='pencil'
                      size='xs'
                      color='brand-4'
                    )
              cell(cols='narrow')
                v-button-element
                  v-caption(
                    color='default'
                    size='6'
                    display="lg"
                    :variant='["uppercase", "bold"]'
                  ) {{ _('t Tickets') }}
          +b.cols--5
            v-button(
              :disable='loading'
              :variant='["clear"]'
              hover='default'
              alignment='center'
              border='horizontal'
              @click='triggerDiscountList'
            )
              template(v-slot:prepend='')
                cell(cols='narrow')
                  v-button-element
                    icon(
                      type='percent'
                      size='xs'
                      color='brand-4'
                    )

              cell(cols='narrow')
                v-button-element
                  v-caption(
                    color='default'
                    size='6'
                    display="lg"
                    :variant='["uppercase", "bold"]'
                  ) {{ _('t Discounts') }}
          +b.cols--2
            v-button(
              :disable='loading'
              :variant='["clear", "block"]'
              hover='success'
              @click='download(show.statistic_id)'
            )
              v-button-element
                icon(
                  type='download'
                  color='success'
                  size='xs'
                )

      card-panel(variant='footer' v-if='!agent')
        row(offset space='none' alignment='center')
          +b.cols--5
            v-button(
              :disable='loading'
              :variant='["clear"]'
              hover='success'
              tag='router-link'
              alignment='center'
              :to='{name: "edit-show", params: {id: show.event, showId: show.id}}'
            )
              template(v-slot:prepend='')
                cell(cols='narrow')
                  v-button-element
                    icon(
                      type='cog'
                      size='xs'
                      color='brand-4'
                    )
              cell(cols='narrow')
                v-button-element
                  v-caption(
                    color='default'
                    size='6'
                    display="lg"
                    :variant='["uppercase", "bold"]'
                  ) {{ _('t edit') }}
          +b.cols--5
            v-button(
              :disabled='loading || show.status.id === 0 || show.status === 0'
              :variant='["clear", "copy"]'
              border='horizontal'
              alignment='center'
              v-copypaste='{initial: _("copy"), clicked: _("copied")}'
            )
              template(v-slot:prepend='')
                cell(cols='narrow')
                  v-button-element
                    icon(
                      type='copy'
                      size='xs'
                      color='brand-4'
                    )

              cell(cols='narrow')
                v-button-element
                  v-caption(
                    color='default'
                    size='6'
                    display="lg"
                    :variant='["uppercase", "bold"]'
                  ) {{ _('t copy link') }}
                  input.form__input(
                    readonly
                    :value='link'
                    style='position: absolute; top: -1000vh'
                  )
          +b.cols--2
            v-button(
              :disable='loading'
              :variant='["clear", "block"]'
              hover='danger'
              @click='modalTrigger'
            )
              v-button-element
                icon(
                  type='close'
                  color='danger'
                  size='xs'
                )
      card-panel(variant='footer' v-if='agent')
        row(
          offset
          space='none'
          alignment='center'
          justification='between'
        )
          +b.cols--5
            v-button(
              :disabled='loading || show.status.id === 0 || show.status === 0'
              :variant='["clear", "copy"]'

              alignment='center'
              v-copypaste='{initial: _("copy"), clicked: _("copied")}'
            )
              template(v-slot:prepend='')
                cell(cols='narrow')
                  v-button-element
                    icon(
                      type='copy'
                      size='xs'
                      color='brand-4'
                    )

              cell(cols='narrow')
                v-button-element
                  v-caption(
                    color='default'
                    size='6'
                    display="lg"
                    :variant='["uppercase", "bold"]'
                  ) {{ _('t copy link') }}
                  input.form__input(
                    readonly
                    :value='link'
                    style='position: absolute; top: -1000vh'
                  )
          //- +b.cols--5
            v-button(
              :disabled='loading || !show.status.id'
              :variant='["clear", "copy"]'
              alignment='center'
              border='horizontal'
              @click='assignFee'
            )
              template(v-slot:prepend='')
                cell(cols='narrow')
                  v-button-element
                    icon(
                      type='percent'
                      size='xs'
                      color='brand-4'
                    )

              cell(cols='narrow')
                v-button-element
                  v-caption(
                    color='default'
                    size='6'
                    display="lg"
                    :variant='["uppercase", "bold"]'
                  ) {{ _('t assign fee') }}

          +b.cols--2
            v-button(
              :disable='loading'
              :variant='["clear", "block"]'
              border='horizontal'
              hover='success'
              @click='download(show.statistic_id)'
            )
              v-button-element
                icon(
                  type='download'
                  color='success'
                  size='xs'
                )

</template>

<script>
import { cases } from '@cabinet/util'
import { mapActions, mapGetters, mapState } from 'vuex'
import { eventStatuses } from '@cabinet/components/utils/types'
import ShowsService from '@cabinet/services/api/shows'
import StatsService from '@cabinet/services/api/stats'

import UiInfo from '@cabinet/components/modals/UiInfo'
import UiShowMenu from './UiShowMenu'
import UiPrint from '@cabinet/components/modals/UiPrint'
import UiShowFees from '@cabinet/components/show/modals/agents/UiShowFees'
import UiShowDiscounts from '@cabinet/components/show/modals/discounts/UiShowDiscounts'
import UiShowWithdraw from '@cabinet/components/show/modals/withdraw/UiShowWithdraw'
import UiDelete from '@cabinet/components/modals/UiDelete'

export default {
  components: {
    UiShowMenu,
  },
  data() {
    return {
      loading: false,
      descriptors: [],
      active: false,
      menuActive: false
    }
  },

  computed: {
    link() {
      return `${this.show.absolute_url}`
    },
    dateAt() {
      let date = new Date(this.show.date_at)
      let hours = date.getHours()
      date.setHours(hours + this.show.tz)
      return date
    },
    ...mapState('userModule', ['prefix']),
    ...mapGetters('eventsModule', ['eventStatuses']),
    disabledStyles() {
      return this.show.editable ? '' : `pointer-events: none; opacity: .25`
    },
    archivedStyles() {
      return this.type === 'archived'
        ? `pointer-events: none; opacity: .25`
        : ''
    },
    unixDate() {
      // let [date] = this.show.date_at.split('T')
      let timeToUnix =
        new Date(this.show.date_at).getTime() - new Date().getTime()
      return {
        timeToUnix,
      }
    },
    humanCase() {
      let item = this.statuses.find(i => i.id == this.show.status)

      return item.title
    },
    color() {
      return cases(this.show.label, 'color')
    },
  },
  props: {
    agent: {
      type: Boolean,
      default: false
    },
    show: {
      required: true,
    },
  },
  methods: {
    ...mapActions('showsModule', ['deleteShow', 'fetchShow']),
    ...mapActions('hallModule', ['getAvailableSectors', 'getSectorData', 'getSectorsList']),
    download(id) {

      let method = 'getShowStatisticsInPdf'

      if (this.agent) {
        method = 'getVendorShowStatisticsInPdf'
      }
      const tz = new Date().getTimezoneOffset() / 60
      StatsService[method](id, tz).then(res => {
        let url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf; base64'}))
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `show-statistics.pdf`)
        document.body.appendChild(link)
        link.click()
        this.loading = false
      })
    },
    async toggleActive() {
      this.menuActive = !this.menuActive

    },
    async mapSectors() {
      this.loading = true
      try {

        let descriptors = (await ShowsService.getAvailableSectors({
          id: this.show.id,
        })).data.data
        this.loading = true
        this.descriptors = descriptors
        this.descriptors.sort((a, b) => a.id - b.id)
        this.loading = false
        const res = await this.getSectorsList({ id: this.show.hall.id })
        this.sectors = res.data

      } catch (err) {
        console.error(err)
        this.loading = false
      }

      try {

        // this.show.sectors.sort((a, b) => a.id - b.id)
      } catch (err) {
        this.loading = false
      }


      // this.sectors.forEach((s, index) => {
      //   s.checked = this.show.sectors[index].checked
      // })

      // this.sectors = this.sectors.filter(s => s.checked)
      // this.sectors.forEach(s => {
      //   s.row.sort((a, b) => a.id - b.id)
      //   s.row.forEach(r => r.seat.sort((a, b) => a.title - b.title))
      // })
    },
    async printTickets() {
      let data = await this.fetchShow(this.show.id)
      this.$modal.show(
        UiPrint,
        
        {
          itemId: this.show.id,
          info: data,

        },
        {
          height: 'auto',
          adaptive: true,
          classes: ['middle-modal-wrap'],
        }
      )
    },
    async getTickets() {
      this.$modal.show(
        UiShowWithdraw,
        {
          id: this.show.id,
          descriptors: this.descriptors,
          sectors: this.sectors

        },
        {
          height: 'auto',
          adaptive: true,
          classes: ['middle-modal-wrap', 'modal--variant_vendors'],
          scrollable: true,
          maxWidth: 1000,
          width: '100%'
        }
      )
    },
    async triggerDiscountList() {
      this.$modal.show(
        UiShowDiscounts,
        {
          show: this.show,
          descriptors: this.descriptors,
          sectors: this.sectors
        },
        {
          height: 'auto',
          adaptive: true,
          classes: ['middle-modal-wrap'],
          scrollable: true,
          maxWidth: 1000,
          width: '100%'
        }
      )
    },
    async deleteShow_(id) {
      // return await useResponse(this.deleteShow(id), this, null)
      if (id) {
        try {
          this.modalDeleteing()
          this.$emit('loading', {state: true, resp: false})
          await this.deleteShow(id)
          this.$emit('loading', {state: false, resp: true})
          this.$modal.hide('ui-info')
          this.$emit('close')
        } catch (err) {
          console.error(err)
          this.$modal.hide('ui-info')
          this.$emit('loading', {state: false, resp: false})
        }
      } else {
        return Promise.reject()
      }
    },
    modalDeleteing() {
      this.$modal.show(
        UiInfo,
        {
          text: this._('Process deleting, please wait'),
        },
        {
          minHeight: 125,
          maxHeight: 125,
          adaptive: true,
          width: 310,
          name: "ui-info"
        }
      )
    },
    modalTrigger() {
      return this.$modal.show(
        UiDelete,
        {
          fn: this.deleteShow_,
          itemID: this.show.id,
          type: this._('show'),
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }
      )
    },
    assignFee() {
      console.log('assign fee')

      this.$modal.show(
        UiShowFees,
        
        {
          id: this.show.id
        },
        {
          height: 'auto',
          adaptive: true,
          classes: ['middle-modal-wrap'],
          scrollable: true,
          maxWidth: 600,
          width: '100%'
        }
      )
      return null
    }
  },
  async mounted() {
    await this.mapSectors()

    this.show.status = {
      ...this.eventStatuses.find(s => s.id === this.show.status.id || s.id === this.show.status)
    }

    let status = eventStatuses[this.show.status.id] || eventStatuses[this.show.status]

    this.show.status.type = status.type
    this.show.status.icon = status.icon
  }
}
</script>
