


<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'row',
  modifierProps: [
    'justification',
    'alignment',
    'space',
    'offset',
    'nowrap',
    'inline',
    'column',
    'overflow',
    'fullheight',
    'scroll'
  ],
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      'div',
      { ...props.data, class: ['row', $blockClasses, props.data.staticClass] },
      props.children
    )
  },
  props: {
    fullheight: {
      type: [String, Array, Boolean],
      default: Boolean,
    },
    states: {
      type: Array,
      default: () => [],
    },
    justification: {
      type: [String, Array],
      default: '',
    },
    alignment: {
      type: [String, Array],
      default: '',
    },
    space: {
      type: [String, Array],
      default: '',
    },
    offset: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: [Boolean, String, Array],
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    column: {
      type: [Boolean, String, Array],
      default: () => [],
    },
    overflow: {
      type: [String, Array],
      default: () => []
    },
    scroll: {
      type: [Boolean, String, Array],
      default: () => []
    }
  },
}
</script>
