<template lang="pug">
  include /mixins
  +b-context('table')
    +e.TBODY.tbody
      +e.TR.row()
        +e.TD.cell(@click='toggleExpand'  style='cursor:pointer')
          row(
            offset
            alignment='center' 
            nowrap 
            space='5xs' 
            justification='between'
          )
            cell(cols='narrow')
              v-caption(
                size='6'
                color='default'
              ) {{ event.title }}
            cell(cols='narrow')
              icon(
                :type='isExpanded ? "uparrow" : "downarrow"' 
                size='3xs'
                color='brand-4'
              )
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
            variant='center'
          ) {{ event.balance ? event.balance.length : '-'}}
        +e.TD.cell--bordered_right()
          v-tag(:type='event.balance.some(i => i.active) ? "success" : "accent"') {{ event.balance.some(i => i.active) ? _('s Active') : _("s Archive")}}
          
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) {{ event.balance.reduce((acc, i) => acc+= parseFloat(i.sold_amount), 0) }}
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) {{ event.balance.reduce((acc, i) => acc+= parseFloat(i.full_price), 0) | toUSD }}
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) $ {{ event.balance.reduce((acc, i) => acc+= parseFloat(i.commission), 0) | toUSD }}
        
        +e.TD.cell--bordered_right_top.--bordered_right_bottom()
          v-caption(
            size='6'
            color='default'
          ) 


      +e.TR.row(v-for='show in event.balance' :key='show.id' v-show='isExpanded')
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) {{ event.title }}
        +e.TD.cell() 
          v-caption(
            size='6'
            color='default'
            variant='center'
          ) {{ show.show_title }}
        +e.TD.cell--bordered_right()
          v-tag(:type='show.active ? "success" : "accent"') {{ show.active ? _('s Active') : _("s Archive")}}
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) {{ show.sold_amount }}
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) {{ show.price | toUSD }}
        +e.TD.cell()
          v-caption(
            size='6'
            color='default'
          ) $ {{ show.commission | toUSD }}
        
        +e.TD.cell--bordered_right_top.--bordered_right_bottom()
          v-button(@click='openCharge(show.id)')
            v-button-element(size='lg') 
              v-caption(
                variant="['bold', 'upppercase']"
              ) {{ _('s to pay') }}
            
    


</template>

<script>
import ProvidersService from '@cabinet/services/api/providers'
import ChargeModal from '@cabinet/components/providers/ChargeModal'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    event: {
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
    async charge(price) {
      await useResponse(ProvidersService.charge({ show, price }), this)
      
    },
    async openCharge(show) {
      this.$modal.show(
        ChargeModal,
        {
          fn: this.charge
        }
      )
    },
  }
}
</script>