<template lang="pug">
  include /mixins
  card()
    card-panel(offset='full')
      +b.FORM.form
        row(offset)
          cell(cols='12')
            +b.UI-TABS.tabs.mt-1(ref='tab' :active='active')
              template(v-slot='{ activeItem, activate }')
                +b.tab-list
                  +e.body
                    +e.toggle--variant_default.tab-list__item(
                      @click='activate(activateLocaly(index))'
                      v-for='(item, index) in tabs'
                    )
                      v-caption(
                        :style='item.disabled ? "pointer-events:none;opacity:.5;" : ""'
                        :variant="activeItem === index ? 'bold' : 'secondary' "
                      ) {{ item.title }}
        card(variant='section' stacked='top')
          card-panel(offset='full' v-show='active === 0')
            row(offset :space='["lg-md", "xl-xl", "2xl-2xl"]')
              cell(cols='12 6-lg')
                card-panel(offset='bottom')
                  SecurityLocalization(@legal='blockProviderSettings')
                    template(v-slot:hint='')
                      v-caption(
                        size='6'
                        variant='bold'
                      ) {{ _("You have to enter all the localization setting to unlock to tariff payment tab") }}
                  v-caption(
                    size='4'
                    variant='bold'
                    color='danger'
                  ) {{ _("Для окончания регистрации и продолжения работы сервисов выберите методы приема платежей")}}
                card-panel(offset='bottom')
                  SecurityBilling(@legal='setLegal')
                    template(v-slot:hint='')
                      v-caption(
                        size='6'
                        variant='bold'
                      ) {{ _("You have to enter billing address and card credentials to proceed to next step. Test pay is also required")}}
              cell(cols='12 6-lg')
                ProviderPayments(ref='payments')
                  template(v-slot:hint='')
                    v-caption(
                      size='6'
                      variant='bold'
                    ) {{ _("Select at least one payment type to open all the service features. Dont forget to activate you tariff in 'provider tariff' tab ")}}
                Security

          card-panel(offset='full' v-show='active === 1 && legal')
            row(offset)
              cell(cols='12')
                ProviderTarifs
          card-panel(offset='full' v-show='active === 1 && tabs[1].legal')
            row(offset :space='["lg-md", "xl-xl", "2xl-2xl"]')
              cell(cols='12 6-xl')
                row(offset)
                  cell(cols='12')
                    ProviderFees
                  cell(cols='12')

              cell(cols='12 6-xl')
                row(offset)
                  cell(cols='12')
                    ProviderQuoteHall
                  cell(cols='12')
                    ProviderRules
                  cell(cols='12')
                    ProviderAds


</template>

<script>

import { ref/* , onMounted */ } from '@vue/composition-api'

import UiTabs from '@cabinet/components/vendor/UiTabs'
import Security from '@cabinet/components/settings/Security'
import SecurityLocalization from '@cabinet/components/settings/SecurityLocalization'
import SecurityBilling from '@cabinet/components/settings/SecurityBilling'
import ProviderFees from '@cabinet/components/settings/ProviderFees'
import ProviderPayments from '@cabinet/components/settings/ProviderPayments'
import ProviderAds from '@cabinet/components/settings/ProviderAds'
import ProviderTarifs from '@cabinet/components/settings/ProviderTarifs'
import ProviderQuoteHall from '@cabinet/components/settings/ProviderQuoteHall'
import ProviderRules from '@cabinet/components/settings/ProviderRules'
import SettingsAccessGranted from '@cabinet/components/modals/SettingsAccessGranted'

export default {
  components: {
    UiTabs,
    Security,
    SecurityLocalization,
    SecurityBilling,
    ProviderFees,
    ProviderPayments,
    ProviderAds,
    ProviderTarifs,
    ProviderQuoteHall,
    ProviderRules,
  },

  setup(props, context) {
    const active = ref(0)

    const tabs = ref([
      {
        title: context.root._('Profile settings'),
        id: 1,
        legal: true
      },
      // {
      //   title: context.root._('Provider tarifs'),
      //   id: 2,
      //   legal: false
      // },
      {
        title: context.root._('Provider settings'),
        id: 3,
        legal: false
      }
    ])
    const legal = ref(true)
    const openModal = () => {
      context.root.$modal.show(
        SettingsAccessGranted,
        
        {
          text: context.root._('You have unlocked all provider settings')
        },
        {
          adaptive: true,
          height: 'auto',
          maxWidth: 330,
        }
      )
    }
    const setLegal = (value) => {
      legal.value = value
    }

    const blockProviderSettings = async (value) => {
      tabs.value[1].legal = value
      // tabs.value[2].legal = value

      if (tabs.value.every(tab => tab.legal)) {
        await context.refs.payments.fetchData(true)
        if (!localStorage.getItem('impulse.shown')) {
          openModal()
          localStorage.setItem('impulse.shown', true)
        }
      }
    }

    const activateLocaly = (index) => {
      if (tabs.value[index].legal) {
        active.value = index
        return index
      }
      return active.value
    }
    return {
      tabs,
      active,
      legal,

      setLegal,
      activateLocaly,
      blockProviderSettings
    }
  }
}
</script>