<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; ')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    card
      card-panel(offset)
        notice
          template(v-slot:icon='')
            icon(
              :type='icon' 
              :color='iconColor' 
              size='5xl'
            )
          template(v-slot:caption='')
            v-caption(variant='bold') {{ text }}
    
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'check'
    },
    text: {
      type: String,
      
    },
    iconColor: {
      type: String,
      default: 'success'
    }
  }
}
</script>
