import OrdersService from '@cabinet/services/api/orders'

export default {
  namespaced: true,
  state: {
    orders: {
      pagination: {},
      orders_row: []
    },
    error: '',
    order: {
      shipment: [],
      payment: []
    },
    providerOrder: {},
    statuses: []
  },
  getters: {
    getStatuses(state) {
      return state.statuses.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
    getShipment(state) {
      return state.order.shipment.map(s => {
        return {
          title: s[1],
          id: s[0],
          canActivate: s[2],
          price: s[3]

        }
      })
    },
    getPayment(state) {
      return state.order.payment.map(s => {
        return {
          title: s[1],
          id: s[0]
        }
      })
    },
  },
  actions: {
    refund(store, payload) {
      return OrdersService.refund(payload)
    },
    async fetchProviderList({ commit }, payload) {
      let { data, meta: { statuses, pagination } } = (await OrdersService.fetchProviderList(payload)).data
      commit('SET_ORDERS', {
        data, pagination
      })

      commit('SET_PROVIDE_META', statuses)
      return data
    },
    async fetchProviderOrder({ commit }, payload) {
      let { data, meta: { statuses } } = (await OrdersService.fetchProviderOrder(payload)).data
      commit('SET_PROVIDE_META', statuses)
      commit('SET_PROVIDE_ORDER', data)
    },
    createOrder({ commit }, payload) {
      return OrdersService.create(payload)
        .then(res => {
          commit('SET_ORDER_ID', res.data.data)
        })
    },
    updateOrderInfo({ commit }, payload) {
      return OrdersService.updateCurrent(payload)
    }
  },
  mutations: {
    SET_ORDERS(state, payload) {
      state.orders.pagination = payload.pagination
      state.orders.orders_row = payload.data
    },
    SET_ORDER_ID(state, payload) {
      state.order = payload
    },
    SET_PROVIDE_ORDER(state, payload) {
      state.providerOrder = payload
    },
    SET_PROVIDE_META(state, payload) {
      state.statuses = payload
    }

  },
}
