<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card(variant='section')
      card-panel(offset='full')
        card
          card-label(:position='["left", "top"]')
            v-caption(
              size='5' 
              variant='bold'
            ) {{ item.id }}
          card-panel(offset='top_lg')
            row(
              offset
              justification='between'
              alignment='center'
            )
              cell(cols='12 6-2xl')
                row(
                  alignment='center'
                  justification='between'
                )
                  cell(cols='6 4-2xl')
                    row(alignment='center')
                      cell(cols='narrow')
                        icon(
                          type='acrobat' 
                          :color='colors[item.status.id]'
                          size='3xl'
                        )
                      cell(cols='narrow')
                        v-caption(size='4') {{ item.created_at | humanDate('en') }}
                  cell(cols='6 4-2xl')
                    +b.A(
                      :href='item.pdf'
                      target='_blank'
                    ).link
                      v-caption(
                        size='5' 
                        :color='colors[item.status.id]'
                      ) {{ item.name }}
                  cell(cols='12 4-2xl')
                    v-caption(
                      size='4'
                      variant='bold'
                      :color='colors[item.status.id]'
                    ) {{ item.show }}
              cell(cols='12 6-2xl')
                row(offset)
                  cell(cols='6 4-sm 3-md 4-xl')
                    v-select(
                      v-model.number='item.status'
                      :val='item.status'
                      :options='statuses'
                      size='lg'
                      bg='relief-3'
                      :placeholder="_('Status')"
                      :name='"status"'
                      :style='{"border": "1px solid " + colorsHex[item.status.id]}'
                    )
                  cell(cols='6 4-sm 3-md 4-xl')
                    ui-input(
                      v-model='item.comment'
                      :val='item.comment'
                      type='search'
                      autofocus=''
                      size='lg'
                      bg='relief-3'
                      name='comment'
                      :placeholder="_('Comment')"
                    )
                  cell(cols='6 4-sm 3-md 4-xl' justification='right')
                    v-button(
                      variant='clear'
                      @click='changeInvoice(item)'
                      hover='success'
                    )
                      v-button-element(size='lg')
                        v-caption(
                          size='5' 
                          variant='bold'
                          color='success'
                        ) {{ _('i SAVE') }}
</template>
<script>
import { mapActions } from 'vuex'
export default {
  
  props: {
    item: {
      required: false
    },
    id: {
      required: true
    }
  },
  watch: {
    item(val) {
      if (!val.status.id) {
        val.status = this.statuses.find(s => s.id == val.status)
      }

    }
  },
  data() {
    return {
      loading: false,
      statuses: [
        {
          title: 'not paid',
          id: 'not_paid',
        },
        {
          title: 'paid',
          id: 'paid',
        }
      ],
      colors: {
        "not_paid": 'warning',
        'paid': 'success'
      },
      colorsHex: {
        "not_paid": '#ed1c24',
        'paid': '#59bf48'
      },
      form: {
        status: '',
        comment: ''
      }
    }
  },
  methods: {
    ...mapActions('agentsModule', [
      'updateInvoice',
      
    ]),
    changeInvoice(inv) {
      let data = JSON.parse(JSON.stringify(inv))
      data.status = data.status.id
      delete data.pdf
      this.changeData(data)
    },
    async changeData(event) {
      this.loading = true
      try {
        await this.updateInvoice(event)
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
  },
  mounted() {
    let status
    if (!this.item.status.id) {
      status = this.statuses.find(s => s.id === this.item.status)
      this.item.status = status
    } 
  },
}
</script>
  