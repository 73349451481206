<template lang="pug">
  include /mixins
  card-panel
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      @submit.prevent='() => null'
      autocomplete='off'
    )
      row(
        offset
        alignment='center'
      )
        slot(name='prepend')
        cell(cols='12 auto-md 8-2xl')
          ui-search(@input='search = $event' cols='12 auto-md')
            //- ui-input(
            //-   type='search' 
            //-   v-model='search' 
            //-   :value='search'
            //-   size='lg'
            //-   border='whole'
            //-   :placeholder='_("s search")'
            //- )
            template(v-slot:activator='{data,send}')
              cell(cols='12 6-sm narrow-md')
                +b.control-box
                  v-button(
                    variant='success-lite'
                    size='lg'
                    border='whole'
                    @click='$emit("send", search)'
                  )
                    template(v-slot:prepend='')
                      cell
                        v-button-element(
                          size='lg'
                          variant="success"
                        )
                          v-caption(variant='bold')
                            icon(type='search'
                                size='md')
                    cell(cols='auto')
                      v-button-element(size='lg') 
                        v-caption(
                          :variant='["bold", "uppercase"]' 
                          size='6'
                        ) {{ _('s search') }}
        slot(name='append')
        
          
          
    
</template>

<script>
import { ref } from '@vue/composition-api'
import UiSearch from '@cabinet/components/forms/UiSearch'
export default {
  components: {
    UiSearch
  },
  
  props: {
    meta: {
      required: true
    }
  },
  setup(props, context) {
    const search = ref('')
    
    return {
      search
    }
  }
}
</script>