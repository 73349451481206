<script>
export default {
  data() {
    return {
      // get the state out of active prop passed in
      activeState: this.active
    }
  },
  props: {
    active: {
      default: false,
      type: Boolean,
      required: false
    },
    showAccordion: {
      default: false,
      type: Boolean,
      required: false,
    },
    breakpoint: {
      default: 992,
      type: [Number, String],
      required: false
    },
    reactive: {
      default: false,
      type: Boolean,
      required: false
    },
    id: { },
  },
  methods: {
    toggleActive(optional) {
      this.activeState = !this.activeState
      this.$nextTick(() => {
        this.scrolly(150)
      })
    },
    collapse() {
      this.activeState = false
    },
    getOffset(element) {
      let rect = element.getBoundingClientRect()
      let offset = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        bottom: rect.bottom + window.scrollY
      }
      return offset
    },
    async scrolly(time) {
      setTimeout(async () => {
        Element.prototype.documentOffsetTop = function () {
          return this.offsetTop + ( this.offsetParent ? this.offsetParent.documentOffsetTop() : 0 )
        }
        const offset = 54
        var top = document.getElementById(this.id).documentOffsetTop() - offset
        // console.log(top)
        window.scrollTo({ top: top, behavior: 'smooth' })
      }, time)
    }
  },
  created() {
    // collapse accordion if breakpoint is greater than  window.innerWidth 
    if (window.innerWidth < this.breakpoint && !this.showAccordion && !this.active) {
      this.activeState = false
    } 
    // collapse accordion if breakpoint is greater than  window.innerWidth 

    // watch active prop change if reactive prop passed in
    this.reactive && this.$watch(
      this.active, 
      (nval, oval) => this.activeState = nval
    )
    // watch active prop change if reactive prop passed in
  },
  render(h) {
    return this.$scopedSlots.default({
      activeState: this.activeState,
      toggleActive: this.toggleActive,
      collapse: this.collapse
    })
  }
}
</script>