<template lang="pug">
  loading-wrapper(:loading='loading')
    SecurityAccordion(:active="false")
      template(v-slot:caption='')
        slot(name='caption')
          | {{ _("ps Billing information:") }}
      template(v-slot:content='')
        card-panel(offset='vertical')

          validation-observer(
            ref='observer'
            tag='form'
            v-slot='{invalid}'
            @submit.prevent='postBilling'
            autocomplete='off'
          )
            row(offset)
              cell(cols='12')
                slot(name='hint')
              cell(cols='12 6-sm 4-md 6-lg 4-xl')
                ui-input(
                  :placeholder="_('First name*')"
                  :val='billingInfo.first_name'
                  type='search'
                  :name='!type ? "billing address first name" : "profile " + type.replace("_", " ") + " first name" '
                  bg='relief-3'
                  size='lg'
                  border='whole'
                  v-model='billingInfo.first_name'
                  validate='required|alpha_spaces'
                )
              cell(cols='12 6-sm 4-md 6-lg 4-xl')
                ui-input(
                  :placeholder="_('Last name*')"
                  :val='billingInfo.last_name'
                  type='search'
                  :name='!type ? "billing address last name" : "profile " + type.replace("_", " ") + " last name" '
                  bg='relief-3'
                  size='lg'
                  border='whole'
                  v-model='billingInfo.last_name'
                  validate='required|alpha_spaces'
                )
              cell(cols='12 6-sm 4-md 6-lg 4-xl')
                ui-input(
                  v-model='billingInfo.phone'
                  :val='billingInfo.phone'
                  
                  :placeholder="_('Phone *')"
                  :name='!type ? "billing address phone" : "profile " + type.replace("_", " ") + " phone" '
                  
                  v-mask='["(###) ###-####"]'
                  validate='required'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )
              cell(cols='12 6-sm 12-md' v-if='isVendor')
                ui-input(
                  v-model='company'
                  :val='company'
                  :placeholder="_('Company')"
                  :name='"profile " + type.replace("_", " ") + " company" '
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )
              
              cell(cols='12 8-sm 10-md 8-lg 10-2xl')
                ui-input(
                  :placeholder="_('Address *')"
                  :val='billingInfo.address_line_1'
                  type='search'
                  :name='!type ? "billing address address line 1" : "profile " + type.replace("_", " ") + " address line 1" '
                  v-model='billingInfo.address_line_1'
                  validate='required'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )
              cell(cols='12 4-sm 2-md 4-lg 2-2xl')
                ui-input(
                  :placeholder="_('Unit #')"
                  :val='billingInfo.unit'
                  type='search'
                  :name='!type ? "billing address unit" : "profile " + type.replace("_", " ") + " unit" '
                  v-model='billingInfo.unit'
                  validate=''
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )

              cell(cols='12 6-sm 3-md 6-lg 3-2xl')
                ui-input(
                  v-model='billingInfo.city'
                  :val='billingInfo.city'
                  
                  :placeholder="_('City *')"
                  :name='!type ? "billing address city" : "profile " + type.replace("_", " ") + " city" '
                  type='search'
                  validate='required'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )
              cell(cols='12 6-sm 3-md 6-lg 3-2xl')
                ui-input(
                  v-model='billingInfo.subdivision'
                  :val='billingInfo.subdivision'
                  :placeholder="_('State/Province *')"
                  
                  :name='!type ? "billing address subdivision" : "profile " + type.replace("_", " ") + " subdivision" '
                  type='search'
                  validate='required'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )
              cell(cols='12 6-sm 3-md 6-lg 3-2xl')
                ui-input(
                  :placeholder="_('Postal Code *')"
                  :val='billingInfo.postal_code'
                  :name='!type ? "billing address postalcode" : "profile  " + type.replace("_", " ") + " postalcode" '
                  type='search'
                  v-model='billingInfo.postal_code'
                  validate='required'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )
              cell(cols='12 6-sm 3-md 6-lg 3-2xl')
                ui-input(
                  v-model='billingInfo.country'
                  :val='billingInfo.country'
                  :placeholder="_('Country *')"
                  
                  :name='!type ? "billing address country" : "profile " + type.replace("_", " ") + " country" '
                  type='search'
                  validate='required'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                )

              cell(
                cols='narrow' 
                v-if='type' 
                justification='right'
              )
                v-button(
                  type='submit'
                  variant='success'
                  border='whole'
                )
                  cell(justification='center').cols
                    v-button-element(size='lg') 
                      v-caption(
                        size='6' 
                        :variant='["bold", "uppercase"]'
                      ) {{ _('save') }}

              cell(cols='12 6-md 12-lg 6-xl' v-if='!type')
                ui-credit-card-view(
                  :email='billingInfo.email',
                  :number='billingInfo.card_number',
                  :date='billingInfo.date',
                  :name='billingInfo.first_name'
                  :surname='billingInfo.last_name'
                  :code='billingInfo.cvv'
                )

              cell(cols='12 6-md 12-lg 6-xl' v-if='!type')
                row(offset)
                  cell(cols='12')
                    ui-input(:placeholder="_('Card number*')"
                            :val='billingInfo.card_number'
                            type='search'
                            inputmode="numeric"
                            name='authorize number'
                            size='lg'
                            bg='relief-3'
                            v-mask="'#### #### #### ####'"
                            v-model='billingInfo.card_number'
                            validate='required|credit')
                  cell(cols='12 6-md')
                    ui-input(:placeholder="_('month/year*')"
                            :val='billingInfo.date'
                            type='search'
                            inputmode="numeric"
                            name='authorize date'
                            v-mask="'##/##'"
                            size='lg'
                            bg='relief-3'
                            v-model='billingInfo.date'
                            validate='required|length:5|date')

                  cell(cols='12 6-md')
                    ui-input(:placeholder="_('CVV2 code*')"
                            :val='billingInfo.cvv'
                            type='search' v-digitsonly=''
                            inputmode="numeric"
                            name='cvv'
                            size='lg'
                            bg='relief-3'
                            v-model='billingInfo.cvv'
                            validate='required|max:4')
                  cell(cols='6 12-md')
                    v-button(
                      type='submit'
                      variant='success'
                      border='whole'
                    )
                      cell(justification='center').cols
                        v-button-element(size='lg') 
                          v-caption(
                            size='6' 
                            :variant='["bold", "uppercase"]' 
                          ) {{ _('SAVE') }}

                  cell(cols='6 12-md' v-if='!billingInfo.is_checked')
                    v-button(
                      type='button'
                      border='whole'
                      @click='showVerificationModal'
                    )
                      cell(justification='center').cols
                        v-button-element(size='lg') 
                          v-caption(
                            size='6' 
                            :variant='["bold", "uppercase"]'
                          ) {{ _('test pay') }}
              slot(name='checkbox')

</template>

<script>
import { 
  ref,
  onMounted
} from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import ProviderSettingsService from '@cabinet/services/api/provider-settings'
import UserService from '@cabinet/services/api/user'
import { useStoreState } from '@cabinet/composables/use-store'
import isEqual from 'lodash/isEqual'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import UiCreditCardView from '@cabinet/components/forms/UiCreditCardView'
import SecurityTestPay from '@cabinet/components/settings/SecurityTestPay'

export default {
  props: {
    type: {
      required: false,
      type: String
    }
  },
  
  components: {
    SecurityAccordion,
    UiCreditCardView,
  },
  setup(props, context) {

    const isVendor = context.root.$store.state.userModule.userData.is_vendor


    const loading = ref(false)
    const billingInfo = ref({})
    const company = ref('')

    const fetchBilling = async () => {
      loading.value = true
      try {
        const res = await useResponse(ProviderSettingsService.fetchBilling(), context.root, context.refs.observer)
        if (res.data) {
          context.emit('legal', res.data.is_checked)
        }
        billingInfo.value = res.data
      } catch (error) {
        loading.value = false
      }
      loading.value = false
    }

    const makeTestPayment = async () => {
      loading.value = true
      try {
        await useResponse(ProviderSettingsService.makeTestPayment(), context.root, context.refs.observer)
        loading.value = false
        fetchBilling()
      } catch (error) {
        console.error(error)
        loading.value = false
      }
      loading.value = false
    }

    const showVerificationModal = () => {
      context.parent.$modal.show(SecurityTestPay,
        
        {
          action: makeTestPayment
        },
        {
          adaptive: true,
          height: 'auto',
          maxWidth: 330,
        }
      )
    }

    const postProviderBilling = async () => {
      loading.value = true
      try {
        await useResponse(ProviderSettingsService.postBilling(billingInfo.value), context.root, context.refs.observer)
        loading.value = false
        fetchBilling()
      } catch (error) {
        loading.value = false
      }
      loading.value = false
    }

    const postUserBilling = async () => {
      loading.value = true
      try {
        await useResponse(UserService.updateData({
          profile: {
            first_name: billingInfo.value.first_name,
            last_name: billingInfo.value.last_name,
            [props.type]: billingInfo.value,
            company: company.value
          }
        }), context.root, context.refs.observer)
        loading.value = false
      } catch (err) {

        loading.value = false
      }
    }

    const postBilling = async () => {

      try {
        const isValid = await context.refs.observer.validate()
        if (isValid) {
          if (!props.type) {
            await postProviderBilling()
          } else {
            await postUserBilling()
          }
          context.root.$store.dispatch('userModule/getData')
        }
      } catch (err) {
      }

    }

    const getAddress = (userModule, field) => {
      return useStoreState(context.root.$store.state, userModule).userData.profile[field]
    }

    onMounted(() => {
      if (!props.type) {
        fetchBilling()
      } else {
        const billing = {...getAddress('userModule', 'billing_address')}
        

        const delivery = getAddress('userModule', 'delivery_address')

        if (delivery && billing) {
          delete delivery.id
          delete billing.id
          if (!isEqual(billing, delivery)) {
            context.emit('input')
          }
        }
        if (props.type === 'delivery_address') {
          if (delivery) {
            billingInfo.value = delivery
          }
        } else if (props.type === 'billing_address') {
          if (billing) {
            billingInfo.value = billing
          }
        }
      }
    })

    return {
      billingInfo,
      loading,
      isVendor,
      showVerificationModal,
      postBilling,
      company
    }
  }  
}
</script>