import PaymentsService from '@cabinet/services/api/payments'
export default {
  namespaced: true,
  state: {
    payments: [],
    fetched: false,
  },
  getters: {
    hasPayment(state) {


      const active = state.payments.filter(p => p.is_active && p.variant != 'cash')
      const validActive = active.filter(p => p.checked)
      return validActive.length >= 1
    }
  },
  actions: {
    async getPayments({commit}) {
      try {
        let res = await PaymentsService.fetch()
        await commit('SET_PAYMENTS', res.data.data)

      } catch (err) {
        await commit('SET_PAYMENTS', [])
      }

    }
  },
  mutations: {
    SET_PAYMENTS(state, payload) {
      state.payments = payload
      state.fetched = true
    }
  }
}
