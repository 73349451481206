import Api from '@cabinet/services/'
import { AxiosPromise } from 'axios'

export default {
  fetch() {
    return Api().get('/postie/settings/receive/')
  },
  create(data) {
    return Api().post('/postie/settings/create/', data)
  },
  update(data) {
    return Api().patch('/postie/settings/update/', data)
  },
  activate() {
    return Api().post('/postie/settings/activation/')
  }
}