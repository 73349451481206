import { ID } from '@cabinet/util'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  props: {
    visibility: {
      default: false
    },
    users: {
      default: false,
    },
    data: {},
    service: {
      required: false
    }
  },
  components: {
    flatPickr,
  },
  computed: {
    ...mapGetters('eventsModule', [
      'eventStatuses',
      'eventTypes'
    ]),
    ...mapState('eventsModule', [
      'type',
      'finish_at__lt',
    ]),
    ...mapState('vendorsModule', [
      'types',
      'countries',
      'states',
      'cities',
    ]),
  },
  watch: {
    date(values) {
      if (values) {
        let val = values.split(' to ')
        if (val.length > 1) {
          let start_at__date__gte = val[0].replace(/\//g, '-').split('-')
          let finish_at__date__lte = val[1].replace(/\//g, '-').split('-')

          start_at__date__gte = [
            start_at__date__gte[2],
            start_at__date__gte[0],
            start_at__date__gte[1]
          ].join('-')
          finish_at__date__lte = [
            finish_at__date__lte[2],
            finish_at__date__lte[0],
            finish_at__date__lte[1]
          ].join('-')

          this.form.start_at__date__gte = start_at__date__gte
          this.form.finish_at__date__lte = finish_at__date__lte
        } else {
          let start_at__date__gte = values.replace(/\//g, '-').split('-')
          start_at__date__gte = [
            start_at__date__gte[2],
            start_at__date__gte[0],
            start_at__date__gte[1]
          ].join('-')
          this.form.start_at__date__gte = start_at__date__gte
          this.form.finish_at__date__lte = start_at__date__gte

        }
      }
    },
    form: {
      deep: true,
      handler(val) {
        this.send()
        let items = Object.keys(val).filter(item => val[item]).map(key => {
          if (val[key] && val[key].title) {
            return {
              title: val[key].title,
              id: ID(),
              type: key
            }
          } else if (val[key]) {
            return {
              title: val[key],
              id: ID(),
              type: key
            }
          }
        })

        if (items.length) {
          this.someSelected = true
        } else {
          this.someSelected = false
        }
        this.addTags(items)
      }
    }
  },
  data() {
    return {
      someSelected: false,
      config: {
        mode: 'range',
        disableMobile: 'true',
        dateFormat: 'm/d/Y',
      },
      tagsModel: [],
      tags: [],
      date: '',
      form: {
        country: '',
        subdivision: '',
        city: '',
        status: '',
        type: '',
        start_at__date__gte: '',
        finish_at__date__lte: '',
      },
    }
  },
  methods: {
    addTags(items) {
      this.tags = items
      this.tagsModel = items
    },
    removeTag(item) {
      this.form[item.type] = ''
      if (item.type === "finish_at__date__lte") {
        let index = this.tags.findIndex(t => t.type === "start_at__date__gte")
        this.form.date = ''
        this.date = ''
        this.tags.splice(index, 1)
        this.tagsModel.splice(index, 1)
      } else if (item.type === "start_at__date__gte") {
        this.form.date = ''
        this.date = ''
        let index = this.tags.findIndex(t => t.type === "finish_at__date__lte")
        this.tags.splice(index, 1)
        this.tagsModel.splice(index, 1)
      }


    },
    send(search) {
      console.log(search)
      if (typeof search == 'object') {
        if (this.type == 'archived') {
          search.finish_at__lt = this.finish_at__lt
        } else {
          search.finish_at__lt = ''
        }
        this.$emit('input', search)
        return
      }
      if (this.type == 'archived') {
        this.form.finish_at__lt = this.finish_at__lt
      } else {
        this.form.finish_at__lt = ''
      }

      this.$emit('input', this.transform(this.form))
      return

    },
    removeUnnecessary(object) {
      if (this.users) {
        delete object.status
        delete object.type
        delete object.start_at__date__gte
        delete object.finish_at__date__lte
        delete object.finish_at__lt
        
      }
      delete object.finish_at__gte
      return object
    },
    transform(object) {
      let copy = { ...object }
      Object.keys(copy).forEach(k => {
        if (copy[k]) {
          copy[k] = copy[k].id || copy[k]
        }
      })
      return copy
    },
    ...mapActions('eventsModule', ['getEventStatuses']),
    ...mapActions('vendorsModule', [
      'fetchCountries',
      'fetchStates',
      'fetchCities',
    ]),
    resetFilter() {
      Object.keys(this.form).forEach(e => (this.form[e] = ''))
      this.date = ''
      this.send(this.form)
    },
    async setVendors(obj) {
      try {
        obj.country && await this.fetchStates(obj.country)

      } catch (error) {
        console.log(error)
      }
      try {
        obj.subdivision && await this.fetchCities(obj.subdivision)

      } catch (error) {
        console.log(error)
      }

      if (obj.country) {
        obj.country = this.countries.find(s => s.id == obj.country)
      }
      if (obj.subdivision) {
        obj.subdivision = this.states.find(s => s.id == obj.subdivision)
      }
      if (obj.city) {
        obj.city = this.cities.find(s => s.id == obj.city)
      }
      
      return obj
    },
  },
  async mounted() {

    if (this.service) {
      try {
        const res = await this.service()
        await this.$store.commit('eventsModule/SET_VENDORS', res.data)
        this.$emit('statuses', {
          eventStatuses: this.eventStatuses,
          eventTypes: this.eventTypes
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        await this.getEventStatuses()
        this.$emit('statuses', {
          eventStatuses: this.eventStatuses,
          eventTypes: this.eventTypes
        })
      } catch (error) {
        console.log(error)
      }
    }

    try {
      await this.fetchCountries()
    } catch (error) {
      console.error(error)
    }

    this.form = await this.setVendors(this.removeUnnecessary({ ...this.form, ...this.data }))
  },
}
