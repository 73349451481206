<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    autocomplete='off'
  )
    .rows-description
      row(offset)
        transition(name='fade')
          validation(name='same_name')

        transition(name='fade')
          cell(cols='12' v-if='item.seat.length')
            ui-input(
              v-model='item.title'
              :placeholder="_('Row name')"
              :val='item.title'
              type='search'
              bg='relief-3'
              name='row name'
              autocomplete='off'
              validate='required'
            )
        transition(name='fade')
          cell(cols='12' v-if='seat.length')
            v-caption(
              size='6'
              @click='setData(item)'
            )  {{ seat.length }} {{_('seating chart(s) selected')}}

      row(offset justification='between' alignment='center')
        cell()
          p(v-if='item.seat.length')  {{ assigned.length }} {{_('seating chart(s) assigned')}}
        cell(alignment='center' cols='narrow')
          v-button(
            @click='addInfo(item)'
            :variant='["clear"]'
            type='button'
          )
            icon(
              type='add'
              color='success'
            )
      validation(name='count')
      row(offset)
        cell(cols='12')
          card(key='2')
            card(
              v-for='(group, index) in item.info'
              :key='"group--"+index'
            )
              row(offset justification='between' alignment='center')
                //- --margin_sm
                cell(cols='auto')
                  ui-input(
                    :name='"group_count--"+index'
                    :placeholder="_('Count:')"
                    v-model.number='group.count'
                    @input='sliceGroup(group, index, item)'
                    type='search' v-digitsonly=''
                    bg='relief-3'
                  )
                cell(cols='auto')
                  ui-input(
                    :name='"group_start--"+index'
                    :placeholder="_('Start:')"
                    v-model.number='group.start'
                    :readonly="item.info.length > 1 && !group.count"
                    @input='changeGroup(group, $event, group.end)'
                    type='search' v-digitsonly=''
                    bg='relief-3'
                  )
                cell(cols='narrow' alignment='center')

                  v-button(
                    @click='removeInfo(item, index)'
                    :variant='["clear"]'
                    type='button'
                  )
                    icon(
                      type='close'
                      color='danger'
                    )
              row(offset justification='between')
                cell(cols='12')
                  v-select(v-model='group.numbers'
                            :full="true"
                            :val='group.numbers'
                            :options='numbers'
                            :placeholder="_('Numbers:')"
                            :name='"numbers__"+index'
                            bg='relief-3'
                            @input='changeNumbers(group, $event)').form__control--tiny.multiselect--tiny
                cell(cols='12' alignment='center')

                  ui-toggler(type='checkbox'
                            v-model='group.order'
                            :val='group.order'
                            @input='reverseGroup(group)'
                            :name='"group__"+index')
                    template(v-slot:append='')
                      v-tag(:type='!group.order ? "success" : "warning"') {{ !group.order ? _('Ascending') : _('Descending')}}


      row(justification='between' offset)
        cell(cols='6')
          v-button(
            :variant='["clear", "block"]'
            hover='danger'
            type='button'
            @click='cancel'
          )
            v-button-element
              v-caption(
                color='warning'
                :variant='["uppercase", "bold"]'
              ) {{ _('cancel') }}
        transition(name='fade')
          cell(cols='6' v-if='item.info.length')
            v-button(
              :variant='["clear", "block"]'
              hover='success'
              type='button'
              @click='addRow(item)'
            )
              v-button-element
                v-caption(
                  color='success'
                  :variant='["uppercase", "bold"]'
                ) {{ index !== null ? _('edit') : _('add') }} {{ _('row')}}
</template>

<script>
import { closestG } from '@cabinet/util/selectable'
import { EventBus } from '@cabinet/components/eventbus/eventbus'

function validateGroupIntesection(initialArr, incomeArr) {
  return initialArr.some(seat => incomeArr.find(s => s.title === seat.title))
}

export default {
  props: {
    row: Array,
    seat: Array,
    propsItem: {
      type: Object
    },
    index: null
  },
  watch: {
    seat: {
      async handler(nval) {
        if (!nval.id) {
          await this.resetItem()
        }

        this.item.seat = nval
      },
      deep: true,
      immediate: true
    },
    propsItem: {
      async handler(nval) {
        this.item = nval && nval.seat && nval.seat.length ? nval : this.item
      },
      deep: true,
      immediate: true
    },
    item: {
      handler(val) {
        if (val.info.length && val.info.some(i => !i.start)) {
          EventBus.$emit('blockEvent', true)
        } else {
          EventBus.$emit('blockEvent', false)
        }
      },
      deep: true
    }

  },
  computed: {
    assigned() {
      return this.item.info.reduce((acc, group) => acc.concat(group.items || []), [])
    }
  },
  data() {
    return {
      item: {
        seat: [],
        title: '',
        info: []
      },
      numbers: [
        {
          title: this._('Regular(1,2...)'),
          id: 'regular',
        }, {
          title: this._('Even(2,4...)'),
          id: 'even',
        }, {
          title: this._('Odd(1,3...)'),
          id: 'odd',
        }
      ]
    }
  },
  methods: {
    resetItem() {
      this.item = {
        seat: [],
        title: '',
        info: []
      }
    },
    numberHandlers(type, group) {
      let handlers = {
        regular: (group) => {
          group.items.forEach((seat, index) => {
            this.setTitle(seat, index + (+group.start))
          })
        },
        even: (group) => {

          group.items.forEach((seat, index) => {
            this.setTitle(seat, (index + group.start) * 2 - group.start)
          })
        },
        odd: (group) => {

          group.items.forEach((seat, index) => {
            this.setTitle(seat, (index + group.start) * 2 - (group.start))
          })
        }

      }
      return handlers[type](group)
    },
    setData(item) {
      if (item.info[0]) {
        item.info[0].count = item.seat.length
        this.sliceGroup(item.info[0], 0, item)
      }
    },
    validateGroup(group, index) {
      let negativeIncluded = group.items.some(i => i.title != Math.abs(i.title) || i.title === 0)
      let isOdd = group.items.some(i => i.title % 2 === 1)
      let isEven = group.items.some(i => i.title % 2 === 0)

      if (group.numbers.id === 'even') {
        if (isOdd) {
          this.setErrors(`group_start--${index}`, this._('Numbers are not even'))
        }
      } else if (group.numbers.id === 'odd') {
        if (isEven) {
          this.setErrors(`group_start--${index}`, this._('Numbers are not odd'))
        }
      }
      if (negativeIncluded) {
        this.setErrors(`group_start--${index}`, this._('Has negative values'))
      }
    },
    setErrors(item, errors) {
      this.$refs.observer.setErrors({
        [item]: [errors]
      })
    },
    addRow(item) {
      this.$refs.observer.reset()
      let items = this.row.filter(s => s.title === item.title)
      if (items.length > 1) {
        this.setErrors('row name', this._('Already has row with such name'))
        return
      }

      let itemsCount = item.info.reduce((acc, g) => {
        acc.realCount += g.length
        acc.count += g.count
        return acc
      }, {realCount: 0, count: 0})
      if (item.seat.length < itemsCount.count) {
        this.$refs.observer.setErrors({
          [`count`]: [this._('Wrong number')]
        })
        return
      }
      let data = { ...item}

      data.seat = data.info.reduce((acc, group, index) => {
        if (group.items) {

          group.items = group.items.map((i, _index) => {
            let _item = {}
            let id = i.geom_id.id ? i.geom_id.id : i.geom_id

            if (item.seat[_index] && id) {
              _item = item.seat.find(seat => seat.geom_id === id)
            }
            return {
              ..._item,
              ...i,
              title: i.title,
              geom_id: id,
            }
          })
        }

        this.validateGroup(group, index)


        if (validateGroupIntesection(acc, group.items)) {
          this.$refs.observer.setErrors({
            [`group_start--${index}`]: [this._('Has intersected seats')]
          })
        }
        acc.push(...group.items)
        return acc
      }, [])

      const errors = this.$refs.observer.errors

      const hasErrors = Object.keys(errors).filter(k => errors[k].length).length
      if (!hasErrors) {
        this.$refs.observer.validate().then(isValid => {
          if (isValid) {
            this.$emit('add', data)
            EventBus.$emit('removeSelected')
          }
        })
      }

    },
    cancel() {
      EventBus.$emit('blockEvent', false)
      EventBus.$emit('removeSelected')

      if (!this.item.id) {
        this.item.seat.forEach(({geom_id}) => {
          let text = document.querySelector(`[data-title='${geom_id.id}']`)
          if (text) {
            text.remove()
          }
        })

        // this.item.seatthis.item.seat.filter
        this.item = {
          seat: [],
          title: '',
          info: []
        }
      }

      this.$emit('cancel')
    },
    center(item) {
      let center
      if (typeof item == 'string') {
        item = document.getElementById(item)
        center = item.dataset.center.split(',')
      } else {
        center = item.dataset.center.split(',')
      }
      return [parseFloat(center[0]), parseFloat(center[1])]
    },
    changeGroup(group) {
      this.changeNumbers(group, group.numbers)
    },
    reverseGroup(group) {

      if (group.items.length) {
        group.items.reverse()
        this.changeGroup(group)
      }
    },
    changeNumbers(group, value) {
      if (group.items && group.items.length) {
        this.numberHandlers(value.id, group)
      }
    },
    setTitle(seat, number) {
      let id = seat.geom_id.id ? seat.geom_id.id : seat.geom_id
      let text = document.querySelector(`[data-title="${id}"]`)
      let tspan
      if (text) {
        tspan = [...text.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'tspan')][0]
        tspan.setAttribute('font-size', 1)
      }

      if (!text) {
        text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
        text.dataset.title = id
        tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
        tspan.setAttribute('font-size', 1)
        const closest = closestG(id)
        text.appendChild(tspan)
        closest.appendChild(text)
        text.setAttribute('fill', '#fff')
        text.setAttribute('line-height', '1')
      }

      text.setAttribute('text-anchor', 'middle')
      seat.title = number
      tspan.textContent = number
      let { height } = text.getBBox()
      text.setAttribute('x', this.center(id)[0])
      text.setAttribute('y', this.center(id)[1] + height / 2 * .75)
    },
    addInfo(item) {
      item.info.push({
        start: null,
        numbers: this.numbers[0],
        order: false,
        count: ''
      })
    },
    removeInfo(group, index) {
      let res = {...group.info[index]}
      if (res.items && res.items.length) {
        res.items.forEach(item => {
          let node
          let id
          if (typeof item.geom_id == 'object') {
            node = document.getElementById(item.geom_id.id)
            id = item.geom_id.id
          } else {
            node = document.getElementById(item.geom_id)
            id = item.geom_id
          }
          node.classList.remove('is-selected', 'ui-selected')
          if (node) {
            let text = document.querySelector(`[data-title='${id}']`)
            if (text) {
              text.remove()
            }
          }
        })
      }
      group.info.splice(index, 1)
    },
    sliceGroup(group, index, item) {
      if (group.count < 0) {
        if (!group.items.length) {
          return
        }
        group.count = group.items.length
      }
      this.$refs.observer.reset()

      let seat = [...this.seat]
      this.seat.map((s, index) => {
        if (item.seat[index] && item.seat[index].geom_id === s.geom_id.id) {
          s = item.seat[index]
        }
        return s
      })
      this.seat.forEach(s => {
        let title = document.querySelector(`[data-title="${s.geom_id.id}"`)
        title && true ? title.remove() : null
      })
      item.info = item.info.map((group, index) => {
        // group.items = []

        // count -= group.count
        if (group.count) {
          group.items = seat.splice(0, group.count)
        }
        // if (!group.start) {
        //   group.start = 1
        // }
        if (group.items && group.items.length) {
          this.numberHandlers(group.numbers.id || 'regular', group)
        }

        return group
      })
    }
  },
  mounted() {
    EventBus.$on('noremove', () => {
      this.item.id = 1
    })
  },
  beforeDestroy() {


    if (!this.item.id) {
      this.item.seat.forEach(({geom_id}) => {
        let text = document.querySelector(`[data-title='${geom_id.id}']`)
        if (text) {
          text.remove()
        }
      })
    }
    // this.item.id = null
  }
}
</script>

