import flatten from 'ramda/src/flatten'

const normalizerRegex = /\s/img

function normalizePassedTypes(elements) {
  return flatten(flatten(elements).map(x => x.split(normalizerRegex)))
}

export function cols(...types) {
  // console.log(types)
  return normalizePassedTypes(types).reduce((acc, type) => {
    acc[`cols--${type}`] = true
    return acc
  }, {})
  // return colsBlock()
}
