<template lang='pug'>
  include /mixins  
  +b.progress(:class='[$blockClasses]')
    +e.label(v-if='$scopedSlots.default')
      slot 
    +e.container
      +e.bar(
        v-for='(bar, index) in bars'
        :key='index'
        :style='{width: bar+"%"}'
        :data-amount='amount'
      )
    

</template>

<script>
  import BemComponentMixin from '@cabinet/components/utils/bem/BemComponentMixin.js'
  export default {
    mixins: [BemComponentMixin],
    bem: {
      block: 'progress',
      modifierProps: ['variant', 'type']
    },
    props: {
      amount: {
        required: false
      },
      variant: {
        type: [String, Array]
      },
      bars: {
        type: Array
      }
    },
 
  }
</script>