<script>

import { cols as colsGenerator } from './cols'
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'cell',
  modifierProps: [
    'justification',
    'alignment',
    'padding',
    'space',
  ]
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      props.props.tag,
      { ...props.data, style: props.data.staticStyle, class: ['cell cols', $blockClasses, colsGenerator(props.props.cols)] },
      props.children
    )
  },
  props: {
    justification: {
      type: [String, Array],
      default: ''
    },
    alignment: {
      type: [String, Array],
      default: ''
    },
    padding: {
      type: [String, Array],
      default: ''
    },
    cols: {
      type: [String, Array],
      default: () => []
    },
    space: {
      type: [String, Array],
      default: () => []
    },
    tag: {
      type: String,
      default: 'div'
    }
  }
}
</script>
