<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close(@click='$emit("close")') 
      icon(type='close')
    card(offset='full')
      card-panel
        validation-observer(
          ref='observer'
          v-slot='{invalid}'
          tag='form'
          @submit.prevent='savePromo'
          autocomplete='off'
        )
          row(offset)
            cell(cols='12')
              v-caption(
                size='3'
                type='bold'
              ) {{ _('Add new promo (form)') }}

            cell(cols='12')
              ui-input(
                :placeholder="_('Promo title')"
                bg='relief-3'
                type='search'
                name='title'
                v-model='formData.title'
                size='lg'
                border='whole'
                validate='required|alpha_spaces'
              )
            cell(cols='12')
              v-select(
                :val='formData.variation'
                bg='relief-3'
                :placeholder="_('Variantion*')"
                :options='variations'
                :name='"variation"'
                validate='required'
                size='lg'
                border='whole'
                v-model='formData.variation'
              )

            
            cell(cols='12' v-if='formData.variation && formData.variation.id === "glob"')
              ValidationProvider.control-input(:rules='"required"' v-slot='{errors}')
                flat-pickr(
                  v-model='formData.start_at'
                  :config='config'
                  name='start at'
                  validate='required'
                  :placeholder="_('start at*')"
                ).flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                transition(name='fade')
                  .form__error(v-if='errors[0]') {{ errors[0] }}
            cell(cols='12' v-if='formData.variation && formData.variation.id === "glob"')
              ValidationProvider.control-input(:rules='"required"' v-slot='{errors}')
                flat-pickr(
                  v-model='formData.end_at'
                  :config='config'
                  name='end at'
                  validate='required'
                  :placeholder="_('end at*')"
                ).flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3.flatpickr-input--border_whole
                transition(name='fade')
                  .form__error(v-if='errors[0]') {{ errors[0] }}
              
            cell(cols='12')
              v-select(
                :val='formData.shows'
                bg='relief-3'
                :placeholder="_('pp shows*')"
                :options='shows'
                :name='"shows"'
                validate='required'
                size='lg'
                multiple
                :searchable='true'
                border='whole'
                @open='getList(null)'
                v-model='formData.shows'
                @search-change='$event => getList(null, $event)'
              )
                template(#afterList='')
                  div(
                    v-observe-visibility="visible => getList(visible)" v-if="shows_pagination.total > (shows_pagination.limit + shows_pagination.offset)"
                  )

            cell(cols='12')
              ui-input(
                :placeholder="_('Promo code amount')"
                bg='relief-3'
                type='search' v-digitsonly=''
                name='amount'
                v-model='formData.amount'
                size='lg'
                border='whole'
                validate='required'
              )

            cell(cols='12')
              ui-input(
                :placeholder="_('Promo code price %')"
                bg='relief-3'
                type='search' v-digitsonly=''
                name='price'
                v-model='formData.price'
                size='lg'
                border='whole'
                validate='required'
              )

            cell(cols='12')
              row(alignment='center' space='xs')
                cell(cols='narrow')
                  v-checkbox
                    +b.INPUT(
                      type='checkbox'
                      v-model='formData.is_fee'
                      :value='formData.is_fee'
                      name='is fee'
                    ).control-checkbox__input
                cell(cols='narrow') {{ _('is fee') }}

            cell(cols='12')
              +b.control-box
                v-button(
                  type='submit'
                  variant='success'
                  border='whole'
                )
                  cell(justification='center').cols
                    v-button-element(size='lg') 
                      v-caption(size='4' variant='bold') {{ _('create') }}

</template>

<script>
import ShowsService from '@cabinet/services/api/shows'
import PromosService from '@cabinet/services/api/promos'
import { useResponse } from '@cabinet/composables/use-response'
import flatPickr from 'vue-flatpickr-component'

import { mapState } from 'vuex'

import { formatToUTC } from '@cabinet/util'
export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      config: {
        // disableMobile: 'true',
        dateFormat: 'm/d/Y',
        altFormat: true,
        // minDate: 'today',
        // allowInput: true,
        // altInput: true
      },
      shows: [],
      variations: [],
      formData: {
        "is_fee": false,
        "title": "",
        "variation": null,
        "start_at": null,
        "end_at": null,
        "shows": [],
        "amount": null,
        "price": null
      },
      shows_pagination: {},
      loading: false,
      page: 1,
      slug: '',
      search: '',
    }
  },
  computed: {
    ...mapState('userModule', ['userData']),
  },
  mounted() {
    // const finish_at__gte = formatToUTC()
    // this.slug = this.userData.provider_settings.slug
    // ShowsService.getAllShows({ finish_at__gte, pagination: 0 }, slug).then(res => {
    //   this.shows = res.data.data.map(({id, title}) => ({id, title}))
    // })

    this.fetchVariations()
  },
  methods: {
    async getList(visible, query) {
      if (false === visible) return
      let page = Math.floor(this.shows_pagination.offset / this.shows_pagination.limit) + 2
      
      if (this.page == page) return

      if (!visible) page = 1
      if (visible) this.page = page
      
      this.search = query
      this.loading = true
      const { data: { data, meta: { pagination } } } = (await ShowsService.getAllShows({
        finish_at__gte: formatToUTC(),
        page,
        search: this.search,
      }, this.slug))

      if (visible) {
        this.shows.push(...data)
      } else {
        this.shows = data
      }
      this.shows_pagination = pagination
      this.loading = false
    },
    async savePromo() {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        try {
          const res = await useResponse(PromosService.create(this.transformData(this.formData)), this, this.$refs.observer)
          console.log(res)
          this.formData = {
            "title": "",
            "is_fee": false,
            "variation": null,
            "start_at": null,
            "end_at": null,
            "shows": [],
            "amount": null,
            "price": null
          }
          this.$emit('close')
        } catch (error) {
          console.error(error)        
        }
      }
    },
    transformData(data) {

      
      let item = {}
      Object.keys(data).forEach(key => {
        if (data[key]) {
          item[key] = data[key].id || data[key]
        } 

        if (Array.isArray(data[key])) {
          item[key] = data[key].map(k => k.id)
        }
      })




      if (item.variation != 'glob') {
        delete item.start_at
        delete item.end_at
      } else {

        const start_at = new Date(item.start_at)
        const end_at = new Date(item.end_at)

        const startAtOffset = start_at.getTimezoneOffset() * 60000
        const endAtOffset = end_at.getTimezoneOffset() * 60000


        item.start_at = new Date(start_at - startAtOffset).toISOString().slice(0, -1)
        item.end_at = new Date(end_at - endAtOffset).toISOString().slice(0, -1)
      }
      return item
    },
    async fetchVariations() {

      try {
        const res = await PromosService.fetchOptions()
        console.log()
        this.variations = res.data.actions.POST.variation.choices.map(({ display_name, value}) => ({ title: display_name, id: value}))
      } catch (error) {
        console.error(error)
      }
    }
  },
}
</script>