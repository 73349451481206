<template lang="pug">
  include /mixins
  +b.file-preview
    vue-dropzone(
      ref="myVueDropzone",
      id="dropzone",
      :options="dropzoneOptions",
      @vdropzone-file-added="fileAdded",
      @vdropzone-max-files-exceeded="maxfilesexceeded"
    )
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'

const getCookie = name => {
  let matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        "\\$1"
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}


const optionsDefaults = {
  url: 'https://httpbin.org/post',
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRFToken": getCookie("csrftoken")
  },
  thumbnailWidth: 150,
  thumbnailHeight: 150,
  maxFiles: 1,
  addRemoveLinks: true,
  dictDefaultMessage: `<span class="icon icon--size_md icon--type_upload"></span>Drop files here to upload`
}

export default {
  name: 'DropZone',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    options: {
      type: Object
    },
  },
  data() {
    return {
      dropzoneOptions: {...optionsDefaults, ...this.options}
    }
  },
  methods: {
    fileAdded(file) {
      this.$emit('add-file', file)
    },
    maxfilesexceeded(file) {
      this.$refs.myVueDropzone.removeAllFiles()
      this.$refs.myVueDropzone.addFile(file)
    }
  }
}
</script>