<template lang="pug">
  include /mixins
  .form__control
    label.form__label.form__label--selector
      input.form__input(v-bind='attrs'
                        v-on='listeners'
                        :value='val'
                        :checked='value === val'
                        v-validate='$attrs.validate')
      span.form__text
        slot(name="text") 
    slot(name='error')
      transition(name='fade')
        +b.control-hint.--styling_default.--variant_error(v-if='errors.has($attrs.name)') {{ errors.first($attrs.name) }}
</template>

<script>
import {inputMixin} from '@cabinet/mixin/input'
export default {
  inject: ['$validator'],
  mixins: [ inputMixin ],
  props: {
    val: {
      default: () => {}
    },
    value: {
      default: () => {}
    }
  },
}
</script>

