<template lang="pug">
include /mixins
card
  card-panel(offset='full')
    card-panel(offset='bottom')
      row(
        offset
        alignment='center'
      )

        cell(cols='12 narrow-sm')
          +b.control-box
            v-button(
              variant='danger'
              border='whole'
              @click='addPromo'
            )
              template(v-slot:prepend='')
                cell
                  v-button-element(
                    size='lg'
                    variant="warning-lite"
                  )
                    v-caption(variant='bold')
                      icon(
                        type='add'
                        variant='opaque'
                        size='md'
                      )
              cell(cols='auto')
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('Add new promo') }}
        cell(cols='12 8-md 7-lg 5-2xl')
          ui-search(
            @input='fetchPromos($event)'
            type='search'
          )
            template(v-slot:activator='{send, data}')  
              cell(cols='12 narrow-md')
                v-button(
                  size='lg'
                  :border='["top_right", "bottom_right"]'
                  @click='send(data)'
                  variant='success'
                )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ _('s search') }}
        
    loading-wrapper(:loading='loading')
      card-panel(offset='bottom' v-if='!promos.length')
        notice()
          template(v-slot:icon='')
            icon(
              type='error' 
              color='warning' 
              size='5xl'
            )
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('No promos found')}}
      card-panel(offset='bottom' v-else )
        card(space='md')
          card-panel(offset='bottom')

            +b.scrollable-wrapper--till-2xl.--full()
              +b.TABLE.table--variant_scroll.--space_lg(style='min-width:initial')
                +e.THEAD.thead
                  +e.TR.row--relief
                    +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
                      v-caption(color='white') {{ _('p Title') }}
                    +e.TH.cell--variant_bold.--head
                      v-caption(color='white') {{ _('p Variantion') }}
                    +e.TH.cell--variant_bold.--head
                      v-caption(color='white') {{ _('p Promo-code') }}
                    +e.TH.cell--variant_bold.--head
                      v-caption(color='white') {{ _('p is active') }}
                    +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom(width='15%')
                      v-caption(color='white') {{ _('p Delete') }}
                +e.TBODY.tbody
                  +e.TR.row(v-for='(promo, index) in promos')
                    +e.TD.cell.--bordered_left_top.--bordered_left_bottom
                      v-caption(
                        size='6'
                        color='default'
                      ) {{ promo.title }}
                    +e.TD.cell
                      v-caption(
                        size='6'
                        color='default'
                      ) {{ promo.variation }}

                    +e.TD.cell
                      v-caption(
                        size='6'
                        color='default'
                      ) {{ promo.code }}

                    +e.TD.cell
                      v-caption(
                        size='6'
                        color='default'
                      ) {{ promo.is_active }}

                    +e.TD.cell.--bordered_right_top.--bordered_right_bottom
                      +b.A(
                        @click='modalTrigger(promo.id)' 
                      ).link
                        row(
                          offset 
                          space='2xs' 
                          alignment='center' 
                          justification='center_till-lg'
                        )
                          cell(cols='narrow')
                            icon(
                              type='close'
                              size='xs'
                              color='warning'
                            )
                          cell(cols='narrow')
                            v-caption(size='6' color='warning') {{ _('pp delete') }}
          card-panel(offset='bottom')
            cell(justification='right-sm')
          ui-pagination(
            :offset='meta.offset'
            :total='meta.total'
            :limit='meta.limit'
            @input='fetchPromos({page: $event, search})'
          )
</template>

<script>
import UiSearch from '@cabinet/components/forms/UiSearch'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import { useResponse } from '@cabinet/composables/use-response'

import PromosService from '@cabinet/services/api/promos'
import PromoModal from '@cabinet/components/forms/PromoModal'
import UiDelete from '@cabinet/components/modals/UiDelete'

export default {
  components: {
    UiSearch,
    UiPagination
  },
  data() {
    return {
      meta: {},
      search: '',
      promos: [],
      loading: false
    }
  },
  methods: {
    addPromo() {
      this.$modal.show(
        PromoModal,
        
        {

        }, {
          height: 'auto',
          adaptive: true,
          width: 310,
          classes: ['visibleModal']
        }, {
          'before-close': () => this.fetchPromos()
        }
      )
    },
    deletePromo(id) {
      return PromosService.deletePromo(id).then(res => this.fetchPromos())
    },
    async deletePromo_(id) {
      if (id) {
        try {
          await useResponse(this.deletePromo(id), this, null)
          
        } catch (error) {
          console.error(error)
        }
      }
    },
    async modalTrigger(promo) {
    
      this.$modal.show(UiDelete,
        {
          fn: this.deletePromo,
          itemID: promo,
          type: this._('pp promo'),
          
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }
      )
      
    },
    async fetchPromos(data) {
      try {
        console.log(data)
        this.loading = true
        const res = await useResponse(PromosService.fetch(data), this)

        this.promos = res.data
        console.log(res)
        this.loading = false
        this.meta = res.meta.pagination
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    }
  },
  mounted() {
    // this.fetchPromos()
  },
}
</script>


// include /mixins.pug

// row(v-if='total', offset alignment="baseline" justification='center')
//   template(v-if='hasPrevious()')
//     cell(cols="narrow") 
//       +b.pagination__item--variant_icon.--variant_text.--variant_box-shadow.cols.cols--narrow(@click.prevent='previous()') {{ _('Previous') }}
//     cell(cols="narrow") 
//       +b.pagination__item.cols(@click.prevent='select(range.min)') {{ range.min }}
//   cell(cols="narrow", v-if='gaps.left') ...
//   cell(cols="narrow", v-for='page in fills.left', :key="page")
//     +b.pagination__item.cols(@click.prevent='select(page)') {{ page }}
//   cell(cols="narrow") 
//     +b.pagination__item.cols(size="sm") {{ getPageNumber() }}
//   cell(cols="narrow", v-for='page in fills.right', :key="page")
//     +b.pagination__item.cols(@click.prevent='select(page)') {{ page }}
//   cell(cols="narrow", v-if='gaps.right') ...
//   template(v-if='hasNext()')
//     cell(cols="narrow") 
//       +b.pagination__item.cols(@click.prevent='select(range.max)') {{ range.max }}
//     cell(cols="narrow") 
//       +b.pagination__item--variant_icon.--variant_text.--variant_box-shadow.cols.cols--narrow(@click.prevent='next()') {{ _('Next') }}
