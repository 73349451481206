<template lang='pug'>
  include /mixins
  +b.control-box(:name='$attrs.name')
    ValidationProvider(
      :rules='$attrs.validate' 
      v-slot='{errors}' 
      :name='$attrs.name'
      tag='div'
    )
      multiselect(
        track-by="title",
        label="title",
        :options='options'
        :name='$attrs.name'
        :id='$attrs.id'
        :placeholder="placeholder",
        :searchable="searchable",
        :taggable='taggable'
        :allow-empty="allowEmpty"
        :multiple="multiple"
        :close-on-select='!multiple || closeOnSelect'
        :disabled='disabled'
        :select-label='"+"'
        :deselect-label='"-"'
        ref='select'
        v-on='listeners'
        @open='adjustSizing'
        v-model='checked'
        :class='[$blockClasses]'
        :loading='loading'
      )
        //- @select='$event => $emit("select", $event)'
        template(v-slot:single-label="{ option }")
          span {{ option.title }}

        template(v-slot:option="{option}")
          .option__desc(:title='option.tooltip')
            span.option__title {{ option.title }}

        template(v-slot:tag='{option, remove}')
          .multiselect__custom-tag
            row(alignment='center' space='xs' offset nowrap)
              cell {{ option.title || option.id }}
              cell
                +b.multiselect__custom-remove
                  slot(name='action' :remove='remove' :option='option')
                    span(@click.stop.prevent='removeItem(remove, option)')
                      icon(type='close' color='danger' size='xs')

        template(v-slot:no-result="") {{ _('Oops! No elements found. Consider changing the search query.') }}
        template(
          v-slot:[name]="binded"
          v-for="(name, key) in eSlots"
        )
          slot(
            v-bind="binded"
            :name="key"
          )
      slot(name='error')
        +b.control-hint.--styling_default.--variant_error(v-if='errors[0]') {{ errors[0] }}
    
</template>

<script>
import BemComponentMixin from '@cabinet/components/utils/bem/BemComponentMixin.js'

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})


export default {
  // watch: {
  //   val(value) {
  //     this.value = value
  //   }
  // },
  inheritAttrs: false,
  bem: {
    block: 'multiselect',
    modifierProps: ['size', 'bg']
  },
  mixins: [BemComponentMixin],
  computed: {
    checked: {
      get() {
        this.value = this.val
        return this.value
      },
      set(val) {
        this.value = val
      },
    },
    listeners() {
      return {
        ...this.$listeners,
        remove: $event => this.$emit("remove", $event),
        // close: () => this.$emit("input", this.value),
        
      }
    },
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
  },
  props: {
    bg: {
      default: ''
    },
    size: {
      default: ""
    },
    options: {
      required: true
    },
    placeholder: {
      required: false
    },
    multiple: {
      default: false
    },
    searchable: {
      default: false
    },
    taggable: {
      default: false,
    },
    val: {
      required: true
    },
    callback: {
      required: false
    },
    allowEmpty: {
      required: false,
      default: false
    },
    closeOnSelect: {
      default: false
    },
    disabled: {
      default: false
    },
    loading: {
      default: false,
    }
    
  },
  
  data() {
    return {
      value: ''
    }
  },
  methods: {
    adjustSizing() {
      // let { select: { $el } } = this.$refs
      // const wrapper = [...$el.getElementsByClassName('multiselect__content-wrapper')][0]
      // this.$nextTick().then(res => {
      //   wrapper.scrollIntoView({ behavior: 'smooth', block: 'center'})
      // })
    },
    removeItem(remove, option, callback = this.callback) {
      if (callback) {
        callback().then(res => {
          remove(option)
        })
      } else {
        remove(option)
      }
    }
  },
}
</script>

