import useEvent from './use-event'
import { onMounted } from '@vue/composition-api'
function setPadding(vue) {
  // let width = window.innerWidth
  let { header, footer } = vue.$refs
  let content = vue.$el
  let { clientHeight } = header.$el
  if (footer) {
    let { clientHeight: footerScrollHeight } = footer
    content.style.paddingBottom = `${footerScrollHeight}px`
  }
  content.style.paddingTop = `${clientHeight}px`
}

export function usePadding(vue, callback = setPadding) {
  // const height = ref(window.innerHeight)
  
  onMounted(() => {
    callback(vue)

  })
  useEvent(window, 'orientationchange', () => {
    callback(vue)
  })
  useEvent(window, 'resize', () => {
    callback(vue)
  })
}
