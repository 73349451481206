<template lang='pug'>
  include /mixins
  loading-wrapper(:loading='loading')
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      autocomplete='off'
    )
      validation(name='non field errors')
      card-panel(v-if='items.length')
        card-panel
          +b.scrollable-wrapper--till-2xl.--full
            +b.TABLE.table--variant_scroll.--space_lg(style='min-width: initial')
              +e.THEAD.thead
                +e.TR.row--relief
                  +e.TH.cell--variant_bold.--head
                    row(alignment='center' space='md' nowrap)
                      cell
                        v-checkbox
                          +b.INPUT(type="checkbox"
                                  :value='allSelected'
                                  @input='allSelected = !allSelected;selectItems(allSelected)').control-checkbox__input
                      cell
                        v-caption(color='white'
                                  size='6') {{ _('p print') }}
                  +e.TH.cell--variant_bold.--head
                    v-caption(color='white') {{ _('p sector') }}
                  +e.TH.cell--variant_bold.--head
                    v-caption(color='white') {{ _('p row') }}
                  +e.TH.cell--variant_bold.--head
                    v-caption(color='white') {{ _('p seat') }}
              +e.TBODY.tbody

                +e.TR.row(v-for='ticket in items' :key='ticket.id')
                  +e.TD.cell--bordered_left_top.--bordered_left_bottom
                    row(alignment='center' space='md' nowrap)
                      cell
                        v-checkbox
                          +b.INPUT(type="checkbox"
                                  :value='ticket'
                                  v-model='selected').control-checkbox__input

                  +e.TD.cell
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ ticket.sector }}

                  +e.TD.cell
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ ticket.row }}

                  +e.TD.cell
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ ticket.seat }}

        card-panel
          row(justification='center')
            cell(cols='narrow')
              v-button(
                :variant='["clear", "block"]'
                hover='danger'
                @click='refund'
                :disabled='!selected.length'
              )
                v-button-element
                  v-caption(
                    color='warning'
                    :variant='["uppercase", "bold"]'
                  ) {{ _('open print') }} {{ selected.length ? `${selected.length} tickets`: ''}}
      card-panel(v-else)
        notice
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('No items in list')}}


</template>

<script>
import {
  ref, onMounted,

} from '@vue/composition-api'

import TicketsService from '@cabinet/services/api/tickets'

export default {
  props: {

    id: {
      required: true
    }
  },
  setup(props, context) {

    const loading = ref(false)
    const allSelected = ref(false)
    const items = ref([])
    const selected = ref([])

    const refund = async () => {
      loading.value = true
      try {
        loading.value = true
        const ids = prepareData(selected.value)
        const link = `${window.location.origin}/api/v1/tickets/print/?as=pdf&ids=${ids.join(',')}`
        window.open(link, '_blank')
        loading.value = false
        context.emit('fetch')
      } catch (err) {
        loading.value = false
        console.error(err)
      }
    }

    const fetchList = async () => {
      try {
        loading.value = true
        items.value = (await TicketsService.showPrintedList({ id: props.id })).data.data
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
    }

    const selectItems = value => {
      if (value) {
        selected.value = items.value
      } else {
        selected.value = []
      }
    }

    const prepareData = array => {
      return array.map(item => item.ticket)
    }

    onMounted(() => {
      fetchList()
    })

    return {
      allSelected,
      selected,
      selectItems,
      refund,

      items,
      loading
    }
  }
}
</script>
