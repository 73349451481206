import Selectable from 'selectable.js'
import { EventBus } from '@cabinet/components/eventbus/eventbus'

export const closestG = target => {
  if (typeof target === 'string') {
    return document.getElementById(target).closest('g')
  }
  return target.closest('g')
}

export const gCircles = g => g.querySelectorAll('circle')

export default class Select {
  constructor(node, emitter) {
    this.selectable = new Selectable({
      filter: document.querySelectorAll(node),
      lasso: {
        backgroundColor: "rgba(219, 10, 91, 0.4)"
      },
      maxSelectable: 1
    })
    window.isDragging = false
    this.selected = []
    this.emitter = EventBus
    this.removeSelected = this.removeSelected.bind(this)
    window.removeSelected = this.removeSelected
  }
  removeSelected() {
    window.isDragging = true
    if (this.selected) {
      this.selected.forEach(c => c.classList.remove('is-selected', 'is-highlighted'))
      this.selected = []
    }
  }
  addSelected(items) {
    this.selectable.select(items)
  }
  assignSelected(e, items) {
    let circles = gCircles(closestG(items[0].node ? items[0].node : items[0].geom_id))
    circles.forEach(c => c.classList.add('is-selected'))

    this.addSelected(circles)
    this.selected = circles
    this.selectable.disable()
    this.emitter.$emit('setSelection', circles)
    window.isDragging = false
  }
  getSelected() {
    window.isDragging = true
  }
  init() {
    this.selectable.on("start", (e, items) => {
      this.removeSelected(e, items)
    })
    this.selectable.on("drag", this.getSelected)
    this.selectable.on("end", (e, items) => {
      this.assignSelected(e, items.length ? items : [{node: e.target}])
    })
    this.emitter.$on('removeSelected', () => {
      this.removeSelected()
    })

    this.emitter.$on('setSelected', items => {
      this.assignSelected(null, items)
    })
    this.emitter.$on('getSelected', items => {
      this.assignSelected(null, items)
    })

    return this
  }

}
