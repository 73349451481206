import Api from '@cabinet/services'

export default {
  fetch() {
    return Api().get('/flack/list/')
  },
  create({ data }) {
    return Api().post('/flack/create/', data)
  },
  update({ id, data }) {
    return Api().patch(`/flack/update/${id}/`, data)
  },
  delete(id) {
    return Api().delete(`/flack/delete/${id}/`)
  }
}
