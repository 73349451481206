<template lang="pug">
loading-wrapper(
  :loading='loading'
)
  card
    SecurityAccordion
      template(v-slot:caption='')
        | {{ _('m Advertising' )}}
      template(v-slot:content='')
        card-panel(offset='top')
          row(offset space='xl-xl')
            cell(cols='12 8-md 12-lg 8-2xl')
              row(offset)
                transition-group(name='fade')
                  cell(
                    cols='12'
                    v-for='(ad, index) in ads'
                    :key='"item" + index'
                  )
                    ProviderAd(
                      :ad='ad'
                      :index='index + 1'
                      @save='save'
                      @remove='remove($event, index)'
                    )
            cell(cols='12 4-md 12-lg 4-2xl')
              row(offset)
                cell(cols='narrow 12-md narrow-lg 12-2xl')
                  v-button(
                    variant='success'
                    @click='addAd($event)'
                    
                  )
                    v-button-element(size='lg') 
                      v-caption(variant='bold' size='6') {{ _('ADD NEW AD') }}
                cell(cols='12')
                  v-caption(size='6') {{ _('Attention! You can add a maximum of 9 ads to your e-ticket template.') }}
            

</template>

<script>

import { ref, onMounted } from '@vue/composition-api'
import AdsService from '@cabinet/services/api/ads'
import { useResponse } from '@cabinet/composables/use-response'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import ProviderAd from '@cabinet/components/settings/ProviderAd'

export default {
  components: {
    SecurityAccordion,
    ProviderAd,
  },
  setup(props, context) {
    const ads = ref([])
    const loading = ref(false)

    const ad = ref({
      title: '',
      ordering: '',
      image: '',
    })

    const fetch = async () => {
      try {
        loading.value = true
        const res = await useResponse(AdsService.fetch(), context.root)
        ads.value = res.data
        loading.value = false
        
      } catch (error) {
        loading.value = false
      }
    }

    const addAd = () => {
      if (ads.value.length >= 9) {
        return
      }
      ads.value.push({...ad.value })
    }

    const remove = async (ad, index) => {
      if (ad.id) {
        await AdsService.delete(ad.id)
      }
      ads.value.splice(index, 1)
    }

    const save = async (ad) => {
      const action = ad.id ? 'update' : 'create'
      
      try {
        await useResponse(AdsService[action]({data: ad, id: ad.id}), context.root)
        
      } catch (error) {
        console.error(error)
      }
    }

    onMounted(() => {
      fetch()
    })
    return {
      ads,
      loading,

      remove,

      save,
      addAd
    }
  }
}
</script>

