

<template lang="pug">
  include /mixins.pug
  row(v-if='total', offset alignment="baseline" justification='center')
    template(v-if='hasPrevious()')
      cell(cols="narrow") 
        +b.pagination__item--variant_icon.--variant_text.--variant_box-shadow.cols.cols--narrow(@click.prevent='previous()') {{ _('Previous') }}
      cell(cols="narrow") 
        +b.pagination__item.cols(
          @click.prevent='select(range.min)'
          ) {{ range.min }}
    cell(cols="narrow", v-if='gaps.left') ...
    cell(cols="narrow", v-for='page in fills.left', :key="page")
      +b.pagination__item.cols(
        @click.prevent='select(page)'
        ) {{ page }}
    cell(cols="narrow") 
      +b.pagination__item.cols(
        size="sm"
        :class="{'is-active' : current === getPageNumber()}",
        ) {{ getPageNumber() }}
    cell(cols="narrow", v-for='page in fills.right', :key="page")
      +b.pagination__item.cols(
        @click.prevent='select(page)'
        ) {{ page }}
    cell(cols="narrow", v-if='gaps.right') ...
    template(v-if='hasNext()')
      cell(cols="narrow") 
        +b.pagination__item.cols(
          @click.prevent='select(range.max)'
          ) {{ range.max }}
      cell(cols="narrow") 
        +b.pagination__item--variant_icon.--variant_text.--variant_box-shadow.cols.cols--narrow(@click.prevent='next()') {{ _('Next') }}
</template>
<script>
const PAGE_SHIFT = 1

export default {
  name: 'pagination',
  props: {
    total: Number,
    limit: Number,
    offset: Number,
    current: Number,
    extra: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      range: {
        min: 0,
        max: Infinity
      },
      gaps: {
        left: false,
        right: false
      },
      fills: {
        left: [],
        right: []
      },
    }
  },

  created() {
    if (this.total) this.recalcRange()
  },

  watch: {
    total: { handler: 'recalcRange' },
    limit: { handler: 'recalcRange' },
    offset: { handler: 'recalcRange' },
  },

  methods: {
    hasNext() {
      return this.offset + this.limit < this.total
    },

    hasPrevious() {
      return this.offset > 0
    },

    getPageNumber(offset = this.offset, shift = PAGE_SHIFT) {
      return Math.floor(offset / this.limit) + shift
    },

    getPageRange(distance = null, current = this.getPageNumber()) {
      const min = 1
      const max = Math.max(min, Math.ceil(this.total / this.limit))

      if (null === distance || Infinity === distance) {
        return [min, max]
      }

      return [
        Math.max(min, current - distance),
        Math.min(max, current + distance),
      ]
    },

    recalcRange() {
      let [min, max] = this.getPageRange()
      let current = this.getPageNumber()
      let [start, end] = this.getPageRange(this.extra, current)
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      const range = Array
        .apply(null, { length: end - start + 1 })
        .map((x, i) => i + start)
      let currentIndex = range.indexOf(current)

      if (currentIndex !== -1) {
        this.fills.left = range.slice(0, currentIndex).filter(x => x !== min)
        this.fills.right = range.slice(currentIndex + 1).filter(x => x !== max)
      } else {
        this.fills.left = this.fills.right = []
      }

      this.gaps.left = (
        this.fills.left.length &&
      this.fills.left[0] !== min + 1
      )
      this.gaps.right = (
        this.fills.right.length &&
      this.fills.right[this.fills.right.length-1] !== max - 1
      )
      this.range.min = min
      this.range.max = max
    },

    getParameters(number = null) {
      let page = 1

      if (null !== number) {
        const [min, max] = this.getPageRange(Infinity, number)

        page = Math.min(max, Math.max(min, number))
      } else {
        page = this.getPageNumber()
      }

      return {
        offset: this.limit * (page - PAGE_SHIFT),
        limit: this.limit
      }
    },
    
    select(number) {
      const pageData = this.getParameters(number)
      this.$emit('input', Math.ceil(pageData.offset / this.limit) + 1)
    },
    next() {
      const pageData = this.getParameters(this.getPageNumber() + PAGE_SHIFT)
      this.$emit('input', Math.ceil(pageData.offset / this.limit) + 1)
    },
    previous() {
      const pageData = this.getParameters(this.getPageNumber() - PAGE_SHIFT)
      this.$emit('input', Math.ceil(pageData.offset / this.limit) + 1)
    },
  }
}

</script>

