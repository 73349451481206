<script>
export default {
  props: {
    provider: {
      required: true,
      type: String
    }
  },
  methods: {
    download(items) {
      items = JSON.parse(items.replace(/'/g, '"'))
      console.log(items)
      items
        .forEach((t, idx) => {
          setTimeout(() => this.createFakeLink(t), 1000 * (idx + 1))
        })
      // for (var i = 0; i < items.length; i++) {
      //   setTimeout(() => this.createFakeLink(items[i]), (i+1) * 2000)
      // }
    },
    createFakeLink(url) {
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      console.log(link)
      link.click()
    }
  },
  render(h) {
    return this.$scopedSlots.default({
      download: this.download
    })
  }
}
</script>