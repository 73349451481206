<template lang="pug">
  include /mixins
  card()  
    card-panel(offset='full')
      row(offset)
        cell(cols='12')
          +b.UI-TABS.tabs.mt-1(ref='tab' :active='active')
            template(v-slot='{ activeItem, activate }')
              +b.tab-list
                +e.body
                  +e.toggle--variant_default.tab-list__item(
                    @click='activate(activateLocaly(index, active))'
                    v-for='(item, index) in tabs'
                  )
                    v-caption(
                      :style='item.disabled ? "pointer-events:none;opacity:.5;" : ""'
                      :variant="[activeItem === index ? 'bold' : 'secondary', 'uppercase' ]"
                    ) {{ item.title }}
      card(variant='section' stacked='top')
        card-panel(offset='full' v-if='active === 0')
          row(offset)
            cell(cols='12')
              LettersTemplates
        card-panel(v-if='active === 1')
          row(offset)
            cell(cols='12')
              Letters
        card-panel(v-if='active === 2')
          row(offset)
            cell(cols='12')
              ProviderMail
        
</template>

<script>
import { ref } from '@vue/composition-api'

import UiTabs from '@cabinet/components/vendor/UiTabs'
import Letters from '@cabinet/components/letters/Letters'
import LettersTemplates from '@cabinet/components/letters/LettersTemplates'
import ProviderMail from '@cabinet/components/settings/ProviderMail'

export default {
  components: {
    UiTabs,
    Letters,
    LettersTemplates,
    ProviderMail,
  },
  setup(props, context) {

    const tabs = ref([
      {
        title: context.root._('Templates'),
        id: 1
      },
      {
        title: context.root._('Sent'),
        id: 2
      },
      {
        title: context.root._('Mail Settings'),
        id: 3
      }
    ])

    const activateLocaly = (index) => {
      active.value = index
      return index
    }

    const active = ref(0)

    return {
      tabs,
      active,
      activateLocaly
    }
  }
}
</script>