import Vue from 'vue'
import Icon from './Icon/Icon'
import Card, { CardPanel, CardLabel } from './Card/Card'
import VTag from './VTag'
import VCaption from './VCaption'
import VButton from './Button/VButton'
import VButtonElement from './Button/VButtonElement'
import Row from './Row/Row'
import Cell from './Row/Cell'
import LoadingWrapper from './LoadingWrapper'
import Loading from './Loading'
import Progress from './Progress'
import UiCounter from './UiCounter'
import Validation from './Validation'
import Notice from './Notice'
import VideoWrapper from './VideoWrapper'
import ContactForm from './../forms/ContactForm'

import Downloader from './Downloader'
import LocalstorageLink from './LocalstorageLink'

export default function install(Vue) {

Vue.component('downloader', Downloader)
Vue.component('icon', Icon)
Vue.component('card', Card)
Vue.component('card-panel', CardPanel)
Vue.component('card-label', CardLabel)
Vue.component('v-tag', VTag)
Vue.component('v-caption', VCaption)
Vue.component('v-button', VButton)
Vue.component('v-button-element', VButtonElement)
Vue.component('row', Row)
Vue.component('cell', Cell)
Vue.component('loading-wrapper', LoadingWrapper)
Vue.component('loading', Loading)
Vue.component('progress-bar', Progress)

Vue.component('ui-counter', UiCounter)
Vue.component('validation', Validation)
Vue.component('notice', Notice)

Vue.component('VideoWrapper', VideoWrapper)

Vue.component('ContactForm', ContactForm)
Vue.component('LocalstorageLink', LocalstorageLink)

}
