<template lang="pug">
  include /mixins
  +b.HEADER.header--general
    +e.mask(:class="{'is-active': menuActive}")
    //- +e.block--top
    //-   .container
    //-     slot(name='top-block')
    ui-click-outside(:do='collapse')
      +e.block--blue
        .container(:class='containerClass')
          +e.main-block
            row(alignment='center' justification="between")
              cell
                +e.logo
                  slot(name='logo')
              
              cell
                slot(name='page-title')

              cell(cols="6-xl")
                slot(name='nav')

              cell(v-if='menuActive')
                row(offset alignment='center')
                  slot(
                    name='cart'
                    :count='reducedLength'
                    :goto='goTo'
                  )
                  transition(name='slide')
                    cell(justification='right')
                      +e.menu--right(v-if='menuActive')
                        //- row(
                        //-   column='till-xl' 
                        //-   justification='between' 
                        //-   alignment='center'
                        //- )
                        slot(
                          name='menu' 
                          :openModal='openModal' 
                          :logout='logout'
                        )

              cell(justification='right' v-if='!isAccount').header__toggler-wrapper
                +e.toggler(@click.prevent='toggleMenu')
                  icon(:type='menuActive ? "close" : "menu"' size='md')
</template>


<script>
import { headerMixin } from '@cabinet/components/header'
export default {
  mixins: [
    headerMixin
  ]
}
</script>
