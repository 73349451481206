import UiCreateEvent from '@cabinet/components/forms/UiCreateEvent.vue'
export function useOpenTourModal(id, vue, event, callback) {
  vue.$modal.show(
    UiCreateEvent, {
      id,
      event,
      callback
    }, {
      adaptive: true,
      height: 'auto',
      maxWidth: 431,
      scrollable: true,
      classes: ['v--modal', 'is-overflowed']
    }
  )
}