<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; ')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close' color='warning')
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      @submit.prevent='send'
      autocomplete='off'
    )
      loading-wrapper(:loading='loading')
        row(offset)
          cell(cols='12')
            v-caption(size='2' color='default' variant='bold') {{ _('ask for advance') }}
          cell(cols='12')
            v-caption(size='6' color='default' variant='bold') {{ _('An email will be send to admin') }}
          cell(cols='12')
            v-button(
              variant='success'
              type='submit'
            )
              v-button-element(size='lg') 
                v-caption(
                  :variant='["bold", "uppercase"]' 
                  size='6'
                ) {{ _('c charge') }}

          
</template>

<script>
import { ref } from '@vue/composition-api'
import ShowsService from '@cabinet/services/api/shows'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    show: {
      required: true
    }
  },
  setup(props, context) {
    const loading = ref(false)
    const send = async () => {
      try {
        loading.value = true
        await useResponse(ShowsService.charge({id: props.show.id}), context.root, context.refs.observer)
        loading.value = false
        context.emit('close')
      } catch (error) {
        loading.value = false
      }
    }
    return {
      loading,
      send
    }
  }
}
</script>