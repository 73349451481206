<script>
import { useClassesComposition } from '@cabinet/components/utils/bem/use-classes-composition'
const bem = {
  block: 'control-button__element',
  modifierProps: ['size', 'variant']
}
export default {
  functional: true,
  render(createElement, props) {
    const $blockClasses = useClassesComposition(props.props, bem)
    return createElement(
      'div',
      { class: ['control-button__element', $blockClasses, props.data.staticClass] },
      props.children
    )
  },
  props: {
    variant: {
      type: [String, Array],
      default: ''
    },
    size: {
      type: [String, Array],
      default: ''
    }
  }
}
</script>
