<template lang="pug">
  row(
    offset 
    alignment='top_till-2xl' 
    justification='between'
  )
    cell(cols='12')
      v-caption(size='1' variant='bold') {{ _('Active events stats') }}
    cell(cols='12 4-md narrow-xl')
      card(:variant='["stats", "white"]')
        card-panel(:offset='["horizontal", "horizontal_xl_xl", "vertical"]')
          row(
            offset 
            :space='["xs", "md-2xl"]'
          )
            cell(cols='narrow')
              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Events:') }}
                  cell(cols='12')
                    card-panel
                      v-caption(
                        size='1' 
                        variant='bold'  
                        display='xs'
                      ) {{ stats.total || 0 }}
            cell(cols='narrow')
              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Tickets:')}}
                  cell(cols='12')
                    card-panel
                      v-caption(
                        size='1' 
                        display='xs'
                        variant='bold'  
                      ) {{ stats.seats_amount || 0}}
    cell(cols='12 4-md narrow-2xl')
      card(:variant='["stats", "white"]')
        card-panel(:offset='["horizontal", "horizontal_xl_xl", "vertical"]')
          row(
            offset 
            column='till-lg'
            :space='["xs", "md-2xl"]'
          )
            cell(cols='narrow')

              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Sold tickets (qty):')}}
                  cell(cols='12')
                    card-panel
                      v-caption(
                        size='1' 
                        display='xs'
                        variant='bold'  
                      ) {{ stats.tickets_amount }}
            cell(cols='narrow')
              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Sold tickets:') }} 
                  cell(cols='12')
                    card-panel
                      row(offset alignment='bottom' space='5xs' nowrap)
                        cell(cols='narrow')
                          v-caption(size='1' variant='bold'  display='xs') $ {{stats.tickets_price | toUSD }}
                        cell(cols='narrow')
                          v-caption(size='6' variant='secondary') (&#8776; {{ parseFloat(stats.tickets_amount_percent || 0).toFixed(1) }} %)
            cell(cols='narrow')
              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Tickets in sale (amnt.):') }}
                  cell(cols='12')
                    card-panel
                      row(offset alignment='bottom' space='5xs' nowrap)
                        cell(cols='narrow')
                          v-caption(size='1' variant='bold' display='xs') $ {{ stats.unsold_price| toUSD }}
                        cell(cols='narrow')
                          v-caption(size='6' variant='secondary') (&#8776; {{ parseFloat(100 - (stats.tickets_amount_percent || 100)).toFixed(1) }} %)
    cell(cols='12 4-md narrow-xl')
      card(:variant='["stats", "white"]')
        card-panel(:offset='["horizontal", "horizontal_xl_xl", "vertical"]')
          row(
            offset
            :space='["xs", "md-2xl"]'
            
          )
            cell(cols='narrow')
              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Started events:')}}
                  cell(cols='12')
                    card-panel
                      v-caption(size='1' display='xs' variant='bold'  ) {{ stats.shows_amount || 0}}
            cell(cols='narrow')
              card
                row(column)
                  cell(cols='narrow')
                    card-panel(offset='bottom')
                      v-caption(
                        size='6' 
                        variant='secondary'
                        color='brand-4'
                      ) {{ _('d Scans:')}}
                  cell(cols='12')
                    card-panel
                      v-caption(size='1' display='xs' variant='bold') {{ parseInt(stats.scaned_shows || 0) }}%
    cell(
      cols='12 narrow-md' 
      justification='right'
      
    )
      row(space='xs')
        cell(:cols='["12 narrow-md", $route.params.id ? "narrow" : ""]')
          card(
            :variant='["fullheight", "white", "button"]' 
            @click='$emit("refresh")'
          )
            card-panel(
              variant='fullheight' 
              :offset='["horizontal_xs", "horizontal_sm-xl", "vertical_xs-md"]'
            )
              row(
                alignment='center'
                fullheight='xs'
                justification='center'
                space='xs'
                column='md'
              )
                cell
                  card-panel()
                    icon(
                      type='refresh' 
                      size='3xl'
                      color='brand-4'
                    )
                cell
                  v-caption(
                    size='6'
                    color='brand-1'
                  ) {{ _('s refresh') }}
        cell(
          :cols='["narrow", $route.params.id ? "narrow" : ""]' 
          v-if='$route.params.id && permissions.report'
        )
            card(
              :variant='["fullheight", "white", "button"]' 
              @click='getShowStatistics($route.meta.show)'
            )
              card-panel(
                variant='fullheight' 
                :offset='["horizontal_xs", "horizontal_sm-xl", "vertical_xs"]'
              )
                row(
                  alignment='center'
                  fullheight='xs'
                  justification='center'
                  space='xs'
                  column
                )
                  cell
                    card-panel()
                      icon(
                        type='download' 
                        size='3xl'
                        color='brand-4'
                      )
                  cell
                    v-caption(
                      size='6'
                      color='brand-1'
                    ) {{ _('s get report') }}

            

</template>

<script>
import StatsService from '@cabinet/services/api/stats'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('userModule', ['userData']),
    permissions() {
      return this.userData.permissions
    }
  },
  props: {
    stats: {
      required: true
    }
  },
  setup(props, context) {
    const tz = new Date().getTimezoneOffset() / 60
    const getShowStatistics = async (id) => {
      StatsService.getShowStatisticsInPdf(id, tz).then(res => {
        let url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf; base64'}))
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `show-statistics.pdf`)
        document.body.appendChild(link)
        link.click()
        this.loading = false
      })
    }
    return {
      getShowStatistics
    }
  }
}
</script>