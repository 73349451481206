<template lang="pug">
  loading-wrapper(:loading='loading')
    row(offset)
      cell(
        cols='12 6-2xl' 
        v-for='template in letters'
        :key='template.id'
      )
        LetterTemplate(:template='template')
          template(v-slot:caption='')
            | {{ template.name }}


</template>

<script>

import { useResponse } from '@cabinet/composables/use-response'
import LettersService from '@cabinet/services/api/letters'
import { ref, onMounted } from '@vue/composition-api'
import LetterTemplate from '@cabinet/components/letters/LetterTemplate'

export default {
  components: {
    LetterTemplate,
  },  
  setup(props, context) {
    const loading = ref(false)

    const letters = ref([])

    const fetchTemplates = async (query) => {
      try {
        loading.value = true
        const res = await useResponse(LettersService.fetchTemplates(), context.root, context.refs.observer)
        
        letters.value = res.data
        
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
    }
    onMounted(() => {
      fetchTemplates()
    })

    return {
      loading,

      letters,
      fetchTemplates
    }
  }
}
</script>