<template lang="pug">

  include /mixins
  +b.timer-wrapper-no
    +e.timer-no(@click='toggleActive')
      ui-countdown-simple(
        :time='getTime'
        :booking='true'
      )
      //- v-if='getTime'
    //- transition(name='fade'
    //-            mode='in-out')
      //- +e.hint(v-if='active') {{ _('The reservation is automatically canceled if the ticket is not paid within ') }}{{ timer }}{{ _(' minutes') }}
</template>

<script>
import { mapState } from 'vuex'
import UiCountdownSimple from '@cabinet/components/vendor/UiCountdownSimple'

export default {
  props: {
    getTime: {
      default: 0
    }
  },
  data() {
    return {
      active: true
    }
  },
  computed: {
    ...mapState('cartModule', [
      'timer'
    ])
  },
  components: {
    UiCountdownSimple
  },
  methods: {
    toggleActive() {
      this.active = !this.active
    }
  }
}
</script>

