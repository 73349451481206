<template lang='pug'>
  include /mixins
  div.checkout__modal
    .checkout-modal__header(style='width: 100%;')
      row
        cell(cols='auto')
          row
            cell
              icon(type='cart', color='white')
            cell
              v-caption(size='3' variant='bold' color='white') {{ _('Cart') }}
        cell(cols='narrow')
          .checkout-modal__close-btn.btn.btn--flex.btn--center(@click='$emit("close")')
            icon(type='close')
    loading-wrapper(:loading='loading')
      template(v-if='isGrouped')
        .checkout-modal__timer
          v-caption(size='6' display='sm')
            countdown.checkout-sidebar__watch(
              v-if='getCurrentTimer'
              :auto-start='true'
              :time='getCurrentTimer'
              v-slot="{ days, hours, minutes, seconds }"
            )
              row(offset nowrap space='none')
                cell(cols='narrow')
                  v-caption(size='6' variant='bold') {{ minutes | counter }}
                cell(cols='narrow')
                  v-caption(size='6' variant='bold') :
                cell(cols='narrow')
                  v-caption(size='6' variant='bold') {{ seconds | counter }} {{ _('left to complete this transaction') }}
        
        checkout-progress(
          v-if='startValue && getCurrentTimer'
          :current-value='getCurrentTimer'
          :start-value='startValue'
          v-slot='{ value, startValue }'
        )
          progress(:max="startValue" :value="value" class='checkout-progress__bar')
        card.checkout-modal__body(space='md')
          card-panel(:offset='["full"]')
            card(space='sm')
              card-panel(:offset='["bottom"]')
                v-caption(size='4' variant='bold' display='sm') {{ hall.title }} - {{ title }}
            v-caption(size='6') {{ date }}, {{ time }}
        template(v-for='(array, key) in grouped' v-if='isGrouped')
          div.checkout-modal__body.checkout-modal__scroll-seats
            card.checkout-modal__body(space='xs' v-for='ticket in array' :key="ticket.id")
              card-panel(:offset='["vertical"]')
                card(space='md')
                  card-panel(:offset='["horizontal"]')
                    row(nowrap alignment='center')
                      cell(cols='narrow')
                        row(space='5xs')
                          cell
                            v-caption(size='6') {{ _('Sector') }}
                          cell
                            v-caption(size='6' variant='bold') {{ ticket.sector.title }}
                      cell(v-if='ticket.row' cols='narrow')
                        row(space='5xs')
                          cell
                            v-caption(size='6') {{ _('Row') }}
                          cell
                            v-caption(size='6' variant='bold') {{ ticket.row }}
                      cell(v-if='ticket.seat' cols='narrow')
                        row(space='5xs')
                          cell
                            v-caption(size='6') {{ _('Seat') }}
                          cell
                            v-caption(size='6' variant='bold') {{ ticket.seat.title }}
                      cell
                        button.checkout-ticket__remove-btn(@click='remove(ticket)')
          card.checkout-modal__body(space='xs')
            card-panel(:offset='["vertical"]')
              card(space='md')
                card-panel(:offset='["horizontal"]')
                  row(justification='between')
                    cell(cols='auto')
                      v-caption(size='6' display='sm') {{ _('Qty:') }}
                      v-caption(size='4' variant='bold') {{ array.length }}
                    cell(cols='auto')
                      v-caption(size='6' display='sm') {{ _('Price:') }}
                      v-caption(size='4' variant='bold') $ {{ price(key).toFixed(2) }}
                    cell(cols='auto')
                      v-caption(size='6' display='sm') {{ _('Subtotal:') }}
                      v-caption(size='4' variant='bold') $ {{ sectorSubtotal(key).toFixed(2) }}
        card.checkout-modal__body(space='xs')
          card-panel(:offset='["vertical"]')
            card(space='md')
              card-panel(:offset='["horizontal"]')
                row(justification='between')
                  cell
                    v-caption(size='4' display='sm' variant='bold') {{ _('Total:') }}
                  cell
                    v-caption(size='4' display='sm' variant='bold') $ {{ total }}
      div(v-else)
        card.checkout-modal__body(space='md')
          card-panel(:offset='["full"]')
            v-caption(size='4' :variant='["bold", "center"]') {{ _('Your cart is empty')}}
    card-panel(offset='full')
      promocode(:disabled='!isGrouped')
    div.checkout-modal__buttons
      row
        cell(cols='6')
          v-button(
            :variant='["danger"]'
            border='whole'
            :class="{'is-disabled': !isGrouped }"
            @click.prevent='emptyCart'
          )
            v-button-element(size='md')
              row(justification='center')
                card(:space='["sm"]')
                  card-panel(offset='right')
                    icon(type='cart', color='white')
                v-caption(
                  size='6'
                  :variant='["uppercase", "bold"]'
                ) {{ _('Empty cart') }}
        cell(cols='6')
          v-button(
            :variant='["accent"]'
            border='whole'
            :class="{'is-disabled': !isGrouped }"
            @click.prevent='redirect'
          )
            v-button-element(size='md')
              row(justification='center')
                v-caption(
                  size='6'
                  :variant='["uppercase", "bold"]'
                ) {{ _('Checkout') }}
                card(:space='["sm"]')
                  card-panel(offset='left')
                    icon(type='right-arrow', color='white')
</template>

<script>
import Countdown from '../../components/vendor/Countdown.vue'
import { groupBy } from '@cabinet/router'
import { mapActions, mapState, mapGetters } from 'vuex'
import { removeClass, getItemFromDOM } from '@cabinet/util/'
import CheckoutProgress from '../../components/vendor/CheckoutProgress.vue'
import Promocode from '@cabinet/components/booking/Promocode'

export default {
  components: {
    Countdown,
    CheckoutProgress,
    Promocode,
  },
  props: {
    hall: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    redirect: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions('cartModule', ['removeMainCartItem', 'fetchFullCart', 'clearCart']),
    price(id) {
      return Number(this.grouped[id][0].price)
    },
    sectorSubtotal(id) {
      return this.grouped[id].reduce((acc, item) => acc + Number(item.price), 0)
    },
    async remove(ticket) {
      const data = [ticket.id]
      this.loading = false
      try {
        await this.removeMainCartItem({ tickets: data, id: ticket.id, type: ticket.type })
        await this.fetchFullCart()
        if (ticket.sector.title !== 'ga') removeClass(getItemFromDOM(ticket.seat.geom_id), ['is-user-reserved', 'is-reserved', 'is-selected'])
        this.loading = false
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    },
    emptyCart() {
      const reservedList = []
      this.tickets.forEach(item => {
        if (item.seat !== null && item.sector.title !== 'ga') {
          reservedList.push(item.seat.geom_id)
        }
      })
      if (reservedList.length) {
        for (let i = 0; i < reservedList.length; i++) {
          removeClass(getItemFromDOM(reservedList[i]), ['is-user-reserved', 'is-reserved', 'is-selected'])
        }
      }
      this.clearCart()
    }
  },
  computed: {
    ...mapState('cartModule', ['cart', 'fullCart', 'cartInfo']),
    ...mapGetters('cartModule', ['getCurrentTimer']),
    isGrouped() {
      return Object.keys(this.grouped).length > 0
    },
    tickets() {
      return this.fullCart.flat(Infinity)
    },
    grouped() {
      return groupBy(this.tickets, item => item.sector.id)
    },
    startValue() {
      return window.TICKET_RETURN * 60 * 1000
    },
    total() {
      return this.tickets.reduce((acc, item) => acc += parseFloat(item.price), 0).toFixed(2)
    },
  },
}
</script>
