<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(:loading='isLoading')
      validation-observer(
        tag='form'
        @submit.prevent='sendData'
        ref='observer'
        v-slot='{invalid}'
        autocomplete='off'
      )
        row(offset)
          cell(cols='12')
            v-caption(size='4' variant='bold') {{ _('Comment to ticket refund') }}
          transition(name='fade')
            validation(name='non field errors')
          cell(cols='12')
            ui-input(:val='formData.comment'
                    type='search'
                    size='lg'
                    bg='relief-3'
                    :placeholder="_('comment')"
                    name='comment'
                    v-model='formData.comment'
                    validate='required').form__control--styled
          cell(cols='12')
            ui-input(:val='formData.price'
                    type='search' v-digitsonly=''
                    :placeholder="_('price')"
                    size='lg'
                    bg='relief-3'
                    name='price'
                    v-model.number='formData.price'
                    validate='required').form__control--styled
          cell(cols='12')
            v-button(
              variant='success'
              type='submit'
            )
              v-button-element(size='lg') 
                v-caption(variant='bold') {{ _('send') }}
</template>

<script>
import UiInput from '@cabinet/components/forms/blocks/UiInput'
// import { successHandler, errorHandler } from '@cabinet/util'
export default {
  props: {
    fn: {
      required: true
    },
    price: {
      required: true
    }
  },
  components: {
    UiInput
  },
  data() {
    return {

      isLoading: false,
      formData: {
        comment: '',
        price: 0
      }
    }
  },
  created() {
    this.formData.price = this.price
  },
  methods: {
    async sendData() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.isLoading = true
        try {
          await this.fn(this.formData, this)
          this.$emit('close')
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

