<template lang="pug">
  include /mixins
  +b-context('table')
    +e.TR.row(@click.stop='')
      +e.TD.cell(style='width: 20%').--bordered_left_top.--bordered_left_bottom
      +e.TD.cell(style='width: 20%')
      +e.TD.cell(style='width: 20%')
        v-caption(size='6' variant='white-normal') {{ vendor.email }}
      +e.TD.cell(style='width: calc(3.9%)').--bordered_right
      +e.TD.cell(style='width: calc(6%)')
        v-caption(
          size='6' 
        ) ${{ vendor.price | toUSD }}
      +e.TD.cell(style='width: 9%')
        ui-accordion(
          v-slot='togglerProps'
        )
          row(alignment='center' offset space='xs')
            cell(cols='auto') 
              row(nowrap alignment='center' offset space='none')
                cell(cols='8')
                  input(
                    type='search' 
                    :disabled='!togglerProps.activeState' 
                    v-model='vendor.fee'
                    style='border: none; width: 100%; background: transparent;height: 24px;padding-left: 6px;'
                    :style='{border: isFeeValid ? "1px solid transparent" : "1px solid red"}'
                    ref='input'
                  )
                cell(cols='narrow')
                  v-caption(size='6') %
            cell(cols='narrow')
              v-button(
                variant='clear'
                type='button'
                @click.stop='toggleFee(vendor, togglerProps.toggleActive, togglerProps.activeState) '
                v-if='!togglerProps.activeState'
              )
                v-button-element
                  icon(
                    :type='togglerProps.activeState ? "check" : "pencil"' 
                    :color='togglerProps.activeState ? "success" : "default"'
                  )
              +b.A.link--success.--variant_underline(
                href='#'
                @click.prevent='toggleFee(vendor, togglerProps.toggleActive, togglerProps.activeState)' 
                v-else
              )
                v-caption(
                  style='cursor: pointer;'
                ) {{ _('save') }}
      
      +e.TD.cell
        v-caption(
          size='6' 
          :color='stat.id ? "success" : "danger"' 
        ) ${{ calculate(vendor) | toUSD }}
      +e.TD.cell.--bordered_right_top.--bordered_right_bottom
        control-select(
          :options='$options.statuses'
          trackBy="id",
          label="title"
          v-model='stat'
          :allow-empty="false"
          @input='changeStatus(vendor.statistic_id)'
          :styling='stat.id ? "success" : "danger"'
        ).control-select.control-select--bg_relief-3.control-select--variant_regular
</template>

<script>
import VendorsService from '@cabinet/services/api/vendors'
import { useResponse } from '@cabinet/composables/use-response'

const setStatuses = (Vue) => {
  return [
    {
      title: Vue._('not paid'),
      id: false,
    },
    {
      title: Vue._('paid'),
      id: true,
    }
  ]
}
export default {
  statuses: [],
  
  props: {
    show: {
      required: true,
      type: Number
    },
    vendor: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      stat: {}
    }
  },
  computed: {
    isFeeValid() {
      return parseFloat(this.vendor.fee) <= 100
    }
  },
  
  methods: {
    calculate(vendor) {
      const value = this.vendor.price / 100 * this.vendor.fee
      return value
    },
    async toggleFee(vendor, cb, state) {
      if (state) {
        try {
          await this.changeFee(vendor)        
          cb()
        } catch (error) {
          
        }
      } else {
        cb()
        await this.$nextTick()
        this.$refs.input.focus()
      }
    },
    async changeFee(vendor) {
      if (!this.isFeeValid) {
        return Promise.reject(false)
      }
      try {
        await useResponse(
          VendorsService
            .changeFee({ 
              show: this.show, 
              vendor: vendor.id, 
              fee: vendor.fee 
            })
        )
        return Promise.resolve(true)
      } catch (error) {
        console.error(error)
        return Promise.reject(false)
      }
    },
    async changeStatus(id) {
      try {
        await useResponse(VendorsService.changeStatus(id), this, null)
      } catch (error) {
        console.error(error)
      }
    }
  },
  created() {
    this.$options.statuses = setStatuses(this)
    this.stat = this.$options.statuses.find(s => s.id === this.vendor.is_paid)
  }
}
</script>