<template lang="pug">
  include /mixins
  card(:variant='["tour"]')

    card-panel(:variant='["img-wrapper"]')
      +b.aspect-ratio--cuted.--ratio_16x10
        //- .--ratio_4x3-md
        +e.IMG.element(:src='event.preview')
      transition(name='fade')
        card-label(:position='["bottom","left"]' v-if='event.status')
          v-tag(:type='event.status.type')
            row(offset alignment='center')
              cell(cols='narrow')
                icon(:type='event.status.icon' size='md' color='brand-2')
              cell(cols='narrow')
                v-caption(
                  size='6' 
                  color='white' 
                  variant='regular'
                ) {{ event.status.title }}
      transition(name='fade')
        card-label(:position='["bottom","right"]' v-if='event.type')
          v-tag(type='brand')
            row(offset alignment='cener')
              cell(cols='narrow')
                v-caption(
                  size='6' 
                  color='brand-4' 
                  variant='regular'
                ) {{ event.type.title }}
    card-panel(offset='full')
      row(offset)
        cell(cols='12')
          v-caption(size='5' variant='bold') {{ event.title }}
      row(offset alignment='center')
        
        cell(cols='8')
          row(offset alignment='center')
            cell
              icon(
                type='calendar'
                color='warning'  
                size='lg'
              )
            cell(cols='auto')
              v-caption(
                size='6', 
                variant='secondary'
                color='brand-4'
              ) {{ event.start_at | humanDate }} - {{ event.finish_at | humanDate }}
        cell(cols='narrow' justification='right')
          v-caption(
            size='6' 
            variant='regular'
            color='default'
          ) {{ _('Events:')}} {{ event.shows }}
    card-panel(variant='footer')
      row(offset space='none')
        +b.cols--6
          v-button(
            :variant='["clear", "block"]' 
            hover='default'
            tag='router-link'
            :to='{name: "create-show", params: { id: event.id }}'
          )
            v-button-element
              v-caption(
                color='default'
                size='5'
                :variant='["uppercase", "bold"]'
              ) {{ _('e add show') }}
        +b.cols--4
          v-button(
            :variant='["clear", "block"]' 
            hover='success' 
            border='horizontal'
            size='5'
            tag='router-link'
            :to='{name: "view-show-events", params: {id: event.id}}'
          )
            //- :to='"/account/events/"+event.id+"/all-shows/"'
            v-button-element
              v-caption(
                color='success' 
                :variant='["uppercase", "bold"]'
              ) {{ _('e edit') }}
        +b.cols--2
          v-button(
            size='5'
            :variant='["clear", "block"]' 
            hover='danger'
            @click='modalTrigger'
          )
            v-button-element
              icon(
                type='close'
                color='danger'
                size='xs'
              )
</template>

<script>
import { mapActions } from 'vuex'
import { eventStatuses } from '@cabinet/components/utils/types'
import { useOpenTourModal } from '@cabinet/composables/use-tour-modal'

import UiInfo from '@cabinet/components/modals/UiInfo'
import UiDelete from '@cabinet/components/modals/UiDelete'

export default {
  props: {
    event: {
      required: true,
    },
    states: {
      required: true
    }
  },
  watch: {
    event: {
      handler(nval, oval) {
        if (nval.status != oval.status) {
          nval.status = oval.status
        } 
        if (nval.type != oval.type) {
          nval.type = oval.type
        }
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions('eventsModule', [
      'deleteEvent'
    ]),
    async deleteEvent_(id) {
      if (id) {
        try {
          this.modalDeleteing()
          this.$emit('loading', {state: true, resp: false})
          await this.deleteEvent(id)
          this.$emit('loading', {state: false, resp: true})
          this.$modal.hide('ui-info')
          this.$emit('close')
        } catch (err) {
          console.error(err)
          this.$modal.hide('ui-info')
          this.$emit('loading', {state: false, resp: false})
        }
      } else {
        return Promise.reject()
      }
    },
    modalDeleteing() {
      this.$modal.show(
        UiInfo,
        {
          text: this._('Process deleting, please wait'),
        },
        {
          minHeight: 125,
          maxHeight: 125,
          adaptive: true,
          width: 310,
          name: "ui-info"
        }
      )
    },
    modalTrigger() {
      return this.$modal.show(
        UiDelete,
        {
          fn: this.deleteEvent_,
          itemID: this.event.id,
          type: this._('event'),
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }
      )
    },
    editTour(id) {
      useOpenTourModal(id, this)      
    }
  },
  created() {
    this.event.status = {
      ...this.states.eventStatuses.find(s => s.id === this.event.status || s.id === this.event.status.id)
    }
    let status = eventStatuses[this.event.status.id]

    this.event.status.type = status.type
    this.event.status.icon = status.icon
    this.event.type = this.states.eventTypes.find(s => s.id === this.event.type || s.id === this.event.type.id)
  }
}
</script>



