<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    tag='form'
    v-slot='{invalid}'
    autocomplete='off'
  )
    row(offset)
      cell(cols='12')
        ui-toggler(type='checkbox'
                  v-model='active'
                  :val='active'
                  @input='checkStatus()'
                  name='active')
          template(v-slot:append='') 
            row(offset space='xs' alignment='center')
              cell(cols='narrow')
                v-caption(size='5' variant='bold') {{ _('Two-factor authentication') }}
              cell(cols='narrow')
                v-tag(:type='active ? "success" : "warning"') {{ active ? _('ACTIVE') : _('Inactive') }}
      cell(cols='12')
        v-caption(size='6') {{ _('Two-factor authentication is a security feature that helps protect your account in addition to password protection.  With the configured two-factor authentication, you will have to enter a special security code each time you try to access the account on our site.  To enable or control two-factor authentication, you need to activate this item, and then select the method that is convenient for you to obtain the verification code.') }}

    transition(name='fade' mode='in-out')
      row(
        offset
        v-if='active'
      )
        //- +e.control--100
        //-   ui-radio(type='radio'
        //-           v-model='auth'
        //-           val='email'
        //-           name='auth'
        //-           :value='auth'
        //-           validate='required')
        //-     template(v-slot:text='') {{ _('Email') }}
        //- +e.control--100
        //-   ui-radio(type='radio'
        //-           v-model='auth'
        //-           val='google'
        //-           name='auth'
        //-           :value='auth'
        //-           validate='required')
        //-     template(v-slot:text='') {{ _('Google Authenticator') }}
        cell(cols='12')
          //- transition(name='fade' mode='in-out')
          //-   ui-email-auth(v-if='auth === "email"')
          //-     template(v-slot:title='') {{ _('Двухфакторная аутентификация через email') }}
          //-     template(v-slot:subtitle='') {{ _('Two-factor authentication email') }}
          transition(name='fade' mode='in-out')
            ui-qr(v-if='active', :is-active='twoFactorStatus')
              template(v-slot:title='')
                v-caption(
                  size='6' 
                  variant='bold'
                ) {{ _('Двухфакторная аутентификация через qr') }}
              template(v-slot:subtitle='')
                v-caption(
                  size='6' 
                  variant='bold'
                ) {{ _('Two-factor authentication qr') }}

        
</template>

<script>
// import UiEmailAuth from '@cabinet/components/forms/UiEmailAuth'
import UiQr from '@cabinet/components/forms/UiQr'
import UiToggler from '@cabinet/components/forms/blocks/UiToggler'
import UiRadio from '@cabinet/components/forms/blocks/UiRadio'
import UiTokenConfirm from '@cabinet/components/modals/UiTokenConfirm'

import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  components: {
    UiRadio,
    UiToggler,
    // UiEmailAuth,
    UiQr
  },
  data() {
    return {
      active: false,
      trigger: false
    }
  },
  computed: {
    ...mapState('userModule', [
      'twoFactorStatus'
    ])
  },
  methods: {
    ...mapActions('userModule', ['getQr', 'updateSettings']), 
    ...mapMutations('userModule', ['SET_TWO_FACTOR_STATUS']),
    setValue(val) {
      val ? this.active = true : false
    },
    checkStatus() {
      if (!this.active && this.twoFactorStatus) {
        let pr = arg => {
          return new Promise((resolve, reject) => {
            resolve(arg)
            reject("error")
          }).then(res => {
            this.trigger = true
            this.SET_TWO_FACTOR_STATUS(false)
          }, err => {
            this.active = true
          })
        }
        this.modalTrigger(pr)
      }
    },
    modalTrigger(promise) {
      //('woot')
      return this.$modal.show(
        UiTokenConfirm,
        
        {
          promise: promise
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        }, {
          closed: (event) => {
            if (!this.trigger) {
              this.active = true
              this.SET_TWO_FACTOR_STATUS(true)
            }
          }
        }
      )
    },
  },
  mounted() {
    this.getQr().then(res => {
      if (res.is_active) {
        this.active = true
      } else {
        this.active = false
      }
    })

  }
}
</script>

