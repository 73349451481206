<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    my-upload(field="avatar"
          @crop-success="cropSuccess"
          :width="width || 150"
          :height="height || 150"
          img-format="jpg/jpeg/png/svg", 
          method='PATCH'
          :lang-type="lang",
          v-show='show'
          )
</template>

<script>
import myUpload from 'vue-image-crop-upload'
export default {
  components: {
    myUpload
  },
  name: "Cropper",
  props: ["promise", 'width', 'height'],
  data() {
    return {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRFToken": this.getCookie("csrftoken")
      },
      show: true,
      lang: document.documentElement.lang
    }
  },
  methods: {
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          `(?:^|; )${name.replace(
            /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
            "\\$1"
          )}=([^;]*)`
        )
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    cropSuccess(imgDataUrl, field) {
      
      this.userImage = imgDataUrl
      this.$emit("close")
      return this.promise(this.userImage)
    },
  }
}
</script>

