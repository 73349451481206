<template lang="pug">
  include /mixins
  card-panel
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      @submit.prevent='() => null'
      autocomplete='off'

    )
      row(
        offset
        alignment='center'
      )
        cell(cols='12 6-sm 4-lg 3-xl 2-2xl')
          .form__control
            label.form__label 
              flat-pickr(v-model='date'
                        :val='date'
                        :config='config'
                        name='date'
                        :placeholder="_('Date from - Date to')").flatpickr-input--size_lg.flatpickr-input--border_full

        cell(cols='12 6-sm 4-lg 2-xl')
          v-select(
            v-model='form.type'
            :val='form.type'
            :options='types'
            :placeholder="_('s types')"
            size='lg'
            name='type'
          )

        cell(cols='6 narrow-md 2-xl')
          +b.control-box
            v-button(
              variant='warning-lite'
              size='lg'
              border='whole'
              @click='fetch'
            )
              cell(cols='auto')
                v-button-element(size='lg') 
                  v-caption(
                    size='6'
                    :variant='["bold", "uppercase"]' 
                  ) {{ _('f apply') }}
        cell(cols='6 narrow-md 2-xl')
          +b.control-box
            v-button(
              size='lg'
              border='whole'
              @click='reset'
            )
              cell(cols='auto')
                v-button-element(size='lg') 
                  v-caption(
                    size='6'
                    :variant='["bold", "uppercase"]' 
                  ) {{ _('f clear') }}
        slot(name='link')
        cell(justification='right-sm')
          pagination(:pagination='meta' @set='$emit("fetch", {page: $event, ...form})')
          
    
</template>

<script>
import Pagination from '@cabinet/components/vendor/Pagination'
import FlatPickr from 'vue-flatpickr-component'
export default {
  components: {
    Pagination,
    FlatPickr,
  },
  watch: {
    date(values) {
      if (values) {
        let val = values.split(' to ')
        if (val.length > 1) {
          let created_at__gte = val[0].replace(/\//g, '-').split('-')
          let created_at__lte = val[1].replace(/\//g, '-').split('-')

          created_at__gte = [
            created_at__gte[2],
            created_at__gte[0],
            created_at__gte[1]
          ].join('-')
          created_at__lte = [
            created_at__lte[2],
            created_at__lte[0],
            created_at__lte[1]
          ].join('-')

          this.form.created_at__gte = created_at__gte
          this.form.created_at__lte = created_at__lte
        } else {
          let created_at__gte = values.replace(/\//g, '-').split('-')
          created_at__gte = [
            created_at__gte[2],
            created_at__gte[0],
            created_at__gte[1]
          ].join('-')
          this.form.created_at__gte = created_at__gte
          this.form.created_at__lte = created_at__gte

        }
      }
    },
  },
  data() {
    return {
      date: '',
      form: {
        created_at__gte: '',
        created_at__lte: '',
        type: ''
      },
      config: {
        mode: 'range',
        disableMobile: 'true',
        dateFormat: 'm/d/Y',
      },
    }
  },
  props: {
    meta: {
      required: true,
      default: () => ({
        total: '',
        limit: ''
      })
    },
    types: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  methods: {
    reset() {
      this.date = ''
      this.form = {
        created_at__gte: '',
        created_at__lte: '',
        type: ''
      }
      this.fetch()
    },
    fetch() {
      let data = { ...this.form }
      data.type = data.type.id || ''
      this.$emit("fetch", data)
    }
  },
}
</script>