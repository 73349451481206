<template lang='pug'>
  include /mixins  
  row(offset)
    cell(cols='12')
      slot(name='title') {{ _('Двухфакторная аутентификация через e-mail') }}
    cell(cols='12')
      slot(name='subtitle') {{ _('Two-factor authentication email') }}
    cell(cols='12')
      row(offset v-if='active')
        cell(cols='12')
          v-caption(size='6') {{ _("Congratilation two-factor authentication qr is active. Now, when you login, you need to enter a code from Google Authenticator app") }}
    cell(cols='12')
      validation-observer(
        ref='observer'
        tag='form'
        v-slot='{invalid}'
        @submit.prevent='send'
        autocomplete='off'
      )
        row(offset v-if='!active')
          cell(cols='12 4-md')
            vue-qrcode(
              :value='link.totp_auth_url', 
              :options='{width: "100%", margin: 1}', 
              tag='img'
            )
          cell(cols='12 8-md')
            row(offset)
              cell(cols='12 8-md')
                ui-input(
                  :placeholder="_('Pin-code')"
                  :val='formData.pincode'
                  type='search' v-digitsonly=''
                  name='token'
                  v-model='formData.pincode'
                  size='lg'
                  bg='relief-3'
                )
              cell(cols='12 4-md')
                v-button(
                  variant='success'
                  type='submit'
                )
                  v-button-element(size='lg') 
                    v-caption(variant='bold') {{ _('se Save') }}
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import { successHandler, errorHandler } from '@cabinet/util'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  components: {
    UiInput,
    VueQrcode,
  },
  props: {
    isActive: {
      type: Boolean, 
      default: false
    }
  },
  computed: {
    ...mapState('userModule', ['link']),
  },
  data() {
    return {
      formData: {
        pincode: '',
      },
      active: this.isActive
    }
  },
  methods: {
    ...mapActions('userModule', ['updateSettings']),
    ...mapMutations('userModule', ['SET_TWO_FACTOR_STATUS']),
    async send() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        
        this.updateSettings({token: parseInt(this.formData.pincode)})
          .then(res => {
            successHandler(res.data, this)
              .then(res => {
                this.formData.pincode = ''
                this.active = true
                this.SET_TWO_FACTOR_STATUS(true)
              })
          })
          .catch(err => {
            //(err)
            let { data } = err.response
            errorHandler(data.errors, this.$refs.observer, err.response, this)
          })
      }
    },
  },
  mounted() {
    
  },
}
</script>

