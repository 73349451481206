<template lang="pug">
  include /mixins
  card-panel
    loading-wrapper(:loading='loading')
      +b.scrollable-wrapper--till-2xl.--full(v-if='event.show')
        +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
          +e.THEAD.thead
            +e.TR.row--relief
              +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom(width='23%')
                v-caption(color='white') {{ _('s Tour name') }}
              +e.TH.cell--variant_bold.--head.--bordered_right(width='23%')
                v-caption(color='white') {{ _('s Ushers') }}
              +e.TH.cell--variant_bold.--head(width='9%') 
                v-caption(color='white') {{ _('s Tickets (qty)') }}
              +e.TH.cell--variant_bold.--head(width='9%') 
                v-caption(color='white') {{ _('s Amount') }}
              +e.TH.cell--variant_bold.--head(width='9%') 
                v-caption(color='white') {{ _('s Sold tickets (qty)') }}
              +e.TH.cell--variant_bold.--head(width='9%')
                v-caption(color='white') {{ _('s Sold tickets') }}
              +e.TH.cell--variant_bold.--head(width='9%')
                v-caption(color='white') {{ _('s Tickets in sale') }}
              +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom(width='9%')
                v-caption(color='white') {{ _('s Scans') }}
          +e.TBODY.tbody
            +e.TR.row
              +e.TD.cell(width='23%')
                v-caption(
                  size='6'
                  color='default'
                ) {{ event.title }}
              +e.TD.cell--bordered_right(width='23%')
              +e.TD.cell(width='9%')
                v-caption(
                  size='6'
                  color='default'
                ) {{ event.seats_amount }}
              +e.TD.cell(width='9%')
                v-caption(
                  size='6'
                  color='default'
                ) $ {{ event.total_price | toUSD }}
              +e.TD.cell(width='9%')
                v-caption(
                  size='6'
                  color='default'
                ) {{ event.tickets_amount }}
              +e.TD.cell(width='9%')
                v-caption(
                  size='6'
                  color='default'
                ) $ {{ event.sold_price | toUSD }}
              +e.TD.cell(width='9%')
                v-caption(
                  size='6'
                  color='default'
                ) $ {{ event.unsold_price | toUSD }}
              +e.TD.cell--bordered_right_top.--bordered_right_bottom(width='9%')
                v-caption(
                  size='6'
                  color='default'
                ) {{ event.scaned_tickets }}
            +e.TR.row(v-for='usher in event.ushers' :key='usher.id')
              +e.TD.cell(width='23%')
              +e.TD.cell--bordered_right(width='23%')
                v-caption(
                  size='6'
                  color='default'
                ) {{ usher.first_name  }} {{ usher.last_name}}
              +e.TD.cell(width='9%')
              +e.TD.cell(width='9%')
              +e.TD.cell(width='9%')
              +e.TD.cell(width='9%')
              +e.TD.cell(width='9%')
              +e.TD.cell--bordered_right_top.--bordered_right_bottom(width='9%')
                row(alignment='center' space='5xs')
                  cell
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ usher.amount }}
                  cell(v-if='usher.is_invalid')
                    icon(
                      type='error' 
                      color='warning' 
                      size='xs'
                    )
      card-panel(
        v-else 
        variant='border' 
        offset='full'
      )
        notice
          template(v-slot:icon='')
            icon(
              type='error' 
              color='warning' 
              size='5xl'
            )
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('Show doesn\'t exist')}}

</template>

<script>
import StatsService from '@cabinet/services/api/stats'
import { ref, onMounted } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  setup(props, context) {
    let { id } = context.root.$route.params
    const event = ref({})
    const loading = ref(false)
    const fetchEvent = async (data) => {
      loading.value = true
      try {
        let res = await useResponse(StatsService.getShowStatistics(id), context.parent)
        if (res.data.show) {
          event.value = res.data
          context.root.$route.meta.show = res.data.id
        } else {

        }
        loading.value = false
      } catch (err) {
        console.error(err)
        loading.value = false
        console.error(err)
      }
    }

    onMounted(async () => {
      await fetchEvent()
    })

    return {
      event,
      loading,
      
    }
  }

}
</script>