<template lang='pug'>
  include /mixins
  row(offset)
    cell(cols='12')
      validation-observer(
        ref='observer'
        v-slot='{invalid}'
        tag='form'
        autocomplete='off'
      )
        row(offset)
          cell(cols='12')
            row(offset)
              cell(cols='12 8-md 6-2xl')
                v-caption(size='4' variant='bold') {{ _('Set a price:') }}
              cell(cols='12 4-md')
                v-select(
                  v-model='preset'
                  :val='preset'
                  :placeholder='_("Choose preset")'
                  :options='presets'
                  :name='"choose preset"'
                  bg='relief-3'
                  size='lg'
                  border='whole'
                  @input='setPreset'
                ).form__control--small.multiselect--tiny

          cell(cols='12' ref='dupes')
            validation(name='dupes')
          cell(cols='12')
            row(offset)
              cell(cols='12 8-lg 6-2xl' v-if='sectors.length')
                row(offset)
                  cell(cols='narrow')
                    v-button(
                      @click='addTicket'
                      type='button'
                      variant='clear'
                    )
                      cell(cols='narrow')
                        v-button-element() 
                          v-caption(
                            size='4' 
                            :variant='["bold"]'
                            color='default'
                          ) {{ _('add') }}
                      template(v-slot:append='')
                        cell(justification='center')
                          v-button-element 
                            icon(
                              type='add'
                              color='success'
                            )                    
                //- ui-button(@click.prevent=''
                //-           btn-class='btn--flex btn--center').btn-wrapper--tiny
                //-   +b.btn__icon--success.icon-big_plus.mr-1
                //-   +b.btn__text--default {{ _('add') }}
                +b.scrollable-wrapper--vertical.--half(v-if='sectors.length && colors.length')
                  card
                    form-repeater(v-model='form.price_descriptor' :disable-remove='true')
                      //- card-panel(
                      //-   offset='vertical'
                      //-   v-for='(ticket, index) in form.price_descriptor'
                      //-   :key='ticket.id || index'
                      //- )
                      template(#default="repeater")
                        tickets-layout(
                          :ref='"ticket_"+repeater.index'
                          :form='form'
                          :ticket='repeater.value'
                          :key='repeater.index'
                          :sectors='[...sectors]'
                          @loaded='calcLoaders'
                          @input='assigned = $event'
                          @remove='removeTicket'
                          :colors='colors'
                          :index='repeater.index'
                          :observer='$refs.observer'
                          @validate='validate'
                        )

              cell(cols='12 4-lg')
                +b.file-input__img-wrapper
                  cell(cols='12' v-if='form.hall && !form.hall.has_schema')
                    row(offset)
                      cell(cols='12')
                        v-caption(size='6' variant='bold') {{ _('This hall has no schema')}}
                      transition(name='fade')
                        cell(cols='12' v-if='form.hall_image')
                          img(:src='form.hall_image')
                  +b.file-input__svg--fullheight.svg(v-else, ref='svg', :style='{height: height+"px", width: "100%"}').driver
                row(
                  alignment='center' 
                  column 
                  justification='between'
                )
                  cell(cols='12 10-md 6-2xl')
                    row(offset justification='between')
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='bold'
                        ) {{ _('total count: ')}} 
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='regular'
                        ) {{ totalSeats }} {{ _('seats') }}
                  cell(cols='12 10-md 6-2xl')
                    row(offset justification='between')
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='bold'
                        ) {{ _('assigned: ')}}
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='regular'
                        ) {{ total.assigned }} {{ _('seats') }}
                    
                  cell(cols='12 10-md 6-2xl')
                    row(offset justification='between')
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='bold'
                        ) {{ _('assigned: ')}}
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='regular'
                        ) {{ totalInPercent }} %

                  cell(cols='12 10-md 6-2xl')
                    row(offset justification='between')
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='bold'
                        ) {{ _('total sum: ')}}
                      cell(cols='narrow')
                        v-caption(
                          size='5' 
                          display='md'
                          variant='regular'
                        ) {{ total.sum | toUSD }} $
              
    cell(cols='12')
      validation-observer(
        ref='presetObserver'
        v-slot='{invalid}'
        tag='form'
        autocomplete='off'
      )
        row(offset data-vv-scope="form-1")
          cell(cols='narrow')
            row(alignment='center' space='md')
              cell(cols='narrow')
                v-checkbox
                  +b.INPUT(
                    type="checkbox"
                    name='save preset'
                    v-model='isSavePreset'
                  ).control-checkbox__input
              cell(cols='narrow')
                v-caption(size='6' variant='regular') {{ _('save preset?') }}
        
        row(offset v-if='isSavePreset')
          cell(cols='narrow')
            ui-input(type='search'
                    v-model='title'
                    :val='title'
                    bg='relief-3'
                    size='lg'
                    validate='required'
                    name='title'
                    :placeholder='_("preset name")').form__control--styled
          cell(cols='narrow')
            v-button(
                @click='savePreset("form-1")'
                type='button'
                variant='success'
              )
                cell(justification='center')
                  v-button-element(size='lg') 
                    v-caption(size='6' :variant='["bold", "uppercase"]') {{ _('save preset') }}

</template>

<script>
import axios from 'axios'
import HallService from '@cabinet/services/api/hall'
import HelpersService from '@cabinet/services/api/helpers'
import { mapActions } from 'vuex'
import { successHandler, errorHandler } from '@cabinet/util'
import TicketsLayout from '@cabinet/components/show/TicketsLayout'
function checkIfDuplicateExists(w) {
  return new Set(w).size !== w.length 
}
export default {
  components: {
    TicketsLayout
  },
  props: {
    form: {
      required: true,
    },
  },
  watch: {
    'form.price_descriptor': {
      immediate: true,
      deep: true,
      handler(nval) {
        this.total = nval.reduce(
          this.reducePrice,
          {
            assigned: 0,
            sum: 0,
          }
        )

      },
    },
  },
  computed: {
    totalSeats() {
      return this.sectors.reduce((acc, s) => {
        acc += s.total_seats
        return acc
      }, 0)
    },
    totalInPercent() {
      return ((this.total.assigned * 100) / this.totalSeats).toFixed(2) || 0
    },
  },
  data() {
    return {
      total: {
        assigned: 0,
        sum: 0,
      },
      loading: true,
      height: '300',
      formData: {},
      sectors: [],
      assigned: 0,
      isSavePreset: false,
      preset: null,
      title: '',
      presets: [],
      colors: [],
      loaders: 0
    }
  },
  methods: {
    /**
     * возвращает кол-во занятых мест в данном 
     * секторе по всем дескрипторам
     * @param { Object } item - конкретный TicketInfo в конкретном price_descriptor'e
     * */ 
    assembleDescriptor(item) {
      let r = JSON.parse(JSON.stringify(this.form.price_descriptor))
      return r
        .map(d => {
          if (this.withdraw) {
            return d.info.filter(sector => sector.sector.id === item.sector.id)
          }
          return d.sectors.filter(sector => sector.sector === item.sector.id)
        })

        .flat(Infinity)
        .reduce((acc, desc) => {
          acc.totals_seats += this.withdraw ? parseFloat(desc.amount) || 0 : parseFloat(desc.totals_seats)
          acc.id = this.withdraw ? desc.sector.id : desc.sector
          return acc
        }, {
          totals_seats: 0
        })
    },
    /**
     * @param { Object } seat - конкретный дескриптор из списка дескрипторов
     * @param { int } index - 
     * @param { int } idx 
    */
    async validateSector(seat, index, idx) {
      const item = this.form.price_descriptor[index].info[idx]
      const initialSector = this.withdraw ? item.sector : this.sectors.find(s => s.id === item.sector.id)
      const descriptor = this.assembleDescriptor(item)
      const alpha = this.withdraw ? initialSector.available_places : initialSector.total_seats
      // сравнение занятых мест в данном секторе по всем 
      // дескрипторам с фактической вместительностью сектора
      if (alpha < descriptor.totals_seats) {
        this.$refs.observer.setErrors({
          [`${index}seat_block${idx}`]: [this._(`Descriptor seats count is too high`)]
        })
          
      } else {
        this.$refs.observer.setErrors({
          [`${index}seat_block${idx}`]: []
        })
      }
    },
    async validateSeats(seats, index, idx) {
      // проверка на предмет дубликатов мест 
      // в различных дескрипторах
      let s = JSON.parse(JSON.stringify(seats))
      const seatsDupes = s.flat(Infinity).length != new Set([...seats.flat(Infinity)]).size
      if (seatsDupes) {
        this.$refs.observer.setErrors({
          [`${index}seat_block${idx}`]: [this._(`This field has intersected values`)]
        })
      }
    },
    /**
     * изменяет количество мест в дескрипторе
     * @param { Object } descriptor - элемент массива price_descriptors
     * */ 
    changeDescriptorSectorSeatsCount(descriptor, index, idx) {
      const descriptorFiltered = descriptor.info
        .filter(s => s.sector.type == 'no_seats')
        .map(s => {
          return { 
            sector: s.sector.id, 
            totals_seats: s.sector.totals_seats || s.sector.total_seats 
          } 
        })
      this.$set(this.form.price_descriptor[index], 'sectors', descriptorFiltered)
    },
    async validate({ seat, index, idx }) {

      let idxParam = idx
      await this.$nextTick()
      let f = JSON.parse(JSON.stringify(this.form.price_descriptor))
      let { seats } = f.reduce((acc, descriptor, idx) => {
        
        if (descriptor.seats && descriptor.seats.length) {
          const items = descriptor.info.filter(i => i.seats).map(i => {
            return i.seats.map(s => s.id)
          })
          acc.seats.push(items.flat(Infinity))
        }
        // МАГИЯ которую я не смог вынести
        // вынести не проблема - проблема в том, чтобы не запускать повторно итерацию
        this.changeDescriptorSectorSeatsCount(descriptor, index, idxParam)
        return acc
      }, {
        seats: [],
      })
      this.$refs.observer.reset()
      // если текущий рядок в price_descriptor'e является стоячим сектором или дробной 
      // частью стоячего сектора, то запускается механизм проверки -
      // не назначено ли мест больше чем положено
      if (seat.sector) {
        this.validateSector(seat, index, idx)
      } else {
        // иначе делается проверка мест на пересечение
        this.validateSeats(seats, index, idx)
      }
    },
    // reducer собирающий общее кол-во назначеных мест и иготовую сумму их стоимости
    reducePrice(acc, item) {
      acc.sum += item.info
        .map(({sector}) => {
          acc.assigned += parseFloat(sector.totals_seats) || 0
          return sector.totals_seats ? sector.totals_seats * parseFloat(item.price) : 0
        })
        .reduce((accValue, price) => accValue += price, 0)
      if (item.info && item.info.length) {
        const seats = item.info.reduce((acc, seat) => {
          return acc += seat.seats.length || 0
        }, 0)
        const sum = parseFloat(item.price) ? seats * parseFloat(item.price) : 0
        acc.sum += sum
        acc.assigned += parseFloat(seats)
      }
      return acc
    },
    calcLoaders(event) {
      this.loaders++
      this.$emit('loading', false)
    },
    /**
     * Добавляет в массив дескрипторов 
     * изначальный пустой декскриптор
     * и скроллит к нему
    */
    addTicket() {
      this.form.price_descriptor.push({
        title: '',
        price: '',
        color: '',
        info: [
          {
            sector: {},
            row: {},
            seats: [],
          },
        ],
      }, )
      // this.$nextTick().then(() => {
      //   this.$refs[`ticket_${this.form.price_descriptor.length - 1}`][0].$el.scrollIntoView({
      //     behavior: 'smooth'
      //   })
      // })
    },
    /**
     * Если есть пресет распоясовки зала то заменяет 
     * все назначеные поля в дескрипторах на значения из пресета
     * TODO - бажит, нужно блокировать возможность 
     * активации в случае если у нас есть хоть один декскриптор 
     * с id из БД, чтобы не давать пользователю изменять его, т.к. это влечет за собой 
     * множество различных багов с назначением цен
     * */ 
    setPreset({ data }) {
      if (data) {
        this.form.price_descriptor = data
      }
    },
    /**
     * Сохранение пресета в БД без назначеных в дескрипторах цен 
     * */ 
    async savePreset(scope) {
      const isValid = await this.$refs.presetObserver.validate() 
      if (isValid) {
        let descriptor = [...this.form.price_descriptor]
        descriptor.forEach(d => (d.price = null))
        HallService.createPreset({
          title: this.title,
          data: descriptor,
          hall: this.form.hall.id,
        })
          .then(res => {
            if (!res.data.meta.title) {
              res.data.meta.title = this._('preset created')
              res.data.meta.content = this._('preset created successfuly')
            }
            successHandler(res.data, this)
            this.isSavePreset = false
            this.title = ''
          })
          .catch(err => {
            errorHandler(data.errors, this)
          })
      }
    },
    /**
     * Сохранение шоу, триггерится из компонента-контроллера(см. Structure.md).
     * Переиспользуется во всех компонентах-этапах 
     * создания шоу с некоторыми особенностями на каждом их этапов
     * @returns { Promise }
     *          со статусом валидности состояния компонента
     **/ 
    async save() {
      const errors = this.$refs.observer.errors
      const errorsList = Object.keys(errors).filter(k => errors[k].length)
      
      const hasErrors = errorsList.length
      const hasDupes = this.form.price_descriptor.some(d => d.ga && d.sectors && checkIfDuplicateExists(d.sectors.map(s => s.sector)))

      if (hasDupes) {
        this.$refs.observer.setErrors({
          dupes: [this._('Some of the descriptors has duplicates. Please check your input')]
        })
        const item = this.$refs.dupes
        item.scrollIntoView({ behavior: 'smooth' })
        return Promise.reject({
          valid: false,
          msg: `${this._('You shall not pass!!!')}`,
        })
      }

      if (errorsList.length) {
        console.log(errorsList)
        const item = this.$refs.observer.$el.querySelector(`[name='${errorsList[0]}']`)
        item.scrollIntoView({ behavior: 'smooth' })
      }
      if (!hasErrors) {
        const isValid = await this.$refs.observer.validate() 
        if (isValid) {
          return Promise.resolve({
            valid: true,
            msg: `${this._('You shall pass!!!')}`,
          })
        }
        // const errors = this.$refs.observer.errors
      }
      return Promise.reject({
        valid: false,
        msg: `${this._('You shall not pass!!!')}`,
      })
    },
    /**
     * Получение файлов секторов и сидений 
     * зала данного шоу
    */
    async placeSvg({ sectors_file, seats_file } = this.formData) {
      if (sectors_file && seats_file) {
        let [sectorData, seatsData] = await Promise.all([
          axios.get(sectors_file),
          axios.get(seats_file),
        ])
        let sectorSvg = sectorData.data
        let seatsSvg = seatsData.data
        if (sectorSvg && seatsSvg) {
          this.$refs.svg.insertAdjacentHTML('beforeend', sectorSvg)
          this.$refs.svg.insertAdjacentHTML('beforeend', seatsSvg)
          this.height = this.$refs.svg
            .querySelector('svg')
            .getBoundingClientRect().height
        }
      }
    },
    ...mapActions('hallModule', [
      'getSectorsList',
      'fetchHall',
      'getSectorData',
    ]),
    /**
     * удаляет сектора и места, если их 
     * нет в списке выбраных на 2-ом этапе*/ 
    setSectors() {
      if (this.form.hall && this.form.hall.has_schema) {
        let allItems = [...this.$refs.svg.querySelectorAll('circle')]
        let items = []
        this.sectors.forEach(sector => {
          if (sector.type == 'no_seats') {
          } else {
            let item = this.$refs.svg.querySelector(
              `[data-title='${sector.title}']`
            )
            if (item) {
              item.remove()
            }
            if (sector.row.length) {
              sector.row.forEach(row => {
                if (row.seat && row.seat.length) {
                  row.seat.forEach(seat => {
                    let item = document.getElementById(seat.geom_id)
                    items.push(item)
                  })
                }
              })
            }
          }
        })
        allItems.forEach(value => {
          if (-1 == items.indexOf(value)) {
            value.remove()
          }
        })
      }
     
    },
    /**
     * фильтрует сектора в зале и возвращает 
     * список только выбраных секторов.
     * Так же добавляет в список рядов и сидений доп. значение "все" 
     * для простоты выбора всех рядов и мест
     * @param {Object[]} sectors - список всех доступных секторов в зале
     * */ 
    mapSectors(sectors) {
      const s = sectors.filter(s => s.checked)
      s.forEach(s => {

        let all = { id: 'all', title: this._('all') }

        s.row = s.row.sort((a, b) => {
          if (a.title < b.title) {
            return -1 
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        })
        const isExists = s.row.findIndex(i => i.id === all.id)
        s.row.forEach(r => {
          if (r.seat) {
            r.seat && r.seat.sort((a, b) => a.title - b.title)
          }
        })
        if (isExists > -1) {
          s.row.splice(isExists, 1)
        }
        s.row.unshift(all)
      })
      return s
    },
    /**
     * @param { int } index - индекс удаляемого дескриптора 
     * */ 
    removeTicket(index) {
      this.form.price_descriptor.splice(index, 1)
    },
    /**
     * получает список цветов
     * */ 
    async getColors() {
      this.colors = (await HelpersService.getColors()).data.data
    },
    /**
     * получает пресеты по холу
     * */ 
    async getPresets() {
      this.presets = (await HallService.getPresets(this.form.hall.id)).data.data
    },
    /**
     * получает данные по холу
     * */ 
    async fetchFormData() {
      this.formData = await this.fetchHall(this.form.hall.id)
    },
    /**
     * @returns { Array | undefined } 
     *          секторы, полученные через api || ничего
     * */ 
    async fetchSectors() {
      try {
        if (!this.form.sectors.length) {
          const res = await this.getSectorsList({ id: this.form.hall.id })
          res.data.forEach((s, index) => {
            this.$set(s, 'checked', true)
            this.$set(s, 'disabled', false)
            return s
          })
          return res.data
        }
      } catch (error) {
        console.error(error)
        return 
      }
    },
    /**
     * сортирует сектора
     * @returns { Array } 
     *          секторы отсортированы по названию
     * */ 
    sortSectors() {
      return this.form.sectors.sort((a, b) => {
        if (a.title.localeCompare(b.title)) {
          return -1
        }
        if (b.title.localeCompare(a.title)) {
          return 1
        }
        return 0
      })
    },
  },
  async created() {
    this.$emit('loading', true)
    await this.getColors()
    await this.getPresets()
    await this.fetchFormData()
    const sectors = await this.fetchSectors()
    await this.placeSvg()
    this.sectors = this.mapSectors(sectors || this.form.sectors)
    this.form.sectors = this.sortSectors()
    await this.setSectors()
  },
}

</script>