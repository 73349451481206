<template lang="pug">
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    @submit.prevent='update'
    autocomplete='off'
  )
    row(offset justification='between')
      cell(cols='12 6-md 2-2xl' alignment='center')
        v-caption(
          size='6' 
          variant='bold'
          color='default'
        ) {{ _('Change e-mail') }}
      cell(cols='12 6-md 4-2xl')
        ui-input(
          :placeholder="_('E-mail')"
          bg='relief-3'
          size='lg'
          border='whole'
          type='email'
          name='email'
          :disabled='disabled'
          v-model.trim='email'
          validate='required|email'
        )
      cell(cols='12 6-md 3-2xl')
        v-button(
          @click='toggleDisabled'
          
        )
          v-button-element(size='lg') 
            v-caption(:variant='["bold", "uppercase"]'  size='6') {{ _('edit') }}
      cell(cols='12 6-md 3-2xl')
        v-button(
          variant='success'
          type='submit'
        )
          v-button-element(size='lg') 
            v-caption(:variant='["bold", "uppercase"]'  size='6') {{ _('SAVE') }}
</template>

<script>
import { ref } from '@vue/composition-api'
import { useStoreState } from '@cabinet/composables/use-store'
import UserService from '@cabinet/services/api/user'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  setup(p, { root, refs }) {
    const email = ref(useStoreState(root.$store.state, 'userModule').userData.email)
    const disabled = ref(true)

    const toggleDisabled = () => {
      disabled.value = !disabled.value
    }

    const update = async () => {
      await useResponse(UserService.patchData({ email: email.value }), root, refs.observer)
    }
    return {
      email,
      disabled,

      toggleDisabled,
      update
    }
  }
}
</script>