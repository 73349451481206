<template lang="pug">
  include /mixins
  card(variant='section')
    card-panel(offset='full')
      row(
        offset 
        :justification='["center", "between-lg"]'
        
      )
        cell(cols='12 6-md')
          card-panel(offset='bottom-till-2xl')
            row(offset)
              cell()
                v-caption(
                  size='4'
                  variant='bold'
                  color='danger'
                ) {{ item.title }}
          card-panel(offset='top-2xl' style='--card-space-vertical: 1.4em')
            row(alignment='center' offset :space='["md", "none-2xl"]')
              cell(cols='12 6-2xl')
                row(offset alignment='center')
                  cell(cols='narrow')
                    icon(
                      type='calendar'
                      color='warning'
                    )
                  cell(cols='narrow')
                    v-caption(
                      size='5'
                      variant='bold'
                    ) {{ item.date_at_display }}, {{ item.time_at_display }}
              cell(cols='12 6-2xl')
                row(justification='between' offset)
                  cell(cols='6')
                    row(offset)
                      cell(cols='narrow')
                        v-caption(variant='bold' size='5') {{ _('Place: ') }}
                      cell(cols='narrow')
                        v-caption(size='5') {{ item.hall }}
                  cell(cols='6')
                    row(offset)
                      cell(cols='narrow')
                        v-caption(variant='bold' size='5') {{ _('Invoice not paid:') }}
                      cell(cols='narrow')
                        v-caption(size='5') {{ item.not_paid }}
        cell(cols='12 6-md' alignment='bottom_lg')
          card-panel(offset='top-till-md')
            row(
              justification='between'
              offset
            )
              cell(cols='6 narrow-sm 3-md narrow-2xl')
                a.link(
                  href='#'
                  @click.prevent='ticketAction("download")'
                )
                  row(
                    alignment='center' 
                    offset
                    justification='center'
                    space='xs'
                  )
                    cell(cols='narrow')
                      icon(
                        type='list'
                        size='xl'
                        color='success'
                      )
                    cell(cols='12')
                      v-caption(
                        color='success'
                        size='6'
                        variant='center'
                      ) {{ _('Download tickets')}}
              cell(cols='6 narrow-sm 3-md narrow-2xl')
                a.link(
                  href='#'
                  @click.prevent='ticketAction("print")'
                )
                  row(
                    alignment='center' 
                    offset
                    justification='center'
                    space='xs'
                  )
                    cell(cols='narrow')
                      icon(
                        type='print'
                        size='xl'
                        color='success-lite'
                      )
                    cell(cols='12')
                      v-caption(
                        size='6'
                        variant='center'
                        color='success-lite'
                      ) {{ _('Print tickets')}}
              cell(cols='6 narrow-sm 3-md narrow-2xl')
                a.link(
                  href='#'
                  @click.prevent='downloadInvoice'
                )
                  row(
                    alignment='center' 
                    offset
                    justification='center'
                    space='xs'
                  )
                    cell(cols='narrow')
                      icon(
                        type='download'
                        size='xl'
                        color='danger-lite'
                      )
                    cell(cols='12')
                      v-caption(
                        size='6'
                        variant='center'
                        color='danger-lite'
                      ) {{ _('Download invoice')}}
              cell(cols='6 narrow-sm 3-md narrow-2xl')
                a.link(
                  href='#'
                  @click.prevent='sendInvoiceQr'
                )
                  row(
                    alignment='center' 
                    offset
                    justification='center'
                    space='xs'
                  )
                    cell(cols='narrow')
                      icon(
                        type='download'
                        size='xl'
                        color='danger-lite'
                      )
                    cell(cols='12')
                      v-caption(
                        size='6'
                        variant='center'
                        color='danger-lite'
                      ) {{ _('Download QR')}}
              cell(cols='6 narrow-sm 3-md narrow-2xl')
                a.link(
                  href='#'
                  @click.prevent='sendInvoice'
                )
                  row(
                    alignment='center' 
                    offset
                    justification='center'
                    space='xs'
                  )
                    cell(cols='narrow')
                      icon(
                        type='send'
                        size='xl'
                      )
                    cell(cols='12')
                      v-caption(
                        size='6'
                        variant='center'
                      ) {{ _('Send invoice')}}

</template>


<script>
// !IMPORTANT - HARD TESTABLE COMPONENT
import { successHandler, errorHandler } from '@cabinet/util'
import Preloader from '@cabinet/components/vendor/Preloader'
import axios from 'axios'
import UiPrintTickets from '@cabinet/components/agent/UiPrintTickets'
import UiAgentInvoiceChoice from '@cabinet/components/agent/UiAgentInvoiceChoice'

import AgentService from '@cabinet/services/api/agents'

function downloadCSV(data, filename = 'data.csv') {
  // Convert the data to a blob
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

  // Create a link element
  const link = document.createElement('a');

  // Create a URL for the blob and set it as the href attribute
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);

  // Set the download attribute with the desired file name
  link.setAttribute('download', filename);

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download by simulating a click
  link.click();

  // Clean up by removing the link and revoking the object URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export default {
  props: {
    item: {
      required: true
    }
  },
  components: {
    Preloader
  },
  data() {
    return {
      isLoad: false
    }
  },
  computed: {
    splitIds() {
      let url = this.item.tickets.split('ids=')
      let ids = url[1].split(',')
      return {ids: ids, url: url[0]}
    },
  },
  methods: {
    ticketAction(action) {
      this.$modal.show(
        UiPrintTickets,
        {
          invoices: this.item.invoices,
          action
        },
        {
          height: 'auto',
          adaptive: true,
          classes: ['middle-modal-wrap'],
          scrollable: true,
        }

      )
    },
    downloadInvoice() {
      let pr = arg => { 
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject(new Error('something went wrong'))
        
        }).then(result => {
          window.open(result.print, '_blank')
        })
      
      }
      this.openInvoiceModal(pr)
    },
    async sendInvoiceQr() {
      let pr = arg => { 
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject(new Error('something went wrong'))
        
        }).then(async result => {
          this.isLoad = true
          try {
            const res = (await AgentService.getInvoiceQrcodes({
              id: result.id,
              vendor: this.attrs['agent-id']
            }))
            downloadCSV(res.data, `${result.title}.csv`)
            successHandler({
              meta: {
                title: this._('CSV download is complete. Check the Downloads folder.')
              },
              data: {
                status: 'complete'
              }
            }, this)
            this.isLoad = false
          } catch (error) {
            let { errors } = error.response.data
            this.isLoad = false
            errorHandler(errors, this)
          }
        }, err => {
          this.isLoad = false

        })
      
      }
      this.openInvoiceModal(pr)
    },
    async sendInvoice() {
      let pr = arg => { 
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject(new Error('something went wrong'))
        
        }).then(async result => {
          this.isLoad = true
          try {
            let data = (await axios.get(result.send)).data

            successHandler(data, this)
            this.isLoad = false
          } catch (error) {
            let { errors } = error.response.data
            this.isLoad = false
            errorHandler(errors, this)
          }
        }, err => {
          this.isLoad = false

        })
      
      }
      this.openInvoiceModal(pr)
    },
    openInvoiceModal(pr) {
      return this.$modal.show(
        UiAgentInvoiceChoice,
        {
          promise: pr,
          invoices: this.item.invoices
        },
        {
          height: 'auto',
          adaptive: true,
          scrollable: true,
          width: 310,
        }

      )
    }
  }
}
</script>
