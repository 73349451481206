<template lang="pug">
include /mixins
+b.FORM.form(@submit.prevent='send(data)')
  row(offset, style='position: relative')
    cell(cols='auto')
      row(space='none')
        cell(:cols='cols')
          ui-input.form__control(
            :placeholder='_("Search")',
            type='search',
            name='query',
            :size='size',
            :border='["top_left", "bottom_left"]',
            :bg='bg',
            v-model='data.search',
            input-class='form__input--variant_search'
          )
            template(v-slot:icon='')
              +b.form__icon(@click='$emit("open")')
                v-button(nowrap, variant='clear', alignment='center')
                  template(v-slot:prepend='')
                    cell(v-if='type == "filter_list"')
                      v-button-element
                        v-caption(size='6', color='default') {{ _("Filter") }}
                  cell
                    v-button-element
                      icon(:type='type', size='md', color='warning')

        slot(name='activator', :data='data', :send='send')

    slot(name='additional-filters', :data='data', :send='send')
</template>

<script>
import * as qs from 'query-string'
export default {
  data() {
    return {
      data: {
        search: '',
      },
    }
  },
  methods: {
    send(value) {
      if (typeof value === 'string') {
        this.data = { ...this.data, search: value }
      } else {
        this.data = { ...this.data, ...value }
      }
      const filtered = Object.entries(this.data).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value
        }

        return acc
      }, {})
      const data = qs.stringify(filtered)
      history.pushState('', 'data', `?${data}`)
      this.$emit('input', this.data)
    },
    parseQuery() {
      this.data = qs.parse(location.search)
      this.$emit('input', this.data)
    },
  },
  props: {
    cols: {
      default: '12 9-md',
      type: String,
    },
    size: {
      default: 'lg',
    },
    type: {
      default: 'filter_list',
    },
    bg: {
      default: '',
    },
  },
  created() {
    this.parseQuery()
    window.addEventListener('popstate', this.parseQuery)
  },
}
</script>

