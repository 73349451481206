
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import Multiselect from 'vue-multiselect'
import Select from './Select'
import OptionalValidationProvider from './OptionalValidationProvider'

import Checkbox from "./Checkbox.vue"
import ControlDescriptor from "./ControlDescriptor.vue"
import FormRepeater from "./FormRepeater.vue"
// import OptionalValidationProvider from "./OptionalValidationProvider.js"
// import Select from "./Select.js"
import UiButton from "./UiButton.vue"
import UiCheckbox from "./UiCheckbox.vue"
import UiInput from "./UiInput.vue"
import UiInputWrapper from "./UiInputWrapper.vue"
import UiPrediction from "./UiPrediction.vue"
import UiRadio from "./UiRadio.vue"
import UiSelect from "./UiSelect.vue"
import UiToggler from "./UiToggler.vue"
import VCheckbox from "./VCheckbox.vue"
import VRadio from "./VRadio.vue"
import VSelect from "./VSelect.vue"
import checkStripePaument from "./checkStripePaument.js"

import { DSelect } from './described'

// const requireComponent = require.context(
//   // The relative path of the components folder
//   '.',
//   // Whether or not to look in subfolders
//   false,
//   // The regular expression used to match base component filenames
//   /[A-Z]\w+\.(vue|js)$/
// )
export default function install(Vue) {
  Vue.component('multiselect', Multiselect)
  Vue.component(Select.name, Select)
  Vue.component('d-select', DSelect)
  Vue.component(OptionalValidationProvider.name, OptionalValidationProvider)
  
  Vue.component('Checkbox', Checkbox)
  Vue.component('ControlDescriptor', ControlDescriptor)
  Vue.component('FormRepeater', FormRepeater)
  Vue.component('UiButton', UiButton)
  Vue.component('UiCheckbox', UiCheckbox)
  Vue.component('UiInput', UiInput)
  Vue.component('UiInputWrapper', UiInputWrapper)
  Vue.component('UiPrediction', UiPrediction)
  Vue.component('UiRadio', UiRadio)
  Vue.component('UiSelect', UiSelect)
  Vue.component('UiToggler', UiToggler)
  Vue.component('VCheckbox', VCheckbox)
  Vue.component('VRadio', VRadio)
  Vue.component('VSelect', VSelect)

}
// Vue.component('checkStripePaument', checkStripePaument)

// console.log(requireComponent.keys());
// requireComponent.keys().forEach(fileName => {
//   // Get component config
//   const componentConfig = requireComponent(fileName)

//   // Get PascalCase name of component
//   const componentName = upperFirst(
//     camelCase(
//       // Strip the leading `'./` and extension from the filename
//       fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
//     )
//   )
//   console.log(componentName)
//   // Register component globally
//   Vue.component(
//     componentName,
//     // Look for the component options on `.default`, which will
//     // exist if the component was exported with `export default`,
//     // otherwise fall back to module's root.
//     componentConfig.default || componentConfig
//   )
// })
