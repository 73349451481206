<template lang='pug'>
  include /mixins
  +b.SECTION.section--main(ref='content')
    +e.el(ref='componentFocusTarget')
    row(offset justification='center')
      cell(cols='12 8-sm 6-md 4-lg 3-xl 2-2xl')
        router-view.child-view(:do-login='true')

</template>

<script>
  export default {
    mounted() {
      
    }
  }
</script>