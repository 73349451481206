<template lang="pug">
  include /mixins
  validation-observer(
    ref='observer'
    tag='form'
    v-slot='{invalid}'
    @submit.prevent='send'
    autocomplete='off'
  )
    row(offset justification='between')
      cell(cols='12' alignment='center')
        v-caption(
          size='6'
          variant='bold'
        ) {{ _('Edit Password') }}

      cell(cols='12 6-sm 4-md 3-2xl')
        ui-input(:placeholder="_('Old Password')"
                  type='password'
                  name='old password'
                  size='lg'
                  bg='relief-3'
                  border='whole'
                  :val='formData.old_password'
                  v-model='formData.old_password'
                  custom-ref='password'
                  validate='min:8|max:32|required').form__control--styled
      cell(cols='12 6-sm 4-md 3-2xl')
        ui-input(:placeholder="_('New Password')"
                  type='password'
                  name='new password1'
                  size='lg'
                  bg='relief-3'
                  border='whole'
                  :val='formData.new_password1'
                  v-model='formData.new_password1'
                  custom-ref='password'
                  validate='required|min:8|max:32').form__control--styled
      cell(cols='12 6-sm 4-md 3-2xl')
        ui-input(:placeholder="_('Re-type New Password')"
                  type='password'
                  name='new password2'
                  size='lg'
                  bg='relief-3'
                  border='whole'
                  :val='formData.new_password2'
                  v-model='formData.new_password2'
                  :custom-class='formData.new_password1 != formData.new_password2 ? "is-error" : null'
                  validate='required').form__control--styled
      cell(cols='12 6-sm 4-md 3-2xl' justification='right')
        +b.control-box
          v-button(
            variant='success'
            type='submit'
          )
            v-button-element(size='lg') 
              v-caption(:variant='["bold", "uppercase"]'  size='6') {{ _('SAVE') }}
    row(offset)
      cell()
        v-caption(
          size='6' 
          variant='bold'
        ) {{ _('*Your password must include') }}
      cell()
        row(
          alignment='center' 
          space='2xs'
        )
          cell
            icon(
              type='check' 
              size='xs'
              :color='message.lengthMessage ? "success" : "brand-5"'
            )
          cell
            v-caption(
              size='6' 
            ) {{ _('8-32 characters') }}
      cell()
        row(
          alignment='center'
          space='2xs'
        )
          cell
            icon(
              type='check'
              size='xs'
              :color='message.customMessage ? "success" : "brand-5"'
            )
          cell
            v-caption(
              size='6' 
            ) {{ _('at least one letter and one number') }}

</template>
<script>
import { usePasswordValidation } from '@cabinet/composables/use-custom-validation'
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import { mapActions } from 'vuex'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  components: {
    UiInput
  },
  data() {
    return {
      message: {},
      formData: {
        'old_password': '',
        'new_password1': '',
        'new_password2': ''
      }
    }
  },
  watch: {
    'formData': {
      async handler(value) {
        let res = await usePasswordValidation(this.formData.new_password1)
        this.message = res
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('userModule', [
      'updatePassword'
    ]),
    async send() {
      const isValid = await this.$refs.observer.validate()
      if (isValid && this.message.customMessage && this.message.lengthMessage) {
        const res = await useResponse(this.updatePassword(this.formData), this, this.$refs.observer)
        if (res.data) {
          Object.keys(this.formData).forEach(v => this.formData[v] = '')
        }
      }
    }
  }
}
</script>
