<template lang='pug'>
  div(class='checkout-progress__wrapper')
    slot(:value='value' :start-value='startValue')
</template>

<script>
export default {
  props: {
    startValue: {
      type: Number,
      required: true,
    },
    currentValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      value: 0,
      timer: null,
      reducedValue: this.currentValue
    }
  },
  mounted() {
    this.setTimer()
  },
  methods: {
    setTimer() {
      const timeout = 1000
      this.timer = setInterval(() => {
        this.reducedValue -= 1000
        this.value = this.startValue - this.reducedValue
      }, timeout)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
