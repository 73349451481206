<template lang="pug">
  include /mixins

  .section
    loading-wrapper(:loading='loading')
      card
        card-panel(offset='horizontal')
          card
            card-panel
              card-panel(v-if='stats.length')
                +b.scrollable-wrapper--till-2xl.--full(style='max-height: initial')
                  +b.TABLE.table--variant_scroll.--variant_stats.--space_lg(style='table-layout: fixed')
                    colgroup
                      col(style='width:12%')
                      col(style='width:21%')
                      col(style='width:13%')
                      col(style='width:7%')
                      col(style='width:10%')
                      col(style='width:12%')
                      col(style='width:6%')
                      col(style='width:5%')
                      col(style='width:7%')
                      col(style='width:7%')
                    +e.THEAD.thead
                      +e.TR.row--relief
                        +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Providers') }}
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Tour name') }}
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Events') }}
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Order #') }}
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Customer name') }}
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Date') }}
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Cost') }}
                        +e.TH.cell--variant_bold.--head.--bordered_right(style='width: 5%;padding-left: 10px; padding-right: 10px;')
                        +e.TH.cell--variant_bold.--head
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Refund') }}
                        +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom
                          v-caption(
                            variant='white-normal' 
                            color='white'
                          ) {{ _('vs Refund total') }}
                    
                    vendor-provider(
                      v-for='(provider, index) in stats'
                      :key='provider.id'
                      :data='provider'
                      v-slot:item='providerProps'
                    )
                      vendor-provider(
                        :data='providerProps.event'
                        v-slot:item='tourProps'
                      )
                        vendor-provider(
                          :data='tourProps.event'
                          :service='VendorsService'
                          v-slot:item='showProps'
                        )
                          vendor-provider(
                            :data='showProps.event'
                          )


</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import VendorsService from '@cabinet/services/api/vendors'
import VendorProvider from './VendorProvider'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  components: {
    VendorProvider
  },
  setup(props, context) {
    const loading = ref(false)
    const stats = ref([])
    const total = computed(() => {
      return stats.value.reduce((acc, s) => {
        acc.sold_amount += s.sold_amount
        acc.sold_price += s.sold_price
        return acc
      }, {
        sold_amount: 0,
        sold_price: 0
      })
    })
 
    const fetchStats = async (data = { is_refresh: 1}) => {
      loading.value = true
      try {
        let res = await useResponse(VendorsService.getVendorStatistics(data), context.parent)
        stats.value = res.data
        loading.value = false
      } catch (err) {
        console.error(err)
        loading.value = false
      }
      
    }
    onMounted(async () => {
      await fetchStats()
    })

    return {
      VendorsService,
      stats,
      total,
      loading
    }
  }
}
</script>
