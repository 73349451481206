<template lang="pug">
  include /mixins
  card
    row(
      offset 
      justification='between' 
    )
      cell(cols='12 7-2xl')
        row(
          offset 
          alignment='center' 
          :justification='["center", "start-sm"]'
          column='till_2xl'
        )
          cell(cols='narrow')
            +b.avatar--size_4xl
              +e.image
                img(:src='provider.avatar || "/static/images/avatar.svg"' style='display:block;width:100%')
          cell(cols='narrow-sm')
            row(
              offset 
              alignment='bottom-sm'
              :justification='["center", "between-md"]'
              column='till-sm'
            )
              cell(cols='narrow-sm')
                row(offset column)
                  cell(cols='12')
                    v-caption(size='3') {{ provider.first_name }} {{ provider.last_name }}
                  cell() 
                    v-caption(size='6' variant='secondary') {{ provider.provider_email }}
                  cell() 
                    v-caption(size='6' variant='bold') {{ provider.phone }}
              cell(cols='narrow-sm')
                row(offset column)
                  cell()
                    row(offset space='5xs' nowrap)
                      cell()
                        v-caption(size='6' variant='secondary')  {{ _('p balance:')}}
                      cell()
                        v-caption(size='6' variant='secondary') $ {{ provider.balance  | toUSD }}
                  cell()
                    row(offset space='5xs' nowrap)
                      cell()
                        v-caption(size='6' variant='secondary')  {{ _('p registration date:')}}
                      cell()
                        v-caption(size='6' variant='secondary') {{ provider.date_joined | humanDate }}
              cell(cols='narrow-sm')
                row(offset column)
                  cell()
                    row(offset space='5xs' nowrap)
                      cell()
                        v-caption(size='6' variant='secondary')  {{ _('p tarif plan:')}}
                      cell()
                        v-caption(size='6' variant='secondary') {{ provider.tariff }}
                  cell()
                    row(offset space='5xs' nowrap)
                      cell()
                        v-caption(size='6' variant='secondary')  {{ _('p total amount:')}}
                      cell()
                        v-caption(size='6' variant='secondary') $ {{ provider.total_amount | toUSD }}
      cell(cols='12 5-2xl')
        row(
          offset 
          alignment='center' 
          :justification='["center", "start-sm"]'
          column='till_2xl'
          space='xl'
        )
          cell(cols='narrow')
            card
              card-panel
                ProviderChart(:chart-data='{active: provider.active_shows, archive: provider.archive_shows}')
                card-label(position='center')
                  row(offset space='xs' column)
                    cell(cols='12')
                      row(offset nowrap justification='center' space='5xs')
                        cell(cols='narrow')
                          v-caption(size='6' variant='secondary' color='warning') {{ provider.active_shows }}
                        cell(cols='narrow')
                          v-caption(size='6' variant='secondary') {{ _('p active events') }}
                    cell(cols='12')
                      row(offset nowrap justification='center' space='5xs')
                        cell(cols='narrow')
                          v-caption(size='6' variant='secondary' color='success') {{ provider.archive_shows }}
                        cell(cols='narrow')
                          v-caption(size='6' variant='secondary') {{ _('p archive events') }}
          cell(cols='12 narrow-sm')
            row(offset)
              cell(cols='12')
                progress-bar(:bars='[pricePercent]' :amount='"$"+provider.sold_price')
                  template(v-slot='')
                    row(justification='between')
                      cell(cols='narrow')
                        v-caption(
                          size='6' 
                          color='brand-2'
                          variant='regular'
                        ) {{ _('t Sold tickets:') }}
                      cell(cols='narrow')
                        v-caption(
                          size='6' 
                          :variant='["secondary", "break"]' 
                          color='brand-3'

                        ) 
                          row(offset space='none')
                            cell(cols='narrow')
                              icon(type='dollar' color='brand-3')  
                            cell(cols='narrow')
                              span {{ provider.total_price | toUSD }}
              cell(cols='12')
                progress-bar(:bars='[qtyPercent]' :amount='provider.sold_amount')
                  template(v-slot='')
                    row(justification='between')
                      cell(cols='narrow')
                        v-caption(
                          size='6' 
                          color='brand-2'
                          variant='regular'
                        ) {{ _('t Sold tickets (qty):') }}
                      cell(cols='narrow')
                        v-caption(
                          size='6'
                          variant='secondary' 
                          color='brand-3'
                        )
                          span {{ qtyPercent.toFixed(2) }}%



</template>

<script>
import ProviderChart from '@cabinet/components/providers/ProviderCart'
export default {
  components: {
    ProviderChart,
  },
  data() {
    return {
    }
  },
  computed: {
    pricePercent() {
      return this.provider.sold_price * 100 / this.provider.total_price
    },
    qtyPercent() {
      return this.provider.sold_amount * 100 / this.provider.total_amount || 0
    },
    // total() {
    //   return this.provider.sold_amount + this.provider.unsold_amount
    // },
    // totalPrice() {
    //   return this.provider.unsold_price + this.p
    // }
  },
  props: {
    provider: {
      required: false,
      default: () => ({
        // active_shows: 20,
        // archive_shows: 40,
        // first_name: '',
        // last_name: '',
        // email: '',
        // phone: '',
        // balance: '',
        // registation_date: '',
        // tarif: '',
        // balance: '',
      })
    }
  }
}
</script>