<template lang="pug">
  include /mixins
  +b-context('table')
    ui-accordion(
      v-slot='{activeState, toggleActive}' 
    )
      +e.TBODY.tbody
        +e.TR.row().--type_hoverable
          +e.TD.cell.--bordered_left_top.--bordered_left_bottom
            v-caption(
              size='6'
              variant='white-normal'
              color='default'
            ) {{ data.first_name }} {{ data.last_name }}
          +e.TD.cell
            card(space='2xs')
              card-panel(offset='bottom')
                v-caption(
                  size='6'
                  variant='white-normal'
                  color='default'
                ) {{ data.shows && data.title }}
              card-panel
                v-caption(
                  size='7'
                  variant='white-normal'
                  color='default'
                  v-if='data.shows'
                ) 
                  span {{ data.shows && data.start_at | humanDate('en') }} -
                  span {{ data.shows && data.finish_at | humanDate('en') }}
          +e.TD.cell
            card(space='2xs')
              card-panel(offset='bottom')
                v-caption(
                  size='6'
                  variant='white-normal'
                  color='default'
                  v-if='!data.shows && data.date_at'
                ) {{ !data.shows ? data.title : '' }}
              card-panel
                v-caption(
                  size='7'
                  variant='white-normal'
                  color='default'
                  v-if='!data.shows && data.date_at && !data.name'
                ) {{ !data.shows && data.date_at }}, {{ !data.shows && data.time_at }}
          +e.TD.cell
            v-caption(
              size='6'
              variant='white-normal'
              color='default'
            ) {{ data.number && data.number }}
          +e.TD.cell
            v-caption(
              size='6'
              variant='white-normal'
              color='default'
            ) {{ data.name && data.name.join(' ') }}
          +e.TD.cell
            v-caption(
              size='7'
              variant='white-normal'
              color='default'
              v-if='data.date_at'
            ) {{ data.date_at && data.date_at | humanDate({ type: 'en', timeZoneName: true }) }}
          +e.TD.cell
            v-caption(
              size='6'
              variant='white-normal'
              color='default'
              v-if='data.number'
            ) ${{ data.base_price | toUSD }}
          +e.TD.cell(
            @click='isFetch || (iterable && iterable.length) ? toggleState(toggleActive, activeState) : () => null ' 
            :style='{"padding-left": !data.events ? "0px" : "14px"}'
            style='cursor: pointer; padding-right: 10px;width: 5%'
          ).--bordered_right
            v-button(
              variant='clear'
              type='button'
              v-if='(isFetch && data.is_orders) || (iterable && iterable.length)'
            )
              v-button-element
                icon(
                  size='2xs'
                  :type='activeState ? "uparrow" : "downarrow"'
                  color='default'
                )
          +e.TD.cell
            v-caption(
              size='6'
              variant='white-normal'
              color='default'
            ) {{ data.fee && `${data.fee+"%"}`}}
          +e.TD.cell.--bordered_right_top.--bordered_right_bottom
            v-caption(
              size='6'
              :variant='["white-normal", totalPrice && "bold"]'
              :color='data.is_paid ? "success" : "danger"'
              v-if='!data.number && (data.price || totalPrice)'
            ) ${{ totalPrice ? totalPrice : data.price / 100 * data.fee | toUSD }}
        
        +e.TR.row(
          v-if='activeState'
          v-for='event in iterable' 
          :key='event.id'
        )
          +e.TD.cell(
            colspan='10' 
            style='padding: 0'
          ) 
            +b.TABLE.table--space_lg(style='table-layout: fixed')
              colgroup
                col(style='width:12%')
                col(style='width:21%')
                col(style='width:13%')
                col(style='width:7%')
                col(style='width:10%')
                col(style='width:12%')
                col(style='width:6%')
                col(style='width:4%')
                col(style='width:7%')
                col(style='width:7%')
              slot(:event='event' name='item')
</template>

<script>
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    data: {
      required: true
    },
    service: {
      required: false
    }
  },
  computed: {
    isFetch() {
      return !this.data.events && !this.data.shows && this.service && true
    },
    totalPrice() {
      return this.data.shows && this.data.shows.length && this.data.shows.reduce((acc, s) => acc += s.price / 100 * s.fee, 0)
    },
    iterable() {
      if (this.isFetch) {
        return this.items
      }
      return this.data.events ? this.data.events : this.data.shows
    }
  },
  data() {
    return {
      items: []
    }
  },
  methods: {
    async toggleState(callback, state) {
      if (!state && this.isFetch && this.service) {
        await this.fetchData()
      }
      callback()
    },
    async fetchData(id = this.data.id) {
      try {
        const res = await useResponse(this.service.getVendorOrdersStatistics(id), this, null)
        this.items = res.data
      } catch (error) {
        console.error(error)
      }     
    }
  },
  
}
</script>