<template lang='pug'>
  include /mixins
  validation-observer(
    ref='observer'
    tag='form'
    v-slot='{invalid}'
    @submit.prevent='send'
    novalidate
    autocomplete='off'
  )
    row(offset)
      cell(cols='12 3-md 6-xl 12-2xl')
        ui-input(
          :placeholder="_('f name *')"
          :val='fee_.title'
          type='search'
          name='title'
          size='lg'
          bg='relief-3'
          border='whole'
          v-model='fee_.title'
          validate='required'
        )
      cell(cols='12 3-md 6-xl 12-2xl')
        ui-input(
          :placeholder="_('f Description')"
          :val='fee_.description'
          type='search'
          name='description'
          size='lg'
          bg='relief-3'
          border='whole'
          v-model='fee_.description'
        )
      cell(cols='6 3-md 6-xl')
        v-select(
          v-model='fee_.type'
          :val='fee_.type'
          :full='true'
          :placeholder="_('f Type *')"
          :options='choices'
          :name='"type"'
          size='lg'
          bg='relief-3'
          border='whole'
          validate='required'
        )            
      cell(cols='6 3-md 6-xl')
        ui-input(
          :placeholder="_('f value *')"
          :val='fee_.price'
          type='search' v-digitsonly=''
          name='price'
          size='lg'
          bg='relief-3'
          border='whole'
          v-model='fee_.price'
          validate='required'
        )  
      
      cell(cols='narrow 12-xl' justification='right')
        v-button(
          variant='success'
          type='submit'
        )
          v-button-element(size='lg') 
            v-caption(
              :variant='["bold", "uppercase"]' 
              size='6'
            ) {{ fee.id ? _('UPDATE fee') : _('SAVE fee') }}
        
</template>

<script>
export default {
  props: {
    fee: {
      required: true
    },
    choices: {
      required: true
    },
    // action: {
    //   required: true
    // },
    deleteFee: {
      default: () => {}
    }
  },
  watch: {
    fee: {
      deep: true,
      handler(v) {
        this.fee_ = {...v}
        this.fee_.type = this.choices
          .find(c => c.id == this.fee.type.id || c.id == this.fee.type)
      }
    }
  },
  methods: {
    async send() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      this.$emit('send', {
        fee: this.fee_,
        observer: this.$refs.observer
      })

      this.$refs.observer.reset()
      this.fee_ = {
        title: '',
        description: '',
        type: '',
        price: ''
      }
    }
  },
  data() {
    return {
      fee_: {
        title: '',
        description: '',
        type: '',
        price: ''
      }
    }
  },
  created() {
    if (this.fee.id) {
      this.fee.type = this.choices
        .find(c => c.id == this.fee.type.id || c.id == this.fee.type)
    }
  }
}
</script>