<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    card
      card-panel
        row(offset)
          cell(cols='12')
            v-caption(size='4' variant='bold') {{ computedAction }} {{ _('tickets ') }} 
          cell(cols='12')
            v-select(
              v-model='invoice'
              :val='invoice'
              :options='invoices'
              :placeholder="_('Pick invoice')"
              validate='required'
              name='invoice'
              size='lg'
              bg='relief-3'
              @input="selectInvoice"
            ).multiselect--fixed_option_height
    
      transition(name='fade')
        +b.card__panel--offset_vertical(style='overflow: hidden' v-if='invoice.tickets && invoice.tickets.length')
            +b.TABLE.table--variant_scroll.--space_lg
              +e.THEAD.thead
                +e.TR.row--relief
                  +e.TH.cell--variant_bold.--head 
                    v-caption(
                      size='4'
                      color='white'
                    ) {{ _('select?') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(
                      size='4'
                      color='white'
                    ) {{ _('sector') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(
                      size='4'
                      color='white'
                    ) {{ _('row') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(
                      size='4'
                      color='white'
                    ) {{ _('seat') }}
                  
            
              +e.TBODY.tbody
                +e.TR.row(
                  key='all'
                )
                  +e.TH.cell
                    v-checkbox
                      +b.INPUT(
                        type="checkbox"
                        v-model='checkAll'
                        @change="selectAll"
                      ).control-checkbox__input
                  +e.TH.cell 
                    v-caption(size='4') {{ "Select All" }}
                  +e.TH.cell 
                    v-caption(size='4') {{ "-" }}
                  +e.TH.cell 
                    v-caption(size='4') {{ "-" }}

                +e.TR.row(
                  v-for='item in invoice.tickets' 
                  :key='item.id'
                )
                  +e.TH.cell
                    v-checkbox
                      +b.INPUT(
                        type="checkbox"
                        v-model='items'
                        :value='item'
                      ).control-checkbox__input
                  +e.TH.cell 
                    v-caption(size='4') {{ item.sector || "-" }}
                  +e.TH.cell 
                    v-caption(size='4') {{ item.row || "-"}}
                  +e.TH.cell 
                    v-caption(size='4') {{ item.seat || "-"}}
                    
      card-panel.offset_top_10xl
        v-button(
          @click='download'
          variant='clear'
          hover='success'
        )
          v-button-element(size='lg')
            v-caption(size='5' variant='bold' color='success') {{ computedAction }}



</template>

<script>
import UiSelect from '@cabinet/components/forms/blocks/UiSelect'
import UiButton from '@cabinet/components/forms/blocks/UiButton'
import axios from "axios"
import UiCheckbox from '@cabinet/components/forms/blocks/UiCheckbox'
import Preloader from '@cabinet/components/vendor/Preloader'
export default {
  components: {
    UiSelect,
    UiButton,
    UiCheckbox,
    Preloader
  },
  data() {
    return {
      items: [],
      invoice: "",
      loading: false,
      checkAll: false
    }
  },
  props: {
    invoices: Array,
    action: {
      required: true
    }
  },
  computed: {
    computedAction() {
      return this.action === 'download' ? this._('download') : this._('print')
    }
  },
  methods: {
    download() {
      let ids = []
      this.items
        .forEach((t, idx) => {
          this.loading = true
          if (this.action == 'download') {
            axios.get(`${t.eticket}`, {responseType: 'blob'}).then(res => {
              let url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf; base64'}))
              let link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `order-${this.invoice.title}-${t.id}.pdf`)
              document.body.appendChild(link)
              link.click()
              this.loading = false
              
            }, err => {
              this.loading = false
            })
          } else {
            ids.push(t.id)
            this.loading = false
            
          }
          this.loading = false
        })
      if (this.action !== 'download') {
        window.open(`/api/v1/tickets/print/?as=pdf&ids=${ids.join(',')}`, '_blank')
      }
    },
    selectAll(event) {
      if (this.checkAll) {
        let allTickents = JSON.parse(JSON.stringify(this.invoice.tickets))
        this.items = allTickents
      }
    },
    selectInvoice() {
      this.checkAll = false
      this.items = []
    }
  }
}
</script>

