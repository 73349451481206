<template lang='pug'>
  include /mixins
  +b-context('table')
    +e.TR.row
      +e.TD.cell--bordered_left_top.--bordered_left_bottom
        row(alignment='center' space='md' nowrap)
          cell
            v-checkbox
              +b.INPUT(type="checkbox"
                      v-model='usher.isSelected').control-checkbox__input
          cell
            v-caption(
              size='6'
              color='default'
            ) {{ usher.first_name }} {{ usher.last_name}}
      +e.TD.cell 
        v-caption(
          size='6'
          color='default'
        ) {{ usher.shows.length }}
      +e.TD.cell 
        v-caption(
          size='6'
          color='default'
        ) {{ usher.country }}
      +e.TD.cell 
        v-caption(
          size='6'
          color='default'
        ) {{ usher.subdivision }}
      +e.TD.cell 
        v-caption(
          size='6'
          color='default'
        ) {{ usher.city }}
      +e.TD.cell--bordered_right_top.--bordered_right_bottom
        slot(name='work')
        //- +b.row
        //-   +b.cell.cols--6
        //-     +b.row--justification_center
        //-       slot(name='add')
          +b.cell.cols--6
            +b.row--justification_center
                
</template>

<script>
export default {
  props: {
    usher: {
      type: Object
    }
  }
}
</script>