<template lang='pug'>
  include /mixins  
  +b.SVG.loading(
    :class="[$blockClasses]"
    viewBox='7 7 86 86'
    preserveAspectRatio='xMidYMid'
  )
    +e.CIRCLE.background(
      cx='50'
      cy='50'
      r='40'
      fill='none'
    )
    +e.CIRCLE.foreground(
      cx='50'
      cy='50' 
      r='40' 
      fill='none'
    )
      animate(
        attributeName='stroke-dashoffset'
        :dur='duration'
        repeatCount='indefinite' 
        from='0' 
        to='502')
      animate(
        attributeName='stroke-dasharray' 
        :dur='duration'
        repeatCount='indefinite' 
        values='150.6 100.4;1 250;150.6 100.4'
      )
    
</template>

<script>
import BemComponentMixin from '@cabinet/components/utils/bem/BemComponentMixin.js'
export default {
  mixins: [BemComponentMixin],
  bem: {
    block: 'loading',
    modifierProps: ['size', 'duration']
  },
  props: {
    size: {
      type: String
    },
    duration: {
      default: '2s',
      type: String
    }
  },

  
}
</script>

