<template lang="pug">
  card
    card-panel(offset='full' :variant='["border", "bordered"]')
      card-label(:position='["top", "right"]')
        v-button(
          @click='$emit("remove", ad)'
          variant='clear'
        )
          cell
            v-button-element(size='xs')
              icon(type='close' color='warning' size='xs')
      validation-observer(
        ref='observer'
        @submit.prevent='save'
        v-slot='{invalid}'
        tag='form'
        autocomplete='off'
      )
        row(offset)
          cell(cols='narrow')
            v-caption(size='6' variant='bold') {{ index }}
          cell(cols='auto')
            row(offset) 
              cell(cols='8 6-lg 8-2xl')
                ui-input(
                  :placeholder="_('ad title')"
                  :val='ad.title'
                  type='search'
                  name='title'
                  validate='required'
                  v-model='ad.title'
                  size='lg'
                  border='whole'
                  bg='relief-3'
                )
              cell(cols='4 6-lg 4-2xl')
                ui-input(
                  :placeholder="_('ad ordering')"
                  :val='ad.ordering'
                  type='search' v-digitsonly=''
                  name='ordering'
                  validate='required'
                  v-model='ad.ordering'
                  size='lg'
                  border='whole'
                  bg='relief-3'
                )
              cell(cols='12')
                loading-wrapper(:loading='loading')
                  ValidationProvider(
                    name='image' 
                    :rules='"image"' 
                    v-slot='{errors, validate}'
                  )
                    
                    .form__image-wrapper
                      row.form__image-wrapper-label(
                        justification='center' 
                      )
                        cell(cols='10')
                          row(offset)
                            cell(cols='12')
                              v-caption(
                                size='5'
                                :variant='["center", "secondary"]'
                                color='brand-4'
                              ) Size: 1020*980 px
                            cell(cols='12')
                              v-button(
                                variant='danger'
                                border='whole'
                                @click='toggleCropper'
                              )
                                //- tag='label'
                                //- :for='index+"file"'
                                template(v-slot:prepend='')
                                  cell
                                    v-button-element(
                                      size='lg'
                                      variant="warning-lite"
                                    )
                                      v-caption(variant='bold')
                                        icon(type='upload'
                                            size='md')
                                cell(cols='auto')
                                  v-button-element(size='lg') 
                                    v-caption(
                                      size='6'
                                      :variant='["bold", "uppercase"]'
                                    ) {{ _('Upload Poster(T)') }}
                        input(
                          type='file'
                          style='display:none'
                          :id='index + "file"'
                          accept='image/x-png,image/gif,image/jpeg'
                          ref='image'
                          @change='getFile($event.target.files, validate);'
                        )
                      img(:src='ad.image || "/static/images/poster-stub-2.png"')
                    transition(name='fade')
                      .form__error(v-if='errors[0]') {{ errors[0] }}
              cell(cols='narrow' justification='right')
                v-button(
                  variant='success'
                  type='submit'
                )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        size='6'
                        :variant='["bold", "uppercase"]'
                      ) {{ ad.id ? _('update ad') : _('save ad') }}

</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import Cropper from '@cabinet/components/vendor/Cropper'

export default {
  props: {
    ad: {
      required: true,
      type: Object
    },
    index: {
      required: true
    }
  },
  setup(props, context) {

    const loading = ref(false)
    const file = ref()
    onMounted(() => {
      file.value = props.ad.image
    })
    

    const getFile = (files, validate) => {
      loading.value = true
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let reader = new FileReader()
        reader.onload = e => {
          // setTimeout(() => {
          props.ad.image = e.target.result
          loading.value = false
          validate()
        }
        reader.readAsDataURL(file)
        
      }
    }

    const save = async () => {
      let isValid = await context.refs.observer.validate()
      if (isValid) {
        let ad = { ...props.ad }
        if (props.ad.image === file.value) {
          delete ad.image
        }
        context.emit('save', ad)
      }
    }

    const promise = () => {
      return arg => {
        return new Promise((resolve, reject) => {
          resolve(arg)
          reject('error')
        }).then(avatar => {
          props.ad.image = avatar
        })
      }
    }

    const toggleCropper = () => {
      context.root.$modal.show(
        Cropper,
        
        {
          promise: promise(),
          width: 1000,
          height: 1000
        },
        {
          height: 'auto',
          classes: 'loadPopup',
          adaptive: true,
          scrollable: true,
          width: 1000,
          
        }
      )
    }

    return {
      getFile,
      save,

      toggleCropper,

      loading
    }
  }
}
</script>