import axios from 'axios'

const URL = '/api/v1/'

function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

let headers = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRFToken": getCookie("csrftoken")
}

let slug = document.getElementById('slug').dataset.slug
export const editedSlug = slug == 'None' ? '' : `${slug}`

export default (prefix, additionalHeaders = {}) => {
  return axios.create({
    baseURL: `${prefix ? prefix : ''}${URL}`,
    headers: {...headers, ...additionalHeaders}, 
    paramsSerializer: (params) => {
      // Sample implementation of query string building
      let result = ''
      Object.keys(params).forEach(key => {
        if (params[key]) {
          result += `${key}=${encodeURIComponent(params[key])}&`
        }
      })
      return result.substr(0, result.length - 1)
    } 
  })
}
