import TicketsService from '@cabinet/services/api/tickets'
import { groupBy } from '@cabinet/router'
export default {
  namespaced: true,
  state: {
    dates: [],
    totalTickets: 0,
    totalPrice: 0,
    currency: '',
    totalFees: 0,
    ticketsOrderHistory: [],
    pagination: {}
  },
  actions: {
    async refund({ commit }, data) {
      return await TicketsService.refund(data)
    },
    async printTickets({ commit }, data) {
      return await TicketsService.printTickets(data).then(res => window.open(res.request.responseURL, '_blank'))
      //
    },
    async fetchTicketsData({ commit }, params) {
      let { data, meta } = (await TicketsService.fetchTicketsData(params)).data
      return { data, meta }
    },
    async sendTickets({ commit }, tickets) {
      return await TicketsService.sendTickets(tickets)
    },
    async FETCH_TICKETS({ commit }, id) {
      commit('FETCH_TICKETS')
    },
    async updateTicket({ commit }, {id, data}) {
      return TicketsService.patch(id, data)
    },
    async REMOVE_TICKET({ commit }, payload) {
      commit('REMOVE_TICKET', payload)
      commit('FETCH_TICKETS')
    },
    async fetchHistory({ commit }, { query }) {
      let { data, meta } = (await TicketsService.fetchHistory(query)).data
      commit('SET_PAGINATION', meta)
      commit('SET_ORDER_HISTORY', data)
    }

  },
  mutations: {
    REMOVE_TICKET(state, id) {
      return TicketsService.delete(id)
      // state.commit('FETCH_TICKETS')
    },
    async FETCH_TICKETS(state, id) {
      try {
        state.dates = (await TicketsService.fetch(id)).data
        state.currency = state.dates[0].currency
        state.totalPrice =
          state.dates
            .reduce((acc, item) => {
              state.totalFees += parseFloat(item.fee)
              return acc += parseFloat(item.price)
            }, 0).toFixed(2)
        state.totalTickets = state.dates.length
        let grouped = groupBy(state.dates, t => t.date)
        let groups = []
        for (let nestedKey in grouped) {
          groups.push({
            [nestedKey]: groupBy(grouped[nestedKey], e => e.title)
          })
        }
        state.dates = groups
        return state.dates
      } catch (error) {
      }
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload.pagination
    },
    SET_ORDER_HISTORY(state, payload) {
      state.ticketsOrderHistory = payload
    }
  }
}

