<template lang='pug'>
  include /mixins
  ValidationProvider.control-input(
    :rules='$attrs.validate' 
    v-slot='{errors}' 
    :name='$attrs.name'
  )
    textarea.control-input__element(v-if='$attrs.type === "textarea"'
            v-bind='attrs'
            v-on='listeners'
            ref='input'
            style='overflow: auto'
            :placeholder='$attrs.placeholder'
            :value='value'
            auto-complete='on'
            :class='[$blockClasses, {"is-error": errors[0]} || customClass]')
    input.control-input__element(
      v-bind='$attrs'
      v-on='{...$listeners, ...listeners}'
      :placeholder='$attrs.placeholder'
      :disabled='$attrs.disabled'
      v-else
      ref='input'
      :value='value'
      :type="typeInput"
      
      :class='[$blockClasses, {"is-error": errors[0]} || customClass, ...$attrs.class]')
    button.toggle-password-wrapper(v-if='togglePassword' )
      .toggle-password(:class="{'toggle-password-active': typeInput === 'text'}" @click.prevent="changePasswordDisplay")
    .control-input__icon(v-if='$slots.icon')
      slot(name='icon' :errors='{errors}')
    slot(name='dropdown')
    slot(name='error')
      +b.control-hint.--styling_default.--variant_error(v-if='errors[0] || customClass') {{ errors[0] || _('Passwords don\'t match')}}
</template>

<script>
import BemComponentMixin from '@cabinet/components/utils/bem/BemComponentMixin.js'
import { inputMixin } from '@cabinet/mixin/input.js'
export default {
  mixins: [ inputMixin, BemComponentMixin ],
  bem: {
    block: 'control-input__element',
    modifierProps: ['size', 'variant', 'border', 'bg']
  },
  inheritAttrs: false,
  props: {
    size: {
      type: [Array, String],
      default: ''
    },
    bg: {
      default: ""
    }, 
    border: {
      type: [Array, String],
      default: ''
    },
    negative: {
      default: true
    },
    inputType: {
      default: '!number'  
    },
    value: {
      default: () => {},
    },
    customClass: {
      default: ''
    },
    masked: {
      default: false
    },
    inputClass: {
      type: String
    },
    togglePassword: {
      type: Boolean,
    },
    type: String,
  },
  data() {
    return {
      active: false,
      typeInput: this.type,
    }
  },
  methods: {
    changePasswordDisplay() {
      this.typeInput = this.typeInput === 'password' ? 'text' : 'password'
    }
  }
}
</script>
