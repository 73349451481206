<template lang='pug'>
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    autocomplete='off'
  )
    transition(name='fade')
      hall-modal(
        v-if='windowVisible'
        @close='resetActive'
        @settle='settle($event)'
        @delete='deleteSector_($event)'
        :hall-data='hall'
        :hall-sectors='hallData'
      )
    loading-wrapper(:loading='loading')
      standalone-fields(
        v-if='!formData.has_schema'
        :hall-data='hallData'
      )
      .file-input__img-wrapper(style='overflow:hidden' v-else)
        +b.file-input__svg--fullheight(ref='svg' :style='{height: height, minHeight: "594px"}').svg.driver.image
        
</template>

<script>
import { SvgManipulationsHall } from '@cabinet/util/svg'
import { mapActions } from 'vuex'
import axios from 'axios'
import { EventBus } from '@cabinet/components/eventbus/eventbus'

import HallModal from '@cabinet/components/hall/HallModal'
import StandaloneFields from '@cabinet/components/hall/StandaloneFields'

export default {
  components: {
    HallModal,
    StandaloneFields,
  },
  props: {
    formData: {
      required: false,
    },
    id: {
      required: false,
    },
  },
  data() {
    return {
      svg: '',
      seats: '',
      windowVisible: false,
      
      hallData: [],
      loading: false,
      poly: {},
      height: `594px`,
    }
  },
  computed: {
    hall() {
      return this.hallData.find((h, index) => {
        h.index = index
        return h.title === this.poly.dataset.title
      })
    },
  },
  methods: {
    ...mapActions('hallModule', [
      'createSector',
      'getSectorsList',
      'getSectorData',
      'getPureSectorData',
      'updateHall',
      'deleteSector',
      'putSector',
      'fetchHall',
    ]),
    deleteSector_(id) {
      let index = this.hallData.map(sector => sector.id).indexOf(id)
      if (index > -1) {
        this.loading = true
        let { title } = this.hallData[index]
        let { svg } = this.$refs
        this.hallData[index].row.forEach(r => {
          r.seat.forEach(s => {
            let text = document.querySelector(`[data-title='${s.geom_id}']`)
            if (text) {
              text.remove()

            }
          })
        })

        let text = [...svg.getElementsByClassName(title.replace(/ /g, '_'))][0]
        if (text) {
          let sector = svg.querySelector(`polygon[data-title='${title}']`)
          sector.dataset.title = ''
          text.remove()

        }
        this.deleteSector(id)
          .then(res => {
            this.hallData.splice(index, 1)
            this.windowVisible = false
            this.resetActive()
            this.updateSvg()
            this.loading = false
          })
          .catch(err => {
            this.loading = false
          })
      }
    },
    save() {
      return this.$refs.observer.validate().then(res => {
        if (res) {
          EventBus.$emit('fetchHall')
          return Promise.resolve({
            valid: true,
            msg: this._('You can pass'),
          })
        }
        return Promise.reject({
          valid: false,
          msg: this._('step not valid'),
        })
      })
    },
    resetActive() {
      this.$emit('deactivate')
      EventBus.$emit('blockEvent', false)
      this.windowVisible = false
    },
    async settle(data) {
      this.loading = true
      window.manipulations.removeCircles()
      this.poly.dataset.title = data.title.toLowerCase()
      data.geom_id = this.poly
      let text = document.querySelector(
        `text[data-id='${this.poly.dataset.id}']`
      )
      if (!text) {
        let closest = this.poly.closest('g')
        // let points = this.poly.getAttribute('points')
        // let polygonCenter = center(points)
        let text = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'text'
        )
        text.setAttribute('fill', '#fff')
        text.style.fontSize = '2px'
        text.textContent = data.title.toLowerCase()
        text.setAttribute('class', data.title.replace(/ /g, '_')) 
        text.dataset.id = this.poly.dataset.id
        closest.appendChild(text)
        

        const bbox = this.poly.getBBox()
        const centerPoint = {
          x: bbox.x + bbox.width / 2,
          y: bbox.y + bbox.height / 2,
        }

        text.setAttribute('x', centerPoint.x)
        text.setAttribute('y', centerPoint.y)

        text.addEventListener('click', () => {
          window.panzoom.pause()
        })

        text.addEventListener('mouseleave', () => {
          window.panzoom.resume()
        })
      } else {
        text.textContent = data.title.toLowerCase()
        if (data.title) {
          text.classList.add(data.title.replace(/ /g, '_'))
        }
      }
      let item = this.hallData.find(s => s.index === data.index)
      if (item) {
        item = data
      } else {
        this.hallData.push(data)
      }
      await this.replace(data)

      return this.workWithSector(data)
    },
    updateSvg(noDismiss = false) {
      if (!this.formData.has_schema) {
        return
      }
      let { svg } = this.$refs
      let elements = [...svg.querySelectorAll('svg')]
      let [sectors, seats] = elements
      seats.classList.add('is-hidden')
        
        
      let polies = [...sectors.querySelectorAll('polygon')]
      polies.forEach(p => p.style.opacity = 1)
      // this.$emit('fetch', { seats_file, sectors_file })

      let [sectors_file, seats_file] = elements.map((el, index) => {
        el.classList.remove('is-hidden', 'is-active')
        let circles = [...el.querySelectorAll('circle')]
        if (circles.length) {
          circles.forEach(c => {
            c.classList.remove('is-hidden')
            c.classList.remove('is-passive')
            c.classList.remove('is-active')
          })
        }
        let val = new XMLSerializer().serializeToString(el)
        return 'data:image/svg+xml;base64,'.concat(
          btoa(unescape(encodeURIComponent(val)))
        )
      })
      
      return this.updateHall({
        id: this.$route.params.id,
        data: {
          seats_file,
          sectors_file,
        },
      }).then(res => {
        if (!noDismiss) {
          seats.classList.add('is-hidden')
          EventBus.$emit('noremove')
          this.windowVisible = false
        }
        this.fetchSectorsData()
      })
    },
    /**
     * updates or creates new sector
     * @param { object } data data to be send to server
     *
     */
    async workWithSectors() {

      let requests = this.hallData.map(item => this.workWithSector(item, true))
      const res = await Promise.all(requests).catch(err => console.error(err))
      await this.updateSvg()
      return res
    },
    workWithSector(data, isCallback = false) {
      let action = data.id ? 'putSector' : 'createSector'
      let id = data.id ? data.id : this.$route.params.id
      
      return this[action]({
        id,
        data,
      })
        .then(async res => {
          if (this.formData.has_schema) {
            if (!isCallback) {
              await this.updateSvg(data.noDismiss)
            }
            if (data.noDismiss) {
              let sector = document.getElementById(data.geom_id)
              let polies = [...sector.parentElement.querySelectorAll('polygon')]
              let index = polies.findIndex(i => i.id == data.geom_id)
              this.clickHandler(sector, index, this.intersectionHandler)
            }
            if (data.callback) {
              data.callback()
            }
          }
          
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          EventBus.$emit('err', err)
          this.loading = false
          return err
        })
    },
    replace(item) {
      item.geom_id = item.geom_id.id ? item.geom_id.id : item.geom_id
      if (item.row) {
        let count = 0
        item.row.forEach(row => {
          if (row.seat.length) {
            row.seat.forEach(seat => {
              count++
              if (seat.geom_id instanceof HTMLElement) {
                seat.geom_id = seat.geom_id
              }
              this.$nextTick(() => {
                this.$set(
                  seat,
                  'text',
                  this.$refs.svg.getElementsByClassName(seat.geom_id)[0]
                )
              })
            })
          }
        })
        item.type = count ? 'seats' : 'no_seats'
        // item.total_seats = count
        // this.$set(item, 'total_seats', count)
      }
    },
    
    /**
     * creates inline svg from parsed svg file
     * @param { string } sector sector URL
     * @param { string } seat sector URL
     * returns concatinated svg out of params
     * fetched from server
     */
    async placeSvg(sector, seats) {
      let { sectors_file, seats_file } = this.formData

      let sectorSvg, seatsSvg
      try {
        let [sectorData, seatsData] = await Promise.all([
          axios.get(sectors_file),
          axios.get(seats_file),
        ])
        sectorSvg = sectorData.data
        seatsSvg = seatsData.data
      } catch (err) {
        console.error(err)
      }
      // seatsSvg
      return {
        sectorSvg,
        seatsSvg,
      }
    },
    /**
     * fetches sectors data of particular hall from server
     */
    async fetchSectorsData() {
      let id =
        this.$route.params.id &&
        typeof parseInt(this.$route.params.id) != 'string'
          ? this.$route.params.id
          : this.id
      this.hallData = (await this.getSectorsList({ id, showId: null, additional: 1})).data || []
    },
    async fetchSector(id) {
      let { data } = (await this.getPureSectorData({id, additional: true})).data
      return data
    },
    async clickHandler(p, index, callback) {
      let { title } = p.dataset
      this.poly = p
      let item = this.hallData.find(s => s.title === title)
      let indexOfItem = this.hallData.findIndex(s => s.title === title)
      // if item is in list fetch its data from server
      if (item) {
        try {
          item = await this.fetchSector(item.id)
        } catch (err) {
        }
        
        this.hallData.splice(indexOfItem, 1, item)

      }
      
      manipulations
        .detectIntersection(index)
        .then(circles => {
          if (circles.length) {
            callback(p, circles, index)
          }
        })
        .catch(err => {
          callback(p, [], index)
        })
    },
    /**
     * creates inline svg from parsed svg file
     * @param { object } p clicked polygon
     * @param { string[] } circles array of fetched circles
     * which are inside the clicked polygon
     * @param { number } index of clicked polygon inside array
     * usefull to create id for particular polygon
     * to make interactions with it more easy
     */
    intersectionHandler(p, circles, index) {
      this.windowVisible = true
      this.poly = p
      this.poly.dataset.id = index
      let { svg } = this.$refs
      let [sectors, seats] = [...svg.querySelectorAll('svg')]
      if (!circles.length) {
        sectors.classList.add('is-active')
        let polies = [...sectors.querySelectorAll('polygon')]
        polies.forEach(p => p.classList.add('is-hidden'))
        this.poly.classList.remove('is-hidden')
        seats.classList.remove('is-hidden')
      } else {
        sectors.classList.remove('is-active')
        sectors.classList.add('is-hidden')
        seats.classList.remove('is-hidden')
        seats.classList.add('is-active')
        circles.forEach(c => {
          c.classList.remove('is-hidden', 'is-passive')
        })
      }
    },
    async placeImages() {
      window.manipulations = new SvgManipulationsHall('.image')
      let polies = await window.manipulations.init()
      polies.forEach((p, index) => {
        p.onclick = e =>
          this.clickHandler(p, index, this.intersectionHandler)
      })
      return polies
    },
    /**
     * Тогглит возможность взаимодействия с сиденьями
     * @param { bool } val - "булка" тоггла состояния сидений
     * */ 
    toggleDisable(val) {
      if (this.$refs.svg) {
        let { svg } = this.$refs
        // Здесь берем только 2-ую свг, т.к. именно на ней находятся сиденья
        let seats = [...svg.querySelectorAll('svg')][1]
        val ? seats.classList.add('is-disabled') : seats.classList.remove('is-disabled')
      }
    }
  },
  async mounted() {
    await this.fetchSectorsData()
    
    if (this.formData.has_schema) {
      let { sectorSvg, seatsSvg } = await this.placeSvg()
    
      let { svg } = this.$refs
      svg.insertAdjacentHTML('beforeend', sectorSvg)
      svg.insertAdjacentHTML('beforeend', seatsSvg)
    
      const val = [...document.getElementsByClassName('svg-item')][0].getBoundingClientRect().height
      console.log(val)
      svg.setAttribute('height', '100%')
      this.placeImages()
      this.height = `${val - 100}px`
      this.$on('deactivate', () => {
        window.manipulations.removeCircles()
      })
  
      EventBus.$on('blockEvent', this.toggleDisable)
    }
    
  },
}
</script>
