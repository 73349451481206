<template lang="pug">
  .preloader
    img(src="/static/images/loading.svg")
    slot
</template>

<script>
export default {
  props: {
    color: {
      type: Array,
      default() {
        return [255, 255, 0, 1]
      },
    },
  },

  computed: {
    background() {
      return `rgba(${this.toAlpha(this.color, 0.059).join(',')})`
    },
    foreground() {
      return `rgba(${this.toAlpha(this.color, 1).join(',')})`
    },
  },

  methods: {
    toAlpha(color, alpha = 1) {
      alpha =
        parseFloat(color.length == 4 ? color[color.length - 1] : 1) * alpha

      return color.slice(0, 3).concat([alpha])
    },
  },
}
</script>