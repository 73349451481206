<template lang='pug'>
  include /mixins
  +b.modal-wrapper
    +e.close(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(
      :loading='isLoading'
    )
      card(offset='full')
        card-panel
          validation-observer(
            ref='observer'
            v-slot='{invalid}'
            tag='form'
            @submit.prevent='saveUsher'
            autocomplete='off'
          )
            row(offset)
              cell(cols='12')
                v-caption(
                  size='3'
                  type='bold'
                ) {{ _('Add new usher (form)')}}
              cell(cols='12')
                ui-input(:placeholder="_('Usher first name')"
                        bg='relief-3'
                        type='search'
                        name='first name'
                        v-model='formData.first_name'
                        size='lg'
                        border='whole'
                        validate='required|alpha_spaces')
              cell(cols='12')
                ui-input(:placeholder="_('Usher last name')"
                        bg='relief-3'
                        type='search'
                        name='last name'
                        v-model='formData.last_name'
                        size='lg'
                        border='whole'
                        validate='required|alpha_spaces')
                        
              cell(cols='12')
                ui-input(:placeholder="_('Usher email')"
                        bg='relief-3'
                        type='email'
                        name='login'
                        v-model='formData.login'
                        size='lg'
                        border='whole'
                        validate='required|email')

              cell(cols='12')
                ui-input(:placeholder="_('Password')"
                        bg='relief-3'
                        type='password'
                        name='password'
                        autocomplete='false'
                        v-model='formData.password'
                        size='lg'
                        border='whole'
                        custom-ref='password'
                        validate='required|min:8|max:32|custom_password')
              cell(cols='12')
                p.no-padding.is-bold {{ _('Your password must include:')}}
                row(alignment='center' offset)
                  cell
                    icon(name='check' 
                        :class='{"is-success" : message.lengthMessage }')
                  cell
                    p.no-padding {{ _('8-32 characters') }}
                    
                row(alignment='center')
                  cell
                    icon(name='check'
                        :class='{"is-success" : message.customMessage }')
                  cell
                    p.no-padding {{ _('at least one letter and one number') }}
              cell(cols='12')
                +b.control-box
                  v-button(
                    type='submit'
                    variant='success'
                    border='whole'
                  )
                    cell(justification='center').cols
                      v-button-element(size='lg') 
                        v-caption(size='4' variant='bold') {{ formData.id ? _('"usher" update') : _('"usher" Go') }}
</template>

<script>
import UshersService from '@cabinet/services/api/ushers'
import Preloader from '@cabinet/components/vendor/Preloader'
import {mapActions, mapState} from 'vuex'
import { successHandler, errorHandler } from '@cabinet/util'
import { usePasswordValidation } from '@cabinet/composables/use-custom-validation'
export default {
  components: {Preloader},
  props: {
    usher: {
      required: true
    },
    callback: {
      type: Function
    }
  },
  watch: {
    'formData': {
      async handler(value) {
        let res = await usePasswordValidation(this.formData.password)
        this.message = res
      },
      deep: true
    }
  },
  computed: {
    ...mapState('vendorsModule', [
      'countries',
      'states',
      'cities',
    ]),
  },
  data() {
    return {
      message: {},
      isLoading: false,
      formData: {
        login: '',
        first_name: '',
        last_name: '',
        password: '',
        password_again: '',
      },
      _formData: {}
    }
  },
  methods: {
    ...mapActions('vendorsModule', [
      'fetchCountries',
      'fetchStates',
    ]),
    resetFields(...args) {
      args.forEach(arg => (this.formData[arg] = ''))
    },
    transformData(data = this.formData) {
      let item = {}
      Object.keys(data).forEach(key => {
        if (data[key]) {
          item[key] = data[key].id || data[key]
        } 
      })
      return item
    },
    async saveUsher() {
      const isValid = await this.$refs.observer.validate() 
      if (isValid) {
        this.isLoading = true
        if (this.formData.id) {
          UshersService
            .updateUsher(this.formData.id, 
              this.transformData(this.formData)
            ).then(res => {
              this.isLoading = false
              successHandler(res.data, this)
              this.formData = this._formData
              this.$emit('close')
              this.callback()
            })
            .catch(err => {
              let { data: errors } = err.response
              errorHandler(errors.errors, this.$refs.observer, err.response, this)
              this.isLoading = false
            })
        } else {
          UshersService
            .createUsher(
              this.transformData(this.formData)
            ).then(res => {
              this.isLoading = false
              successHandler(res.data, this)
              this.formData = this._formData
              this.$emit('close')
              this.callback()
            })
            .catch(err => {
              let { data: errors } = err.response
              errorHandler(errors.errors, this.$refs.observer, err.response, this)
              this.isLoading = false
            })
        }
      }
    }
  },
  async created() {
    await this.fetchCountries()
    let usher = null
    this._formData = {...this.formData}
    if (this.usher) {
      usher = { ...this.usher }
      this.formData = usher
    }
  },
}
</script>