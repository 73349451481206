var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('card-panel',{attrs:{"offset":"full"}},[_c('row',{attrs:{"offset":""}},[_c('cell',{attrs:{"cols":"12 narrow-sm"}},[_c('div',{staticClass:"control-box"},[_c('v-button',{attrs:{"variant":"danger","border":"whole"},on:{"click":_vm.addTour},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('cell',[_c('v-button-element',{attrs:{"size":"lg","variant":"warning-lite"}},[_c('v-caption',{attrs:{"variant":"bold"}},[_c('icon',{attrs:{"type":"add","size":"md","color":"brand-2","variant":"opaque"}})],1)],1)],1)]},proxy:true}])},[_c('cell',{attrs:{"cols":"auto"}},[_c('v-button-element',{attrs:{"size":"lg"}},[_c('v-caption',{attrs:{"variant":["bold", "uppercase"],"size":"6"}},[_vm._v(_vm._s(_vm._('Add new tour')))])],1)],1)],1)],1)]),_c('cell',{attrs:{"cols":"12 8-md 7-lg 6-xl 5-2xl"}},[_c('ui-search',{on:{"input":_vm.send,"open":function($event){_vm.visible = !_vm.visible}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var data = ref.data;
var send = ref.send;
return [_c('cell',{attrs:{"cols":"12 narrow-md"}},[_c('v-button',{attrs:{"size":"lg","border":["top_right", "bottom_right"],"variant":"success"},on:{"click":function($event){return send(data)}}},[_c('cell',{attrs:{"cols":"auto"}},[_c('v-button-element',{attrs:{"size":"lg"}},[_c('v-caption',{attrs:{"variant":["bold", "uppercase"],"size":"6"}},[_vm._v(_vm._s(_vm._('apply')))])],1)],1)],1)],1)]}},{key:"additional-filters",fn:function(ref){
var data = ref.data;
var send = ref.send;
return [_c('ui-filter',{ref:"filter",attrs:{"data":data,"visibility":_vm.visible},on:{"statuses":_vm.setData,"input":send}})]}}])})],1)],1)],1),_c('card-panel',{attrs:{"offset":"horizontal"}},[_c('row',{attrs:{"offset":""}},[_c('cell',{attrs:{"cols":"12"}},[_c('ui-tabs',{ref:"tabs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var activeItem = ref.activeItem;
var activate = ref.activate;
return [_c('row',{attrs:{"offset":"","space":"xs"}},_vm._l(([ { type: "active", text: _vm._("Active") }, { type: "archived", text: _vm._("Archived") } ]),function(item,index){return _c('cell',{key:index,attrs:{"cols":"narrow"}},[_c('v-button',{attrs:{"variant":["clear", "block", "shadow", "tab", item.type !== _vm.type ? "opaque": ""],"border":"round"},on:{"click":function($event){return _vm.activateTab(index, item.type, activate)}}},[_c('v-button-element',{attrs:{"size":"md"}},[_c('v-caption',{attrs:{"size":"4","variant":"bold","color":item.type === _vm.type ? 'warning' : 'default'}},[_vm._v(_vm._s(item.text))])],1)],1)],1)}),1)]}}])})],1)],1),(_vm.states)?_c('card-panel',{ref:"wrapper",staticStyle:{"position":"relative"},attrs:{"offset":"vertical"}},[(_vm.events.events_row.length)?_c('row',{attrs:{"offset":"","space":"lg"}},_vm._l((_vm.events.events_row),function(event,index){return _c('cell',{key:("cell-" + (event.id) + "-" + index),attrs:{"cols":"12 6-sm 4-lg  2-10-2xl"}},[_c('transition-group',{attrs:{"name":"fade"}},[(_vm.states)?_c('ui-event',{key:("event-" + (event.id)),attrs:{"event":event,"states":_vm.states},on:{"loading":function($event){return _vm.deleteEvent($event)}}}):_vm._e()],1)],1)}),1):_c('row',{attrs:{"offset":""}},[_c('cell',{attrs:{"cols":"12"}},[_c('notice',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('icon',{attrs:{"type":"error","color":"warning","size":"5xl"}})]},proxy:true},{key:"caption",fn:function(){return [_c('v-caption',{attrs:{"variant":"bold"}},[_vm._v(_vm._s(_vm._('Event list is empty')))])]},proxy:true}],null,false,2991245443)})],1)],1)],1):_vm._e()],1),_c('card-panel',{attrs:{"offset":"full"}},[(_vm.events.pagination.total > _vm.events.pagination.limit)?_c('ui-pagination',{attrs:{"from-client":true,"offset":_vm.events.pagination.offset,"total":_vm.events.pagination.total,"limit":_vm.events.pagination.limit},on:{"input":function($event){return _vm.sendData($event, _vm.events.pagination.limit)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }