<template lang='pug' functional>
include /mixins
ValidationProvider.control-input.control-input--variant_checkbox(
  v-slot='{errors}'
  v-bind='data.attrs' 
)
  row(offset space='5xs')
    cell(cols='12')
      row(offset alignment='center' space='5xs')
        cell(cols='narrow')
          +b.LABEL.control-checkbox
            slot
            +e.body(:class='variant && "control-checkbox__body--variant_" + variant')
              +e.mark
        cell(cols='narrow')
          slot(name='text')
    cell(cols='12')
      slot(name='error')
        transition(name='fade')
          +b.control-hint.--styling_default.--variant_error(v-if='errors[0]') {{ errors[0] }}
</template>

<script>
export default {
  props: {
    variant: {
      default: '',
      type: String
    }
  }
}
</script>