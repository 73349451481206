import Api from '@cabinet/services'

export default {
  getPresets(id) {
    return Api().get(`places/preset/list/${id}/`)
  },
  createPreset(data) {
    return Api().post(`places/preset/create/`, data)
  },
  deletePreset(id) {
    return Api().delete(`places/preset/delete/${id}/`)
  },

  fetch(query) {
    return Api().get(`places/hall/list/`, {
      params: query
    })
  },
  fetchHall(id) {
    return Api().get(`places/hall/${id}/receive/`)
  },
  deleteHall(id) {
    return Api().delete(`places/hall/${id}/delete/`)
  },
  post(id, data) {
    // let sectors = JSON.parse(localStorage.getItem('sectors')) || []
    // sectors.push(data)
    // sectors = JSON.stringify(sectors)
    // localStorage.setItem('sectors', sectors)
    // return new Promise(resolve => {
    //   resolve(sectors)
    // })
  },
  createHall({data}) {
    return Api().post(`places/hall/create/`, data)
  },
  getStatuses() {
    return Api().options(`places/hall/create/`)
  },
  updateHall({ id, data }) {
    return Api().patch(`places/hall/${id}/update/`, data)
  },
  getSectorsList({ id, showId, additional }) {
    showId = showId ? `&show=${showId}` : ''

    additional = additional ? `&additional=1` : ''
    return Api().get(`/places/sector/list/${id}/?pagination=0${showId}${additional}`)
  },
  getSectorsListWithShow(id) {
    return Api().get(`/places/sector/list/shows/${id}/?pagination=0`)
  },
  createSector({ id, data }) {
    return Api().post(`/places/sector/${id}/create/`, data)
  },
  deleteSector(id) {
    return Api().delete(`/places/sector/${id}/delete/`)
  },
  getSectorData(id) {
    let flag
    if (typeof id == 'object') {
      flag = `?booked=${id.showId}`
      id = id.id 
    }
    return Api().get(`/places/sector/${id}/receive/${flag ? flag : ''}`)
  },
  getPureSectorData({ id, additional}) {
    let add = additional ? `?additional=${additional}` : ''
    return Api().get(`/places/sector/${id}/receive/${add}`)
  },
  // full update
  putSector({ id, data }) {
    return Api().patch(`/places/sector/${id}/update/`, data)
  },
  // partial update
  patchSector(id, data) {
    return Api().patch(`/places/sector/${id}/update/`, data)
  },
  deleteRow(id) {
    return Api().delete(`/places/row/${id}/delete/`)
  }
}
