<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(:loading='isLoading')
      ValidationObserver(
        ref='observer'
        tag='form'
        @submit.prevent='process'
        v-slot='{invalid}'
      )

        +b.row--offset
          +b.cell.cols--12
            v-caption(size='4' variant='bold') {{ _("assign show to vendor") }}
          +b.cell.cols--12
              form-repeater(
                v-model='items'
                :initial='{ show: "", fee: "" }'   
                :add-label='_("add more")',
              )
                template(#default="repeater")
                  +b.row.--appearance_spaced
                    +b.cols.cols--12.--8-md.cell
                      card(space='xs')
                        card-panel(offset='bottom')
                          d-select(
                            size="lg",
                            name='shows_show'
                            :options="shows",
                            :placeholder="_('select show')"
                            v-model="repeater.value.show",
                            trackBy="id",
                            label="title"
                            rules='required'
                            :loading='loading'
                          ).control-select.control-select--bg_relief-3
                            template(#afterList='')
                              div(
                                v-observe-visibility="visible => getList(visible)" v-if="shows_pagination.total > (shows_pagination.limit + shows_pagination.offset)"
                              )
                        card-panel
                          v-caption(size='6') {{ _('hint 1')}}
                    +b.cols.cols--12.--4-md.cell
                      card-panel(space='xs')
                        card-panel(offset='bottom')
                          //- input(type='search' v-digitsonly='' v-model='repeater.value.fee' step='any')
                          ui-input(
                            :placeholder="_('v fee')"
                            bg='relief-3'
                            size='lg'
                            v-digitsonly=''
                            type='search' 
                            step='any'
                            name='shows_fee'
                            v-model='repeater.value.fee'
                            validate='required|max:100'
                          )
                            template(v-slot:icon='')
                              icon(type='percent' color='default')
                        card-panel
                          v-caption(size='6') {{ _('hint 2')}}
          +b.cell.cols--12
            +b.row--offset.--justification_end
              +b.cell.g-cols--narrow
                +b.control-box
                  v-button(
                    variant='success'
                    type='submit'
                  )
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ _('save') }}
</template>

<script>
import VendorsService from '@cabinet/services/api/vendors'
import { useResponse } from '@cabinet/composables/use-response'
import { clone } from 'ramda'
export default {
  props: {
    vendor: {
      required: true,
      type: Number
    },
    showsList: {
      type: Array,
      required: true
    },
    shows_initial: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      items: [{show: '', fee: ''}],
      shows: [],
      shows_pagination: {},
      loading: false,
      page: 1,
      search: '',
    }
  },
  computed: {
    isValid() {
      const set = new Set([...this.items.map(i => i.show.id)])
      console.log(set.size)
      return set.size === this.items.length
    }
  },
  created() {
    this.items = this.shows_initial.map(s => ({show: s, fee: s.fee}))
    this.getList()
  },
  methods: {
    async getList(visible, query) {
      if (false === visible) return
      let page = Math.floor(this.shows_pagination.offset / this.shows_pagination.limit) + 2
      if (this.page == page) return
      if (!visible) page = 1
      if (visible) this.page = page

      this.search = query

      this.loading = true
      const { data: { data, meta: { pagination } } } = (await VendorsService.getAllAvailableShows({...this.query, page: page, search: this.search }, this.slug))
      let d = data.map(({ id, title }) => ({id, title}))
      if (visible) {
        this.shows.push(...d)
      } else {
        this.shows = d
      }
      this.shows_pagination = pagination
      this.loading = false
    },
    transformTo(data) {
      return clone(data).map(i => {
        // let fee = i.fee
        // i.fee.fee
        return {
          show: i.show.id || 0,  
          fee: typeof i.fee == 'number' ? i.fee : i.fee.replace(',', '.')
        }
      })

    },
    async process() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.isLoading = true
        try {
          await useResponse(
            VendorsService
              .process({ 
                vendor: this.vendor, 
                shows: this.transformTo(this.items)
              }), this, this.$refs.observer)
          this.isLoading = false
          this.$emit('close')
        } catch (error) {
          console.error(error)
          this.isLoading = false
        }
      }
    }
  },

  
}
</script>