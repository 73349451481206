<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    validation-observer(
      ref='observer'
      v-slot='{invalid, errors}'
      tag='form'
      @submit.prevent='save'
      autocomplete='off'
    )
      card
        card-panel
          row(offset)
            cell(cols='12')
              v-caption(size='3' color='default' variant='bold') 
                slot
                  | {{ _('t Vendors manipulations')}}
          card-panel
            row(offset justification='center')
              cell(cols='12')
                +b.scrollable-wrapper--vertical.--half
                  card
                    card-panel(v-if='form')
                      
                        tickets-layout(
                          v-for='(ticket, index) in form.price_descriptor'
                          :ticket='ticket'
                          :key='index'
                          :form='form'
                          :index='index'
                          :sectors='sectors'
                          :descriptors='descriptors'
                          :observer='$refs.observer'
                          :withdraw='true'
                        )
                          template(v-slot:sector-errors='' )
                            .form__error(v-if='errors[`seats ${index} non field errors`] && errors[`seats ${index} non field errors`].length') {{ errors[`seats ${index} non field errors`][0] }}
                          template(v-slot:seats-errors='' )
                            .form__error(v-if='errors[`sectors ${index} non field errors`] && errors[`sectors ${index} non field errors`].length') {{ errors[`sectors ${index} non field errors`][0] }}
                          template(v-slot:discounts='' v-if='type === "agent"')
                            cell(cols='12')
                              row(
                                offset 
                                alignment='center'
                              )
                                cell(cols='12 6-sm')
                                  v-select(
                                    v-model.number='ticket.vendor'
                                    :val='ticket.vendor'
                                    :options='vendors'
                                    :placeholder="_('Type name here')"
                                    validate='required'
                                    bg='relief-3'
                                    :name='"agent"'
                                  )
              cell(cols='narrow')
                v-button(
                  @click='save'
                  type='button'
                  variant='success'
                )
                  cell(cols='narrow')
                    v-button-element() 
                      v-caption(
                        size='4' 
                        :variant='["bold"]'
                        color='text-1'
                      ) {{ type === 'agent' ? _('u appoint') : _('u print') }}
</template>

<script>
import { useResponse } from '@cabinet/composables/use-response'
import AgentsService from '@cabinet/services/api/agents'
import VendorsService from '@cabinet/services/api/vendors'
import TicketsService from '@cabinet/services/api/tickets'
import TicketsLayout from '@cabinet/components/show/TicketsLayout'
export default {
  components: {
    TicketsLayout
  },
  props: {
    sectors: {
      required: true
    },
    descriptors: {
      required: true 
    },
    show: {
      required: true
    },
    type: {
      default: 'agent'
    }
  },
  data() {
    return {
      loading: false,
      vendors: [],
      form: {
        price_descriptor: [
          {
            vendor: '',
            info: [
              {
                sector: {},
                row: {},
                seats: {},
              },
            ],
          }
        ]
      }
    }
  },
  methods: {
    save() {
      if (this.$refs.observer.flags.failed) {
        return 
      }
      this.$refs.observer.validate().then(async res => {
        if (res) {
          let object = this.form.price_descriptor.reduce((acc, desc) => {
            if (desc.seats) {
              acc.seats.push(...desc.seats)
            }
            if (desc.info.filter(s => s.amount).length) {
              console.log(desc.info)
              acc.sectors.push(...desc.info
                .filter(s => s.amount)
                .map(s => ({id: s.sector.sector_descriptor_id, amount: s.amount}))
              )
            }
            return acc
          }, {
            seats: [],
            sectors: [],
          })
          console.log(object)
          if (!(object.seats.length + object.sectors.length)) {
            return
          }
          this.loading = true
          object.vendor = this.form.price_descriptor[0].vendor.id
          const method = () => {
            return this.type === 'agent' ? 
              AgentsService.withdraw({ data: object, id: this.show.id }) : 
              TicketsService.printHardTickets({ data: object, id: this.show.id })
          }
          try {
            let res = await useResponse(method(), this, this.$refs.observer)
            if (res) {
              this.form = {
                price_descriptor: [
                  {
                    vendor: '',
                    info: [
                      {
                        sector: {},
                        row: {},
                        seats: {},
                      },
                    ],
                  }
                ]
              }
              this.$refs.observer.reset()
              this.$emit('close')
            }
            this.loading = false
          } catch (err) {
            this.loading = false
            console.error(err)            
          }
          
        } else {
          console.log(res)
        }
      })
    }
  },
  async created() {
    
    if (this.type === 'agent') {
      try { 
        this.loading = true
        this.vendors = (await VendorsService.getVendorList()).data.data.map(a => {
          return {
            ...a,
            title: a.first_name ? `${a.first_name} ${a.last_name}` : `${a.email}`
          }
        })
        this.loading = false
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    }
  }
}
</script>