<template lang="pug">
  include /mixins
  +b.modal-wrapper--bg-contrast
    +e.close(@click='$emit("close")') 
      icon(type='close')
    card
      card-panel
        +b.FORM.form
          row(offset)
            cell(cols='12')
              +b.UI-TABS.tabs.mt-1(ref='tab')
                template(v-slot='{ activeItem,activate: activateTab }')
                  +b.tab-list
                    +e.body
                      +e.toggle--variant_default.tab-list__item(
                        @click='activate(index);activateTab(index)'
                        v-for='(item, index) in stepsList'
                      )
                        v-caption(
                          :color="activeItem === index ? 'default' : 'brand-5' "
                        ) {{ item.name }}
      card(variant='section' stacked='top')
        card-panel(offset='vertical')
          loading-wrapper(:loading='loading' )
            row(offset) 
              cell(cols='12')
                card(:variant='["offset"]')
                  card-panel(offset='horizontal')
                    transition-group(name='fade')
                      ui-show-discount-form(
                        v-if='stepsList[0].active'
                        key='1'
                        :show='show'
                        :sectors='sectors'
                        :descriptors='descriptors'
                        @close='$emit("close")'
                      )
                      ui-show-discounts-list(
                        v-if='stepsList[1].active'
                        key='2'
                        :show-id='show.id'
                      )

</template>

<script>
  import UiTabs from '@cabinet/components/vendor/UiTabs'
  import UiShowDiscountForm from '@cabinet/components/show/modals/discounts/UiShowDiscountForm'
  import UiShowDiscountsList from '@cabinet/components/show/modals/discounts/UiShowDiscountsList'
  export default {
    components: {
      UiTabs,
      UiShowDiscountForm,
      UiShowDiscountsList,
      
    },
    props: {
      sectors: {
        required: true
      },
      show: {
        required: true
      },
      descriptors: {
        required: true
      }
    },
    data() {
      return {
        stepsList: [
          {
            name: this._('d Assign Discount'),
            active: true
          },
          {
            name: this._('d Discounts List'),
            active: false
          },
        ],
        loading: false
      }
    },
    methods: {
      activate(index) {
        this.stepsList.forEach(s => s.active = false)
        this.stepsList[index].active = true
      },
      
    },
  }
</script>