<template lang='pug'>
  include /mixins
  card
    card-panel(offset='full')
      card-panel
        v-button(
          tag='router-link'
          to='/account/vendors/'
          :variant='["clear", "narrow"]' 
          alignment='center'
        )
          template(v-slot:prepend='')
            cell
              v-button-element()
                icon(
                  type='left-arrow' 
                  color='default'
                  size='xs'
                )
          cell(cols='auto')
            v-button-element
              v-caption(
                variant='bold' 
                size='6'
                color='default'
              ) {{ _('ag back') }}

      card-panel(offset='bottom')
        
        card(variant='blue')
          card-panel(offset='full')
            row(:alignment='["end", "center-sm"]' space='lg' offset :column='["till-sm"]')
              cell()
                row(justification='end-till-sm')
                  cell
                    v-caption(color='white' :variant='["white-space", "bold"]') {{ _('vendor fullname:')}}
                  cell
                    v-caption(color='white' variant='white-space') {{ vendor.first_name ? vendor.first_name +' '+ vendor.last_name : '-'}}
              cell()
                row(justification='end-till-sm')
                  cell
                    v-caption(color='white' :variant='["white-space", "bold"]') {{ _('Phone number:')}}
                  cell
                    v-caption(color='white' variant='white-space') {{ vendor.phone ? vendor.phone : '-' }}
              cell()
                row(justification='end-till-sm')
                  cell
                    v-caption(color='white' :variant='["white-space", "bold"]') {{ _('Email:')}}
                  cell
                    v-caption(color='white' variant='white-space') {{ vendor.email }}
              cell()
                row(justification='end-till-sm')
                  cell
                    v-caption(color='white' :variant='["white-space", "bold"]') {{ _('Company:')}}
                  cell
                    v-caption(color='white' variant='white-space') {{ vendor.company ? vendor.company : '-' }}
              cell()
                row(justification='end-till-sm')
                  cell
                    v-caption(color='white' :variant='["white-space", "bold"]') {{ _('Invoices:')}}
                  cell
                    v-caption(color='white' variant='white-space') {{ vendor.invoice_amount }}/{{ vendor.paid_invoice_amount }}
              cell()
                row(justification='end-till-sm')
                  cell
                    v-caption(color='white' :variant='["white-space", "bold"]') {{ _('active shows:')}}
                  cell
                    v-caption(color='white' variant='white-space') {{ vendor.invoice_shows }}
              cell(justification='right')
                v-button(
                  :variant='["clear"]'
                  @click='modalTrigger(vendor.id)'
                )
                  template(v-slot:prepend='')
                    cell()
                      v-button-element
                        icon(type='close' color='warning')

      card-panel
        +b.FORM.form
          row(offset)
            cell(cols='12')
              +b.UI-TABS.tabs.mt-1(ref='tab' :active='0')
                template(v-slot='{ activeItem, activate }')
                  +b.tab-list
                    +e.body
                      +e.toggle--variant_default(
                        @click='activateLocaly(index, item.type, activate)'
                        v-for='(item, index) in tabs'
                      )
                        v-caption(
                          :variant='activeItem !== index ? "secondary" : ""'
                          :style='item.disabled ? "pointer-events:none;opacity:.5;" : ""'
                        ) {{ item.text }}
      card(variant='section' stacked='top')
        card-panel(offset='full')
          row(offset justification='between' alignment='center')
            cell(cols='12 auto-md')
              ui-search(
                @input='sendData($event)'
                bg='relief-3'
                type='search'
                cols='12'
              )
                template(v-slot:additional-filters='{data, send}')
                  cell(cols='12 narrow-md')
                    v-button(
                      variant='success-lite'
                      size='lg'
                      border='whole'
                      @click='send(data)'
                    )
                      template(v-slot:prepend='')
                        cell
                          v-button-element(
                            size='lg'
                            variant="success"
                          )
                            v-caption(variant='bold')
                              icon(type='search'
                                  size='md')
                      cell(cols='auto')
                        v-button-element(size='lg') 
                          v-caption(
                            :variant='["bold", "uppercase"]' 
                            size='6'
                          ) {{ _('s search') }}
            cell(cols='narrow')
              pagination(:pagination='meta' v-if='meta.total.value || meta.total')

          row(offset)
            cell(cols='12' v-if='activeComponent.items.row.length')
              row(offset)
                cell(
                  v-for='(item, idx) in activeComponent.items.row'
                  cols='12'
                  :key='item.id + "qwe" + idx'
                )
                  component(
                    :is='activeComponent.component'
                    :item='item'
                    :agent-id='id'
                    :id='item.id'
                    @changeInvoice='changeData($event)'
                  )
            cell(cols='12' v-else)
              card
                card-panel(offset)
                  notice
                    template(v-slot:icon='')
                      icon(
                        type='error' 
                        color='warning' 
                        size='5xl'
                      )
                    template(v-slot:caption='')
                      v-caption(variant='bold') {{ _('You have no data yet')}}
                //- ui-pagination(:from-client='true'
                //-         :offset='activeComponent.items.pagination.offset'
                //-         :total='activeComponent.items.pagination.total'
                //-         :limit='activeComponent.items.pagination.limit'
                //-         v-if='activeComponent.items.pagination && activeComponent.items.pagination.total > activeComponent.items.pagination.limit'
                //-         @input='sendData({ page: $event })')          
                
                
</template>

<script>


import { errorHandler } from '@cabinet/util'
import usePagination from '@cabinet/composables/use-pagination'

import Pagination from '@cabinet/components/vendor/Pagination'
import UiTabs from '@cabinet/components/vendor/UiTabs'
import UiSearch from '@cabinet/components/forms/UiSearch'
import UiAgentShow from '@cabinet/components/agent/UiAgentShow'
import UiAgentInvoice from '@cabinet/components/agent/UiAgentInvoice'
import UiAgentSummary from '@cabinet/components/agent/UiAgentSummary'
import { mapActions, mapState } from 'vuex'

import VendorsService from '@cabinet/services/api/vendors'
import { useResponse } from '@cabinet/composables/use-response'
import UiDelete from '@cabinet/components/modals/UiDelete'

export default {
  components: {
    Pagination,
    UiTabs,
    UiSearch,
    UiAgentShow,
    UiAgentInvoice,
    UiAgentSummary,
  },
  
  props: {
    id: {
      required: false,
    },
  },
  watch: {
    agent(val) {
      this.formData = val
    }
  },
  data() {
    return {
      tabs: [
        { type: 'shows', text: this._('Shows') },
        { type: 'invoices', text: this._('Invoice') },
        { type: 'summary', text: this._('Summary table') },
      ],
      formData: {},
      disabled: true,
      vendor: {}
    }

  },
  computed: {
    meta() {
      return usePagination(this.activeComponent.items.pagination)
    },
    activeComponent() {
      const components = {
        shows: 'UiAgentShow',
        invoices: 'UiAgentInvoice',
        summary: 'UiAgentSummary',
      }
      return {
        component: components[this.type],
        items: this[this.type],
      }
    },
    ...mapState('agentsModule', [
      // 'agent',
      'type',
      'shows',
      'invoices',
      'summary',
      
    ]),
    fullName() {
      return `${this.vendor.first_name} ${this.vendor.last_name}`
    },
    
  },
  mounted() {
    
    
  },
  methods: {
    ...mapActions('agentsModule', [
      'setType', 
      'getVendorShows',
      'getAgentsInvoices',
      'getInfoForSummaryTable']),
    activateLocaly(index, type, fn) {
      fn(index)
      this.setType(type)
      this.sendData()
    },
    async sendData(type) {
      let query = ''
      if (type) {
        query = Object.keys(type).map(k => `${k}=${type[k]}`).join('&')
      }
      this.loading = true

      let method 
      if (this.activeComponent.component == 'UiAgentShow') {
        method = 'getVendorShows'
      } else if (this.activeComponent.component == 'UiAgentInvoice') {
        method = 'getAgentsInvoices'
      } else if (this.activeComponent.component == 'UiAgentSummary') {
        method = 'getInfoForSummaryTable'

      }
      try {
        await this[method]({ id: this.vendor.id, query })
        
      } catch (error) {
      }
      this.loading = false
    },
    async deleteAgent(id) {
      try {
        return Promise.resolve(await useResponse(VendorsService.removeAgent(id), this, null))
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },
    
    deleteAgent_(id) {
      return this.deleteAgent(id)
        .then(res => this.$router.replace({ name: 'vendors'}))
        .catch(error => {
          this.$emit('close')
          errorHandler(error.response.data, this)
        })
        // .then(res => {
        //   successHandler(res.data.data, this)
        // })
        
    },
    modalTrigger() {
      return this.$modal.show(UiDelete,
        
        {
          fn: this.deleteAgent_,
          itemID: this.vendor.id,
          type: this._('agent'),
        },
        {
          height: 'auto',
          adaptive: true,
          width: 310,
          scrollable: true
        }
      )
    },
    async fetchVendor() {
      try {
        const res = await useResponse(VendorsService.fetchOneVendor(this.$route.params.id), this, null)
        console.log(res)
        this.vendor = res.data
      } catch (error) {
        console.error(error)
      }
    }
  },
  async created() {
    await this.fetchVendor()
    await this.getVendorShows({ id: this.id })
    await this.getAgentsInvoices({id: this.id})
    await this.getInfoForSummaryTable({ id: this.id })
  }
}
</script>

