var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control-box",attrs:{"name":_vm.$attrs.name}},[_c('ValidationProvider',{attrs:{"rules":_vm.$attrs.validate,"name":_vm.$attrs.name,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',_vm._g({ref:"select",class:[_vm.$blockClasses],attrs:{"track-by":"title","label":"title","options":_vm.options,"name":_vm.$attrs.name,"id":_vm.$attrs.id,"placeholder":_vm.placeholder,"searchable":_vm.searchable,"taggable":_vm.taggable,"allow-empty":_vm.allowEmpty,"multiple":_vm.multiple,"close-on-select":!_vm.multiple || _vm.closeOnSelect,"disabled":_vm.disabled,"select-label":"+","deselect-label":"-","loading":_vm.loading},on:{"open":_vm.adjustSizing},scopedSlots:_vm._u([{key:"single-label",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.title))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"option__desc",attrs:{"title":option.tooltip}},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(option.title))])])]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('div',{staticClass:"multiselect__custom-tag"},[_c('row',{attrs:{"alignment":"center","space":"xs","offset":"","nowrap":""}},[_c('cell',[_vm._v(_vm._s(option.title || option.id))]),_c('cell',[_c('div',{staticClass:"multiselect__custom-remove"},[_vm._t("action",[_c('span',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeItem(remove, option)}}},[_c('icon',{attrs:{"type":"close","color":"danger","size":"xs"}})],1)],{"remove":remove,"option":option})],2)])],1)],1)]}},{key:"no-result",fn:function(){return [_vm._v(_vm._s(_vm._('Oops! No elements found. Consider changing the search query.')))]},proxy:true},_vm._l((_vm.eSlots),function(name,key){return {key:name,fn:function(binded){return [_vm._t(key,null,null,binded)]}}})],null,true),model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},_vm.listeners)),_vm._t("error",[(errors[0])?_c('div',{staticClass:"control-hint control-hint--styling_default control-hint--variant_error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }