import Api from '@cabinet/services/'

export default {
  getAll(query) {
    return Api().get('/vendor/list/', {
      params: query
    })
  },
  getVendorList() {
    return Api().get('/vendor/simple/list/')
  },
  create(data) {
    return Api().post('/vendor/create/', data)
  },
  process(data) {
    return Api().post('/vendor/show/process/', data)
  },
  getGeneralStatistics(params) {
    return Api().get('/vendor/statistic/', {
      params: params
    })
  },
  getStatisticsByShow(id, data) {
    return Api().get(`/vendor/statistic/${id}/shows/`, { params: data})
  },
  changeFee(data) {
    return Api().post(`/vendor/change/fee/`, data)
  },
  changeStatus(id) {
    return Api().post(`/vendor/statistic/${id}/pay/`)
  },
  fetchOneVendor(id) {
    return Api().get(`/vendor/${id}/receive/`)
  },
  removeAgent(id) {
    return Api().post(`/vendor/${id}/remove/`)
  },
  fetchVendorOrders(data) {
    return Api().get(`/vendor/orders/`, {
      params: data
    })
  },
  fetchVendorOrder(id) {
    return Api().get(`vendor/order/${id}/receive/`)
  },
  updateOrderDelivery({ id, data}) {
    return Api().post(`vendor/order/${id}/update-delivery/`, data)
  },
  fetchVendorShows(data, page) {
    return Api().get(`/vendor/shows/?page=${page}`, {
      params: data
    })
  },
  fetchOne(id ) {
    return Api().get(`vendor/customer/${id}/receive/`)
  },
  deleteVendorTicket(data) {
    return Api().post(`/withdrawn/vendor/delete/`, data)
  },
  refundVendorList({id, agent_id}) {
    return Api().get(`withdrawn/${id}/vendor/${agent_id}/list/`)
  },


  getVendorStatistics() {
    return Api().get(`vendor/my/statistic/`)
  },
  getVendorOrdersStatistics(id) {
    return Api().get(`vendor/my/statistic/${id}/orders/`)
  },
  getAllAvailableShows(data) {
    return Api().get('vendor/shows/available/', { params: data })
  },
} 


