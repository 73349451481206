import UserService from '@cabinet/services/api/user'
export default {
  namespaced: true,
  state: {
    isAuth: window.auth == 'True' ? true : false,
    isAdmin: false,
    isSuperAdmin: false,
    userData: null,
    link: {},
    twoFactorStatus: false,
    prefix: document.getElementById('slug').dataset.slug !== 'None' ? `/${document.getElementById('slug').dataset.slug}` : '',
    prefixSlug: document.getElementById('prefix-slug').dataset.slug !== 'None' ? `/${document.getElementById('prefix-slug').dataset.slug}` : '',
    
  },
  actions: {
    login({ commit }) {
      commit('LOGIN')
    },
    getData({ commit, state }) {
      return UserService.getData(state.prefix.split('/')[1]).then(res => {
        commit('SET_USER_DATA', res.data.data)
        return res.data.data
      })
    },
    updateData({ commit }, payload) {
      return UserService.updateData(payload).then(res => {
        commit('SET_USER_DATA', res.data.data)
      })
    },
    patchData({ commit }, payload) {
      return UserService.patchData(payload)
        .then(res => {
          // commit('SET_USER_DATA', res.data)
          return res.data
        })
        .catch(err => {
          return err.response
        })
    },
    updatePassword({ commit }, payload) {
      return UserService.changePassword(payload)
    },
    async getQr({ commit }) {
      let response = (await UserService.getQr()).data
      commit("SET_LINK", response.data.link)
      commit("SET_TWO_FACTOR_STATUS", response.data.is_active)
      return response.data
    },
    async updateSettings({ commit }, data) {
      let response = (await UserService.updateSettings(data)).data
      return response.data
    },
    restorePassword(payload) {
      return UserService.recoveryPassword(payload)
    }
  },
  mutations: {
    SET_SUPER_ADMIN(state) {
      state.isSuperAdmin = true
    },
    LOGIN(state) {
      state.isAuth = true
    },
    SET_USER_DATA(state, payload) {
      if (!payload.profile.billing_address) {
        payload.profile.billing_address = {
          city: null
        }
      }
      state.userData = payload
      state.isAdmin = payload.is_manager
      state.isSuperAdmin = payload.is_staff
      
    },
    SET_LINK(state, payload) {
      state.link = payload
    },
    SET_TWO_FACTOR_STATUS(state, payload) {
      state.twoFactorStatus = payload
    }
  },

}
