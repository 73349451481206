<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    drop-zone(:options="dropOptions" ,@add-file="addFile")
    card-panel(offset='bottom')
    row(offset justification="center" space='xl-xl')
      cell(cols='narrow')
        v-button(
          variant='success'
          @click='submitFile'
        )
          v-button-element(size='lg') 
            v-caption(
              :variant='["bold", "uppercase"]' 
              size='6'
            ) {{ _('Confirm') }}
</template>

<script>

import DropZone from '@cabinet/components/vendor/DropZone'


export default {
  props: {
    promise: ''
  },
  components: {
    DropZone
  },
  data() {
    return {
      dropOptions: {
        acceptedFiles: "image/jpeg, image/jpeg, image/png"
      },
      file: ''
    }
  },
  methods: {
    addFile(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', e => {
        this.file = e.target.result
      })
    },
    submitFile(file) {
      this.$emit("close")
      return this.promise(this.file)
    }
  }
}
</script>