<script>
export default {
  render() {
    return this.$scopedSlots.default({
      activeItem: this.activeItem,
      activate: this.activate
      
    })
  },
  watch: {
    active: {
      handler: (val) => {
        this.activeItem = val
      }
    }
  },
  props: {
    active: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      activeItem: this.active
    }
  },
  methods: {
    activate(item) {
      if (item < 0) {
        item = 0
      } 
      return this.activeItem = item
    }
  } 
}
</script>
