<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; ')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    row(offset)
      cell(cols='12')
        v-caption(
          size='4' 
          :variant='["center", "bold"]'
        ) {{ text || _('Time to make an order is over.') }}
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
  },
}
</script>