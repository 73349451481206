<template lang="pug">
  include /mixins
  div
    validation-observer(
      ref='observer'
      v-slot='{invalid}'
      tag='form'
      autocomplete="off"
      @submit.prevent='validateBeforeSubmit'
    )
      +b.card__panel--offset_bottom_2sm
        ui-input.contact-form__input(
          :placeholder="_('Name*')"
          bg="transparent"
          border="underlined"
          type='text'
          name='name'
          v-model='formData.name'
          validate='required|alpha_spaces'
        )

      +b.card__panel--offset_bottom_2sm
        ui-input.contact-form__input(
          :placeholder="_('Phone number*')"
          bg="transparent"
          border="underlined"
          type='tel'
          name='phone'
          v-model='formData.phone'
          v-mask='["(###) ###-####"]'
          validate='required'
        )
      +b.card__panel--offset_bottom_2sm
        ui-input.contact-form__input(
          :placeholder="_('Email*')"
          bg="transparent"
          border="underlined"
          type='email'
          name='email'
          v-model='formData.email'
          validate='required|email'
        )

      +b.card__panel--offset_bottom_2sm
        ui-input.contact-form__textarea(
          :placeholder="_('Message*')"
          bg="transparent"
          border="underlined"
          type='textarea'
          name='message'
          v-model='formData.message'
          validate='required'
        )

      +b.card__panel--offset_top_xl
        +b.control-box
          v-button(
            type='submit'
            variant='accent'
            border='whole'
            hover="success"
            class="control-button__element--variant_accent"
          )
            cell(justification='center').cols
              v-button-element(size='lg') 
                v-caption(size='4' :variant='["bold", "uppercase"]') {{_('Send us ')}}
        
</template>

<script>
import FeedbackService from '@cabinet/services/api/feedback.js'
import { useResponse } from '@cabinet/composables/use-response'


export default {
  name: "contact-form",
  
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: '',
        message: ''
      }
    }
  },

  methods: {
    async send() {
      try {
        await useResponse(FeedbackService.createFeedback(this.formData), this, this.$refs.observer)
        await this.resetForm()
      } catch (err) {
        console.error(err)
      }
    },

    async validateBeforeSubmit() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.send(isValid)
      } else {
        return
      }
    },

    resetForm() {
      this.$refs.observer.reset()
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })
    }
  }
}
</script>

