<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; margin: 0px auto 0;padding: 0')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close' color='white')
    card
      //- card-panel(offset='full')
      iframe.video-frame(
        :src="video" 
        width='100%' 
        height='600' 
        style='display:block'
      )
          
</template>


<script>
export default {
  props: {
    video: {
      required: true,
      type: String
    }
  }
}
</script>