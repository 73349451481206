<template lang="pug" functional>
  include /mixins
  +b-context('table')
    +e.TR.row
      +e.TD.cell--bordered_left_top.--bordered_left_bottom
        router-link(
          :to='{name: "provider", params: { id: props.provider.id }}'
        ).link
          v-caption(
            size='6'
            color='default'
            variant='bold'
          ) {{ props.provider.first_name ? `${props.provider.first_name} ${props.provider.last_name}` : 'John Doe' }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          variant='bold'
        ) {{ props.provider.date_joined | humanDate }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          variant='bold'
        ) $ {{ props.provider.balance | toUSD }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          variant='bold'
        ) {{ props.provider.shows }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          variant='bold'
        ) {{ props.provider.sold_tickets }}
      +e.TD.cell
        v-caption(
          size='6'
          color='default'
          variant='bold'
        ) {{ props.provider.tariff }}
      +e.TD.cell--bordered_right_top.--bordered_right_bottom
        v-caption(
          size='6'
          color='default'
          variant='bold'
        ) $ {{ props.provider.commission | toUSD }}
</template>
<script>
export default {
  props: {
    provider: {
      required: true,
      type: Object
    }
  }
}
</script>
