<template lang="pug">
include /mixins
div
  component(
    :is='currentComponent.component',
    :order='order',
    :data-info='componentData',
    :total-price='totalPrice',
    @cash-selected='$emit("cash-selected", $event)',
    @shipment='$emit("shipment", $event)',
    @set-info='updateInfo'
    :currency='currency'
  )
</template>

<script>

import FirstStep from './checkoutSteps/FirstStep'
import SecondStep from './checkoutSteps/SecondStep'

export default {
  components: {
    FirstStep,
    SecondStep
  },
  props: {
    order: {
      required: true
    },
    totalPrice: {
      required: true
    },
    currency: {
      required: false,
      default: 'USD'
    },
  },
  data() {
    return {
      activeStep: 0,
      steps: [
        { id: 0, component: FirstStep },
        { id: 2, component: SecondStep }
      ],
      componentData: {}
    }
  },
  computed: {
    currentComponent() {
      return this.steps[this.activeStep]
    }
  },
  methods: {
    updateInfo(event) {
      console.log(event)
      Object.keys(event).forEach(key => {
        this.$set(this.componentData, key, event[key])
      })
      this.goForward()
    },
    goForward() {
      this.activeStep++
    }
  }
}
</script>
