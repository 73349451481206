<template lang="pug">
  include /mixins
  +b.scrollable-wrapper--till-2xl.--full(style='min-height: initial; max-height: initial')
    +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
      +e.THEAD.thead
        +e.TR.row--relief
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('s Date and time') }}
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('s Transaction type') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('s Subject') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('s Amount') }}
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('s balance') }}
          +e.TH.cell--variant_bold.--head(v-if='items.some(i => i.link)')
            v-caption(color='white') {{ _('s balance') }}
      +e.TBODY.tbody
        FinanceRow(
          v-for='report in items' 
          :report='report'
          :key='report.id'
        )
        
        
</template>

<script>
import FinanceRow from '@cabinet/components/finance/FinanceRow'

export default {
  components: {
    FinanceRow,
  },
  props: {
    items: {
      required: true,
      type: Array
    }
  },
}
</script>