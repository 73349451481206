<template lang="pug">
include /mixins
card(:variant='["section", "tour"]')
  card-panel(:offset='["horizontal", "vertical_xs"]')
    row(space='xs' offset)
      cell(cols='12')
        v-button(
          justification='left'
          variant='clear'
          @click='manageUshers'
        )
          template(v-slot:prepend='')
            cell(cols='1')
              v-button-element(size='sm')
                icon(
                  type='usher' 
                  size='xs' 
                  variant='secondary'
                  color='default'
                ) 
            cell(cols='narrow')
              v-button-element(size='sm')
                v-caption(
                  size='6' 
                  color='default' 
                  variant='secondary'
                ) {{ _('t Appoint ushers')}}
      
      cell(cols='12')
        v-button(
          justification='left'
          variant='clear'
          @click='withdrawFunds'
        )
          template(v-slot:prepend='')
            cell(cols='1')
              v-button-element(size='sm')
                icon(
                  type='dollar' 
                  size='xs' 
                  variant='secondary'
                  color='default'
                ) 
            cell(cols='narrow')
              v-button-element(size='sm')
                v-caption(
                  size='6' 
                  color='default' 
                  variant='secondary'
                ) {{ _('t Withdraw funds')}}  

  card-panel(:offset='["horizontal", "vertical_xs"]')
    row( space='none')
      cell(cols='12')
        v-button(
          justification='left'
          variant='clear'
          @click='printTickets'
        )
          template(v-slot:prepend='')
            cell(cols='1')
              v-button-element(size='sm')
                icon(
                  type='print' 
                  size='xs' 
                  variant='secondary'
                  color='default'
                ) 
            cell(cols='narrow')
              v-button-element(size='sm')
                v-caption(
                  size='6' 
                  color='default' 
                  variant='secondary'
                ) {{ _('t Print hard-tickets')}}

  card-panel(:offset='["horizontal", "vertical_xs"]')
    row( space='none')
      //- cell(cols='12')
      //-   v-caption(
      //-     size='6' 
      //-     color='default' 
      //-     variant='secondary'
          
      //-   ) {{ _('t For Agents')}}
      cell(cols='12')
        v-button(
          justification='left'
          variant='clear'
          @click='appointTickets'
        )
          template(v-slot:prepend='')
            cell(cols='1')
              v-button-element(size='sm')
                icon(
                  type='usher' 
                  size='xs' 
                  variant='secondary'
                  color='default'
                ) 
            cell(cols='narrow')
              v-button-element(size='sm')
                v-caption(
                  size='6' 
                  color='default' 
                  variant='secondary'
                ) {{ _('Appoint tickets to agents')}}
</template>

<script>
  import UiShowUshers from '@cabinet/components/show/modals/ushers/UiShowUshers'
  import UiShowPrints from '@cabinet/components/show/modals/tickets/UiShowPrints'
  import Charge from '@cabinet/components/show/modals/tickets/Charge'
  import UiShowTickets from '@cabinet/components/show/modals/agents/UiShowTickets'
  export default {
    props: {
      sectors: {
        required: true
      },
      show: {
        required: true
      },
      descriptors: {
        required: true
      }
    },
    methods: {
      withdrawFunds() {
        this.$modal.show(
          Charge, 
          {
            show: this.show
          },
          {
            height: 'auto',
            adaptive: true,
            classes: ['middle-modal-wrap'],
            scrollable: true,
            maxWidth: 400,
            width: '100%'
          },
          {
            'before-close': () => {
              this.$emit('fetch')
            }
          }
        )
      },
      printTickets() {
        this.$modal.show(
          UiShowPrints, 
          {
            ...this.$props
          },
          {
            height: 'auto',
            adaptive: true,
            classes: ['middle-modal-wrap'],
            scrollable: true,
            maxWidth: 1200,
            width: '100%'
          },
          {
            'before-close': () => {
              this.$emit('fetch')
            }
          }
        )
      },
      appointTickets() {
        this.$modal.show(
          UiShowTickets, 
          {
            ...this.$props
          },
          {
            height: 'auto',
            adaptive: true,
            classes: ['middle-modal-wrap', 'modal', 'modal--variant_fixed'],
            scrollable: true,
            maxWidth: 1200,
            width: '100%'
          },
          {
            'before-close': () => {
              this.$emit('fetch')
            }
          }
        )

      },
      manageUshers() {
        this.$modal.show(
          UiShowUshers,
          
          {
            id: this.show.id
          },
          {
            height: 'auto',
            adaptive: true,
            classes: ['middle-modal-wrap', 'modal', 'modal--variant_fixed'],
            scrollable: true,
            maxWidth: 1200,
            width: '100%'
          }
          
        )
      }
    }
  }
</script>