import { ref } from '@vue/composition-api'
export function useSplice(itemsToRemove, items) {
  itemsToRemove.forEach(u => {
    let i = items.value.length
    while (i--) {
      let index = items.value.findIndex(i => i.id === u)
      if (index >= 0) {
        items.value.splice(index, 1)
      }
    }
  })
}
