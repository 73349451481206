<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(:loading='loading')
      validation-observer(
        ref='observer'
        v-slot='{invalid}'
        tag='form'
        @submit.prevent='send'
      )
        row(offset)
          cell(cols='12')
            v-caption(size='2' variant='bold') {{ _('REGISTRATION') }}
          validation(name='non field errors')
          cell(cols='12')
            ui-input(
              :placeholder="_('First name')"
              bg='relief-3'
              size='lg'
              type='search'
              name='first_name'
              v-model='formData.first_name'
              validate='required|alpha_spaces'
            )

          cell(cols='12')
            ui-input(:placeholder="_('Last name')"
                  bg='relief-3'
                  size='lg'
                  type='search'
                  name='last_name'
                  v-model='formData.last_name'
                  validate='required|alpha_spaces')
          cell(cols='12')
            ui-input(:placeholder="_('E-mail')"
                  bg='relief-3'
                  size='lg'
                  type='search'
                  name='email'
                  inputmode='email'
                  v-model.trim='formData.email'
                  validate='required|email')
          cell(cols='12')
            ui-input(:placeholder="_('Create password')"
                  bg='relief-3'
                  size='lg'
                  type='password'
                  name='password1'
                  v-model='formData.password1'
                  custom-ref='password'
                  validate='required|min:8|max:32|custom_password'
                  :toggle-password="true"
                )
          cell(cols='12')
            ui-input(:placeholder="_('Reenter password')"
                  bg='relief-3'
                  size='lg'
                  type='password'
                  name='password2'
                  v-model='formData.password2'
                  data-vv-as='password'
                  :custom-class='formData.password1 != formData.password2 ? "is-error" : null'
                  validate='required'
                  :toggle-password="true"
                )
          cell(cols='12')
            v-caption(size='6' variant='bold') {{ _('Your password must include:')}}
          cell(cols='12') 
            row(
              alignment='center' 
              offset 
              space='xs'
            )
              cell
                icon(
                  type='check'
                  size='xs' 
                  :color='message.lengthMessage ? "success" : ""'
                )
              cell
                v-caption(size='6') {{ _('8-32 characters') }}

            row(
              alignment='center' 
              offset 
              space='xs'
            )
              cell
                icon(
                  type='check'
                  size='xs'
                  :color='message.customMessage ? "success" : ""'
                )
              cell
                v-caption(size='6') {{ _('at least one letter and one number') }}
          //- cell(cols='12' v-if='index')  
          //-   v-select(
          //-     v-model='formData.variation'
          //-     size='lg'
          //-     border='whole'
          //-     bg='relief-3'
          //-     :val='formData.variation'
          //-     :full='true'
          //-     :placeholder="_('Sign up type*')"
          //-     :options='variations'
          //-     :name='"variation"'
          //-     validate='required'
          //-   )            
          //- transition(name='fade')
          //-   cell(cols='12' v-if='index || isIndex')  
          //-     v-select(
          //-       v-model='formData.tariff'
          //-       size='lg'
          //-       border='whole'
          //-       bg='relief-3'
          //-       :disabled='disabled'
          //-       :val='formData.tariff'
          //-       :full='true'
          //-       :placeholder="_('Tariff plan:*')"
          //-       :options='tariffs'
          //-       name='tariff'
          //-       validate='required'
          //-     )

          cell(cols='12')
            +b.control-box
              v-button(
                variant='success'
                type='submit'
                
              )
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('SIGN UP') }}
        //-   cell(cols='12')
          //-   +b.row--between.--row.--events
        //-     cell--50(v-for='social in urls_')
        //-       +b.btn-wrapper--auto
        //-         +b.btn--center.--flex
        //-           a(:href='social.url' style='width:100%')
        //-             +e.icon--blue.--default(:style='{"color": social.color}' :class='["icon-"+social.icon]')
          

</template>

<script>
import UserService from '@cabinet/services/api/user'
import ProvidersService from '@cabinet/services/api/providers'
import { successHandler, errorHandler } from '@cabinet/util'
import { usePasswordValidation } from '@cabinet/composables/use-custom-validation'
export default {
  props: {
    urls: Array,
    index: {
      required: false,
      type: Number
    },
    type: '',
    disabled: false,
  },
  computed: {
    urls_() {
      return this.urls.map(url => {
        let icon = url.match(/\/accounts\/(.*)\/login\//)[1]
        let colors = {
          google: '#dc334a',
          twitter: '#2FA9DC',
          facebook: '#3F65AD'
        }
        
        return {
          url, 
          icon,
          color: colors[icon]
        }
        
      })
    },
  },

  watch: {
    'formData': {
      async handler(value) {
        let res = await usePasswordValidation(this.formData.password1)
        this.message = res
      },
      deep: true
    }
  },
  computed: {
    isIndex() {
      return location.pathname == "/"
    }
  },  
  data() {
    return {
      loading: false,
      tariffs: [],
      message: {},
      // variations: [
      //   {
      //     title: this._('Vendor'),
      //     id: 'vendor'
      //   },
      //   {
      //     title: this._('Provider'),
      //     id: 'provider'
      //   }
      // ],
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        password2: '',
        tariff: '',
      }
    }
  },
  methods: {
    async send() {
      const isValid = await this.$refs.observer.validate() 
      if (isValid) {
        try {
          const data = { ...this.formData }
          if (data.tariff) {
            data.tariff = data.tariff.id
          }
  
          this.loading = true
          const res = await UserService
            .register(data, this.isIndex ? 'provider/' : '')
          this.$emit('close')
          successHandler(res.data, this, () => location.reload())
          this.loading = false
          
        } catch (error) {
          this.loading = false
          let { data: errors } = error.response
          errorHandler(errors.errors, this.$refs.observer, error.response, this)
          
        }
      }
    }
  },
  async mounted() {
    if (this.isIndex) {
      try {
        this.tariffs = (await ProvidersService.fetchTarifs()).data.data.map(({id, title}) => ({id, title}))
        this.formData.tariff = this.tariffs.find(t => t.id == this.index) || this.tariffs[0]
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>



