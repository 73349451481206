import Api, {editedSlug as provider} from '@cabinet/services'


const cartPrefix = 'sub-cart'
export default {

  fetchCart() {
    return Api().get('/cart/receive/', {
      headers: {
        provider
      }
    })
  },
  fetchFullCart() {
    return Api().get('/cart/receive/?type=advance', {
      headers: {
        provider
      }
    })
  },
  removeItem(tickets) {
    return Api().post(`/cart/remove/`, tickets, {
      headers: {
        provider
      }
    })
  },
  cleanCart(data) {
    return Api().post('/cart/clean/', data, {
      headers: {
        provider
      }
    })
  },
  addPromo(data) {
    return Api().post('cart/add/promo/', data)
  },
  postShowItem({ items, id }) {

    const headers = {
      provider,
    }
    const url = new URL(document.location.href)
    const vendor = url.searchParams.get('vendor')
    if (vendor) {
      headers.vendor = vendor
    }
    return Api().post(`${cartPrefix}/${id}/add/`, items, {
      headers
    })
  },
  fetchShowCart({id}) {
    return Api().get(`${cartPrefix}/${id}/receive/`, {
      headers: {
        provider
      }
    })
  },
  removeFromShowCart({ id, tickets }) {
    return Api().post(`${cartPrefix}/${id}/remove/`, tickets, {
      headers: {
        provider
      }
    })
  },
  mergeShowCart({ id }) {
    return Api().post(`${cartPrefix}/${id}/merge/`, {}, {
      headers: {
        provider
      }
    })
  }

}
