export default {
  data() {
    return {
      loading: 0,
    }
  },

  computed: {
    isLoading() {
      return this.loading > 0
    }
  },

  methods: {
    setLoading() {
      this.loading++

      if (this.loading === 1) {
        this.$emit('loadingStarted')
      }
    },

    unsetLoading() {
      this.loading--

      if (this.loading === 0) {
        this.$emit('loadingFinished')
      }
    }
  }
}
