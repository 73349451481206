<template lang="pug">
  include /mixins
  +b.sidebar
    card(style='overflow:hidden')
      card-panel(offset='full-xl')
        row(
          offset
          :justification='collapsed ? "center" : "between"'
        ).is-hidden-till-xl
          //- alignment='center'
          cell(v-if='!collapsed' cols='10')
            ui-credentials(:collapsed='collapsed')
          cell(cols='narrow')
            card
              card-panel(:offset='collapsed ? "" : "vertical"')
                slot
      card-panel(:offset='["vertical-xl"]')
        ui-nav(:collapsed='collapsed')
</template>

<script>

import UiCredentials from '@cabinet/components/personal/UiCredentials'
import UiNav from '@cabinet/components/personal/UiNav'
export default {
  components: {
    UiCredentials,
    UiNav
  },
  props: {
    collapsed: {
      default: false
    }
  }
}
</script>
