<template lang="pug">
  include /mixins
  card
    card-panel(offset='full')
      card(stacked='top' variant='section')  
        card-panel(offset='full')
          row(offset :space='["lg-md", "xl-xl", "2xl-2xl"]')
            cell(cols='12 6-lg')
              card-panel(offset='bottom')
                SecurityBilling(
                  type='billing_address'
                  @input='billing = true'
                )
                  template(v-slot:checkbox='')
                    cell(cols='12')
                      row(offset space='xs')
                        cell(cols='narrow')
                          v-checkbox
                            input(
                              type='checkbox'
                              size='lg'
                              border='whole'
                              bg='relief-3'
                              v-model='billing'
                              name='active'
                            ).control-checkbox__input
                        cell(cols='narrow') {{ _('If the delivery address is different, please activate this check-box') }}
              card-panel(offset='bottom' v-if='billing')
                SecurityBilling(type='delivery_address')
                  template(v-slot:caption='')
                    | {{ _("u Delivery information:") }} 

            cell(cols='12 6-lg')
              Security
            //- cell(cols='12 6-lg' v-if='isStaff')
            //-   ProviderFees
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import Security from '@cabinet/components/settings/Security'
import SecurityBilling from '@cabinet/components/settings/SecurityBilling'
import ProviderFees from '@cabinet/components/settings/ProviderFees'
import SettingsAccessGranted from '@cabinet/components/modals/SettingsAccessGranted'
// import { mapState } from 'vuex'
export default {
  components: {
    Security,
    SecurityBilling,
    ProviderFees,
  },
  computed: {
    // ...mapState('userModule', ['userData']),
    // isStaff() {
    //   return this.userData.is_vendor
    // },
  },
  setup(props, context) {
    const billing = ref(false)
    onMounted(() => {
      if (context.root.$store.state.userModule.userData.is_vendor && !context.root.$store.state.userModule.userData.profile.billing_address.city) {
        context.root.$modal.show(
          SettingsAccessGranted,
          {
            text: context.root._('Please enter billing information'),
            icon: 'settings',
            iconColor: 'danger'
          },
          {
            adaptive: true,
            height: 'auto',
            maxWidth: 330,
          }
        )
      }
    })

    return {
      billing
    }
  }
}
</script>