<template lang="pug">
  include /mixins
  +b.row.--between.--wrap.--expences
    +e.el--50
      +b.UI-INPUT(v-model='expences.name'
               type='search'
               name='expences name'
               autofocus=''
               :val='expences.name'
               validate='').form__control--styled.--expences
               
    +e.el--50
      +b.row.--between
        +e.el--66
          +b.UI-INPUT(v-model.number='expences.cost'
              type='search' v-digitsonly=''
              name='expences cost'
              :val='expences.cost'
              validate='').form__control--styled.--expences
        +e.el--33
          slot(name='actions')

</template>

<script>
import UiInput from '@cabinet/components/forms/blocks/UiInput'
export default {
  props: {
    expences: {
      required: true,
    },
  },
  components: {
    UiInput,
  },
  
}
</script>
