<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    card
      row
        cell(cols='12')
          card-panel
            v-caption(
              tag='div'
              size='4' 
              variant='bold'
            ) {{ customText ||  _('Are you sure want to delete this ') + type }}
        cell(cols='12')
          card-panel(offset='top')
            row(justification='between')
              cell(cols='6')
                v-button(
                  @click='reject'
                  variant='danger'
                )
                  v-button-element {{ _('No') }}
              cell(cols='6')
                v-button(
                  @click='resolve'
                  variant='success'
                ) 
                  v-button-element {{ _('YES') }}

</template>

<script>
import UiButton from '@cabinet/components/forms/blocks/UiButton'
export default {
  components: {
    UiButton
  },
  props: {
    text: {
      required: false
    },
    fn: {
      required: true
    },
    itemID: {
      default: 0
    },
    type: {
      required: false
    },
    customText: {
      type: String
    }
  },
  methods: {
    reject() {
      return this.fn().catch(() => this.$emit('close'))
    },
    resolve() {
      return this.fn(this.itemID).then((res) => {
        this.$emit('close')
      })
    }
  }
}
</script>

