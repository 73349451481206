<template lang="pug">
  include /mixins
  +b.modal-wrapper(style='width: 100%; ')
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")')
      icon(type='close' color='warning')
    validation-observer(
      @submit.prevent='send'
      ref='observer'
      tag='form'
      v-slot='{invalid}'
      autocomplete='off'
    )
      row(offset)
        cell(cols='12')
          ui-input(
            :placeholder="_('Hall name')"
            type='text'
            name='hall_name'
            validate='required'
            size='lg'
            bg='relief-3'
            :val='formData.hall_name'
            v-model='formData.hall_name'
          )
        cell(cols='12')
          ui-input(
            :placeholder="_('Address')"
            type='text'
            name='address_line_1'
            size='lg'
            bg='relief-3'
            :val='formData.address_line_1'
            v-model='formData.address_line_1'
          )
        cell(cols='12')
          ui-input(
            :placeholder="_('Comment')"
            type='textarea'
            name='comment'
            size='lg'
            bg='relief-3'
            :val='formData.comment'
            v-model='formData.comment'
          )
        cell(cols='12')
          row(offset space='xs' alignment='center')
            cell(cols='narrow')
              v-checkbox
                input(
                  type='checkbox'
                  name='is_hall_image'
                  size='lg'
                  border='whole'
                  bg='relief-3'
                  v-model='formData.is_hall_image'
                  @change='noImageActivate'
                ).control-checkbox__input
            cell(cols='narrow')
              v-caption(variant='regular') {{ _('The hall has image') }}
        template(v-if='formData.is_hall_image')
          cell(cols='12')
            drop-zone(@add-file="addFile")
            validation(name='image')
        cell(cols='12')
          v-button(
            variant='success'
            border='whole'
            type='submit'
          )
            cell(cols='auto')
              v-button-element(size='lg')
                v-caption(
                  size='5'
                  :variant='["bold", "uppercase"]'
                ) {{ _('send request') }}


</template>

<script>
import { ref } from '@vue/composition-api'
import PSS from '@cabinet/services/api/provider-settings'
import { useResponse } from '@cabinet/composables/use-response'

import DropZone from '@cabinet/components/vendor/DropZone'

export default {
  components: {
    DropZone
  },
  setup(props, context) {
    const loading = ref(false)
    const formData = ref({
      city: '',
      subdivision: '',
      country: '',
      address_line_1: '',
      comment: '',
      image: '',
      hall_name: '',
      is_hall_image: true
    })


    const addFile = (file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', e => {
        formData.value.image = e.target.result
      })
    }

    const send = async () => {
      const isValid = await context.refs.observer.validate()
      if (!formData.value.image && formData.value.is_hall_image) {
        context.refs.observer.setErrors({
          'image': [context.root._('field is required')]
        })
        return
      }


      if (isValid) {
        try {
          loading.value = true
          const res = await useResponse(PSS.requestHall(formData.value), context.root, context.refs.observer)
          if (res.data) {
            context.emit('close')
          }
          loading.value = false
        } catch (error) {
          console.error(error)
          loading.value = false
        }
      } else {

      }
    }

    const noImageActivate = () => {
      if (!formData.value.is_hall_image) {
        formData.value.image = ''
      }
    }

    return {
      loading,
      formData,
      addFile,
      send,
      noImageActivate
    }
  }
}
</script>