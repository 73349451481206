<template lang='pug'>
  include /mixins
  div
    +b.control-input--variant_clear(v-if='pagination && pagination.total && pagination.limit && (pagination.total.value > pagination.limit.value || pagination.total > pagination.limit)')
      //- (v-if='pagination.total')
      +e.element.--variant_clear.--variant_no-padding
        +b.row--justification_between.--alignment_center.--space_xs
          //- .pagination.pagination--variant_primary
          slot(name='hint')
            +b.cols.cell
              v-caption(
                size='6'
                color='default'
                variant='bold'
              ) {{ pagination.total || (pagination.total && pagination.total.value) ? current : 0}}
            +b.cols.cell
              v-caption(
                size='6'
                color='default'
                variant='bold'
              ) {{ _('from') }} {{ typeof pagination.total === 'number' ? pagination.total : (pagination.total && pagination.total.value) || 0 }}
          +b.cols--auto.cell
            +b.pagination--variant_primary
              .pagination__body
                +b.row.--space_5xs
                  +b.cols.cell
                    +b.pagination__item--variant_icon.cols(
                      @click='$emit("set", pagination.currentPage.value ? (pagination.currentPage.value - 1) : (pagination.currentPage  - 1))' 
                      :class='{"is-disabled": pagination.offset && pagination.offset.value ? !pagination.offset.value  : !pagination.offset}'
                    )
                      icon(
                        type='left_arrowhead' 
                        size='md'
                      )
                  +b.cols.cell
                    +b.pagination__item--variant_icon.cols(
                      :class='{"is-disabled":pagination.offset && pagination.currentPage.value ? (pagination.currentPage.value === pagination.lastPage.value) : (pagination.currentPage === pagination.lastPage || !pagination.total)}'
                      @click='$emit("set", pagination.currentPage.value ? (pagination.currentPage.value  + 1) : (pagination.currentPage + 1))'  
                    )
                      icon(
                        type='right_arrowhead' 
                        size='md'
                      )
</template>

<script>
import { createComponent, computed } from '@vue/composition-api'
export default createComponent({
  props: {
    pagination: Object,
  },
  setup(props) {
    const current = computed(() => {
      const { pagination } = props
      if (pagination.limit && pagination.limit.value) {
        return `${Math.max(pagination.offset.value + 1, pagination.offset.value - pagination.limit.value + 1)} - ${Math.min(pagination.offset.value + pagination.limit.value, pagination.total.value)}`
      }
      return `${Math.max(pagination.offset + 1, pagination.offset - pagination.limit + 1)} - ${Math.min(pagination.offset + pagination.limit, pagination.total)}`
      
    })
    return {
      current
    }
  }
})
</script>

