<template lang="pug">
  include /mixins
  card
    +b.scrollable-wrapper--full
      +b.TABLE.table--space_lg.--variant_inner
        +e.THEAD.thead
          +e.TR.row--relief
            +e.TH.cell--variant_bold.--bordered_left_top.--bordered_left_bottom.--relief
              v-caption(size='6' color='white') {{ _('c Order #')}}
            +e.TH.cell--variant_bold.--relief
              v-caption(size='6' color='white') {{ _('c Date')}}
            +e.TH.cell--variant_bold.--relief
              v-caption(size='6' color='white') {{ _('c Event name')}}
            +e.TH.cell--variant_bold.--bordered_right_top.--bordered_right_bottom.--relief
              v-caption(size='6' color='white') {{ _('c Amount')}}
        +e.TBODY.tbody
          +e.TR.row(v-for='order in userDetails' :key='order.id')
            +e.TH.cell.--relief-3
              v-caption(size='6') {{ order.number }}
            +e.TH.cell.--relief-3
              v-caption(size='6') {{ order.created_at | humanDate('en') }}
            +e.TH.cell.--relief-3
              v-caption(
                size='6' 
                v-for='(event, index) in order.events'
                :key='index'
              ) {{ event }}
            +e.TH.cell.--relief-3
              v-caption(size='6') $ {{ order.price | toUSD}}
</template>

<script>
export default {
  props: {
    userDetails: {
      required: true,
      type: Array
    }
  }
}
</script>