import Api, {editedSlug as provider} from '@cabinet/services'

let headers = provider ? {headers: {provider: provider}} : {headers: {}}


export default {
  create(data) {
    return Api().post(`rules/create/`, data, headers)
  },
  receive() {
    return Api().get(`rules/receive/`, {}, headers)
  },
  update(data) {
    return Api().put(`rules/update/`, data, headers)
  }
}
