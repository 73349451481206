<template lang='pug'>
  include /mixins
  loading-wrapper(:loading='loading')
    validation-observer(
      ref='observer'
      autocomplete='off'
      v-slot='{invalid}'
      tag='form'
    )
      validation(name='non field errors')
      validation(name='items')
      card-panel(v-if='items.length')
        card-panel
          +b.scrollable-wrapper--till-2xl.--full
            +b.TABLE.table--variant_scroll.--space_lg(style='min-width: initial')
              +e.THEAD.thead
                +e.TR.row--relief
                  +e.TH.cell--variant_bold.--head 
                    row(alignment='center' space='md' nowrap)
                      cell
                        v-checkbox
                          +b.INPUT(
                            type="checkbox"
                            v-model='allSelected'
                            @input='selectItems(!allSelected)'
                          ).control-checkbox__input
                      cell
                        v-caption(color='white'
                                  size='6') {{ _('d refund') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('d sector') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('d row') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('d seat') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('d discount type') }}
                  +e.TH.cell--variant_bold.--head 
                    v-caption(color='white') {{ _('d discount amount') }}
              +e.TBODY.tbody
                +e.TR.row(v-for='(discount, index) in items' 
                  :key='index'
                )
                  +e.TD.cell--bordered_left_top.--bordered_left_bottom
                    row(alignment='center' space='md' nowrap)
                      cell
                        v-checkbox(v-if='discount.sector_id')
                          +b.INPUT(
                            type="checkbox"
                            :value='discount'
                            @input=''
                            v-model='selectedSectors'
                          ).control-checkbox__input
                        v-checkbox(v-else)
                          +b.INPUT(
                            type="checkbox"
                            :value='discount'
                            @input=''
                            v-model='selectedSeats'
                          ).control-checkbox__input
                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ discount.sector }}

                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ discount.row }}

                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ discount.seat }}
                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ discount.mechanics }}
                  +e.TD.cell 
                    v-caption(
                      size='6'
                      color='default'
                    ) {{ discount.sale_value }}
        card-panel
          row(justification='center')
            cell(cols='narrow')
              v-button(
                :variant='["clear", "block"]' 
                hover='danger'
                @click='remove'
                :disabled='!(selectedSeats.length + selectedSectors.length)'
              )
                v-button-element
                  v-caption(
                    color='warning'
                    :variant='["uppercase", "bold"]'
                  ) {{ _('d remove') }} {{ (selectedSeats.length + selectedSectors.length) ? `${(selectedSeats.length + selectedSectors.length)} tickets`: ''}}
      card-panel(v-else)
        notice
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('no discount items in list')}}

</template>

<script>
import ShowService from '@cabinet/services/api/shows'
import { ref, onMounted } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    showId: {
      required: true
    }
  },
  setup(props, context) {
    
    const loading = ref(false)
    const allSelected = ref(false)
    const selectedSectors = ref([])
    const selectedSeats = ref([])
    const items = ref([])

    const remove = async () => {
      loading.value = true
      let concated = [] 
      concated.push(...selectedSectors.value)
      concated.push(...selectedSeats.value)


      let result = concated.reduce((acc, item) => {
        let i = acc.find(i => i.descriptor === item.id)

        if (i) {
          if (item.seat_id) {
            i.seats.push(item.seat_id)
          } else if (item.sector_id) {
            i.sectors.push(item.sector_id)
          }
        } else if (item.id) {
          let seats = []
          let sectors = []
          if (item.seat_id) {
            seats.push(item.seat_id)
          } else if (item.sector_id) {
            sectors.push(item.sector_id)
          }
          acc.push({
            descriptor: item.id,
            seats,
            sectors
          })
        }
        return acc
      }, [])

      try {
        let res = await useResponse(
          ShowService
            .deleteDiscount({ id: props.showId, data: { items: result }}), context.root, context.refs.observer
        )
        if (res) {
          items.value = (await ShowService.fetchDiscounts({ id: props.showId})).data.data  
        }
        loading.value = false
        context.emit('fetch')
      } catch (err) {
        loading.value = false
      }
    }

    const selectItems = (value) => {

      selectedSectors.value = value ? 
        items.value.filter(s => s.sector_id) : 
        []
      selectedSeats.value = value ? 
        items.value.filter(s => s.seat_id) : 
        []
    }
    
    onMounted(async () => {
      loading.value = true
      try {
        items.value = (await ShowService.fetchDiscounts({ id: props.showId })).data.data.sort((a, b) => parseFloat(a.seat) - parseFloat(b.seat))
        loading.value = false
      } catch (err) {
        loading.value = false
      }
    })

    return {
      allSelected,
      selectedSeats,
      selectedSectors,
      selectItems,
      remove,
      items,
      loading
    }
  }
}
</script>