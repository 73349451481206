<template lang="pug">
  include /mixins
  card
    card-panel(offset='full')
      row(offset)
        cell(cols='12 narrow-sm')
          +b.control-box
            v-button(
              variant='danger'
              border='whole'
              @click='addTour'
            )
              template(v-slot:prepend='')
                cell
                  v-button-element(
                    size='lg'
                    variant="warning-lite"
                  )
                    v-caption(variant='bold')
                      icon(
                        type='add'
                        size='md'
                        color='brand-2'
                        variant='opaque'
                      )
              cell(cols='auto')
                v-button-element(size='lg') 
                  v-caption(
                    :variant='["bold", "uppercase"]' 
                    size='6'
                  ) {{ _('Add new tour') }}
        cell(cols='12 8-md 7-lg 6-xl 5-2xl')
          ui-search(
            @input='send' 
            @open='visible = !visible'
          )
            template(v-slot:activator='{ data, send }')
              cell(cols='12 narrow-md')
                v-button(
                  size='lg'
                  :border='["top_right", "bottom_right"]'
                  @click='send(data)'
                  variant='success'
                )
                  cell(cols='auto' )
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ _('apply') }}
            template(v-slot:additional-filters='{ data, send }')
              ui-filter(
                ref='filter'
                @statuses='setData'
                :data='data'
                @input='send'
                :visibility='visible'
              )



    card-panel(offset='horizontal')
      row(offset)
        cell(cols='12')
          ui-tabs(ref='tabs')
            template(v-slot='{ activeItem, activate }')
              row(offset space='xs')
                cell(
                  cols='narrow'
                  
                  v-for='(item, index) in [ { type: "active", text: _("Active") }, { type: "archived", text: _("Archived") } ]'
                  :key='index'
                )
                  v-button(
                    @click='activateTab(index, item.type, activate)'
                    :variant='["clear", "block", "shadow", "tab", item.type !== type ? "opaque": ""]'
                    border='round'
                  )
                    v-button-element(size='md')
                      v-caption(
                        size='4'
                        variant='bold'
                        :color="item.type === type ? 'warning' : 'default'"
                      ) {{ item.text }}
      card-panel(
        offset='vertical' 
        ref='wrapper'
        style='position: relative'
        v-if='states'
      )
        row(offset
          space='lg' 
          v-if='events.events_row.length'            
        )
          cell(
            cols='12 6-sm 4-lg  2-10-2xl' 
            v-for='(event, index) in events.events_row'
            :key='`cell-${event.id}-${index}`'
            )
            transition-group(name='fade')
              ui-event(
                :event='event' 
                :states='states' 
                v-if='states' 
                :key='`event-${event.id}`'
                @loading='deleteEvent($event)'
              )
        row(offset v-else)
          cell(cols='12')
            notice
              template(v-slot:icon='')
                icon(
                  type='error' 
                  color='warning' 
                  size='5xl'
                )
              template(v-slot:caption='')
                v-caption(variant='bold') {{ _('Event list is empty')}}

    card-panel(offset='full')
      ui-pagination(
        :from-client='true'
        :offset='events.pagination.offset'
        :total='events.pagination.total'
        :limit='events.pagination.limit'
        v-if='events.pagination.total > events.pagination.limit'
        @input='sendData($event, events.pagination.limit)'
      )
        

</template>
<script>
import { mapActions, mapState } from 'vuex'
import { formatToUTC } from '@cabinet/util'

import { useOpenTourModal } from '@cabinet/composables/use-tour-modal'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import UiFilter from '@cabinet/components/forms/UiFilter'
import UiTabs from '@cabinet/components/vendor/UiTabs'
import UiSearch from '@cabinet/components/forms/UiSearch'
import UiEvent from '@cabinet/components/default/UiEvent'
export default {
  computed: {
    ...mapState('eventsModule', [
      'type', 'events'
    ]),
  },
  components: {
    UiPagination,
    UiFilter,
    UiTabs,
    UiSearch,
    UiEvent,
  },
  data() {
    return {
      visible: false,
      active: false,
      isButtonVisible: false,
      states: null,
      loading: false,
    }
  },
  methods: {
    expand() {
      this.active = !this.active
    },
    /**
     * activateTab complex function
     * @param   { number } index is an index of tab the to be selected
     * @param   { string } type is the type of store events to be saved 
     * as current inside store by calling this.setType(type) method
     * @param { function } fn is the function which passed here 
     * from slot scope of parent to activate specific tab on click
     * to learn more see UiTabs.vue file
     */
    activateTab(index, type, fn) {
      fn(index)
      let date = formatToUTC()
      this.loading = true
      if (type == 'archived') {
        this.setType({ type, finish_at__lt: date })
        this.send({finish_at__lt: date})
      } else {
        this.setType({ type, finish_at__gte: date })
        this.send({finish_at__gte: date})
      }
    },
    async send(search) {
      this.loading = true
      const isSearchFull = Object.entries(search).filter(([key, value]) => value && value.length).length
      if (!isSearchFull) {
        if (this.type == 'archived') {
          search.finish_at__gte = ''
          search.finish_at__lte = ''
          search.finish_at__lt = formatToUTC()
        } else {
          search.finish_at__gte = formatToUTC()
          search.finish_at__lte = ''
        }
      }
      this.fetchEvents(search)
      
      this.loading = false
      // await filter.send( search )
      //   .then(res => this.loading = false)
    },
    ...mapActions({
      setType: 'eventsModule/setType',
      fetchEvents: 'eventsModule/fetchEvents',
    }),
    async sendData(data) {
      
      await this.send({ id: this.$route.params.id, page: data})
      
      let { wrapper } = this.$refs
      if ( wrapper.$el ) {
        let { top } = wrapper.$el.getBoundingClientRect()
        window.scrollBy({
          top: top - 200,
          left: 0,
          behavior: 'smooth'
        })
      }
      this.loading = false
    },
    checkWidth() {
      if (window.innerWidth < 1366) {
        this.isButtonVisible = true
      } else {
        this.isButtonVisible = false
        this.active = true
      }
    },
    setData({eventStatuses, eventTypes}) {
      this.states = {
        eventStatuses, eventTypes
      }
    },

    activateRef() {
      this.activateTab(1, 'archived', this.$refs.tabs.activate)
    },
    addTour() {
      useOpenTourModal(0, this, null, this.activateRef)
    },
    deleteEvent(event) {
      this.loading = event.state
      if (event.resp) {
        this.activateTab(1, this.type, e => {})
      } else {
        return
      }
    },
  },
  mounted() {
    // let date = formatToUTC()
    // this.send({finish_at__gte: date})
    this.checkWidth()
    window.addEventListener('resize', this.checkWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth)
    let date = formatToUTC()
    this.setType({ type: 'active', finish_at__gte: date })
  }
}
</script>

