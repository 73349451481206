<template lang="pug" >
  include /mixins
  +b.modal-wrapper--absolute#infowindow
    card
      card-panel(
        offset='bottom'
        v-for='(infoBlock, index) in infoBlocks'
        :key='index'
      )
        card-label(
          :position='["right", "top"]'
          style='top: -26px;right: -15px;'
        )
          v-tag(
            v-if='infoBlock.s && infoBlock.s.booked'
            type='warning'
            style='color:white'
          ) {{ _('unavailable') }}



        row(
          justification='between'
          alignment='start'
          offset
          space='5xs'
          v-if='infoBlocks.length === 1 && infoBlocks[0].s'
        )
          cell(cols='narrow')
            row(offset space='5xs')
              cell(cols='12')
                v-caption(size='5' variant='secondary') {{ _('sector') }}
              cell(cols='12')
                v-caption(
                  size='5'
                  variant='bold'
                ) {{ infoBlock.s.sector.title || infoBlock.s.sector }}
          cell(cols='narrow')
            row(offset space='5xs')
              cell(cols='12')
                v-caption(size='5' variant='secondary') {{ sectorType.type }}
              cell(cols='12')
                v-caption(
                  size='5'
                  variant='bold'
                ) {{ sectorType.name }}
          cell(cols='narrow' v-if='!infoBlock.s.sector.title')
            row(offset space='5xs')
              cell(cols='12')
                v-caption(size='5' variant='secondary') {{ _('seat') }}
              cell(cols='12')
                v-caption(
                  size='5'
                  variant='bold'
                ) {{ infoBlock.s.title }}
          cell(cols='narrow' v-else)
            row(offset space='5xs')
              cell(cols='narrow')
                v-caption(size='5' variant='secondary') {{ _('tickets left') }}
              cell(cols='narrow')
                v-caption(
                  size='5'
                  variant='bold'
                ) {{ infoBlock.s.totals_seats }}
        row(
            justification='between'
            alignment='start'
            offset
            )
          cell(cols='12')
            row(offset space='5xs')
              cell(cols='12')
                v-caption(size='5' variant='secondary') {{ _('title') }}
              cell(cols='12')
                v-caption(
                  size='5'
                  variant='bold'
                ) {{ infoBlock.title }}
          cell(cols='12' v-if='infoBlock.s && !infoBlock.s.booked')
            row(offset space='5xs')
              cell(cols='12')
                v-caption(size='5' variant='secondary') {{ _('price') }}
              cell(cols='12')
                v-caption(
                  size='5'
                  variant='bold'
                ) {{ currency }} {{ infoBlock.price | toUSD }}
          cell(cols='narrow' v-if='sectorType.name === "fan zone"')
            v-caption(
              size='5'
              variant='bold'
            ) {{ infoBlock.sector.title }}

</template>

<script>
export default {
  props: {
    infoBlocks: {
      default: () => [],
      type: [Object, Array]
    },
    sectorType: {
      default: () => {}
    },
    currency: {
      required: false,
      type: String,
      default: 'USD',
    },
  },
}
</script>
