<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card
      card-panel(offset='full')
        card
          card-panel(offset='bottom_xl')
            GeneralStatistics(
              :stats='stats'
              @refresh='fetchStats({refresh: 1})'
            )
          router-view
            template(v-slot:time='')
              v-caption(v-if='stats.last_update.length' size='6' color='success') {{_('stats last update: ')}} {{ stats.last_update[1] | humanDate(offset) }}


</template>

<script>
import StatsService from '@cabinet/services/api/stats'
import { onMounted, ref } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import GeneralStatistics from '@cabinet/components/dashboard/GeneralStatistics'

export default {
  components: {
    GeneralStatistics,
  },
  setup(props, context) {
    const stats = ref([])
    const loading = ref(false)

    const offset = ref(new Date().getTimezoneOffset() / -60)
    
    const fetchStats = async (data) => {
      loading.value = true
      try {
        let res = await useResponse(StatsService.getGeneral(data), context.parent)
        stats.value = res.data
        loading.value = false
      } catch (err) {
        console.error(err)
        loading.value = false
      }
      
    }
    onMounted(async () => {
      await fetchStats()
    })
    return {
      stats, 
      loading, 
      fetchStats,
      offset
    }
  }
}
</script>