import Api, {editedSlug as provider} from '@cabinet/services'


export default {
  fetch(data) {
    return Api(provider).get(`/usher/list/`, {
      params: data
    })
  },
  createUsher(data) {
    return Api(provider).post('/usher/create/', data)
  },
  updateUsher(id, data) {
    return Api(provider).put(`/usher/${id}/update/`, data)
  },
  killUsher(data) {
    return Api(provider).post(`/usher/remove/`, data)
  },
  addShow(data) {
    return Api(provider).post('/usher/add/show/', data)
  },
  getUsherShowList(id) {
    return Api(provider).get(`/usher/${id}/show/list/`)
  },
  removeShow(data) {
    return Api(provider).post(`/usher/remove/show/`, data)
  },
  getShowUshersList(id) {
    return Api(provider).get(`/usher/show/${id}/list/`)
  },
}