import { successHandler, errorHandler } from '@cabinet/util'


export async function useResponse(promise, vue, observer) {
  return await promise.then(res => {
    successHandler(res.data, vue)
    return {
      data:  res.data.data,
      meta: res.data.meta
    }
  })
  .catch(err => {
    if (err.response.data) {
      // console.error(err.response)
      let { data: errors } = err.response
  
      throw errorHandler(errors.errors, observer, err.response, vue)
    }
  })
}