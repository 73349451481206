<template lang="pug">
  include /mixins
  +b.scrollable-wrapper--till-2xl.--full(style='max-height: initial')
    +b.TABLE.table--variant_scroll.--variant_stats.--space_lg(style='min-width: 1300px; table-layout: fixed; min-height: initial')
      colgroup
        col(style='width: 18%')
        col(style='width: 18%')
        col(style='width: 18%')
        col(style='width: 4%')
        col(style='width: 6%')
        col(style='width: 9%')
        col(style='width: ')
        col(style='width: 10%')
      +e.THEAD.thead
        +e.TR.row--relief
          +e.TH.cell(style='width: 18%').--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
            v-caption(color='white') {{ _('v Tour name') }}

          +e.TH.cell(style='width: 18%').--variant_bold.--head
            v-caption(color='white') {{ _('v Events') }}

          +e.TH.cell(style='width: 18%').--variant_bold.--head 
            v-caption(color='white') {{ _('v Vendors') }}

          +e.TH.cell(style='width: 4%').--variant_bold.--head.--bordered_right

          +e.TH.cell(style='width: 6%').--variant_bold.--head 
            v-caption(color='white') {{ _('v Price') }}

          +e.TH.cell(style='width: 9%').--variant_bold.--head 
            v-caption(color='white') {{ _('v Refund %') }}

          +e.TH.cell(style='width: ').--variant_bold.--head 
            v-caption(color='white') {{ _('v Тotal') }}

          +e.TH.cell(style='width: 10%').--variant_bold.--head.--bordered_right_top.--bordered_right_bottom
            v-caption(color='white') {{ _('v Status') }}
      vendors-finance-row(
        v-for='(tour, index) in items' 
        :tour='tour' 
        :key='tour.id'
      )
</template>

<script>
import VendorsFinanceRow from './VendorsFinanceRow'
export default {
  components: {
    VendorsFinanceRow
  },
  props: {
    items: {
      required: true,
      type: Array
    }
  },
}
</script>