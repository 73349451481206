<template lang="pug">
  loading-wrapper(:loading='loading')
    card
      SecurityAccordion
        template(v-slot:caption='')
          slot(name='caption')
        template(v-slot:content='')
          card-panel(offset='top')
            validation-observer(
              ref='observer'
              tag='form'
              v-slot='{invalid}'
              @submit.prevent='send'
              autocomplete='off'
            )
              row(offset)
                cell(cols='12')
                  validation-provider(
                    name='text'
                    rules='required'
                    v-slot='{errors}'
                  )
                    wysiwyg(
                      v-model="letter.text"
                    )
                    transition(name='fade')
                      .form__error(v-if='errors.length') {{ errors[0] }}
                cell(cols='12')
                  p(
                    style='white-space: pre'
                    v-html='template.legend'
                  )

                cell(
                  cols='narrow' 
                  justification='right'
                )
                  v-button(
                    variant='success'
                    type='submit'
                  )
                    v-button-element(size='lg') 
                      v-caption(
                        :variant='["bold", "uppercase"]' 
                        size='6'
                      ) {{ template.provider_template ? _('Update') : _('m Save') }}



          

</template>

<script>
import { useResponse } from '@cabinet/composables/use-response'
import LettersService from '@cabinet/services/api/letters'
import { ref, onMounted } from '@vue/composition-api'

import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'

export default {
  props: {
    template: {
      required: true,
      type: Object
    }
  },
  components: {
    SecurityAccordion,
  },
  setup(props, context) {
    const loading = ref(false)

    const letter = ref({
      text: '',
      template: props.template.id,
      
    })

    const fetchTemplate = async (query) => {
      try {
        loading.value = true
        const res = await useResponse(
          LettersService.fetchTemplate({ id: props.template.provider_template }),
          context.root,
          context.refs.observer
        )
        if (res.data) {
          letter.value = res.data
        }
        
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
    }

    const createTemplate = async () => {
      try {
        loading.value = true
        const res = await useResponse(
          LettersService.createTemplate({
            data: letter.value
          }),
          context.root,
          context.refs.observer
        )
        if (res.data) {
          letter.value = res.data
        }
        
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
    }

    const updateTemplate = async () => {
      try {
        loading.value = true
        const res = await useResponse(
          LettersService.updateTemplate({
            id: props.template.provider_template,
            data: letter.value,
          }),
          context.root,
          context.refs.observer
        )
        if (res.data) {
          letter.value = res.data
        }
        
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
    }


    const send = async () => {
      const isValid = await context.refs.observer.validate()
      if (isValid) {
        !props.template.provider_template ? createTemplate() : updateTemplate()
      }
    }


    onMounted(() => {
      if (props.template.provider_template) {
        fetchTemplate()
      }
    })

    return {
      loading,
      letter,
      send,

      createTemplate
    }
  }
}
</script>