<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    row(offset)
      cell(cols='12')
        v-caption(size='4' variant='bold') {{ _('Choose invoice') }}
      cell(cols='12')
        v-select(v-model='invoice'
                  :val='invoice'
                  v-if='invoices'
                  :options='invoices'
                  size='lg'
                  bg='relief-3'
                  :placeholder="_('Pick invoice')"
                  name='invoice')
      cell(cols='12')
        v-button(
          @click='allow(invoice)'
          variant='clear'
          hover='success'
          
        )
          v-button-element(size='lg')
            v-caption(size='5' variant='bold' color='success') {{ _('Ok') }}

            
</template>
<script>
export default {
  name: 'UiAgentInvoiceChoice',
  props: {
    invoices: {
      type: Array
    },
    promise: {
      type: Function
    }
  },
  data() {
    return {
      invoice: {}
    }
  },
  methods: {
    allow(invoice) {
      this.$emit('close')
      this.promise(invoice)
    }
  },

}
</script>
