<template lang="pug" functional>
  include /mixins
  card(:variant='["tour", "section"]')
    //- +b.SPAN.label--warning.--tiny.ticket__label(style='right: 0;position: absolute;left: auto' 
    //-                                      v-if='!props.ticket.free_seats') 
    //-   slot(name='label')
    card-panel(offset="vertical")
      row(space='none', alignment='center')
        cell(cols='12 4-sm 6-lg')
          card-panel(
            offset='full-md' 
          )
            row(offset column='till-sm')
              cell(cols='12-sm')
                v-caption(
                  size='6' 
                  :variant='["bold", "center-till-sm", "break-all"]' 
                  color='default'
                ) 
                  slot(name='title') 
                    | {{ props.ticket.title }}
              cell(cols='12-sm')
                v-caption(
                  size='6' 
                  :variant='["bold", "center-till-sm"]' 
                  color='default'
                ) 
                  slot(name='sector-title') 
                    
              cell(cols='12-sm' v-if='props.isLeftTickets')
                v-caption(size='6' variant='center-till-sm')
                  span
                    slot(name='tickets')
                  strong(:style='{opacity: !props.isLeftTickets ? "0" : "1"}') {{ props.isLeftTickets ? props.ticket.free_seats : '-'}} 
        cell(
          cols='7 4-sm 3-lg' 
          :style='{"pointer-events": !props.ticket.free_seats ? "none" : "all"}'
        ).ticket-order-1
          card-panel(
            offset='full-md' 
            :fullheight='true'
          )
            row(
              alignment='center' 
              offset
              space="2xs"
              :fullheight='true'
              justification='between'
            )
              cell(cols='narrow 12-md')
                v-caption(size='6' :variant='["regular", "center"]') 
                  slot(name='price')
                  
              cell(cols='7 12-md')
                slot(name='counter')
        cell(cols='auto 4-sm 3-lg').ticket-order-2
          card-panel(
            offset='full-md' 
            style='display:flex;align-items:center;justify-content: center'
          )
            row(
              offset 
              justification='center' 
              style='width:100%'
              space="2xs"
            )
              cell(cols='12')
                a.link(
                  @click.prevent='props.addTicket(props.ticket)'
                  :style='{"cursor": !props.ticket.free_seats ? "not-allowed" : "pointer"}'
                )
                  row(offset column alignment='center', space="2xs")
                    cell(cols='narrow')
                      icon(
                        type='cart'
                        :color='!props.ticket.free_seats || saleStarted === "False" ? "warning": "success"'
                      )
                    cell(cols='12')
                      v-caption(
                        size='6 5-md' 
                        variant='center'
                        :color='!props.ticket.free_seats || saleStarted === "False" ? "warning": "success"'
                      )
                        slot(name='button-text') 
</template>

<script>

export default {
  props: {
    saleStarted: {
      default: () => {}
    }, 
    ticket: {
      default: () => {}
    },
    addTicket: {
      default: () => {},
      type: Function
    },
    isLeftTickets: {
      type: Boolean
    }
  }
}
</script>

