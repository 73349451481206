<template lang="pug">
  include /mixins
  card
    card-panel(offset='full')
      row(offset)
        cell(cols='12 6-sm 4-lg 2-xl')
          .form__control
            label.form__label 
              FlatPickr(
                v-model='date'
                :config='config'
                name='date'
                :placeholder="_('Date from - Date to')"
              ).flatpickr-input--size_lg.flatpickr-input--border_full.flatpickr-input--bg_relief-3
        cell(cols='12 8-md 7-lg 5-2xl')
          UiSearch(
            type='search'
            @input='fetch({ ...query, search: $event.search })'
            bg='relief-3'
          )
            template(v-slot:activator='{send, data}')  
              cell(cols='12 narrow-md')
                v-button(
                  :border='["right_top", "right_bottom"]'
                  @click='send'
                  variant='success'
                )
                  cell(cols='auto')
                    v-button-element(size='lg') 
                      v-caption(
                        size='5'
                        :variant='["uppercase", "bold"]'
                      ) {{ _('l search') }}

        cell(cols='12 6-sm narrow-md')
          .control-box
            v-button(
              size='lg'
              border='whole'
              @click='reset'
            )
              cell(cols='auto')
                v-button-element(size='lg') 
                  v-caption(
                    size='5'
                    :variant='["uppercase", "bold"]'
                  ) {{ _('f clear') }}
        cell(justification='right-sm')
          pagination(:pagination='meta' @set='fetch({page: $event, ...query})')
    card-panel(offset='horizontal')
      loading-wrapper(:loading='loading')
        +e.panel--offset_vertical(style='overflow: hidden')
          +b.scrollable-wrapper--till-2xl.--full
            +b.TABLE.table--variant_scroll.--space_lg.--variant-group-bordered
              +e.THEAD.thead
                +e.TR.row--relief
                  +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom(width='15%')
                    v-caption(color='white') {{ _('l Order №') }}
                  +e.TH.cell--variant_bold.--head(width='35%')
                    v-caption(color='white') {{ _('l Subject') }}
                  +e.TH.cell--variant_bold.--head(width='35%') 
                    v-caption(color='white') {{ _('l Status') }}
                  +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom(width='15%')
                    v-caption(color='white') {{ _('l Date') }}
              Letter(
                v-for='letter in letters' 
                :key='letter.id' 
                :letter='letter'
              )


</template>

<script>

import { useResponse } from '@cabinet/composables/use-response'
import LettersService from '@cabinet/services/api/letters'
import usePagination from '@cabinet/composables/use-pagination'
import { ref, onMounted, watch } from '@vue/composition-api'
import Pagination from '@cabinet/components/vendor/Pagination'
import FlatPickr from 'vue-flatpickr-component'
import UiSearch from '@cabinet/components/forms/UiSearch'
import Letter from '@cabinet/components/letters/Letter'

export default {
  components: {
    Pagination,
    FlatPickr,
    UiSearch,
    Letter,
  },
  setup(props, context) {
    const date = ref('')
    const loading = ref(false)

    const query = ref({
      created_at__gte: '',
      created_at__lte: '',
      search: '',
    })

    const config = ref({
      mode: 'range',
      disableMobile: 'true',
      dateFormat: 'm/d/Y',
    })

    const letters = ref([])
    const meta = ref({})

    watch(date,
      (values) => {
        let val = values.split(' to ')
        if (val.length > 1) {
          let created_at__gte = val[0].replace(/\//g, '-').split('-')
          let created_at__lte = val[1].replace(/\//g, '-').split('-')

          created_at__gte = [
            created_at__gte[2],
            created_at__gte[0],
            created_at__gte[1]
          ].join('-')
          created_at__lte = [
            created_at__lte[2],
            created_at__lte[0],
            created_at__lte[1]
          ].join('-')

          query.value.created_at__gte = created_at__gte
          query.value.created_at__lte = created_at__lte
        } else {
          let created_at__gte = values.replace(/\//g, '-').split('-')
          created_at__gte = [
            created_at__gte[2],
            created_at__gte[0],
            created_at__gte[1]
          ].join('-')
          query.value.created_at__gte = created_at__gte
          query.value.created_at__lte = created_at__gte

        }
      }, {
        lazy: true
      }
    )

    
    
    const fetch = async (data) => {
      try {
        loading.value = true
        const res = await useResponse(LettersService.fetch({params: data}), context.root, context.refs.observer)
        letters.value = res.data
        meta.value = usePagination(res.meta.pagination)
        loading.value = false
      } catch (error) {
        console.error(error)
        loading.value = false
      }
    }

    const reset = () => {
      query.value = {
        created_at__gte: '',
        created_at__lte: '',
        search: '',
      }
      fetch()
    }
    onMounted(() => {
      fetch()
    })

    return {
      loading,
      date,
      config,
      query,
      reset,

      letters,
      meta,
      fetch,
    }
  }
}
</script>

