
import Vue from 'vue'
import Vuex from 'vuex'
import ticketsModule from './tickets'
import userModule from './user'
import eventsModule from './events'
import showsModule from './shows'
import agentsModule from './agents'
import vendorsModule from './vendors'
import ordersModule from './orders'
import hallModule from './hall'
import cartModule from './cart'
import paymentsModule from './payments'

Vue.use(Vuex)

const options = {
  modules: {
    ticketsModule,
    userModule,
    eventsModule,
    showsModule,
    agentsModule,
    vendorsModule,
    ordersModule,
    hallModule,
    cartModule,
    paymentsModule
  }
}
console.log(options);


const store = new Vuex.Store(options)

export default store

