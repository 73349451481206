<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    +b.form.FORM
      preloader(v-if='loading',
                class="preloader--with-trans")
      +e.control--100
        h2.default {{ _('print tickets ') }} 
      //- pre {{ form.sectors }}
      +e.control--100
        tickets-layout(v-for='(ticket, index) in form.price_descriptor'
                      :ticket='ticket'
                      :key='index'
                      :form='form'
                      :sectors='sectors'
                      :withdraw='true'
                      v-if='!ticket.color'
                      :index='index')
                      //- v-if='typeof ticket.info == "Array"'
      +e.control--100.--no-padding
        ui-button(@click='withdraw'
                  btn-class='btn--flex btn--center btn--success').btn-wrapper--fixed
          +b.btn__icon--warning
          +b.btn__text {{ _('print') }}


</template>

<script>
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import TicketsLayout from '@cabinet/components/show/TicketsLayout'
import UiSelect from '@cabinet/components/forms/blocks/UiSelect'
import UiButton from '@cabinet/components/forms/blocks/UiButton'
import { errorHandler } from '@cabinet/util'
import { mapActions, mapState } from 'vuex'
import Preloader from '@cabinet/components/vendor/Preloader'
import TicketsService from '@cabinet/services/api/tickets'
export default {
  components: {
    UiInput,
    TicketsLayout,
    UiSelect,
    UiButton,
    Preloader
  },
  computed: {
    ...mapState('vendorsModule', ['colors']),
    ...mapState('agentsModule', ['agents']),
  },
  data() {
    return {
      form: this.info,
      sectors: [],
      loading: false
    }
  },
  props: {
    itemId: {
      type: Number,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
  },
  methods: {
    ...mapActions('hallModule', [
      'getSectorsList',
      'fetchHall',
      'getSectorData',
      'getSectorsListWithShow'
    ]),
    
    ...mapActions('vendorsModule', [
      'fetchVendors',
    ]),
    ...mapActions('agentsModule', [
      'fetchAgents', 
      'addWithdraw'
    ]),
    ...mapActions('showsModule', [
      'addShowWithdraw'
    ]),
    addTickets() {
      this.form.price_descriptor.push({
        title: '',
        price: '',
        color: '',
        info: [
          {
            sector: [],
            row: [],
            seats: [],
            amount: 0
          },
        ],
      })
    },
    async mapSectors() {
      this.loading = true
      let promises = this.form.sectors.map(
        async s => (await this.getSectorData({id: s.id, showId: this.itemId})).data.data
      )
      this.sectors = await Promise.all(promises)
      
      this.sectors.forEach(s => {
        s.row.sort((a, b) => a.id - b.id)
        s.row.forEach(r => r.seat.sort((a, b) => a.title - b.title))
        
      })
      this.loading = false
    },
    withdraw() {
      let data = this.form.price_descriptor[this.form.price_descriptor.length - 1].info.reduce((acc, obj, index) => {
        acc.seats = acc.seats || []
        acc.sectors = acc.sectors || []
        if (obj.row && Object.keys(obj.row).length) {
          obj.ids.forEach(el => {
            acc.seats.push(el)
          })
        } else {
          if (obj.sector.type == "no_seats") {
            acc.sectors.push({id: obj.sector.id, amount: obj.amount})
          } else {
            acc.sectors.push(obj.sector.id)
          }
        }
        return acc
      }, {}) 
      this.$validator.validateAll().then(result => {

        if (result) {
          this.loading = true
          let totalInfo = {
            id: this.itemId,
            data: data
          }
          TicketsService.createTicket(totalInfo)
            .then(res => {
              res.data.data.links.forEach(link => {
                window.open(link, '_blank')
              })
              this.loading = false
              // this.$emit('close')
              // successHandler(res.data, this)
            })
            .catch(err => {
              //(err)
              let { data: errors } = err.response
              errorHandler(errors.errors, this)
              this.loading = false
            })
        }
      })
    }
  },
  async created() {
    this.loading = true
    this.addTickets()
    this.fetchVendors()
    this.fetchAgents()
    this.form.sectors = this.sectors = (await this.getSectorsListWithShow(this.itemId)).data
    
    // this.formData = (await this.fetchHall(this.form.hall.id)).data
    this.form.sectors.forEach(s => {
      s.checked = true
      s.amount = 0
      s.editable = true
      s.writable = true
    })

    this.form.sectors = this.form.sectors.sort((a, b) => {
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }
      return 0
    })
    this.mapSectors()
    this.loading = false
  },

}
</script>
