import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import UiAccordion from './UiAccordion.vue'
import UiAccountHeader from './UiAccountHeader.vue'
import UiClickOutside from './UiClickOutside.vue'
import UiHeader from './UiHeader.vue'
import UiCurrenciesList from './UiCurrenciesList.vue'

export default function install(Vue) {
  Vue.component('UiAccordion', UiAccordion)
  Vue.component('UiAccountHeader', UiAccountHeader)
  Vue.component('UiClickOutside', UiClickOutside)
  Vue.component('UiHeader', UiHeader)
  Vue.component('UiCurrenciesList', UiCurrenciesList)
}
