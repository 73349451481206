<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    card()
      card-panel(:offset='["horizontal", "top"]')
        v-button(
          tag='router-link'
          :to='"/account/providers-list/"'
          :variant='["clear", "narrow"]' 
          alignment='center'
        )
          template(v-slot:prepend='')
            cell
              v-button-element()
                icon(
                  type='left-arrow' 
                  color='default'
                  size='xs'
                )
          cell(cols='auto')
            v-button-element
              v-caption(
                variant='bold' 
                size='6'
                color='default'
              ) {{ _('ag back') }}
      card-panel(offset='full')
        card-panel(offset='bottom')
          card(variant='white')
            card-panel(:offset='["full", "full_xl-xl"]')
              ProviderData(:provider='provider')
        card-panel  
          +b.FORM.form
            row(offset)
              cell(cols='12')
                +b.UI-TABS.tabs.mt-1(ref='tab')
                  template(v-slot='{ activeItem,activate: activateTab }')
                    +b.tab-list
                      +e.body
                        +e.toggle--variant_default.tab-list__item(
                          @click='activate(index);activateTab(index)'
                          v-for='(item, index) in stepsList'
                        )
                          
                          v-caption(
                            :color="activeItem === index ? 'default' : 'brand-5' "
                          ) {{ item.name }}
        card(variant='section' stacked='top')
          card-panel(v-if='stepsList[0].active' offset='full')
            card-panel(offset='bottom')
              FinanceFilter(
                :meta='meta' 
                :types='types'
                @fetch='getProvidersTransactions($route.params.id, $event)'
              )
            card-panel(offset='bottom')
              FinanceList(:items='transations')
          card-panel(v-if='stepsList[1].active' offset='full')
            card-panel(offset='bottom')
              row(offset)
                cell(justification='right-sm')
                  pagination(:pagination='meta' @set='fetchEventsList($route.params.id, {page:$event})')
              ProviderShows(:events='events')


</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import ProvidersService from '@cabinet/services/api/providers'
import { useResponse } from '@cabinet/composables/use-response'
import usePagination from '@cabinet/composables/use-pagination'

import Pagination from '@cabinet/components/vendor/Pagination'
import FinanceFilter from '@cabinet/components/finance/FinanceFilter'
import FinanceList from '@cabinet/components/finance/FinanceList'
import UiTabs from '@cabinet/components/vendor/UiTabs'
import ProviderShows from '@cabinet/components/providers/ProviderShows'
import ProviderData from '@cabinet/components/providers/ProviderData'

export default {
  components: {
    Pagination,
    FinanceFilter,
    FinanceList,
    UiTabs,
    ProviderShows,
    ProviderData,

  },
  setup(p, c) {

    const loading = ref(false)

    const meta = ref({})
    const transations = ref([])
    const events = ref([])
    const types = ref([])
    const provider = ref({})

    const stepsList = ref([
      {
        name: c.root._('p Transaction'),
        active: true
      },
      {
        name: c.root._('p Events'),
        active: false
      },
    ])

    const actions = ref([])

    const activate = (index) => {
      stepsList.value.forEach(s => s.active = false)
      stepsList.value[index].active = true
      actions.value[index](c.root.$route.params.id)
    }

    const getProvidersInfo = async () => {
      let res = await useResponse(ProvidersService.getProvidersInfo({ id: c.root.$route.params.id }), c.parent)
      provider.value = res.data
    }

    const getProvidersTransactions = async (id, data) => {
      
      loading.value = true
      let res = await useResponse(ProvidersService.getProvidersTransactions(id, data), c.parent)
      transations.value = res.data
      types.value = res.meta.types.map(([id, title]) => ({id, title}))
      meta.value = usePagination(res.meta.pagination)
      loading.value = false
    }

    const fetchEventsList = async (id, data = {}) => {
      loading.value = true
      let res = await useResponse(ProvidersService.fetchEventsList(id, data), c.parent)

      
      events.value = res.data
      meta.value = usePagination(res.meta.pagination)
      loading.value = false
    }

    actions.value = [getProvidersTransactions, fetchEventsList]

    onMounted(async () => {

      await getProvidersInfo()
      await getProvidersTransactions(c.root.$route.params.id)
    })

    return {
      loading,
      meta,
      transations,
      types,
      events,
      stepsList,
      activate,
      provider,
      

      getProvidersInfo,
      getProvidersTransactions,
      fetchEventsList
    }
  }
}
</script>