<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    validation-observer(
      ref='observer'
      @submit.prevent='send'
      tag='form'
      v-slot='{invalid}'
      autocomplete='off'
    )
      row(offset)
        cell(cols='12')
          v-caption(size='4' variant='bold') {{ _('Enter token') }}
          v-caption(size='5' variant='bold') {{ _('Some text') }}
        transition(name='fade')
          validation(name='non field errors')
        cell(cols='12')
          ui-input(
            :placeholder="_('Token')"
            :val='formData.otp_token'
            type='search'
            v-digitsonly='formData.otp_token'
            name='otp token'
            autofocus=''
            v-model='formData.otp_token'
          )
        cell(cols='12')
          +b.control-box
            v-button(
              variant='success'
              type='submit'
            )
              v-button-element(size='lg') 
                v-caption(
                  :variant='["bold", "uppercase"]' 
                  size='6'
                ) {{ _('send token') }}
</template>
<script>
// import UserService from '@cabinet/services/api/user'
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import { successHandler, errorHandler } from '@cabinet/util'
import axios from 'axios'
export default {
  name: 'UiTokenConfirm',
  components: {
    UiInput,
  },
  props: {
    url: {
      type: String,
    },
    loginData: {
      type: Object,
    },
    next: {
      required: false,
    },
    type: {
      default: false,
    },
  },
  data() {
    return {
      formData: {
        otp_token: null,
        email: this.loginData.email,
        password: this.loginData.password,
      },
    }
  },
  methods: {
    async send() {
      this.$refs.observer.validate().then(isValid => {
        if (isValid) {
          axios
            .post(this.url, this.formData)
            .then(response => {
              this.$emit('close')
              successHandler(response.data, this)
            })
            .catch(err => {
              //(err)
              let { data: errors } = err.response
              errorHandler(errors.errors, this.$refs.observer, err.response, this)
            })
        }
      })
    },
  },
}
</script>
