import Vue from 'vue'
import moment from 'moment'

Vue.filter('counter', (value) => {
  return value < 10 ? `0${value}` : value
})

Vue.filter('pluralize', (value, binding) => {
  return value == 1 ? binding : `${binding}s`
})

Vue.filter('currency', (value, binding) => {
  let signs = {
    USD: "$",
    CAD: "$"
  }
  return `${signs[binding]} ${value}`
})

Vue.filter('humanDate', (value, binding) => {
  if (value) {
    if (binding && typeof binding === 'object' && !binding.timeZoneName) {
      value = new Date(value)
      let hours = value.getHours()
      value.setHours(hours + binding.baseTimezone)
      value = new Date(value).toISOString()
    } else if (Number.isInteger(binding)) {

      let val = new Date(value).setUTCHours(new Date(value).getUTCHours() + binding)
      // let originalDate = new Date(value) 
      // originalDate.setHours(originalDate.getHours() + binding * 60 * 60)

      // let options = {
      //   timeZone: binding
      // }



      let date = new Date(val).toLocaleString("en-US")
      // let time = originalDate.toLocaleTimeString('en-US', options)
      return `${date}`
    } else if (binding === 'en' || (binding && binding.type == 'en')) {
      const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
      if (binding.timeZoneName) {
        const date = new Date(value)
        options.timeZone = 'America/New_York'
        options.timeZoneName = 'short'
        let d = new Date(date.valueOf() - date.getTimezoneOffset() * 60000)
        return d.toLocaleString('en-US', options)

      }
      return new Date(value).toLocaleString('en-US', options)
    }


    let dateAt = value.split('T')

    let dateAtTime = dateAt[1].split(':')
    let hours = dateAtTime[0] < 12 ? dateAtTime[0] : dateAtTime[0] - 12
    let sign = dateAtTime[0] < 12 ? 'AM' : 'PM'
    let properDateAtTime = `${[hours, dateAtTime[1]].join(':')} ${sign}`

    let date = dateAt[0].split('-')
    date = `${date[1]}/${date[2]}/${date[0]}`
    let time = properDateAtTime
    return `${date} ${binding && !binding.baseTimezone ? binding : ''} ${!binding || binding.baseTimezone ? '' : time}`
  }
})


Vue.filter('toUSD', (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? "-" : ""

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
  } catch (e) {
  }
})

Vue.filter('toUsDate', (value) => {
  if (!value) return ''
  const [d, m, y] = value.split('-')
  return `${m}/${d}/${y}`
})


Vue.filter('toUsTime', (value) => {
  if (!value) return ''
  return moment(value, 'HH:mm').format('h:mm:ss A')
})


// Vue.directive('text-only', (el, binding) => {
//   let element = [...el.querySelectorAll('input')][0]
//   let newValue = element.value.replace(/[0-9]/g, '')
//   element.value = newValue
//   // binding.value = element.value
// })

Vue.directive('digitsonly', (el, binding, vnode) => {
  let element = el.querySelector('input')

  const allowedChars = '0123456789.,'
  function contains(stringValue, charValue) {

    return stringValue.indexOf(charValue) > -1
  }

  if (!contains(allowedChars, element.value)) {
    let newValue = element.value.replace(/[a-zA-Z]+/ig, '')
    // newValue = newValue.replace(/,/, '.')
    if (newValue < 0) {
      newValue = 0
    }
    vnode.context.$nextTick(() => {
      element.value = newValue
    })
  }
})



Vue.directive('copypaste', {
  inserted(el, arg, node) {

    let tooltip = document.createElement('span')
    tooltip.classList.add('control-button__element', 'control-button__element--variant_tooltip')
    tooltip.innerHTML = arg.value.initial
    el.onmouseenter = () => {
      el.appendChild(tooltip)
    }
    el.onmouseleave = () => {
      el.removeChild(tooltip)
    }
    el.onclick = () => {
      let input = el.querySelector('input')
      input.select()
      try {
        document.execCommand("copy")

      } catch (err) {
      }
      tooltip.innerHTML = arg.value.clicked
      setTimeout(() => {
        tooltip.innerHTML = arg.value.initial
      }, 1500)
    }
  }
})

