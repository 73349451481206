<template lang="pug" functional>
  ui-accordion(
    :active='props.active'
  )
    template(v-slot='{activeState, toggleActive, collapse}')
      row(offset)
        cell(cols='12')
          v-button(
            @click='toggleActive' 
            justification='between'
            alignment='center'
          )
            template(v-slot:prepend='')
              cell
                v-button-element(size='lg')
                  v-caption(
                    size='5' 
                    variant='bold'
                  ) 
                    slot(name='caption') 
            cell()
              v-button-element(size='lg')
                icon(
                  :type='activeState ? "minus" : "add"' 
                  color='warning'
                  size='sm'
                )



        transition(name='fade')
          cell(cols='12' v-if='activeState')
            slot(name='content')
</template>

<script>
export default {
  props: {
    active: {
      default: true,
      type: Boolean
    }
  }
}
</script>