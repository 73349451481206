<template lang="pug">
include /mixins
div
  .modal-wrapper__close.btn.btn--flex.btn--center(@click='reject' v-if="!hasWrapper")
    icon(type='close')
  card(:style='{ borderLeft: "6px solid " + localitem.color }', variant='tour')
    card-label(:position='["right", "top"]', v-if='deletable')
      v-button(
        @click='_removeItem(localitem, localitem.count)',
        type='button',
        variant='clear'
      )
        v-button-element
          icon(type='close', color='warning', size='sm')

    card-label(
      :position='["right", "top"]',
      style='bottom: 0px; right: 0px; top: auto',
      v-if='isBooked'
    )
      v-tag(type='warning', style='color: white') {{ _("unavailable") }}

    card-panel(:offset='["full", "top_2sm"]')
      row(justification='between', offset)
        cell(cols='11', v-if='ga')
          v-caption(size='6', variant='bold') {{ localitem.title }}
        cell(cols='12')
          row(offset, justification='between', alignment='center')
            cell(
              cols='auto',
              v-if='!isBooked && localitem.count >= 0 && localitem.totals_seats - localitem.booked'
            )
              row(alignment='center', offset)
                cell(cols='narrow')
                  v-button(@click.prevent='remove', variant='clear')
                    v-button-element
                      icon(type='minus', color='warning')
                cell(cols='5')
                  ui-input.control-input--variant_center(
                    type='search',
                    v-digitsonly='',
                    :value='localitem.count',
                    @input='changeValue',
                    style='text-align: center',
                    name='count'
                  )
                cell(cols='narrow')
                  v-button(@click.prevent='add', variant='clear')
                    v-button-element
                      icon(type='add', color='warning')
      row(justification='between', offset)
        cell(cols='narrow', v-if='!ga') 
          v-caption(size='5', variant='secondary') {{ _("Sector") }}
          v-caption(size='5', variant='bold') {{ localitem.sector.title || localitem.sector }}
        cell(cols='narrow', v-if='!!localitem.free_seats && isLeftTickets') 
          row(offset, justification='between')
            cell(cols='narrow')
              v-caption(size='5', variant='secondary') {{ _("Tickets left") }}
            cell(cols='narrow')
              v-caption(size='5', variant='bold') {{ localitem.free_seats }}

        cell(cols='narrow', v-if='localitem.row')
          v-caption(size='5', variant='secondary') {{ _("Row") }}
          v-caption(size='5', variant='bold') {{ localitem.row }}
        cell(cols='narrow', v-if='localitem.seat')
          v-caption(size='5', variant='secondary') {{ _("Seat") }}
          v-caption(size='5', variant='bold') {{ localitem.seat.title }}

      row(offset, justification='between', space='5xs')
        //- cell(cols='12')
        //-   v-caption(size='5', variant='secondary') {{ _("Title") }}
        //-   v-caption(size='5', variant='bold') {{ localitem.title }}
        cell(cols='12')
          v-caption(size='5', variant='secondary') {{ _("Price") }}
          v-caption(size='5', variant='bold') ${{ localitem.price | toUSD }}

    //- card-panel(offset='full')
    //-   row(offset ref='flyButton')
    //-     cell(cols='12' v-if='!deletable && !disabled && !ga')
    //-       v-button(
    //-         :variant='["success", "block"]',
    //-         @click='resolve(false)',
    //-         border='whole'
    //-       )
    //-         v-button-element(size='lg')
    //-           v-caption(size='7', :variant='["uppercase", "bold"]') {{ _("Add to cart and continue") }}
    //-     cell(cols='12' v-if='!deletable && !disabled && !ga')
    //-       v-button(
    //-         :variant='["accent", "block"]'
    //-         @click='resolve(true)'
    //-         :state="[loading && 'disabled']"
    //-         border='whole'
    //-       )
    //-         v-button-element(size='lg')
    //-           v-caption(
    //-             size='7'
    //-             :variant='["uppercase", "bold"]'
    //-           ) {{ _('Add to cart and go to checkout') }}
      //- cell(cols='12')
      //-   v-button(
      //-     :variant='["clear", "block"]',
      //-     hover='danger',
      //-     border='whole',
      //-     @click='reject'
      //-   )
      //-     v-button-element(size='lg')
      //-       v-caption(
      //-         color='warning',
      //-         size='5',
      //-         :variant='["uppercase", "bold"]'
      //-       ) {{ _("decline") }}
</template>

<script>
import { removeClass, getItemFromDOM } from '@cabinet/util/'
import FlyTicket from '../../mixin/flyTicket'
import { mapActions } from 'vuex'
import { get } from 'http'
export default {
  mixins: [FlyTicket],
  props: {
    item: {
      required: true,
    },
    deletable: {
      default: true,
    },
    fn: {
      required: false,
    },
    selfAction: {
      default: false,
    },
    showId: {
      required: false,
    },
    ticket: {
      required: false,
    },
    ga: {
      default: false,
      type: Boolean,
    },
    isLeftTickets: {
      required: false,
      type: Boolean,
    },
    hasWrapper: {
      required: false, 
      default: false,
    },
    redirect: {
      type: Function,
    }
  },
  data() {
    return {
      disabled: false,
      localitem: {},
      deleted: 0,
      added: 0,
      loading: false,
    }
  },
  computed: {
    absValue() {
      return this.deleted - this.added
    },
    isBooked() {
      return (!this.localitem.free_seats || !(this.localitem.totals_seats - this.localitem.booked)) && this.ga
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val.length) {
          this.localitem = val[0]
          this.$set(this.localitem, 'count', 0)
          // this.localitem.count = val.length
        } else {
          this.localitem = val
        }
        // return this.localitem
      },
    },
  },

  
  methods: {
    ...mapActions('cartModule', ['removeItem', 'addItem']),
    _removeItem(item, count) {
      this.loading = true
      let array = count ? this.item.map(({ id }) => id) : [item.id]
      return this.removeItem({ tickets: { tickets: array }, id: this.showId })
        .then(_ => {
          if (item.seat) {
            removeClass(getItemFromDOM(item.seat.geom_id), [
              'is-reserved',
              'is-user-reserved',
            ])
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
        })
    },
    _addItem(items) {
      if (!this.hasWrapper) this.loading = true
      this.addItem({ items, id: this.item[0].show.id })
        .then(_ => {
          this.loading = false
          this.$emit('close')
        })
        .catch(err => {
          this.loading = false
        })
    },
    resolve(redirect) {
      this.disabled = true
      this.loading = true
      this.redirect(redirect)
      if (this.ga) {
        return this.fn(this.handleFlyingTicket, this.item)
      }
      return this.fn(this.handleFlyingTicket, this.ticket.itemID, this.ticket.seat, this.ticket.type)
    },
    reject() {
      this.$emit('close')
    },
    handleFlyingTicket() {
      const timeout = 1000
      this.initFlyTicket()
      setTimeout(() => {
        this.$emit('close')
      }, timeout)
    },
    remove() {
      if (this.selfAction) {
        this.removeItem({
          tickets: {
            tickets: [this.item[0].id],
          },
          id: this.item[0].show.id,
        })
      } else {
        this.deleted++
        this.localitem.count--
        this.item.count--
      }
    },
    add() {
      if (this.selfAction) {
        this._addItem({
          id: parseInt(this.item[0].show.id),
          items: [{ id: this.item[0].sector.id, type: 'sector' }],
        })
      } else {
        this.added++
        this.localitem.count++
        this.item.count++
      }
    },
    changeValue(e) {
      this.localitem.count = e
      this.item.count = e
    }
  },
}
</script>

