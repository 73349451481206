<template lang='pug' functional>
include /mixins
.control-input.control-input--variant_checkbox(
  v-bind='data.attrs' 
)
  +b.LABEL.control-checkbox
    slot
    +e.body(:class='props.variant && "control-checkbox__body--variant_" + props.variant')
      +e.mark
  
</template>

<script>
export default {
  props: {
    variant: {
      default: '',
      type: String
    }
  }
}
</script>

