import Api from '@cabinet/services'

export default {
  fetch(meta) {
    let query = Object.keys(meta).map(k => {
      if (meta[k]) {
        return `${k}=${meta[k].id > -1 ? meta[k].id : meta[k]}`
      }
    }).filter(i => i).join('&')
    return Api().get(`/events/list/?${query}`)
  },
  createEvent(data) {
    return Api().post('/events/create/', data)
  },
  getEventStatuses() {
    return Api().options('/events/create/')
  },
  updateEvent(data) {
    return Api().patch(`/events/${data.id}/update/`, data.event)
  },
  fetchOne(id) {
    return Api().get(`/events/${id}/receive/`)
  },
  deleteEvent(id) {
    return Api().delete(`/events/${id}/delete/`)
  },
  deleteEventImage(id) {
    return Api().post(`/events/${id}/image/remove/`)
  }
}
