<template lang="pug">
include /mixins  
+b.row.--offset.--space_md(v-bind="$attrs", v-on="$listeners")
  +b.cell.cols--12(v-for="(item, i) in value", :key="`line${i}`")
    +b.row.--appearance_nowrap.--alignment_baseline
      +b.cell.cols--auto
        slot(
          :value="item", 
          :index="i", 
          :input="change(i)", 
          :remove="remove(i)", 
          :add="add"
        )
      +b.cell.cols--narrow(v-if='!disableRemove')
        .form__error(styling="error")
          v-button(
            @click.prevent="remove(i)()"
            :variant='["clear"]'
            type='button'
          )
            v-button-element(size='lg')
              icon(
                type="close"
                size="lg"
                color='danger'
              )
  +b.cell.cols--12(v-if="($scopedSlots.hint || hint) || (!disableAdd && ($scopedSlots.addLabel || addLabel))")
    +b.row.--offset.--justification_between
      +b.cell.cols--auto
        slot(name="hint") {{ hint }}
      +b.cell.cols--12.--narrow-md
        slot(name="add", :add="add", :initial="initial", :value="value")
          v-button(
            type='button'
            :variant='["clear"]'
            @click="add"
          )
            v-button-element(size='lg')
              +b.caption--color_success(appearance="block")
                +b.row.--appearance_nowrap.--space_xs
                  +b.cell.cols--narrow
                    icon(type="add", size="md")
                  +b.cell.cols--auto
                    slot(name="addLabel") {{ addLabel }}
    

</template>

<script>

import { clone } from 'ramda'

export default {
  name: 'control-form-repeater',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    initial: {},
    addLabel: String,
    hint: String,
    disableRemove: Boolean,
    disableAdd: {
      default: false
    },
  },

  methods: {
    change(i) {
      return value => {
        this.value[i] = value
        this.input(this.value)
      }
    },
    remove(i) {
      return () => {
        this.input(this.value.slice(0, i).concat(this.value.slice(i + 1)))
      }
    },
    add() {
      this.input(this.value.concat([clone(this.initial)]))
    },
    input(value) {
      this.$emit('update', value)
    },
  },
}

</script>
