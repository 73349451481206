<template lang="pug">
  include /mixins
  card(variant='tour')
    row(space='none')
      slot(name='label')
      slot(name='action')
      cell(cols='12 4-lg')
        +b.aspect-ratio--cuted.--ratio_16x10.--ratio_16x9-md
          //- card-panel(variant='img-wrapper')
          +e.IMG.element(:src='image')
        slot(name='download')
      cell(cols='12 8-lg')
        card-panel(:offset='["full_xs", "full_sm-md", "full_lg-xl", "right_xs"]' variant='fullheight') 
          row(
            offset 
            fullheight='lg'
            column
            justification='between'
            space='5xs'
            nowrap
          )
            cell(cols='12')
              a(
                v-if='ticket.show' 
                :href='ticket.show.link'
              ).link
                v-caption(
                  size='5' 
                  variant='bold'
                ) {{ ticket.show ? ticket.show.title : ticket.title }}
              v-caption(
                size='5' 
                variant='bold'
                v-else
              ) {{ ticket.show ? ticket.show.title : ticket.title }}
            cell(cols='12' v-if='isHistory')
              v-caption(
                size='5'
                variant='bold'
              ) {{ ticket.show.date_at}} {{ ticket.show.time_at}}
            cell(cols='12')
              row(offset space='5xs' justification='between')
                cell  
                  row(offset space='5xs')
                    cell(cols='12')
                      v-caption(size='6' variant='secondary') {{ _('b Sector')}}
                    cell(cols='12')
                      v-caption(size='6' variant='bold') {{ ticket.sector.title || ticket.sector }}
                cell
                  row(offset space='5xs')
                    cell(cols='12')
                      v-caption(size='6' variant='secondary') {{ _('b Row/Seat')}}
                    cell(cols='12')
                      v-caption(size='6' variant='bold') {{ rowSeat }}
                cell
                  row(offset space='5xs')
                    cell(cols='12')
                      v-caption(size='6' variant='secondary') {{ _('b Price')}}
                    cell(cols='12')
                      v-caption(size='6' variant='bold') ${{ ticket.base_price || ticket.price | toUSD }}
                cell
                  row(offset space='5xs')
                    cell(cols='12')
                      v-caption(size='6' variant='secondary') {{ _('b Discount')}}
                    cell(cols='12')
                      v-caption(size='6' variant='bold') ${{ ticket.discount | toUSD }}




</template>

<script>
export default {

  computed: {
    image() {
      return (this.ticket.show && this.ticket.show.image) || this.ticket.image || '/static/images/poster-stub.png'
    },
    rowSeat() {

      if (this.ticket.row && this.ticket.seat) {
        if (this.ticket.seat.title) {
          return `${this.ticket.row} / ${this.ticket.seat.title}`
        }
        return `${this.ticket.row} / ${this.ticket.seat}`
      } 
      return '-'
      
    }
  },
  props: {
    ticket: {
      required: true
    },
    isHistory: {
      default: false,
      required: false
    }
  }
}
</script>