<template lang="pug">
  loading-wrapper(:loading='loading')
    SecurityAccordion
      template(v-slot:caption='')
        | {{ _("ps Fees:") }}
      template(v-slot:content='')
        card()
          card-panel(offset='bottom')
            row(offset)
              cell(cols='12 7-sm 12-md 7-2xl')
                row(offset)
                  cell(
                    cols='12'
                    v-for='(fee, index) in fees'
                    :key='index'
                  )
                    ProviderFee(
                      :fee='fee'
                      :index='index + 1'
                      @input='setFee(fee)'
                      @remove='removeFee($event, index)'
                    )

              cell(cols='12 5-sm 12-md 5-2xl')
                FeeForm(
                  :fee='fee'
                  :choices='choices'
                  @send='send($event)'
                )
</template>


<script>
import { ref, onMounted } from '@vue/composition-api'
import FeesService from '@cabinet/services/api/fee'
import { useResponse } from '@cabinet/composables/use-response'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'
import FeeForm from '@cabinet/components/show/FeeForm'
import ProviderFee from '@cabinet/components/settings/ProviderFee'
export default {
  components: {
    SecurityAccordion,
    FeeForm,
    ProviderFee,
  },
  setup(props, context) {
    const loading = ref(false)
    const fee = ref({})
    const fees = ref([])
    const choices = ref([])

    const fetchFees = async () => {
      loading.value = true
      try {
        const res = await useResponse(FeesService.fetch(), context.root)
        fees.value = res.data
        loading.value = false

      } catch (err) {
        console.error(err)
        loading.value = false
      }
    }

    const setFee = (_fee) => {
      fee.value = _fee
    }

    const removeFee = async (fee, index) => {
      loading.value = true
      if (fee.id) {
        await useResponse(FeesService.delete(fee.id), context.root)
      }
      fees.value.splice(index, 1)
      loading.value = false
    }


    const transform = (data) => {
      let copy = { ...data }
      Object.keys(copy).forEach(key => {
        if (copy[key].id) {
          copy[key] = copy[key].id
        }
      })
      return copy
    }
    const send = async ({fee, observer}) => {
      loading.value = true
      try {
        if (fee.id) {
          await useResponse(FeesService.update(transform(fee), fee.id), context.root, observer)
        } else {
          await useResponse(FeesService.create(transform(fee)), context.root, observer)
        }
        fetchFees()
        loading.value = false
      } catch (error) {
        loading.value = false
      }
      
    }

    const getOptions = async () => {
      try {
        let data = (await FeesService.getCreateOptions()).data
        choices.value = data.actions.POST.type.choices.map(({value, display_name}) => ({id: value, title: display_name}))
        // this.fees = data.data
      } catch (err) {
        console.error(err)
      }
    }

    onMounted(async () => {
      await getOptions()
      await fetchFees()
    })

    return {
      fees,
      fee,
      choices,
      loading,

      setFee,
      removeFee,
      fetchFees,
      send
    }

  }
}
</script>
