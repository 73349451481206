<template lang="pug">
include /mixins
validation-observer(
  ref='observer',
  v-slot='{ invalid }',
  tag='form',
  autocomplete='off'
)
  card
    card-panel
      row(offset)
        cell(cols='12')
          v-caption(size='3', color='default', variant='bold') {{ _("t Discount manipulations") }}
      card-panel
        row(offset, justification='center')
          cell(cols='12')
            +b.scrollable-wrapper--vertical.--half
              card
                card-panel(v-if='form')
                  validation-observer(
                    ref='observer',
                    v-slot='{ invalid }',
                    tag='form',
                    @submit.prevent='save',
                    autocomplete='off'
                  )
                    validation(name='non field errors')
                    validation(name='seats')
                    validation(name='sectors')
                    tickets-layout(
                      v-for='(ticket, index) in form.price_descriptor',
                      :ticket='ticket',
                      :key='index',
                      :form='form',
                      :index='index',
                      :sectors='sectors',
                      :descriptors='descriptors',
                      :observer='$refs.observer',
                      :discount='true',
                      :withdraw='true'
                    )
                      template(v-slot:discounts='')
                        cell(cols='12')
                          row(offset, alignment='center')
                            cell(cols='narrow')
                              row(alignment='center', space='md', offset)
                                cell(cols='narrow')
                                  v-radio(name='mechanics', v-slot:input='')
                                    +b.INPUT.control-radio__element(
                                      type='radio',
                                      v-model='ticket.mechanics',
                                      value='fixed'
                                    )
                                cell(cols='narrow') {{ _("d fixed") }}
                            cell(cols='narrow')
                              row(alignment='center', space='md', offset)
                                cell(cols='narrow')
                                  v-radio(name='mechanics', v-slot:input='')
                                    +b.INPUT.control-radio__element(
                                      type='radio',
                                      v-model='ticket.mechanics',
                                      value='ratio'
                                    )
                                cell(cols='narrow') {{ _("d percent") }}
                            cell(cols='narrow')
                              ui-input(
                                :placeholder='_("d Amount")',
                                type='search',
                                v-digitsonly='',
                                :val='ticket.sale_value',
                                bg='relief-3',
                                v-model='ticket.sale_value',
                                validate='required'
                              )
          cell(cols='narrow')
            v-button(@click='save', type='button', variant='success')
              cell(cols='narrow')
                v-button-element 
                  v-caption(size='4', :variant='["bold"]', color='text-1') {{ _("dd discount") }}
</template>

<script>
import { mapActions } from 'vuex'
import { useResponse } from '@cabinet/composables/use-response'
import TicketsLayout from '@cabinet/components/show/TicketsLayout'
export default {
  components: {
    TicketsLayout,
  },
  props: {
    descriptors: {
      required: true
    },
    show: {
      required: true
    },
    sectors: {
      required: true
    }
  },
  data() {
    return {
      form: {
        price_descriptor: [
          {
            mechanics: 'fixed',
            sale_value: '',
            info: [
              {
                sector: {},
                row: {},
                seats: {},
              },
            ],
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('showsModule', ['assignDiscount']),
    save() {
      this.$refs.observer.validate().then(async res => {
        if (res) {
          let object = this.form.price_descriptor.reduce((acc, desc) => {
            if (desc.seats) {
              acc.seats.push(...desc.seats)
            }
            if (desc.info.filter(s => s.sector.type === 'no_seats').length) {
              acc.sectors.push(...desc.info.map(info => info.sector.sector_descriptor_id))
            }

            return acc
          }, {
            seats: [],
            sectors: [],
          })
          if (!(object.seats.length + object.sectors.length)) {
            return
          }
          this.loading = true

          object.sale_value = this.form.price_descriptor[0].sale_value
          object.mechanics = this.form.price_descriptor[0].mechanics
          try {
            let res = await useResponse(this.assignDiscount({ data: object, id: this.show.id }), this, this.$refs.observer)
            if (res) {
              this.$emit('close')
              this.form = {
                price_descriptor: [
                  {
                    agent: '',
                    info: [
                      {
                        sector: {},
                        row: {},
                        seats: {},
                      },
                    ],
                  }
                ]
              }
            }
          } catch (err) {
            console.error(err)
          }

        }
      })
    }
  }
}
</script>