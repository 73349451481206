import CartService from '@cabinet/services/api/cart'
import { groupBy } from '@cabinet/router'

export default {
  namespaced: true,
  state: {
    cart: [],
    cartData: [],
    end: 0,

    fullCart: [],
    cartInfo: {
      updated_at: 0
    },
    timer: 0,
    currentTimerValue: 0,
  },
  getters: {
    getTime(state) {
      if (parseFloat(state.cartInfo.price)) {
        return state.cartInfo.updated_at
      // } else if (state.cart.length && state.timer) {
      //   return state.cartInfo.updated_at
      }
      // return 0
    },
    getCurrentTimer(state) {
      return state.currentTimerValue
    }
  },
  actions: {
    async addItem({ commit, dispatch }, { items, id }) {
      return CartService.postShowItem({ items, id })
        .then(res => {
          return dispatch('fetchCart', id)
        })
        .catch(err => {
          throw err
        })
    },

    async fetchMainCart({commit}) {
      let { data: { tickets, ...others }, meta: timer } = (await CartService.fetchCart())
        .data

      commit("SET_TIMER", timer)
      commit('SET_FULL_CART_ITEM', tickets)
      commit("SET_CART_INFO", others)
    },
    async fetchCart({ commit, dispatch }, id) {
      let { data: { tickets } } = (await CartService.fetchShowCart({ id }))
        .data
      // dispatch('fetchMainCart')
      commit("SET_TIMER", { timer: 15 })
      commit('SET_CART_ITEM', tickets)

    },
    async fetchFullCart({ commit }) {
      let { data: { shows, ...other }, meta: timer } = (await CartService.fetchFullCart())
        .data
      commit("SET_TIMER", timer)
      commit('SET_CART_AND_REFACTOR', shows)
      commit("SET_CART_INFO", other)
    },
    async removeItem({ commit, dispatch }, { id, tickets }) {
      try {
        await CartService.removeFromShowCart({ id, tickets })
        dispatch('fetchCart', id)
      } catch (error) {
        console.error(error)
      }
    },
    async removeMainCartItem({ commit, dispatch}, {tickets}) {
      try {
        await CartService.removeItem({ tickets })
        dispatch('fetchMainCart')
      } catch (error) {
        console.error(error)
      }
    },
    async clearCart({ commit, dispatch }) {
      let { data } = (await CartService.cleanCart()).data
      commit('CLEAR_ALL_CART', data)
      // dispatch('fetchFullCart')
      dispatch('fetchMainCart')
    }
  },
  mutations: {
    SET_TIMER(state, payload) {
      state.timer = payload.timer
    },
    SET_CURRENT_TIMET_VALUE(state, payload) {
      state.currentTimerValue = payload
    },
    SET_FULL_CART_ITEM(state, payload) {
      state.fullCart = []
      let seatTickets =
        payload.filter(t => t.seat)

      let sectorTickets =
        payload.filter(t => !t.seat)

      let groupedSectorTickets =
        groupBy(sectorTickets, t => !t.seat)
      console.log("SET_FULL_CART_ITEM:  ", sectorTickets, groupedSectorTickets)
      let values = Object.keys(groupedSectorTickets).reduce((acc, ticket) => {
        acc.push(groupedSectorTickets[ticket])
        return acc
      }, [])

      state.fullCart = [...seatTickets, ...values]
    },
    SET_CART_ITEM(state, payload) {
      state.cart = []
      let seatTickets =
        payload.filter(t => t.seat)

      let sectorTickets =
        payload.filter(t => !t.seat)

      let groupedSectorTickets =
        groupBy(sectorTickets, t => !t.seat)
      console.log("SET_CART_ITEM:  ", sectorTickets, groupedSectorTickets)
      let values = Object.keys(groupedSectorTickets).reduce((acc, ticket) => {
        acc.push(groupedSectorTickets[ticket])
        return acc
      }, [])

      state.cart = [...seatTickets, ...values]
    },
    REMOVE_ITEM({ cart }, { id, type }) {
      let index = cart.findIndex(i => {
        return type === 'seat' ? i.seat.id === id : i.sector.id === id
      })
      cart.splice(index, 1)
    },
    SET_CART_AND_REFACTOR(state, payload) {
      state.cartData = payload
    },
    ADD_ITEM({ cart }, payload) {
      cart.push(payload)
    },
    SET_CART_INFO(state, payload) {
      let s = payload.updated_at
      let a = s.split(/[^0-9]/)
      //for (i=0;i<a.length;i++) { alert(a[i]); }
      let date = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5])
      let offset = date.getTimezoneOffset() / 60
      // TODO REMOVE THIS AS SOON AS POSSIBLE
      let time = date.setHours(date.getHours() - offset)
      let then = new Date(time).getTime() + (state.timer * 60 * 1000)
      let now = new Date().getTime()
      state.cartInfo = payload
      state.cartInfo.updated_at = then - now
      state.end = then
    },
    CLEAR_ALL_CART(state, payload) {
      state.cartData = []
    }
  }
}
