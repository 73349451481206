<template lang='pug'>
  include /mixins
  validation-observer(
    ref='observer'
    v-slot='{invalid}'
    tag='form'
    autocomplete='off'
  )
    row(justification='between')
      cell(cols='12 4-md')
        file-input(
          :form-data='formData'
          :field='_("sectors")'
          name='sectors_file'
        )
      cell(cols='12 4-md')
        file-input(
          :form-data='formData'
          :field='_("seats")'
          name='seats_file'
        )
      cell(cols='12 4-md')
        file-input(
          :form-data='formData'
          :field='_("elements")'
          name='elements_file'
        )
      
     

</template>

<script>
import UiButton from '@cabinet/components/forms/blocks/UiButton'
import FileInput from '@cabinet/components/hall/FileInput'
import { mapActions } from 'vuex'
import { EventBus } from '@cabinet/components/eventbus/eventbus'
export default {
  components: {
    UiButton,
    FileInput,
  },

  props: {
    formData: {
      required: true,
    },
  },
  data() {
    return {
      models: {
        sectors: false,
        seats: false,
        elements: false,
      }
    }
  },
  watch: {
    'formData': {
      handler: 'validateSvgs',
      deep: true,
      // immediate: true
    }
  },
  methods: {
    validateSvgs(val) {
      console.log(val)
      const { elements_file, seats_file, sectors_file } = val
      const fields = [elements_file, seats_file, sectors_file]
      let valid = fields.every(i => i)
      if (valid) {
        this.$emit('validation', {valid: true})  
      } else {
        this.$emit('validation', {valid: false})
      }
    },
    ...mapActions('hallModule', ['updateHall']),
    save() {
      return this.$refs.observer.validate().then(res => {
        if (res) {
          let {sectors_file, seats_file, elements_file } = this.formData
          // seats_file = btoa(seats_file)
          // sectors_file = btoa(sectors_file)
          // elements_file = btoa(elements_file)
          let files = {
            sectors_file,
            seats_file,
            elements_file
          }
          
          // arr.push(sectors_file, seats_file, elements_file)
          let svgWrappers = [...document.getElementsByClassName('file-input__svg')]
          let svgs = svgWrappers.map(w => w.querySelector('svg')).flat()
          
          Object.keys(files).forEach((f, index) => {

            if (files[f] && files[f].includes('.svg')) {
              let file = new XMLSerializer().serializeToString(svgs[index])
              files[f] = 'data:image/svg+xml;base64,'.concat(btoa(unescape(encodeURIComponent(file))))
            }
          })
          return this.updateHall({
            id: this.$route.params.id,
            data: files,
          }).then(res => {
            EventBus.$emit('fetchHall')
            return Promise.resolve({
              valid: true,
              msg: this._('You can pass'),
            })

          })
        }
        return Promise.reject({
          valid: false,
          msg: this._('step is valid'),
        })
      })
    },
    setData(item) {
      return value => {
        return new Promise(resolve => {
          resolve(value)
        }).then(result => {
          this.formData[item] = result
        })
      }
    },
    getFile(files, model) {
      this.formData[model] = ''
      for (var i = 0; i < files.length; i++) {
        var file = files[i]
        var reader = new FileReader()
        reader.onload = e => {
          this.formData[model] = e.target.result
          // let svg = atob(e.target.result
          // .replace(/data:image\/svg\+xml;base64,/, ''))
        }
        reader.readAsDataURL(file)
      }
    },
    
  },
  created() {
    this.validateSvgs(this.formData)
  }
}
</script>
