<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(:loading='loading')
      validation-observer(
        ref='observer'
        v-slot='{invalid}'
        tag='form'
        @submit.prevent='send'
        autocomplete='on'
      )
        row(offset)
          cell(cols='12')
            card
              card-panel(offset='bottom')
                v-caption(size='2' type='bold') {{ _('Restore password') }}
              card-panel(offset='bottom')
                v-caption(size='6' type='bold') {{ _('Specify the e-mail you used at registration') }}
          cell(cols='12')
            ui-input(:placeholder="_('E-mail')"
                  type='search'
                  bg='relief-3'
                  name='email'
                  size='lg'
                  v-model.trim='formData.email'
                  validate='required|email'
                  autocomplete='on'
                  )
          cell(cols='12')
            card
              card-panel(offset='vertical')
                v-caption(size='5' type='bold') {{ _('Instructions for password recovery will be sent to the email address.') }}
          cell(cols='12')
            v-button(
              variant='success'
              type='submit'
              
            )
              v-button-element(size='lg') 
                v-caption(
                  :variant='["bold", "uppercase"]' 
                  size='6'
                ) {{ _('SEND') }}
</template>

<script>
import UserService from '@cabinet/services/api/user'
import UiInput from '@cabinet/components/forms/blocks/UiInput'
import { successHandler, errorHandler } from '@cabinet/util'
export default {
  components: {
    UiInput
  },
  data() {
    return {
      loading: false,
      formData: {
        email: '',
      }
    }
  },
  methods: {
    openModal() {
      
    },
    async send() {
      const isValid = await this.$refs.observer.validate()
      
      if (isValid) {
        this.loading = true
        UserService
          .restore(this.formData)
          .then(res => {
            this.$emit('close')
            successHandler(res.data, this)
            this.loading = false
          })
          .catch(err => {
            //(err)
            let { data: errors } = err.response
            errorHandler(errors.errors, this.$refs.observer, err.response, this)
            this.loading = false
          })
      }
    }
  }
}
</script>



