import Api from '@cabinet/services'

export default {
  fetchOneInvoices(data) {
    let query = data.query ? `&${data.query}` : ''
    return Api().get(`/invoices/list/?vendor_id=${data.id}${query}`)
  },
  updateInvoice(data) {
    return Api().patch(`/invoices/${data.id}/update/`, data)
  },
  getInvoiceQrcodes(data) {
    return Api('', {
      responseType: 'blob'
    }).get(`/invoices/${data.id}/tickets/export/?vendor_id=${data.vendor}&as=csv`, {
      
    })
  },
  sendInvoice(id) {
    return Api().get(`/invoices/${id}/send-vendor/`)
  },
  withdraw({ id, data }) {
    return Api().post(`/withdrawn/${id}/create/`, data)
  },




  getVendorShows({ id, query = ''}) {
    console.log(id)
    return Api().get(`/withdrawn/${id}/list/?${query}`)
  },

  getSummaryInfo({ id, query }) {
    return Api().get(`/expenses/${id}/show/list/?${query ? query : ''}`)
  },

  saveSummary(data) {
    return Api().post(`/expenses/remaining/process/`, data)
  },

  createExpences({ data }) {
    return Api().post(`/expenses/create/`, data)
  },

  updateExpences({ data }) {
    return Api().post(`/expenses/update/`, data)
  },

  deleteExpences({ id }) {
    return Api().delete(`/expenses/${id}/delete/`)
  },

  getExpences(id, showId) {
    return Api().get(`/expenses/list/?pagination=0&vendor=${id}&show=${showId}`)
  },
  fetchFees() {
    return Api().get(`/fee/list`)
  },
  postFee(data) {
    return Api().post(`/fee/create`, data)
  },
  deleteFee(id) {
    return Api().delete(`/fee/${id}/delete/`)
  },

  fetch(data) {
    return Api().get(`vendor/customers/`, { params: data })
  },

  fetchOptions(data) {
    return Api().options(`vendor/customers/`)
  },

  fetchVendorShowsOptions() {
    return Api().options(`/vendor/shows/`)
  },

  fetchVendorList(data) {
    return Api().get(`/vendor/list/`, {
      params: data
    })
  },

  addShow(data) {
    return Api().post(`vendor/show/process/`, data)
  },

  removeShow(data) {
    return Api().post(`vendor/show/process/`, data)
  },

  toggleVendor(data) {
    return Api().post(`vendor/process/`, data)
  },

}
