<template lang="pug">
  include /mixins
  section.section
    loading-wrapper(:loading='loading')
      card
        card-panel(offset='horizontal')
          

            
          card-panel(v-if='shows.length' offset='vertical')
            card-panel
              row(offset ref='wrapper')
                cell(cols='12')
                    row(offset)
                      cell(cols='12 6-md 4-lg 2-10-2xl'
                        v-for='(show, index) in shows'
                        :key='show.id'
                      )
                        ui-show(
                          :show='show'
                          agent
                        )
            ui-pagination(
              :from-client='true'
              :offset='pagination.offset'
              :total='pagination.total'
              :limit='pagination.limit'
              v-if='pagination.total > pagination.limit'
              @input='sendData({page: $event})'
            )
          card-panel(v-else offset='vertical')  
            notice
              template(v-slot:icon='')
                icon(
                  type='error' 
                  color='warning' 
                  size='5xl'
                )
              template(v-slot:caption='')
                v-caption(variant='bold') {{ _('List is empty')}}
       
</template>

<script>

import VendorsService from '@cabinet/services/api/vendors'

import { useResponse } from '@cabinet/composables/use-response'
import { ref, onMounted } from '@vue/composition-api'
import SecurityAccordion from '@cabinet/components/settings/SecurityAccordion'

import UiShow from '@cabinet/components/default/UiShow'
import UiPagination from '@cabinet/components/vendor/UiPagination'
export default {
  components: {
    SecurityAccordion,
    UiShow,
    UiPagination,
  },
  setup(props, context) {
    const shows = ref([])
    const loading = ref(false)
    const pagination = ref({})
    const fetchShows = async (data, page=1) => {
      loading.value = true
      try {
        const res = await useResponse(VendorsService.fetchVendorShows(data, page), context.root)
        shows.value = res.data
        loading.value = false
        pagination.value = res.meta.pagination
        context.root.$store.commit('eventsModule/SET_VENDORS', { statuses: res.meta.statuses })
      } catch (error) {
        loading.value = false
      }

    }
    const sendData = async ({ page }) => {

      await fetchShows(null, page)
      
      // let { wrapper } = this.$refs
      // let { top } = wrapper.$el.getBoundingClientRect()
      // window.scrollBy({
      //   top: top - 200,
      //   left: 0,
      //   behavior: 'smooth'
      // })
    }
    onMounted(async () => {
      await fetchShows(null, 1)
    })
    return {
      shows,
      loading,
      pagination,
      fetchShows,
      sendData,
    }
  }
}
</script>