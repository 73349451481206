<template lang="pug">
  include /mixins
  loading-wrapper(:loading='loading')
    //- validation-observer(
    //-   ref='observer'
    //-   v-slot='{invalid}'
    //-   tag='form'
    //- )

    card-panel
      card-panel(offset='bottom')
        row(offset justification='center')
          cell(cols='12 6-md')
            v-select(
              v-model.number='agent'
              :val='agent'
              :options='agents'
              :placeholder="_('Type name here')"

              validate='required'
              bg='relief-3'
              :name='"agent"'
            )
      //- pre {{ items }}
      card-panel(v-if='items.length && agent.id')
        +b.scrollable-wrapper--till-2xl.--full
          +b.TABLE.table--variant_scroll.--space_lg(style='min-width: initial')
            +e.THEAD.thead
              +e.TR.row--relief
                +e.TH.cell--variant_bold.--head 
                  row(alignment='center' space='md' nowrap)
                    cell
                      v-checkbox
                        +b.INPUT(type="checkbox"
                                :value='allSelected'
                                @input='allSelected = !allSelected;selectItems(allSelected)').control-checkbox__input
                    cell
                      v-caption(color='white'
                                size='6') {{ _('t refund') }}
                +e.TH.cell--variant_bold.--head 
                  v-caption(color='white') {{ _('t sector') }}
                +e.TH.cell--variant_bold.--head 
                  v-caption(color='white') {{ _('t row') }}
                +e.TH.cell--variant_bold.--head 
                  v-caption(color='white') {{ _('t seat') }}
            +e.TBODY.tbody
              +e.TR.row(v-for='ticket in items' :key='ticket.id')
                +e.TD.cell--bordered_left_top.--bordered_left_bottom
                  row(alignment='center' space='md' nowrap)
                    cell
                      v-checkbox
                        +b.INPUT(type="checkbox"
                                :value='ticket.id'
                                v-model='selected').control-checkbox__input

                +e.TD.cell 
                  v-caption(
                    size='6'
                    color='default'
                  ) {{ ticket.sector }}

                +e.TD.cell 
                  v-caption(
                    size='6'
                    color='default'
                  ) {{ ticket.row }}

                +e.TD.cell 
                  v-caption(
                    size='6'
                    color='default'
                  ) {{ ticket.seat }}
        card-panel
          row(justification='center')
            cell(cols='narrow')
              v-button(
                :variant='["clear", "block"]' 
                hover='danger'
                @click='refund'
                :disabled='!selected.length'
              )
                v-button-element
                  v-caption(
                    color='warning'
                    :variant='["uppercase", "bold"]'
                  ) {{ _('refund') }} {{ selected.length ? `${selected.length} tickets`: ''}}
      card-panel(v-else-if='!items.length && agent.id')
        notice
          template(v-slot:caption='')
            v-caption(variant='bold') {{ _('this agent has no tickets')}}
</template>

<script>
import VendorsService from '@cabinet/services/api/vendors'

import { ref, onMounted, watch } from '@vue/composition-api'
import { useToggleSelect } from '@cabinet/composables/use-selected'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    id: {
      required: true
    }
  },
  setup(props, context) {
    let { id } = props

    const loading = ref(false)
    const allSelected = ref(false)
    const agents = ref([])
    const items = ref([])
    const agent = ref({})
    const selected = ref([])
    const fetchAgents = async () => 
      agents.value = 
        (await VendorsService.getAll()).data.data
          .map(a => {
            const title = a.first_name ? `${a.first_name} ${a.last_name}` : a.email
            return {
              ...a,
              title: title
            }
          })

    const fetchAgentsTickets = async agent_id => {
      try {
        items.value = (await VendorsService.refundVendorList({ id, agent_id })).data.data
      } catch (err) {
        console.error(err)
      }
    }

    const selectItems = (value) => {
      selected.value = useToggleSelect(items, value)
    }

    watch(
      () => agent.value, 
      (nval, oval) => {
        if (oval && oval.id != nval.id) {
          fetchAgentsTickets(nval.id)
        }
      }
    )

    onMounted(async () => {
      await fetchAgents()
    })

    const refund = async () => {
      loading.value = true
      let res = useResponse(await VendorsService.deleteVendorTicket({ids: selected.value}), context.root)
      if (res) {
        loading.value = false
        selected.value = []
        allSelected.value = false
        items.value = (await VendorsService.refundVendorList({ id, agent_id: agent.value.id })).data.data
      } else {
        loading.value = false
        console.error(res)
      }
    }

    return {
      loading,
      allSelected,
      agents, 
      agent,
      items, 
      fetchAgents,
      fetchAgentsTickets,
      refund,
      selected,
      selectItems
    }
  }
}
</script>