<template lang="pug">
  include /mixins
  +b.modal-wrapper
    +e.close.btn.btn--flex.btn--center(@click='$emit("close")') 
      icon(type='close')
    loading-wrapper(:loading='isLoading')    
      validation-observer(
        tag='form'
        ref='observer'
        v-slot='{invalid}'
        @submit.prevent='send'
        autocomplete='off'
      )
        row(offset)
          cell(cols='12')
            v-caption(size='4') {{ _('Create vendor') }}
          validation(name='non field errors')
          cell(cols='12')
            ui-input(:placeholder="_('E-mail')"
                    type='email'
                    name='email'
                    size='lg'
                    bg='relief-3'
                    :val='formData.email'
                    v-model.trim='formData.email'
                    validate='required|email').form__control--styled
          
          cell(cols='12')
            v-button(
              type='submit'
              variant='success'
              border='whole'
            )
              cell(justification='center').cols
                v-button-element(size='lg') 
                  v-caption(size='4' variant='bold') {{ _('v save') }}

</template>

<script>
import VendorsService from '@cabinet/services/api/vendors'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  data() {
    return {
      formData: {
        email: '',
      },
      isLoading: false
    }
  },
  methods: {
    async send() {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.isLoading = true
        try {
          const res = await useResponse(VendorsService.create(this.formData), null, null)  
          console.log(res)
          this.isLoading = false
          this.$emit('close')
        } catch (error) {
          console.error(error)
          this.$emit('close')
          this.isLoading = false
        }  
      }
    },
  },
}
</script>



