import { ref, computed } from '@vue/composition-api'
import { validate } from 'vee-validate'

function validateField(field, rules) {
  return computed(async () => {
    if (field) {
      return (await validate(field, rules)).valid
    }
  }).value
}

export async function usePasswordValidation(field) {
  field = ref(field)
  const customMessage = await validateField(field.value, 'custom_password')
  const lengthMessage = await validateField(field.value, 'min:8|max:32')
  return {
    customMessage,
    lengthMessage
  }
}
