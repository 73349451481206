<template lang="pug">
  include /mixins
  +b.modal-wrapper--bg-contrast
    +e.close(@click='$emit("close")') 
      icon(type='close')
    validation-observer(
      ref='observer'
      tag='form'
      @submit.prevent='saveFees'
      autocomplete='off'
    )
      card()
        card-panel(:offset='["horizontal", "bottom"]')
          v-caption(variant='bold' size='3') {{ _('show fees') }}
        card-panel(:offset='["horizontal", "bottom"]')
          validation(name='non field errors')
        card-panel(offset='full')
          row(alignment='center' offset)
            cell(cols='narrow')
              v-caption(
                size='5'
                tag='h5'
              ) {{ _('Fees:')}}
            cell(cols='narrow')
              v-button(
                @click='addFee' 
                :variant='["clear"]'
                type='button'
              )
                template
                  icon(
                    type='add'
                    color='success'
                  )
          row(
            v-for='(fee, index) in feesData' 
            :key='index'
            offset
          )
            cell(cols='4')
              validation-provider(
                rules='required'
                name='fee descriptor price'
                v-slot='{errors}'
              )
                row(offset)
                  cell(cols='12')
                    ui-input(
                      :placeholder="_('Payment price '+(fee && fee.fee && fee.fee.type ? (fee.fee.type === 'percent' ? '%' : '$') : '')+'')"
                      :val='fee.fee.price'
                      :negative='false'
                      type='search' v-digitsonly=''
                      v-model='fee.fee.price'
                      size='lg'
                      border='whole'
                    )
                  cell(cols='12' v-if='errors[0]')
                    .form__error {{ errors[0]}}
            cell(cols='4')
              validation-provider(
                rules='required'
                name='fee descriptor fee'
                v-slot='{errors}'
              )
                row(offset)
                  cell(cols='12')
                    v-select(
                      v-model='fee.fee'
                      size='lg'
                      border='whole'
                      :val='fee.fee'
                      :full='true'
                      @input='fee.price = fee.fee.price'
                      :options='fees'
                      :placeholder="_('Fee name')"
                    )
                  cell(cols='12' v-if='errors[0]')
                    .form__error {{ errors[0]}}

            cell(cols='auto')
              row(alignment='center' space='md')
                card
                  card-panel(:offset='"vertical"')
                    cell(cols='narrow')
                      checkbox(
                        name='payment methods'
                      )
                        template(v-slot='')
                          +b.INPUT(
                            type='checkbox'
                            v-model='fee.is_show'
                            size='lg'
                            border='whole'
                            bg='relief-3'
                            :value='fee.id'
                            :name='fee.title'
                          ).control-checkbox__input
                        template(v-slot:text='')
                          v-caption(size='5') {{ _('show in hard') }}
              
            cell(cols='narrow' )
              card
                card-panel(:offset='"vertical"')
                  transition(name='fade' mode='out-in')
                    v-button(
                      @click='deleteFee(fee, index)' 
                      :variant='["clear"]'
                      type='button'
                    )
                      template
                        icon(
                          type='close'
                          color='danger'
                        )
          row(offset v-if='feesData.length')
            cell(cols='12')
              +b.control-box
                v-button(
                  variant='success'
                  type='submit'
                )
                  v-button-element(size='lg') 
                    v-caption(
                      :variant='["bold", "uppercase"]' 
                      size='6'
                    ) {{ _('save') }}
</template>

<script>
import FeesService from '@cabinet/services/api/fee'
import { useResponse } from '@cabinet/composables/use-response'
export default {
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      feesData: [],
      fees: [],
    }
  },
  methods: {
    async saveFees() {
      const isValid = this.$refs.observer.validate()

      if (isValid) {
        try {

          console.log('save')

          const data = this.feesData.map(f => {

            const data = {
              price: f.fee.price,
              is_show: f.is_show || false,
              fee: f.fee.fee || f.fee.id,
              show: this.id,
            }

            if (f.fee.id) {
              data.id = f.fee.id
            }

            console.log(f)
            return data
          })

          console.log(data)
          const res = await useResponse(FeesService.createShowFee({ items: data }), this, this.$refs.observer)
          console.log(res)
        } catch (error) {

          console.log(error)
          
        }
      }
    },
    addFee() {
      this.feesData.push({
        fee: {
          fee: {}
        },
        price: '',
      })
    },
    async deleteFee(fee, index) {
      
      console.log(fee.fee.id)

      if (fee.fee.id) {
        await FeesService.deleteFee(fee.fee.id)
      }
      this.feesData.splice(index, 1)
    },
    async getFees() {
      try {
        let data = (await FeesService.fetch()).data
        this.fees = data.data
        // this.fees = data.data
      } catch (err) {
      }
    },
    async fetchFeesList() {
      try {
        let res = (await FeesService.fetchShowFees(this.id))

        console.log(res)

        this.feesData = res.data.data.map(fee => {
          let fee_ = this.fees.find(f => f.id == fee.fee)

          // /* console.log(fee)
          console.log(fee_)
          return {
            fee: {
              ...fee_,
              ...fee
            },
            is_show: fee.is_show
          }
        })
        console.log(this.feesData)
      } catch (error) {
        console.error(error)
      }
    }
  },
  mounted() {
    this.getFees()
    this.fetchFeesList()
  }
}
</script>