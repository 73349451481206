<template lang="pug">
  include /mixins
  +b-context('table')
    ui-accordion(v-slot='{activeState, toggleActive}')
      +e.TBODY.tbody(@click='toggleActive')
        +b.TR.table__row.--type_hoverable
          +e.TD(style='width: 18%').cell.--bordered_left_top.--bordered_left_bottom
            row(offset space='xs')
              cell(cols='12')
                v-caption(size='6' :variant='["bold", "white-normal"]') {{ tour.title }}
              cell(cols='12')
                v-caption(size='6' variant='white-normal') {{ tour.start_at | humanDate('en') }} - {{ tour.finish_at | humanDate('en') }}
          +e.TD(style='width: 18%').cell
            v-caption(size='6' :variant='["bold", "white-normal"]') {{ shows.length }}
          +e.TD(style='width: 18%').cell
            v-caption(size='6' :variant='["bold", "white-normal"]') {{ vendorsCount }}
          +e.TD.cell(style='width: 4%;cursor: pointer;'  ).--bordered_right
            v-button(
              variant='clear'
              type='button'
              
            )
              v-button-element
                icon(
                  size='2xs'
                  :type='activeState ? "uparrow" : "downarrow"'
                  color='default'
                )
          +e.TD.cell
          +e.TD.cell
          +e.TD.cell
          +e.TD.cell.--bordered_right_top.--bordered_right_bottom
        +b.TR.table__row().--type_hoverable
          +e.TD.cell(colspan='8' style='padding: 0')
            ui-accordion(
              v-for='(show, index) in shows' 
              :key='show.id' 
              v-show='activeState'
              v-slot='nestedAccordion'
            )
              +b.TABLE.table--space_lg(style='table-layout: fixed' @click.prevent.stop='nestedAccordion.toggleActive')
                colgroup
                  col(style='width: 18%')
                  col(style='width: 18%')
                  col(style='width: 18%')
                  col(style='width: calc(4.2%)')
                  col(style='width: 6%')
                  col(style='width: 9%')
                  col(style='width: ')
                  col(style='width: 10%')
                +e.TR.row(style='padding: 0' )
                  +e.TD.cell(style='width: 18%').--bordered_left_top.--bordered_left_bottom
                  +e.TD.cell(style='width: 18%')
                    row(offset space='xs')
                      cell(cols='12')
                        v-caption(size='6' :variant='["bold", "white-normal"]') {{ show.title }}
                      cell(cols='12')
                        v-caption(size='6' variant='white-normal') {{ show.date_at }} {{ show.time_at }}
                  +e.TD.cell(style='width: 18%')
                    v-caption(size='6' :variant='["bold", "white-normal"]') {{ show.vendors.length }}
                  +e.TD.cell(style='width: calc(4.2%);cursor: pointer;' ).--bordered_right
                    v-button(
                      variant='clear'
                      type='button'
                      
                      v-if='show.vendors.length'
                    )
                      v-button-element
                        icon(
                          :type='nestedAccordion.activeState ? "uparrow" : "downarrow"'
                          size='2xs'
                          color='default'
                        )
                  +e.TD.cell(style='width: 6%')
                  +e.TD.cell(style='width: 9%')
                  +e.TD.cell()
                    v-caption(size='6' :variant='["white-normal", "bold"]') ${{ totalSum(show) | toUSD }}
                  +e.TD.cell.--bordered_right_top.--bordered_right_bottom
                vendor-finance-expanded(
                  :vendor='vendor'
                  v-for='vendor in show.vendors'
                  v-show='nestedAccordion.activeState'
                  :key='vendor.id'
                  :show='show.id'
                )
                
                  
                  


</template>

<script>
import VendorsService from '@cabinet/services/api/vendors'
import { useResponse } from '@cabinet/composables/use-response'
import VendorFinanceExpanded from './VendorFinanceExpanded'
export default {
  components: {
    VendorFinanceExpanded
  },
  props: {
    tour: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      shows: [],
      total: 0,
    }
  },
  computed: {
    vendorsCount() {
      return this.shows.reduce((acc, show) => acc += show.vendors.length, 0)
    }
  },
  methods: {
    async fetchShows() {
      try {
        const res = await useResponse(VendorsService.getStatisticsByShow(this.tour.id, { is_refresh: 1}), this, null)
        this.shows = res.data
      } catch (error) {
        console.error(error)
      }
      
    },
    totalSum(show) {
      return show.vendors.reduce((acc, { price, fee }) => acc += (price / 100 * fee), 0)
    },
    add(value) {
      this.total += value

    },
  },
  created() {
    this.fetchShows()
  }
}
</script>
