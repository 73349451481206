<template lang="pug">
  include /mixins
  +b.scrollable-wrapper--till-2xl.--full(style='max-height: initial')
    +b.TABLE.table--variant_scroll.--variant_stats.--space_lg(style='min-width: initial; min-height: initial')
      +e.THEAD.thead
        +e.TR.row--relief
          +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
            v-caption(color='white') {{ _('v Name') }}
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('v Company') }}
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('v Active events for invoices') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('v Invoices a/n-p') }}
          +e.TH.cell--variant_bold.--head 
            v-caption(color='white') {{ _('v Assigned events') }}
          +e.TH.cell--variant_bold.--head
            v-caption(color='white') {{ _('v Edit shows') }}
      +e.TBODY.tbody
        +e.TR.row(v-for='(vendor, index) in items')
          +e.TD.cell.--bordered_left_top.--bordered_left_bottom
            v-button(
              tag='router-link'
              :to='{name: "vendor", params: {id: vendor.id}}'
              :variant='["clear", "narrow"]' 
            )
              v-caption(
                size='6'
                color='default'
                :variant='["secondary", "bold"]'
              ) 
                row(alignment='center' space='xs' appearance='spaced') 
                  cell() 
                    v-caption(
                      v-if='vendor.first_name'
                      tag='span'
                    ) {{ vendor.first_name }} {{ vendor.last_name}}
                    v-caption(
                      v-else
                      tag='span'
                      color='brand-4'
                    ) {{ vendor.email }}
                  cell
                    v-caption(
                      tag='span'
                      color='danger'
                    ) {{vendor.is_active ? "" : "(not active)" }}
                
          +e.TD.cell
            v-caption(
              size='6'
              color='default'
            ) {{ vendor.company }}

          +e.TD.cell
            v-caption(
              size='6'
              color='default'
            ) {{ vendor.active_invoice_shows }}
          +e.TD.cell
            v-caption(
              size='6'
            ) 
              v-caption(
                tag='span' 
                variant='bold'
                color='success'
              ) {{ vendor.invoice_amount }}
              span /
              v-caption(
                tag='span' 
                variant='bold'
                color='danger'
              ) {{ vendor.invoice_amount - vendor.paid_invoice_amount}} 

          +e.TD.cell
            v-caption(
              size='6'
              color='default'
            ) {{ vendor.shows.length }}
          
          +e.TD.cell.--bordered_right_top.--bordered_right_bottom
            a(@click='openModal(vendor)').link
              row(
                offset 
                space='2xs' 
                alignment='center' 
                justification='center_till-lg'
                
              )
                cell(cols='narrow')
                  icon(
                    type='pencil' 
                    size='xs'
                  )
                cell(cols='narrow')
                  v-caption(size='6') {{ _('aa edit shows') }} 
</template>

<script>
import VendorsService from '@cabinet/services/api/vendors'
import VendorManipulations from './VendorManipulations'

export default {
  props: {
    items: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      shows: []
    }
  },
  methods: {
    async fetchShows() {
      try {
        this.shows = (await VendorsService.getAllAvailableShows({pagination: 0})).data.data.map(({ id, title}) => ({id, title}))
      } catch (error) {
        console.error(error)
      }
    },
    openModal(vendor) {
      this.$modal.show(VendorManipulations,
        
        {
          vendor: vendor.id,
          showsList: this.shows,
          shows_initial: vendor.shows 
        }, {
          height: 'auto',
          classes: ['middle-modal-wrap', 'modal', 'modal--variant_vendors'],
          adaptive: true,
          scrollable: true
        }, {
          'before-close': () => this.$emit('fetch')
        }
      )
    }
  },
  mounted() {
    this.fetchShows()
  }
}
</script>