<template lang="pug">
  include /mixins
  card-panel(
    offset='vertical' 
    style='overflow: hidden'
  )
    +b.scrollable-wrapper--till-2xl.--full
      +b.TABLE.table--variant_scroll.--variant_stats.--space_lg
        +e.THEAD.thead
          +e.TR.row--relief
            +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom(width='23%')
              v-caption(color='white') {{ _('d Tour name') }}
            +e.TH.cell--variant_bold.--head(width='23%')
              v-caption(color='white' variant='center') {{ _('d Events') }}
            +e.TH.cell--variant_bold.--head.--bordered_right(width='9%') 
              v-caption(color='white') 
            +e.TH.cell--variant_bold.--head(width='9%') 
              v-caption(color='white') {{ _('d Sold tickets (qty)') }}
            +e.TH.cell--variant_bold.--head(width='9%')
              v-caption(color='white') {{ _('d Balance') }}
            +e.TH.cell--variant_bold.--head(width='9%')
              v-caption(color='white') {{ _('d Commission') }}
            +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom(width='9%')
              v-caption(color='white') 
        ProvidersEventShows(
          v-for='event in events' 
          :event='event' 
          :key='event.id'
        )
              
</template>
<script>
import ProvidersEventShows from '@cabinet/components/providers/ProvidersEventShows'
export default {
  components: {
    ProvidersEventShows,
  },
  props: {
    events: {
      required: true
    }
  },
  data() {
    return {

    }
  },
}
</script>